import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ScrollTo() {
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // Clean up the scroll listener when the component unmounts
    return () => {
      // No need to do anything here since there's no listener
    };
  }, [navigate]); // Ensure useEffect runs on navigation changes

  return null;
}

export default ScrollTo;
