import PropTypes from "prop-types";
import { useEffect, useState } from "react";

function PasswordStrength({ password }) {
  const [strength, setStrength] = useState(0);
  const [barClass, setBarClass] = useState("");

  const pattern = [
    { value: /[A-Z]/, message: "one uppercase letter" },
    { value: /[a-z]/, message: "one lowercase letter" },
    { value: /\d/, message: "one number" },
    {
      value: /[#&!/\\*\-+=?%_]/,
      message: "one of the allowed special characters: # & ! / * - + = ? %_",
    },
    { value: /.{8,}/, message: "minimum 8 characters" },
  ];

  const acceptedStrength = pattern.length;

  useEffect(
    function () {
      setStrength(
        pattern.reduce(
          (count, { value }) =>
            value.test(password || "") ? count + 1 : count,
          0,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [password],
  );

  useEffect(
    function () {
      if (0 < strength && strength < (acceptedStrength * 2) / 3)
        setBarClass("w-1/3 bg-red-o-100");
      else if (
        strength >= (acceptedStrength * 2) / 3 &&
        strength < acceptedStrength
      )
        setBarClass("w-2/3 bg-yellow-y-500");
      else if (strength === acceptedStrength)
        setBarClass("w-full bg-green-g-primary");
      else setBarClass("");
    },
    [acceptedStrength, strength],
  );

  if (strength === 0) return null;

  return (
    <div className="flex h-2 w-full items-center bg-slate-200">
      <div className={`h-full transition-all duration-200 ${barClass}`}></div>
    </div>
  );
}

PasswordStrength.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrength;
