import Divider from "../../shared/Divider";
import { RiAddCircleFill } from "react-icons/ri";
import CollapsableCardContent from "../../shared/collapsable/CollapsableCardContent";
import Input from "../../shared/inputs/Input";
import TextArea from "../../shared/inputs/TextArea";
import { useFieldArray, useFormContext } from "react-hook-form";
import CustomDatePick from "../../shared/datepicker/CustomDatePick";
import WorkExperienceHardSkills from "./work-experiences-sections/WorkExperienceHardSkills";
import WorkExperienceSoftSkills from "./work-experiences-sections/WorkExperienceSoftSkills";
import FileInput from "../../shared/inputs/FileInput";
import ControlledDropdown from "../../shared/dropdown/ControlledDropdown";
import { EmploymentTypeArray } from "../../utils/constants";
import { useUploadCompanyLogo } from "../../hooks/useUploadCompanyLogo";
import { useState } from "react";
import Loader from "../../shared/loader/Loader";

function WorkExperienceStep() {
  const { watch, getValues, setValue, control, resetField } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  const { experiences } = getValues();

  const { uploadLogo, isPending, isSuccess } = useUploadCompanyLogo();
  const [resetPlaceholder, setResetPlaceholder] = useState(false);

  const handleUploadLogo = (e, index) => {
    const file = e.target.files[0];

    uploadLogo(file, {
      onSuccess: (response) => {
        setValue(`experiences.${index}.companyLogo`, response?.result);
      },
      onError: (err) => {
        if (err?.code === 400) {
          resetField(`experiences.${index}.companyLogo`);
          setResetPlaceholder(true);
          return;
        }
      },
    });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
        5. Tell us about your Work experience.
      </div>
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div className="mr-8 flex w-full flex-col">
              <div className="flex  items-center">
                <div className="text-black-g-300 font-primary text-xl font-bold">
                  Experiences
                </div>
                <Divider className="ml-4 flex-1" />
              </div>
              <div className="mt-4 flex w-full flex-wrap justify-between gap-4">
                {fields.map((card, index) => (
                  <CollapsableCardContent
                    key={card.id}
                    cardWrapperClassName="p-4 w-full h-fit"
                    title={"Experience " + (index + 1)}
                    maxVisibleChildren={4}
                    childrenWrapperClassName="flex flex-1 flex-wrap gap-4 py-4"
                    removeCard={() => remove(index)}
                  >
                    <Input
                      type="text"
                      id={"jobtitle"}
                      labelClassName="font-primary font-semibold text-sm text-[#060F26]"
                      placeholder="Job Title"
                      label={"Job title"}
                      controllerName={`experiences.${index}.name`}
                      rules={{ required: "required" }}
                      control={control}
                      mainDivClassname={"w-1/2"}
                      fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                      resetField={resetField}
                    />
                    <Input
                      type="text"
                      id={"company"}
                      labelClassName="font-primary font-semibold text-sm text-[#060F26]"
                      label={"Company"}
                      placeholder="Company"
                      controllerName={`experiences.${index}.companyName`}
                      rules={{ required: "required" }}
                      control={control}
                      mainDivClassname={"w-1/2"}
                      fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                      resetField={resetField}
                    />

                    <div className="flex w-full items-center gap-4">
                      <ControlledDropdown
                        controllerName={`experiences.${index}.employmentType`}
                        id={`experiences.${index}.employmentType`}
                        control={control}
                        items={EmploymentTypeArray}
                        defaultValue={experiences[index]?.employmentType}
                        labelName={"Employment type"}
                        className=" rounded-[4px] border border-black-g-50 bg-white  px-2 py-1 font-primary text-sm font-medium"
                      />
                      <div className="flex flex-1 items-center gap-2">
                        {experiences[index]?.companyLogo ? (
                          <>
                            {isPending ? (
                              <Loader />
                            ) : (
                              <img
                                src={experiences[index]?.companyLogo}
                                className="h-14 w-14  rounded-full bg-black-g-50 object-contain"
                              />
                            )}
                          </>
                        ) : null}
                        <FileInput
                          id={`companyLogo_${index}`}
                          accept="image/png, image/gif, image/jpeg"
                          label="Company Logo"
                          btnLabel={"Upload Logo"}
                          controllerName={`experiences.${index}.companyLogo`}
                          control={control}
                          className={"flex-1"}
                          resetField={resetField}
                          uploadFile={(event) => {
                            handleUploadLogo(event, index);
                          }}
                          resetPlaceholder={resetPlaceholder}
                          isUploaded={isSuccess}
                          disabled={isPending}
                        />
                      </div>
                    </div>

                    <CustomDatePick
                      controllerName={`experiences.${index}.startDate`}
                      className={"flex-1"}
                      labelName={"Start date"}
                      control={control}
                    />

                    <CustomDatePick
                      controllerName={`experiences.${index}.endDate`}
                      labelName={"End date"}
                      className={"flex-1"}
                      control={control}
                    />

                    <TextArea
                      label={"Mission"}
                      mainDivClassname="w-full m-0"
                      innerDivClassname="flex-1 p-0"
                      containerClassName="bg-white border"
                      id="description"
                      placeholder="Description ..."
                      autoComplete="off"
                      controllerName={`experiences.${index}.description`}
                      control={control}
                      resetField={resetField}
                      rows={2}
                    />
                    <TextArea
                      label={"Activités"}
                      mainDivClassname="w-full m-0"
                      innerDivClassname="flex-1 p-0"
                      containerClassName="bg-white border"
                      id="activites"
                      placeholder="Activités ..."
                      autoComplete="off"
                      controllerName={`experiences.${index}.activities`}
                      control={control}
                      resetField={resetField}
                      rows={6}
                    />
                    <div className="flex w-full flex-col gap-2">
                      <WorkExperienceHardSkills
                        getValues={getValues}
                        setValue={setValue}
                        resetField={resetField}
                        watch={watch}
                        control={control}
                        controllerName={`experiences.${index}.hardSkills`}
                      />
                      <WorkExperienceSoftSkills
                        getValues={getValues}
                        setValue={setValue}
                        resetField={resetField}
                        watch={watch}
                        control={control}
                        controllerName={`experiences.${index}.softSkills`}
                      />
                    </div>
                  </CollapsableCardContent>
                ))}

                <div
                  className="flex h-32 w-full  flex-col items-center justify-center gap-2 rounded-lg border border-black-g-50 bg-[#F7F9FB]"
                  role="button"
                  onClick={() => {
                    append({
                      name: "",
                      companyName: "",
                      startDate: "",
                      endDate: "",
                      employmentType: 0,
                      activities: "",
                      description: "",
                      mission: "",
                      companyLogo: "",
                      hardSkills: [],
                      softSkills: [],
                    });
                  }}
                >
                  <div className="font-primary text-base font-bold">
                    Add experience
                  </div>
                  <RiAddCircleFill size={25} color="#0054ff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkExperienceStep.propTypes = {};

export default WorkExperienceStep;
