import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const JOBDESCRIPTION_SEARCH_URL = REQUEST_URL.jobDescription_search;
const JOBDESCRIPTION_ID_URL = REQUEST_URL.jobDescription_id;
const JOBDESCRIPTION_RECRUITER_URL = REQUEST_URL.jobDescription_recruiter;

export async function searchJobDescription({
  page,
  size,
  keyword,
  industries,
  countryId,
  cityId,
  minSalary,
  maxSalary,
  jobDescriptionStatus,
  contractTypes,
  workLocations,
  employmentTypes,
  experienceLevels,
  leaveNotice,
  myJobs,
  skills,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + JOBDESCRIPTION_SEARCH_URL, {
    method: "POST",
    body: JSON.stringify({
      page,
      size,
      keyword,
      industries,
      countryId: Number(countryId) || 0,
      cityId: Number(cityId) || 0,
      minSalary: Number(minSalary) || 0,
      maxSalary: Number(maxSalary) || 0,
      jobDescriptionStatus: Number(jobDescriptionStatus) || 0,
      contractTypes,
      workLocations,
      employmentTypes,
      experienceLevels,
      leaveNotice: Number(leaveNotice) || 0,
      myJobs: Boolean(myJobs) || false,
      skills,
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) throw new Error("Search job descriptions failed!");
  return response.json();
}

export async function submitJobDescription({
  id,
  title,
  imageURL,
  education,
  contractType,
  workLocation,
  experienceLevel,
  employmentType,
  mainMission,
  profile,
  responsibilities,
  additionalInformation,
  softSkills,
  rate: { currencyId, rateType, min, max },
  expirationDate,
  keywords,
  industryId,
  countryId,
  isDraft,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const URL = id
    ? `${BASE_URL}${JOBDESCRIPTION_ID_URL}/${Number(id)}`
    : BASE_URL + JOBDESCRIPTION_ID_URL;

  const response = await fetch(URL, {
    method: id ? "PUT" : "POST",
    body: JSON.stringify({
      title,
      imageURL,
      education: Number(education) || 0,
      contractType: Number(contractType) || 0,
      workLocation: Number(workLocation) || 0,
      experienceLevel: Number(experienceLevel) || 0,
      employmentType: Number(employmentType) || 0,
      mainMission,
      profile,
      responsibilities,
      additionalInformation,
      softSkills,
      rate: { currencyId, rateType, min, max },
      expirationDate: expirationDate || new Date(),
      keywords,
      industryId: Number(industryId) || 0,
      countryId: Number(countryId) || 0,
      isDraft,
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error(
      `${Number(id) ? "Update" : "Submit"} job description failed`,
    );
  }

  return response;
}

export async function getJobDescriptionById({ id }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${JOBDESCRIPTION_ID_URL}/${Number(id)}`,
    {
      method: "GET",
      headers: { "Content-Type": "text/plain" },
    },
  );

  if (response.status === 404) {
    const error = new Error("Job offer not found");
    error.code = 404;
    throw error;
  }

  if (!response.ok)
    throw new Error("Cannot display job offer. Please try again later!");
  return response.json();
}

export async function removeJobDescription({ id }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${JOBDESCRIPTION_ID_URL}/${Number(id)}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (response.status === 404) {
    const error = new Error("Job offer not found");
    error.code = 404;
    throw error;
  }

  if (!response.ok)
    throw new Error("Cannot delete job offer. Please try again later!");
  return response.json();
}

export async function publishJobDescription({ id }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const URL = `${BASE_URL}${JOBDESCRIPTION_ID_URL}/${Number(id)}/publish`;

  const response = await fetch(URL, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("job publishing failed");
  }

  return response;
}

export async function getRecruiterJDescription({ page, size }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${JOBDESCRIPTION_RECRUITER_URL}?page=${page}&size=${size}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error("Fetching recruiter job descriptions failed!");
  return response.json();
}
