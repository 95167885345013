/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { HiCheck, HiMiniXCircle, HiXMark } from "react-icons/hi2";
import Loader from "../loader/Loader";

function FileInput({
  id,
  accept,
  className,
  label,
  labelClassName = "btn-blue-b-primary",
  children,
  controllerName,
  control,
  rules,
  resetField,
  resetPlaceholder,
  uploadFile,
  isUploading,
  isUploaded,
  disabled,
  btnLabel,
  placeholderClassname = "mr-2",
  onUploadFinished,
}) {
  const inputRef = useRef();

  const [placeholder, setPlaceholder] = useState("No file is selected");

  useEffect(() => {
    if (isUploaded) {
      onUploadFinished?.();
    }
  }, [isUploaded]);

  useEffect(() => {
    if (resetPlaceholder) {
      setPlaceholder("No file is selected");
    }
  }, [resetPlaceholder]);

  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      defaultValue={[]}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, isDirty, error },
      }) => {
        return (
          <div className={className}>
            {label ? (
              <div className="mb-2 font-primary font-semibold text-[#060F26]">
                {label}
              </div>
            ) : null}
            <div
              className={`overflow-hidden rounded border ${invalid ? "border-red-600" : "border-slate-300"}`}
            >
              <div
                className={`relative ${placeholder ? "-m-1" : ""} flex h-full items-center`}
              >
                <div className={`${placeholderClassname}`}>
                  <label
                    htmlFor={id}
                    className={`${labelClassName} flex h-full`}
                  >
                    {isUploading ? (
                      <div className="flex items-center">
                        <span className="text-sm">UPLOADING...</span>
                        <Loader color="#ffffff" />
                      </div>
                    ) : (
                      btnLabel
                    )}
                  </label>
                </div>

                <input
                  ref={inputRef}
                  type="file"
                  id={id}
                  accept={accept}
                  hidden
                  onChange={(e) => {
                    onChange(e);
                    uploadFile?.(e);
                    const selectedFile = e.target.files[0];
                    const acceptedTypes = accept
                      .split(",")
                      .map((type) => type.trim());
                    if (
                      selectedFile &&
                      acceptedTypes.includes(selectedFile.type)
                    ) {
                      setPlaceholder(selectedFile.name);
                    }
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                />

                {children}

                {placeholder && (
                  <div className="flex-1 p-1">
                    <div className="flex items-center space-x-2">
                      {isDirty && isUploaded && value && (
                        <HiCheck className="h-5 w-5 text-green-600" />
                      )}
                      <span
                        className={`text-sm ${isUploaded && value ? "w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-green-600" : "text-slate-600"}`}
                      >
                        {placeholder}
                      </span>
                    </div>
                  </div>
                )}

                {(isDirty || invalid) && value && (
                  <div
                    className={`${placeholder ? "" : "absolute right-0 top-0"} p-1`}
                  >
                    <div
                      className="cursor-pointer overflow-hidden p-0.5 transition-all duration-200"
                      onClick={() => {
                        resetField?.(controllerName || id);
                        setPlaceholder("No file is selected");
                      }}
                    >
                      {invalid ? (
                        <HiMiniXCircle className="h-5 w-5 text-red-600" />
                      ) : (
                        <HiXMark className="h-5 w-5 text-slate-500" />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {invalid && error?.message && (
              <p className="mt-2 text-sm text-red-o-primary">
                {error?.message}
              </p>
            )}
          </div>
        );
      }}
    />
  );
}

FileInput.propTypes = {
  id: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  children: PropTypes.node,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  resetField: PropTypes.func,
  uploadFile: PropTypes.func,
  isUploading: PropTypes.bool,
  isUploaded: PropTypes.bool,
  disabled: PropTypes.bool,
  btnLabel: PropTypes.string,
  placeholderClassname: PropTypes.string,
  onUploadFinished: PropTypes.func,
  resetPlaceholder: PropTypes.bool,
};

export default FileInput;
