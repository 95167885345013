import PropTypes from "prop-types";
import { HiArrowUpRight } from "react-icons/hi2";
import { NavLink, useLocation } from "react-router-dom";

const USER_AUTH_MENU = {
  recruiter: [
    {
      name: "Log In",
      url: "/login",
      state: { role: "recruiter" },
    },
    {
      name: (
        <div className="flex items-center space-x-2">
          <div>Start a free trial</div>
          <HiArrowUpRight className="h-3.5 w-3.5 stroke-2" />
        </div>
      ),
      className: "btn-blue-b-primary",
      url: "signup",
      state: { role: "recruiter" },
    },
  ],

  candidate: [
    {
      name: "Log In",
      url: "/login",
      state: { role: "candidate" },
    },
    {
      name: "Discover Opportunities",
      className: "btn-blue-b-primary",
      url: "signup",
      state: { role: "candidate" },
    },
  ],
};

function UserAuthMenu({ className }) {
  const location = useLocation();

  const currentRole = location?.state?.role || "recruiter";

  const linkClassName =
    "hover:text-blue-b-primary block p-2 transition-all duration-300";
  const activeLinkClassName = "text-blue-b-primary";

  return (
    <div className={className}>
      <ul className="-m-2.5 flex items-center font-primary font-medium">
        {USER_AUTH_MENU?.[currentRole]?.map((item) => (
          <li key={item?.name} className="p-2.5">
            <NavLink
              className={({ isActive }) => {
                return `${linkClassName} ${isActive ? activeLinkClassName : ""}`;
              }}
              to={item?.url}
              state={item?.state}
            >
              <div className={item?.className}>{item?.name}</div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

UserAuthMenu.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.array,
};

export default UserAuthMenu;
