import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { HiMinus } from "react-icons/hi2";
import Input from "../../shared/inputs/Input";
import TextArea from "../../shared/inputs/TextArea";
import SelectBox from "../../shared/inputs/SelectBox";
import { EmploymentTypeArray } from "../../utils/constants";

function CandidateExperienceForm({ index, className, onRemove }) {
  const { control, resetField } = useFormContext();

  return (
    <div className={className}>
      <div className="-m-4 flex flex-wrap">
        <div className="flex w-full p-4">
          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2"
            onClick={onRemove}
          >
            <HiMinus className="h-6 w-6" />
            <div>Remove</div>
          </button>
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Start date"
            type="date"
            id={`experiences.${index}.startDate`}
            placeholder="Start date"
            controllerName={`experiences.${index}.startDate`}
            control={control}
            rules={{
              required: "Please enter the start date",
              valueAsDate: true,
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="End date"
            type="date"
            id={`experiences.${index}.endDate`}
            placeholder="End date"
            controllerName={`experiences.${index}.endDate`}
            control={control}
            rules={{
              required: "Please enter the end date",
              valueAsDate: true,
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Employer/company"
            type="text"
            id={`experiences.${index}.companyName`}
            placeholder="Employer or organization"
            controllerName={`experiences.${index}.companyName`}
            control={control}
            rules={{
              required: "Please enter the name of the employer",
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Company logo"
            type="text"
            id={`experiences.${index}.companyLogo`}
            placeholder="companyLogo"
            controllerName={`experiences.${index}.companyLogo`}
            control={control}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Position"
            type="text"
            id={`experiences.${index}.name`}
            placeholder="Position or job title"
            controllerName={`experiences.${index}.name`}
            control={control}
            rules={{
              required: "Please enter the position",
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <SelectBox
            label="Employment type"
            id={`experiences.${index}.employmentType`}
            placeholder="Employment type"
            controllerName={`experiences.${index}.employmentType`}
            control={control}
            resetField={resetField}
            rules={{ required: "required field" }}
            options={{
              data: EmploymentTypeArray,
              key: "id",
              value: "id",
              name: "name",
            }}
          />
        </div>

        <div className="w-full p-4">
          <TextArea
            label="Mission"
            id={`experiences.${index}.description`}
            placeholder="Mission / description"
            controllerName={`experiences.${index}.description`}
            control={control}
            resetField={resetField}
          />
        </div>
      </div>
    </div>
  );
}

CandidateExperienceForm.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  onRemove: PropTypes.func,
};

export default CandidateExperienceForm;
