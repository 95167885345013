import React from "react";

const MessageHeader = ({ currentCondidate, defaultProfilePic }) => {
  return (
    <div className="h-15 w-full rounded-xl rounded-bl-none rounded-br-none p-1 shadow-md">
      <div className="flex items-center p-2 align-middle">
        <div className="mr-1 rounded-full p-2 md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </div>

        <div className="flex flex-grow items-center p-2">
          <img
            src={currentCondidate?.profilePic || defaultProfilePic}
            alt={`${currentCondidate?.firstName} ${currentCondidate?.lastName}`}
            className="mr-2 h-10 w-10 rounded-full object-cover"
          />
          <div className="text-md font-semibold">{`${currentCondidate?.firstName} ${currentCondidate?.lastName}`}</div>
        </div>

        <div className="cursor-pointer rounded-full p-2 text-white hover:bg-purple-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MessageHeader;
