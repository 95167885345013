import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { submitApplication as submitApplicationApi } from "../services/apiApplication";

export function useSubmitApplication() {
  const {
    mutate: submitApplication,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ jobDescriptionId, resumeId, status, pdfResume, message }) =>
      submitApplicationApi({
        jobDescriptionId,
        resumeId,
        status,
        pdfResume,
        message,
      }),
    onSuccess: () => {
      toast.success("Your application has been submitted successfully");
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { submitApplication, isPending, isSuccess };
}
