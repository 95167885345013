import { useQuery } from "@tanstack/react-query";
import { getMyCompnayProfile } from "../services/apiRecruiter";

export function useGetMyCompanyProfile() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["my_company_profile"],
    queryFn: getMyCompnayProfile,
    retry: false,
  });

  return { isLoading, data, error };
}
