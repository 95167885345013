import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const CANDIDATE_SEARCH_URL = REQUEST_URL.candidate_search;

export async function searchCandidate({
  keywords,
  category,
  experienceLevels,
  workLocations,
  employmentTypes,
  leaveNotices,
  minSalary,
  maxSalary,
  page,
  size,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + CANDIDATE_SEARCH_URL, {
    method: "POST",
    body: JSON.stringify({
      keywords,
      category,
      experienceLevels,
      workLocations,
      employmentTypes,
      leaveNotices,
      minSalary: Number(minSalary) || 0,
      maxSalary: Number(maxSalary) || 0,
      page,
      size,
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) throw new Error("Search candidates failed!");
  return response.json();
}

export async function searchTalentsTax() {
  const response = await fetch(`/data/talentsTax.json`);
  if (!response.ok) {
    throw new Error("Loading taxonomies failed");
  }
  return response.json();
}
