import { useLocation } from "react-router-dom";
import LogoIcon from "../shared/logo/LogoIcon";
import GoBack from "../shared/GoBack";
import ForgotPasswordForm from "../components/authentication/ForgotPasswordForm";

function ForgotPassword() {
  const location = useLocation();

  return (
    <section>
      <div className="page-px bg-[#F7F9FB] py-16">
        <div className="m-auto max-w-xl bg-white p-8 shadow-md shadow-slate-200">
          <div className="mb-8 flex flex-col text-center">
            <LogoIcon size={18} className="mx-auto" />

            <h1 className="mt-6 text-[2rem] font-medium">
              Recover your password
            </h1>

            {location?.state?.source && (
              <GoBack className="m-auto mt-6 text-sm" />
            )}
          </div>

          <ForgotPasswordForm />
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
