import PropTypes from "prop-types";

function EmojiHappy({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M16 0C7.17733 0 0 7.17733 0 16C0 24.8227 7.17733 32 16 32C24.8227 32 32 24.8227 32 16C32 7.17733 24.8227 0 16 0ZM16 30.6667C7.91333 30.6667 1.33333 24.0867 1.33333 16C1.33333 7.91333 7.91333 1.33333 16 1.33333C24.0867 1.33333 30.6667 7.91333 30.6667 16C30.6667 24.0867 24.0867 30.6667 16 30.6667Z" />
      <path d="M23.157 18.9833C23.1196 18.9939 19.405 19.9993 15.993 19.9993C12.581 19.9993 8.87963 18.9939 8.8423 18.9833L7.6623 18.6593L8.0303 19.8259C8.1183 20.1046 10.2516 26.6646 15.985 26.6646H16.001C21.733 26.6646 23.8796 20.1059 23.9676 19.8273L24.3383 18.6593L23.157 18.9833ZM16.001 25.3326H15.985C12.565 25.3326 10.6143 22.3019 9.78363 20.5819C11.2196 20.8953 13.6396 21.3326 15.993 21.3326C18.3463 21.3326 20.773 20.8953 22.2116 20.5806C21.3783 22.3019 19.421 25.3326 16.001 25.3326Z" />
      <path d="M24.3549 16.9455L22.1736 15.3601L19.9922 16.9455L18.1709 15.6228L19.0042 13.0588L16.8216 11.4735L17.5162 9.33348H20.2136L21.0469 6.76815H23.2976L24.1309 9.33348H26.8269L27.5216 11.4735L25.3402 13.0588L26.1736 15.6228L24.3536 16.9455H24.3549ZM19.7389 15.1135L19.9922 15.2975L22.1736 13.7121L24.3549 15.2975L24.6069 15.1135L23.7736 12.5495L25.9549 10.9641L25.8589 10.6668H23.1629L22.3296 8.10148H22.0176L21.1842 10.6668H18.4869L18.3909 10.9641L20.5736 12.5495L19.7402 15.1135H19.7389Z" />
      <path d="M11.9174 16.9455L9.73607 15.3601L7.55474 16.9455L5.73341 15.6228L6.56674 13.0588L4.38407 11.4735L5.07874 9.33348H7.77607L8.60941 6.76815H10.8601L11.6934 9.33348H14.3894L15.0841 11.4735L12.9027 13.0588L13.7361 15.6228L11.9174 16.9455ZM7.30141 15.1135L7.55474 15.2975L9.73607 13.7121L11.9174 15.2975L12.1694 15.1135L11.3361 12.5495L13.5174 10.9641L13.4214 10.6668H10.7254L9.89207 8.10148H9.58007L8.74674 10.6668H6.04941L5.95341 10.9641L8.13607 12.5495L7.30274 15.1135H7.30141Z" />
    </svg>
  );
}

EmojiHappy.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EmojiHappy;
