import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useForm } from "react-hook-form";
import Container from "../components/application/dnd/Container";
import Item from "../components/application/dnd/Item";
import Input from "../shared/inputs/Input";
import SelectBox from "../shared/inputs/SelectBox";
import { useApplicationSearch } from "../hooks/useApplicationSearch";
import Loader from "../shared/loader/Loader";
import { useGetRecruiterJDescription } from "../hooks/useGetRecruiterJDescription";

function RecruitmentBoard() {
  const [jobDescriptionId, setJobDescriptionId] = useState(0);

  const {
    isLoading: isLoadingRecruiterJDescriptions,
    data: recruiterJDescriptions,
  } = useGetRecruiterJDescription({ page: 1, size: 20 });

  const { isLoading: isLoadingApplicationSearch, data: applicationSearchData } =
    useApplicationSearch({ jobDescriptionId, size: 99 });

  const [containers, setContainers] = useState([]);
  const [newApplicants, setNewApplicants] = useState([]);

  useEffect(
    function () {
      if (
        !isLoadingApplicationSearch &&
        applicationSearchData?.results?.length > 0
      ) {
        const idMap = {};

        setNewApplicants(
          applicationSearchData?.results /* 
            ?.filter((applicant) => applicant?.status === 1)
            ?.filter((applicant) => {
              if (idMap[applicant?.resume?.candidate?.id]) return false;
              else {
                idMap[applicant?.resume?.candidate?.id] = true;
                return true;
              }
            }), */,
        );
      }
    },
    [isLoadingApplicationSearch, applicationSearchData],
  );

  useEffect(
    function () {
      if (jobDescriptionId > 0 && newApplicants?.length > 0) {
        setContainers([
          {
            id: `container-${uuidv4()}`,
            title: "Shortlisted Candidates",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 1)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Interview",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 2)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Assessment",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 3)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Client interview",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 4)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Offer",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 5)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Integration",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 6)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
          {
            id: `container-${uuidv4()}`,
            title: "Archived",
            items: newApplicants
              ?.filter((applicant) => applicant?.status == 7)
              .map((applicant) => ({
                id: `item-${uuidv4()}`,
                data: applicant,
              })),
          },
        ]);
      }
    },
    [newApplicants, jobDescriptionId],
  );

  const [activeId, setActiveId] = useState(null);

  const onAddContainer = (containerName, { onSuccess } = {}) => {
    if (!containerName) return;
    const id = `container-${uuidv4()}`;
    setContainers([
      ...containers,
      {
        id,
        title: containerName,
        items: [],
      },
    ]);

    if (onSuccess) onSuccess?.();
  };

  // Find the value of the items
  function findValueOfItems(id, type) {
    if (type === "container") {
      return containers.find((item) => item.id === id);
    }
    if (type === "item") {
      return containers.find((container) =>
        container.items.find((item) => item.id === id),
      );
    }
  }

  const findContainerTitle = (id) => {
    const container = findValueOfItems(id, "container");
    if (!container) return "";
    return container.title;
  };

  const findItemData = (id) => {
    const container = findValueOfItems(id, "item");
    if (!container) return "";
    const item = container.items.find((item) => item.id === id);
    if (!item) return "";
    return item.data;
  };

  const findContainerItems = (id) => {
    const container = findValueOfItems(id, "container");
    if (!container) return [];
    return container.items;
  };

  // DND Handlers
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;
    setActiveId(id);
  }

  const handleDragMove = (event) => {
    const { active, over } = event;

    // Handle Items Sorting
    if (
      active.id.toString().includes("item") &&
      over?.id.toString().includes("item") &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the active container and over container
      const activeContainer = findValueOfItems(active.id, "item");
      const overContainer = findValueOfItems(over.id, "item");

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) return;

      // Find the index of the active and over container
      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );

      // Find the index of the active and over item
      const activeitemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );
      const overitemIndex = overContainer.items.findIndex(
        (item) => item.id === over.id,
      );
      // In the same container
      if (activeContainerIndex === overContainerIndex) {
        let newItems = [...containers];
        newItems[activeContainerIndex].items = arrayMove(
          newItems[activeContainerIndex].items,
          activeitemIndex,
          overitemIndex,
        );

        setContainers(newItems);
      } else {
        // In different containers
        let newItems = [...containers];
        const [removeditem] = newItems[activeContainerIndex].items.splice(
          activeitemIndex,
          1,
        );
        newItems[overContainerIndex].items.splice(
          overitemIndex,
          0,
          removeditem,
        );
        setContainers(newItems);
      }
    }

    // Handling Item Drop Into a Container
    if (
      active.id.toString().includes("item") &&
      over?.id.toString().includes("container") &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the active and over container
      const activeContainer = findValueOfItems(active.id, "item");
      const overContainer = findValueOfItems(over.id, "container");

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) return;

      // Find the index of the active and over container
      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );

      // Find the index of the active and over item
      const activeitemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );

      // Remove the active item from the active container and add it to the over container
      let newItems = [...containers];
      const [removeditem] = newItems[activeContainerIndex].items.splice(
        activeitemIndex,
        1,
      );
      newItems[overContainerIndex].items.push(removeditem);
      setContainers(newItems);
    }
  };

  // This is the function that handles the sorting of the containers and items when the user is done dragging.
  function handleDragEnd(event) {
    const { active, over } = event;

    // Handling Container Sorting
    if (
      active.id.toString().includes("container") &&
      over?.id.toString().includes("container") &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the index of the active and over container
      const activeContainerIndex = containers.findIndex(
        (container) => container.id === active.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === over.id,
      );
      // Swap the active and over container
      let newItems = [...containers];
      newItems = arrayMove(newItems, activeContainerIndex, overContainerIndex);
      setContainers(newItems);
    }

    // Handling item Sorting
    if (
      active.id.toString().includes("item") &&
      over?.id.toString().includes("item") &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the active and over container
      const activeContainer = findValueOfItems(active.id, "item");
      const overContainer = findValueOfItems(over.id, "item");

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) return;
      // Find the index of the active and over container
      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );
      // Find the index of the active and over item
      const activeitemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );
      const overitemIndex = overContainer.items.findIndex(
        (item) => item.id === over.id,
      );

      // In the same container
      if (activeContainerIndex === overContainerIndex) {
        let newItems = [...containers];
        newItems[activeContainerIndex].items = arrayMove(
          newItems[activeContainerIndex].items,
          activeitemIndex,
          overitemIndex,
        );
        setContainers(newItems);
      } else {
        // In different containers
        let newItems = [...containers];
        const [removeditem] = newItems[activeContainerIndex].items.splice(
          activeitemIndex,
          1,
        );
        newItems[overContainerIndex].items.splice(
          overitemIndex,
          0,
          removeditem,
        );
        setContainers(newItems);
      }
    }
    // Handling item dropping into Container
    if (
      active.id.toString().includes("item") &&
      over?.id.toString().includes("container") &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the active and over container
      const activeContainer = findValueOfItems(active.id, "item");
      const overContainer = findValueOfItems(over.id, "container");

      // If the active or over container is not found, return
      if (!activeContainer || !overContainer) return;
      // Find the index of the active and over container
      const activeContainerIndex = containers.findIndex(
        (container) => container.id === activeContainer.id,
      );
      const overContainerIndex = containers.findIndex(
        (container) => container.id === overContainer.id,
      );
      // Find the index of the active and over item
      const activeitemIndex = activeContainer.items.findIndex(
        (item) => item.id === active.id,
      );

      let newItems = [...containers];
      const [removeditem] = newItems[activeContainerIndex].items.splice(
        activeitemIndex,
        1,
      );
      newItems[overContainerIndex].items.push(removeditem);
      setContainers(newItems);
    }
    setActiveId(null);
  }

  const { control, resetField } = useForm();

  return (
    <div className="page-px py-8">
      <div className="flex items-center">
        <h1 className="text-[2.5rem] font-medium">My recruitment board </h1>
      </div>

      {isLoadingApplicationSearch ? (
        <Loader />
      ) : (
        <>
          <div className="my-16 flex">
            <div className="flex">
              <SelectBox
                id={"jobs"}
                placeholder={"Select job"}
                control={control}
                resetField={resetField}
                isLoading={isLoadingRecruiterJDescriptions}
                options={{
                  data: recruiterJDescriptions?.results,
                  key: "id",
                  value: "id",
                  name: "title",
                }}
                containerBaseClassName="rounded-r-md border-2 h-full"
                containerClassName="bg-white border-black-g-50"
                onClick={(value) => {
                  setJobDescriptionId(value);
                }}
              />
            </div>

            <div className="ml-auto flex">
              <Input
                id={"keyword"}
                placeholder={"Simple or Boolean search"}
                control={control}
                resetField={resetField}
                containerBaseClassName="rounded-l-md border-2 h-full"
                containerClassName="bg-[#F7F9FB] border-black-g-50"
              />

              <SelectBox
                id={"filter"}
                placeholder={"Most recent"}
                control={control}
                resetField={resetField}
                options={{
                  data: [
                    { id: 1, name: "Most recent" },
                    { id: 2, name: "itemlist1" },
                    { id: 3, name: "itemlist2" },
                  ],
                  key: "id",
                  value: "id",
                  name: "name",
                }}
                containerBaseClassName="border-2 h-full"
                containerClassName="bg-white border-black-g-50"
              />
            </div>
          </div>

          <div className="my-16">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
            >
              <div className="h-full w-full overflow-x-auto overflow-y-hidden transition-all duration-500">
                <div className="-m-2 flex">
                  <SortableContext
                    items={containers?.map((container) => container?.id)}
                  >
                    {containers?.map((container) => (
                      <Container
                        key={container?.id}
                        id={container?.id}
                        title={container?.title}
                        className="shrink-0 basis-1/4 p-2"
                      >
                        <SortableContext
                          items={container?.items?.map((i) => i?.id)}
                        >
                          {container?.items?.map((item) => (
                            <Item
                              key={item?.id}
                              id={item?.id}
                              data={item?.data}
                            />
                          ))}
                        </SortableContext>
                      </Container>
                    ))}
                  </SortableContext>

                  <DragOverlay>
                    {activeId && activeId.toString().includes("container") && (
                      <Container
                        id={activeId}
                        title={findContainerTitle(activeId)}
                      >
                        {findContainerItems(activeId)?.map((i) => (
                          <Item key={i?.id} id={i?.id} data={i?.data} />
                        ))}
                      </Container>
                    )}

                    {activeId && activeId.toString().includes("item") && (
                      <Item id={activeId} data={findItemData(activeId)} />
                    )}
                  </DragOverlay>

                  {/* <div className="w-1/4 p-2">
                  <Modal>
                    <Modal.Open windowId={"add_container"}>
                      <button className="btn-blue-b-50">Add new list</button>
                    </Modal.Open>

                    <Modal.Window id={"add_container"} className="min-w-96 p-8">
                      <AddContainer onAddContainer={onAddContainer} />
                    </Modal.Window>
                  </Modal>
                </div> */}
                </div>
              </div>
            </DndContext>
          </div>
        </>
      )}
    </div>
  );
}

export default RecruitmentBoard;
