import PropTypes from "prop-types";

function CategoryIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.187 2H17.287C15.107 2 13.957 3.15 13.957 5.33V7.23C13.957 9.41 15.107 10.56 17.287 10.56H19.187C21.367 10.56 22.517 9.41 22.517 7.23V5.33C22.517 3.15 21.367 2 19.187 2Z" />
      <path d="M7.7566 13.4297H5.8566C3.6666 13.4297 2.5166 14.5797 2.5166 16.7597V18.6597C2.5166 20.8497 3.6666 21.9997 5.8466 21.9997H7.7466C9.9266 21.9997 11.0766 20.8497 11.0766 18.6697V16.7697C11.0866 14.5797 9.9366 13.4297 7.7566 13.4297Z" />
      <path d="M6.8066 10.58C9.1759 10.58 11.0966 8.6593 11.0966 6.29C11.0966 3.9207 9.1759 2 6.8066 2C4.4373 2 2.5166 3.9207 2.5166 6.29C2.5166 8.6593 4.4373 10.58 6.8066 10.58Z" />
      <path d="M18.2265 21.9999C20.5958 21.9999 22.5165 20.0792 22.5165 17.7099C22.5165 15.3406 20.5958 13.4199 18.2265 13.4199C15.8572 13.4199 13.9365 15.3406 13.9365 17.7099C13.9365 20.0792 15.8572 21.9999 18.2265 21.9999Z" />
    </svg>
  );
}

CategoryIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default CategoryIcon;
