import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getRecruiterJDescription } from "../services/apiJobDescription";

export function useGetRecruiterJDescription({ queryString, size }) {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const page = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));

  const { isLoading, data, error } = useQuery({
    queryKey: ["recruiterJDescription", page, size],
    queryFn: () =>
      getRecruiterJDescription({
        page,
        size,
      }),
    retry: false,
  });

  //PRE-FETCHING
  const pageCount = Math.ceil(data?.totalCount / size);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["recruiterJDescription", page + 1, size],
      queryFn: () =>
        getRecruiterJDescription({
          page: page + 1,
          size,
        }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["recruiterJDescription", page - 1, size],
      queryFn: () =>
        getRecruiterJDescription({
          page: page - 1,
          size,
        }),
    });

  return { isLoading, data, error };
}
