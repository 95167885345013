import PropTypes from "prop-types";

function EditIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      fill="none"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M30.94 8.39985L11.7833 28.6765C11.06 29.4465 10.36 30.9632 10.22 32.0132L9.35665 39.5732C9.05331 42.3032 11.0133 44.1699 13.72 43.7032L21.2333 42.4199C22.2833 42.2332 23.7533 41.4632 24.4766 40.6699L43.6333 20.3932C46.9466 16.8932 48.44 12.9032 43.2833 8.02652C38.15 3.19652 34.2533 4.89985 30.94 8.39985Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7432 11.7832C28.7465 18.2232 33.9732 23.1465 40.4598 23.7999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 51.333H49" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

EditIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EditIcon;
