import { useQuery } from "@tanstack/react-query";
import { getIndustries } from "../services/apiInformation";

export function useGetIndustries() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["industries"],
    queryFn: getIndustries,
    retry: false,
  });

  return { isLoading, data, error };
}
