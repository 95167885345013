import PropTypes from "prop-types";
import { useGetCandidateResumeById } from "../../hooks/useGetUserResumeById";
import {
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineCurrencyDollar,
  HiOutlineMapPin,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2";
import {
  LeaveNoticeEnum,
  QualificationTypeEnum,
  RATETYPE_ENUM,
} from "../../utils/constants";
import { Link } from "react-router-dom";
import { Radar } from "react-chartjs-2";
import LoaderFullPage from "./../../shared/loader/LoaderFullPage";

function CandidateModalPreview({ previewId }) {
  const { data, isPending } = useGetCandidateResumeById({
    previewId,
  });

  return (
    <>
      {isPending ? (
        <LoaderFullPage />
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex flex-wrap">
            <div className="h-48 w-48 overflow-hidden rounded-full">
              <img
                className="object-cover object-center"
                src={data?.profilePic || "https://placehold.co/200x200"}
              />
            </div>
            <div className="mx-7 flex flex-1 flex-col flex-wrap">
              {data?.firstName && (
                <div className="mb-2 font-primary text-xl font-black text-black-g-primary">
                  {data?.firstName + " " + data?.lastName}
                </div>
              )}
              {data?.jobTitle && (
                <div className="mb-2 font-primary text-xl font-normal text-black-g-primary">
                  {data?.jobTitle}
                </div>
              )}
              {data?.contactInfo?.address?.country?.name && (
                <div className="mb-2 flex items-center space-x-1 font-primary text-base font-normal text-[#A3A3A3]">
                  <HiOutlineMapPin className="h-5 w-5" />
                  {data?.contactInfo?.address?.city?.name && (
                    <span>{data?.contactInfo?.address?.city?.name}</span>
                  )}
                  {data?.contactInfo?.address?.city?.name &&
                    data?.contactInfo?.address?.country?.name && (
                      <span>, </span>
                    )}
                  {data?.contactInfo?.address?.country?.name && (
                    <span>{data?.contactInfo?.address?.country?.name}</span>
                  )}
                </div>
              )}
              <div className="mb-2 flex items-center space-x-1">
                <div className="flex items-center  space-x-1 font-primary text-base font-normal text-[#A3A3A3]">
                  <HiOutlineBriefcase className="h-5 w-5" />
                  <span>
                    {`${data?.yearsOfExperience} ${data?.yearsOfExperience <= 1 ? "year" : "years"}`}
                  </span>
                </div>

                {data?.rate?.max && (
                  <div className="flex items-center space-x-1 font-primary text-base font-normal text-[#A3A3A3]">
                    <HiOutlineCurrencyDollar className="h-5 w-5" />
                    <span>
                      {data?.rate?.currency?.symbol === "$"
                        ? `$${data?.rate?.max}`
                        : `${data?.rate?.max}${data?.rate?.currency?.symbol}`}{" "}
                      {RATETYPE_ENUM[data?.rate?.type]}
                    </span>
                  </div>
                )}
              </div>

              <div className="mb-2 flex  items-center space-x-1">
                {data?.educationLevel > 0 && (
                  <div className="flex items-center  space-x-1 font-primary text-base font-normal text-[#A3A3A3]">
                    <HiOutlineAcademicCap className="h-5 w-5" />
                    <span>{QualificationTypeEnum[data?.educationLevel]}</span>
                  </div>
                )}

                {data?.disponibility > 0 && (
                  <div className="flex items-center  space-x-1 font-primary text-base font-normal text-[#A3A3A3]">
                    <HiOutlineSquare3Stack3D className="h-5 w-5" />
                    <span>{LeaveNoticeEnum[data?.disponibility]}</span>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap">
                {data?.hardSkills?.map((skill) => (
                  <div key={skill?.id} className="p-1">
                    <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                      {skill?.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Link
              to={`/recruiter/candidate/${data?.id}`}
              className="btn-blue-b-primary h-fit px-8 py-2  font-primary text-base font-medium"
            >
              Details
            </Link>
          </div>
          {data?.description && (
            <div className="py-10">
              <div className="flex items-center space-x-6">
                <h1 className="font-primary text-xl font-bold uppercase text-[#4A5164]">
                  About Candidate
                </h1>
                <div className="h-px flex-1 bg-[#969595]"></div>
              </div>
              <p className="mt-5 font-primary text-sm font-light text-[#4A5164]">
                {data?.description}
              </p>
            </div>
          )}
          {(data?.hardSkills?.length > 0 || data?.softSkills?.length > 0) && (
            <div className="flex justify-between space-x-4">
              {data?.hardSkills?.length > 0 && (
                <div className="w-2/5">
                  <div className="flex items-center space-x-6">
                    <h1 className="font-primary text-xl font-bold uppercase  text-[#4A5164]">
                      Hard skills
                    </h1>
                    <div className="h-px flex-1 bg-slate-300"></div>
                  </div>

                  <div className="px-16">
                    <Radar
                      data={{
                        labels: data?.hardSkills?.map((skill) => skill?.name),
                        datasets: [
                          {
                            label: "Hard skills",
                            data: data?.hardSkills?.map(
                              (skill) => skill?.hardSkillProficiency,
                            ),
                            backgroundColor: "rgba(56,57,128,0.2)",
                            borderColor: "#c5d1e5",
                            borderWidth: 1,
                            pointRadius: 4,
                            pointBackgroundColor: "#0054ff",
                            pointHoverBackgroundColor: "#000",
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: true, // Disable aspect ratio to control the chart's dimensions
                        aspectRatio: 1, // Set the aspect ratio (width/height) of the chart. Adjust as needed.
                        scales: {
                          r: {
                            grid: { circular: true, color: "#96b9ff" },
                            angleLines: { color: "#96b9ff" },
                            beginAtZero: true,
                            ticks: { stepSize: 1 },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {data?.softSkills?.length > 0 && (
                <div className="w-2/5">
                  <div className="flex items-center space-x-6">
                    <h1 className="font-primary text-xl font-bold uppercase text-[#4A5164]">
                      Soft skills
                    </h1>
                    <div className="h-px flex-1 bg-slate-300"></div>
                  </div>

                  <div className="px-16">
                    <Radar
                      data={{
                        labels: data?.softSkills?.map((skill) => skill?.name),
                        datasets: [
                          {
                            label: "Soft skills",
                            data: data?.softSkills?.map(
                              (skill) => skill?.softSkillProficiency,
                            ),
                            backgroundColor: "rgba(0,134,74,0.2)",
                            borderColor: "#b1d9ce",
                            borderWidth: 1,
                            pointRadius: 4,
                            pointBackgroundColor: "#0ac3a9",
                            pointHoverBackgroundColor: "#000",
                          },
                        ],
                      }}
                      options={{
                        maintainAspectRatio: true, // Disable aspect ratio to control the chart's dimensions
                        aspectRatio: 1, // Set the aspect ratio (width/height) of the chart. Adjust as needed.
                        scales: {
                          r: {
                            grid: { circular: true, color: "#9be6dc" },
                            angleLines: { color: "#9be6dc" },
                            beginAtZero: true,
                            ticks: { stepSize: 1 },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

CandidateModalPreview.propTypes = {
  previewId: PropTypes.number,
  results: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedIndex: PropTypes.func,
};

export default CandidateModalPreview;
