import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { uploadResume as uploadResumeApi } from "../services/apiApplication";

export function useUploadApplicationResume() {
  const {
    mutate: uploadResume,
    isPending: isUploadingResume,
    isSuccess: isUploadedResume,
    data: uploadedResume,
    reset: resetUploadResume,
  } = useMutation({
    mutationFn: (file) => uploadResumeApi(file),
    onMutate: () => {
      toast.loading("Uploading resume ...");
    },
    onSuccess: () => {
      toast.dismiss();
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return {
    uploadResume,
    isUploadingResume,
    isUploadedResume,
    uploadedResume,
    resetUploadResume,
  };
}
