import PropTypes from "prop-types";
import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { HiXMark } from "react-icons/hi2";
import { useOutsideClick } from "../hooks/useOutsideClick";

const ModalContext = createContext();

function Modal({ children }) {
  const [openName, setOpenName] = useState("");
  const [bodyPaddingRight, setBodyPaddingRight] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useEffect(
    function () {
      setBodyPaddingRight(
        parseFloat(
          window
            .getComputedStyle(document.body, null)
            .getPropertyValue("padding-right"),
        ),
      );

      setScrollBarWidth(
        parseFloat(window.innerWidth - document.body.clientWidth),
      );
    },
    [bodyPaddingRight, scrollBarWidth],
  );

  const open = (windowId) => {
    setOpenName(windowId);
    document.body.classList.add("overflow-hidden");
    document.body.style.paddingRight = bodyPaddingRight + scrollBarWidth + "px";
  };

  const close = () => {
    document.body.classList.remove("overflow-hidden");
    setOpenName("");
    document.body.style.paddingRight = bodyPaddingRight + "px";
  };

  useEffect(
    function () {
      if (openName === "") {
        document.body.classList.remove("overflow-hidden");
        setOpenName("");
        document.body.style.paddingRight = bodyPaddingRight + "px";
      }
    },
    [openName, bodyPaddingRight],
  );

  return (
    <ModalContext.Provider value={{ openName, open, close }}>
      {children}
    </ModalContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useModal() {
  const context = useContext(ModalContext);

  if (context === undefined)
    throw new Error("useModal was used outside the Modal provider.");
  return context;
}

function Open({ children, windowId }) {
  const { open } = useModal();

  return cloneElement(children, {
    onClick: () => open(windowId),
  });
}

function Close({ className, children, onClick }) {
  const { close } = useModal();

  return children ? (
    <div
      className={className}
      onClick={() => {
        close();
        onClick?.();
      }}
    >
      {children}
    </div>
  ) : (
    <button
      type="button"
      className="ml-auto cursor-pointer rounded bg-slate-100 p-0.5 hover:bg-slate-200 hover:text-red-600"
      onClick={() => {
        close();
        onClick?.();
      }}
    >
      <HiXMark className="h-5 w-5" />
    </button>
  );
}

function Window({
  id,
  children,
  className = "p-4",
  disableOutsideClick = false,
  containerWrapper = "bg-white shadow-lg",
}) {
  const { openName, close } = useModal();
  const modalRef = useOutsideClick(close, true);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (id === openName) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [id, openName]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (id === openName && e.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [id, openName, close]);

  if (id !== openName) return null;

  return createPortal(
    <div
      className={`fixed inset-0 z-[1000] flex items-center overflow-hidden bg-black/40 p-4 transition-opacity duration-300 ${
        isAnimating ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`absolute right-0 top-0 h-full w-1/3 transform transition-transform duration-300 ${
          isAnimating ? "translate-x-0" : "translate-x-full"
        } rounded ${containerWrapper}`}
        ref={disableOutsideClick ? null : modalRef}
      >
        <div className={className}>{children}</div>
      </div>
    </div>,
    document.body,
  );
}

Modal.Open = Open;
Modal.Close = Close;
Modal.Window = Window;

Modal.propTypes = {
  children: PropTypes.node,
};

Open.propTypes = {
  children: PropTypes.node,
  windowId: PropTypes.string,
};

Close.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Window.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disableOutsideClick: PropTypes.bool,
  containerWrapper: PropTypes.string,
};

export default Modal;
