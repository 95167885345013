/* eslint-disable react/no-unescaped-entities */
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useSearchCountries } from "../../hooks/useSearchCountries";
import CheckButton from "../../shared/buttons/CheckButton";
import ControlledDropdown from "../../shared/dropdown/ControlledDropdown";
import Input from "../../shared/inputs/Input";
import Loader from "../../shared/loader/Loader";
import {
  contractTypeArray,
  experienceLevelArray,
  qualificationArray,
  workLocationArray,
} from "../../utils/constants";
const StepOne = (props) => {
  const { currencies, industries } = props;
  const {
    control,
    setValue,
    resetField,
    watch,
    getValues,
    errors,
    clearErrors,
    trigger,
  } = useFormContext();

  const [salaryValue, setSalaryValue] = useState(null);

  const { salaryRange } = watch();

  useEffect(() => {
    setSalaryValue([salaryRange?.[0], salaryRange?.[1]]);
  }, [salaryRange]);

  // Country autocomplete
  const [openCountryList, setOpenCountryList] = useState(false);
  const [countryName, setCountryName] = useState("");
  const countryList = useOutsideClick(() => setOpenCountryList(false));

  const { data: searchedCountries, isLoading: isLoadingSearchedCountries } =
    useSearchCountries({ name: countryName });

  return (
    <>
      <div className="my-4 flex flex-col items-center">
        <div className="mb-1 flex font-primary text-2xl font-medium text-black-g-primary">
          Add basic infomation
        </div>
        <div className="font-primary text-base font-normal text-black-g-primary">
          Let's fill the basic information of the job
        </div>
      </div>

      <div className="flex flex-col flex-wrap gap-1  md:gap-5">
        <div className="flex w-full flex-1 flex-col flex-wrap gap-1 md:flex-row md:gap-0">
          <Input
            column={true}
            label={"Job Title"}
            type="text"
            id={"title"}
            placeholder="Enter job title"
            controllerName={"title"}
            rules={{
              required: "required",
            }}
            control={control}
            mainDivClassname={"flex-1"}
            containerClassName="border-black-g-50"
            containerBaseClassName="border rounded-md"
            fieldClassName="input-transparent text-sm font-primary font-normal bg-white"
            resetField={resetField}
          />

          {/* spacer div here */}
          <div className="mx-3"></div>

          <ControlledDropdown
            id={"industryId"}
            controllerName="industryId"
            control={control}
            items={industries}
            defaultValue={getValues("industryId")}
            labelName={"Job function"}
            rules={{ required: "required" }}
            className=" rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
          />
        </div>

        <div className="flex w-full flex-1 flex-col flex-wrap gap-1 md:flex-row md:gap-0">
          <div className="relative">
            <Input
              label={"Country"}
              type={"hidden"}
              control={control}
              controllerName={"countryId"}
              id={"countryId"}
              resetField={resetField}
            />

            <Input
              label={"Country"}
              type={"text"}
              control={control}
              autoComplete={"off"}
              controllerName={"countryName"}
              id={"countryName"}
              placeholder={"Search country name"}
              rules={{
                validate: () => {
                  return getValues("countryId") || "Please select a country";
                },
                onChange: (e) => {
                  setOpenCountryList(true);
                  setCountryName(e.target?.value);
                },
                onBlur: () => {
                  setValue(
                    "countryName",
                    searchedCountries?.[0] ? searchedCountries?.[0]?.name : "",
                  );
                  setValue(
                    "countryId",
                    searchedCountries?.[0] ? searchedCountries?.[0]?.id : "",
                  );
                  trigger("countryName");
                },
              }}
              resetField={() => {
                resetField("countryId");
                resetField("countryName");
              }}
            />
            {openCountryList && (
              <div
                ref={countryList}
                className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
              >
                {isLoadingSearchedCountries && (
                  <div className="p-4">
                    <Loader />
                  </div>
                )}

                {searchedCountries?.map((country) => (
                  <div
                    key={country?.id}
                    className="ingml-btn hover:bg-blue-b-50"
                    onClick={() => {
                      setValue("countryId", country?.id);
                      setValue("countryName", country?.name);

                      setOpenCountryList(false);
                    }}
                  >
                    {country?.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* spacer div here */}
          <div className="mx-3"></div>

          <div className={`flex flex-1 flex-col flex-wrap rounded`}>
            <div className={`py-1.5 font-primary text-sm font-semibold`}>
              Work Location
            </div>
            <div
              className={`flex flex-wrap items-center gap-4 md:gap-2`}
              id="workLocation"
            >
              {workLocationArray?.map((item) => (
                <CheckButton
                  text={item?.name}
                  key={item?.id}
                  controllerName="workLocation"
                  control={control}
                  rules={{ required: "required" }}
                  selected={parseInt(watch("workLocation")) === item?.value}
                  onClick={() => {
                    setValue("workLocation", item?.value);
                    clearErrors("workLocation");
                  }}
                />
              ))}
            </div>
            {errors?.workLocation ? (
              <p className="mt-2 text-sm text-red-o-primary">
                {"Please select a work location"}
              </p>
            ) : null}
          </div>
        </div>

        <div className="flex w-full flex-1 flex-col flex-wrap gap-1 md:flex-row md:gap-0">
          <ControlledDropdown
            controllerName="currencyId"
            control={control}
            id="currencyId"
            items={currencies}
            /*  defaultValue={getValues("currencyId")} */
            rules={{ required: "required" }}
            labelName={"Currency"}
            className="rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
          />
          {/* spacer div here */}
          <div className="mx-3"></div>

          <div className="flex flex-1 flex-col flex-wrap gap-3">
            <div className="p-1.5 font-primary text-sm font-semibold">
              Salary range
            </div>
            <div className="ml-3">
              <Slider
                value={salaryValue}
                onChange={(event, newValue) =>
                  setValue("salaryRange", newValue)
                }
                valueLabelDisplay="auto"
                sx={{
                  color: "#0054FF",
                  height: "8px",
                  padding: "0px",
                }}
                step={100}
                min={0}
                max={9000}
              />
            </div>
          </div>
        </div>

        <div className="flex w-full flex-1 flex-col flex-wrap gap-1 md:flex-row md:gap-0">
          <ControlledDropdown
            controllerName="education"
            id={"education"}
            control={control}
            items={qualificationArray}
            /* defaultValue={getValues("education")} */
            labelName={"Education Level"}
            rules={{ required: "required" }}
            className=" rounded-[4px] border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
          />
        </div>

        <div className={"flex flex-col flex-wrap rounded"}>
          <div className={"py-2 font-primary text-sm font-semibold"}>
            Contract Type
          </div>
          <div
            className={"flex flex-wrap items-center gap-4"}
            id="contractType"
          >
            {contractTypeArray?.map((item) => (
              <CheckButton
                key={item?.id}
                text={item?.name}
                controllerName="contractType"
                control={control}
                rules={{ required: "required" }}
                selected={parseInt(watch("contractType")) === item?.value}
                onClick={() => {
                  setValue("contractType", item?.value);
                  clearErrors("contractType");
                }}
              />
            ))}
          </div>
          {errors?.contractType ? (
            <p className="mt-2 text-sm text-red-o-primary">
              {"Please select an employment type"}
            </p>
          ) : null}
        </div>

        <div className={"flex flex-col flex-wrap rounded"}>
          <div className={"py-2 font-primary text-sm font-semibold"}>
            Expertise
          </div>
          <div className={"flex flex-wrap items-center gap-4"} id="expertise">
            {experienceLevelArray?.map((item) => (
              <CheckButton
                text={item?.name}
                key={item?.id}
                id={item?.id}
                controllerName="experienceLevel"
                control={control}
                rules={{ required: "required" }}
                selected={parseInt(watch("experienceLevel")) === item?.value}
                onClick={() => {
                  setValue("experienceLevel", item?.value);
                  clearErrors("experienceLevel");
                }}
              />
            ))}
          </div>
          {errors?.expertise ? (
            <p className="mt-2 text-sm text-red-o-primary">
              {"Please select expertise"}
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};

StepOne.propTypes = {
  currencies: PropTypes.array,
  industries: PropTypes.array,
};

export default StepOne;
