import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { HiCheck } from "react-icons/hi2";
import { useContact } from "../../hooks/useContact";
import Input from "../../shared/inputs/Input";
import TextArea from "../../shared/inputs/TextArea";
import Loader from "../../shared/loader/Loader";

function ContactForm() {
  const { control, handleSubmit, resetField, reset } = useForm();

  const { sendMessage, isPending, isMessageSent } = useContact();

  const onSubmit = ({ name, email, subject, phone, message }) => {
    sendMessage(
      { name, email, subject, phone, message },
      {
        onSuccess: () => reset(),
        onError: (err) => {
          toast.error(err?.message);
        },
      },
    );
  };
  const onError = (errs) => {
    toast.error((t) => (
      <div className="flex items-start">
        <p className="mx-2">
          Oops! There are errors in your form submission. Please review and
          correct them.
        </p>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="rounded border bg-slate-200 px-2 py-1 text-xs hover:border-slate-200 hover:bg-white"
        >
          close
        </button>
      </div>
    ));
  };

  return (
    <>
      {!isMessageSent ? (
        <>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="-m-3 flex flex-wrap">
              <div className="w-full p-3 sm:w-1/2">
                <Input
                  label="Name"
                  type="text"
                  id="name"
                  placeholder="Example: Devadoot"
                  autoComplete="name"
                  controllerName="name"
                  control={control}
                  rules={{
                    required: "Name is required",
                    minLength: {
                      value: 2,
                      message: "This name is too short",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message:
                        "Name contains only letters (both uppercase and lowercase)",
                    },
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="w-full p-3 sm:w-1/2">
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  placeholder="example@domain.com"
                  autoComplete="email"
                  controllerName="email"
                  control={control}
                  rules={{
                    required: "Email address is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please provide a valid email address",
                    },
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="w-full p-3 sm:w-1/2">
                <Input
                  label="Subject"
                  type="text"
                  id="subject"
                  placeholder="Subject"
                  controllerName="subject"
                  control={control}
                  rules={{
                    required: "Subject is required",
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="w-full p-3 sm:w-1/2">
                <Input
                  label="Phone"
                  type="tel"
                  id="phone"
                  placeholder="Phone number"
                  autoComplete="tel"
                  controllerName="phone"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value:
                        /^(\+\d{2}|00\d{2}|0)?\d{2,3}[-\s]?\d{2,3}[-\s]?\d{2,4}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="w-full p-3">
                <TextArea
                  label="Message"
                  id="message"
                  placeholder="Your message"
                  controllerName="message"
                  control={control}
                  rules={{
                    required: "Message is required",
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="flex w-full justify-end p-3">
                <button className="btn-blue-b-primary" disabled={isPending}>
                  {isPending ? <Loader color="#ffffff" /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="text-center text-lg text-green-600">
            <HiCheck className="mx-auto mb-8 h-16 w-16" />
            <p>
              Thank you for submitting your message! We&apos;ll get back to you
              as soon as possible.
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default ContactForm;
