import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import RatingEmoji from "../RatingEmoji";

function RatingEmojiInput({
  id,
  controllerName,
  control,
  rules,
  defaultValue,
  onRate,
}) {
  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ""}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, isDirty, error },
      }) => {
        return (
          <RatingEmoji
            onRate={(value) => {
              onChange?.(value);
              onRate?.(value);
            }}
            defaultSelected={defaultValue}
          />
        );
      }}
    />
  );
}

RatingEmojiInput.propTypes = {
  id: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  onRate: PropTypes.func,
};

export default RatingEmojiInput;
