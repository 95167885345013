import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { uploadUserResume as uploadUserResumeApi } from "../services/apiResume";

export function useUploadUserResume() {
  const queryClient = useQueryClient();

  const {
    mutate: uploadUserResume,
    isPending: isUploadingResume,
    isSuccess: isUploadedResume,
    data: uploadedResume,
    reset: resetUploadUserResume,
  } = useMutation({
    mutationFn: (file) => uploadUserResumeApi(file),
    onMutate: () => {
      toast.loading("Uploading resume ...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userPDFResume"] });
      queryClient.invalidateQueries({ queryKey: ["userResume"] });
      toast.dismiss();
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return {
    uploadUserResume,
    isUploadingResume,
    isUploadedResume,
    uploadedResume,
    resetUploadUserResume,
  };
}
