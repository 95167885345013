import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { rateApplicationAPI } from "../services/apiApplication";

export const useApplicationRate = () => {
  const queryClient = useQueryClient();

  const {
    mutate: rateApplication,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ applicationId, rate }) =>
      rateApplicationAPI({
        applicationId,
        rate,
      }),
    onSuccess: (response) => {
      toast.success("Application has been rated successfully");
      queryClient.invalidateQueries({
        queryKey: ["application_search"],
      });

      return response;
    },
    onError: () => {
      toast.error("Failed to rate application");
    },
  });

  return { rateApplication, isPending, isSuccess };
};
