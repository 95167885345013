import { useQuery } from "@tanstack/react-query";
import { searchCities } from "../services/apiInformation";

export function useSearchCities({ countryId, name }) {
  const { isLoading, data, error } = useQuery({
    enabled: !!(countryId > 0 && name), //The query will not execute until countryID > 0
    queryKey: ["searchCities", countryId, name],
    queryFn: () => searchCities({ countryId, name }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  return { isLoading, data, error };
}
