import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { submitJobDescription as submitJobDescriptionAPI } from "../services/apiJobDescription";

export function useSubmitJobDescription() {
  const queryClient = useQueryClient();

  const {
    mutate: submitJobDescription,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({
      id,
      title,
      imageURL,
      education,
      contractType,
      workLocation,
      experienceLevel,
      employmentType,
      mainMission,
      profile,
      responsibilities,
      additionalInformation,
      softSkills,
      rate: { currencyId, rateType, min, max },
      expirationDate,
      keywords,
      industryId,
      countryId,
      isDraft,
    }) =>
      submitJobDescriptionAPI({
        id,
        title,
        imageURL,
        education,
        contractType,
        workLocation,
        experienceLevel,
        employmentType,
        mainMission,
        profile,
        responsibilities,
        additionalInformation,
        softSkills,
        rate: { currencyId, rateType, min, max },
        expirationDate,
        keywords,
        industryId,
        countryId,
        isDraft,
      }),
    onMutate: () => {
      toast.loading("Sending data...");
    },
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["jobDescriptionSearch"] });
      toast.dismiss();
      toast.success("Updated successfully");
      /* if (data.status === 200) {
        toast.success("Your application has been updated successfully");
        return queryClient.invalidateQueries({
          queryKey: ["jobDescriptionSearch"],
        });
      } else if (data.status === 201) {
        toast.success("Your application has been submitted successfully");
        return queryClient.invalidateQueries({
          queryKey: ["jobDescriptionSearch"],
        });
      } else {
        toast.error("Something wrong happened");
      } */
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { submitJobDescription, isPending, isSuccess };
}
