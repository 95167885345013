/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import PropTypes from "prop-types";
import Divider from "../../../shared/Divider";
import CheckButton from "../../../shared/buttons/CheckButton";
import Input from "../../../shared/inputs/Input";
import { Slider } from "@mui/material";
import { useGetSkills } from "../../../hooks/useGetSkills";
import { SoftSkillsArray } from "../../../utils/constants";
import { usePostSkill } from "../../../hooks/usePostSkill";

function SoftSkills({
  watch,
  control,
  resetField,
  setValue,
  hideClearSection,
}) {
  const [selectedSkill, setSelectedSkill] = useState(null);

  // console.log(watch());

  const selectedSoftSkills = watch("softSkills");

  const payload = {
    keyword: watch("soft-input"),
    type: 1,
    page: 1,
    size: 5,
  };
  const { data } = useGetSkills(payload);
  const { results: suggestedSkills } = data || {};
  const { saveSkill } = usePostSkill();

  const handleKeyDown = async (event) => {
    saveSkill({ keyword: event.target.value, type: payload.type });
  };
  return (
    <div className="flex w-full ">
      <div className="flex w-full flex-col">
        <div className="flex  items-center">
          <div className="font-primary text-xl font-bold text-black-g-500">
            Soft skills
          </div>
          <Divider className="ml-4 flex-1" />
        </div>
        <div className="flex w-full flex-col gap-4 py-4">
          <div className="flex flex-wrap items-center gap-2">
            {selectedSoftSkills?.map((item) => (
              <CheckButton
                key={item.id || item.skillId}
                text={item?.name}
                selected={true}
                onClick={() => {
                  const updatedKeywords = selectedSoftSkills?.filter(
                    (obj) => obj.id !== item.id || obj.skillId !== item.skillId,
                  );
                  setValue("softSkills", updatedKeywords); // Update the form value
                }}
              />
            ))}
          </div>

          <div className="flex  gap-4">
            <Input
              type="text"
              id={"soft-input"}
              placeholder="Add skill"
              controllerName={"soft-input"}
              control={control}
              mainDivClassname={"flex-1 "}
              onKeyDown={handleKeyDown}
              containerClassName="border-black-g-50"
              containerBaseClassName="border rounded-md"
              fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
              resetField={resetField}
            />
            <div className="flex flex-1 flex-wrap items-center gap-2">
              {suggestedSkills?.map((item) => (
                <CheckButton
                  key={item.id}
                  text={item?.name}
                  onClick={() => {
                    setSelectedSkill(item);
                  }}
                />
              ))}
            </div>
          </div>
          {selectedSkill ? (
            <div className="flex items-center justify-between gap-4">
              <div className="flex-1 rounded-2xl border p-2 text-center font-primary text-sm font-medium text-black-g-primary">
                {selectedSkill?.name}
              </div>
              <Slider
                // value={watch("salaryRange")}
                onChange={(event, newValue) =>
                  setSelectedSkill((prev) => ({
                    ...prev,
                    softSkillProficiencyLevel: newValue,
                  }))
                }
                marks={SoftSkillsArray}
                shiftStep={1}
                step={1}
                valueLabelDisplay="off"
                sx={{
                  color: "#0054FF",
                  height: "8px",
                  width: "60%",
                  marginBottom: "0px",
                  paddingY: "0px",
                }}
                min={1}
                max={4}
              />
              <div
                className="btn-blue-b-primary flex  items-center justify-center rounded-md py-2 font-primary text-base font-medium"
                role="button"
                onClick={() => {
                  setValue("softSkills", [
                    ...selectedSoftSkills,
                    {
                      skillId: selectedSkill.id,
                      name: selectedSkill.name,
                      softSkillProficiencyLevel:
                        selectedSkill.softSkillProficiencyLevel ?? 1,
                    },
                  ]);
                  setSelectedSkill(null);
                }}
              >
                Add skill
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {!hideClearSection ? (
        <div className="ml-4 flex w-5/12 flex-col">
          <div className="flex items-center">
            <div className="font-primary text-base font-light text-blue-b-primary">
              Clear section
            </div>
            <Divider className="ml-4 flex-1" />
          </div>
          <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
            Here, you'll highlight your technical competencies, expertise, and
            qualifications that directly relate to the job requirements.
          </div>
        </div>
      ) : null}
    </div>
  );
}

SoftSkills.propTypes = {
  watch: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  hideClearSection: PropTypes.bool,
};
SoftSkills.defaultProps = {
  hideClearSection: false,
};
export default SoftSkills;
