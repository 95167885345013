import PropTypes from "prop-types";
import DropdownField from "./DropdownField";
import MultipleDropdownField from "./MultipleDropdownField";

function ControlledDropdown({
  labelName,
  items,
  controllerName,
  control,
  rules,
  id,
  isMultiple,
  className,
  maxHeight = "14rem",
  defaultValue,
  onSelect,
  placeholder,
}) {
  const LabelName = () => {
    return labelName ? (
      <div className="py-1.5 font-primary text-sm font-semibold">
        {labelName}
      </div>
    ) : null;
  };

  return (
    <div className="flex-1">
      <LabelName />
      {!isMultiple ? (
        <DropdownField
          id={id}
          items={items}
          control={control}
          controllerName={controllerName}
          rules={rules}
          className={className}
          maxHeight={maxHeight}
          defaultValue={defaultValue}
          onSelect={onSelect}
          placeholder={placeholder}
        />
      ) : (
        <MultipleDropdownField
          id={id}
          items={items}
          control={control}
          controllerName={controllerName}
          rules={rules}
          className={className}
          maxHeight={maxHeight}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}

ControlledDropdown.propTypes = {
  labelName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
    PropTypes.shape({}),
  ]),
  controllerName: PropTypes.string,
  rules: PropTypes.any,
  control: PropTypes.any,
  isMultiple: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
};
ControlledDropdown.defaultProps = {
  labelName: null,
  dropdownClassName: "",
  items: [],
  controllerName: null,
  rules: null,
  control: {},
  isMultiple: false,
};

export default ControlledDropdown;
