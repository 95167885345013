import PropTypes from "prop-types";

function GraphIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M42.7467 28.0001C48.8133 28.0001 51.3333 25.6668 49.0933 18.0134C47.5767 12.8568 43.1433 8.42343 37.9867 6.90676C30.3333 4.66676 28 7.18676 28 13.2534V19.9734C28 25.6668 30.3333 28.0001 35 28.0001H42.7467Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6666 34.2998C44.4966 45.1031 34.1366 52.9431 22.3533 51.0298C13.5099 49.6064 6.39325 42.4898 4.94659 33.6464C3.05659 21.9098 10.8499 11.5498 21.6066 9.35645"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

GraphIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default GraphIcon;
