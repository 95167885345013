import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { format, parse } from "date-fns";
import { Radar } from "react-chartjs-2";
import { FormProvider, useForm } from "react-hook-form";
import {
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineMapPin,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2";
import CandidateMyResumeForm from "../components/user/CandidateMyResumeForm";
import { useUpdateUserResume } from "../hooks/useUpdateUserResume";
import { useUploadUserPicture } from "../hooks/useUploadUserPicture";
import { useUserResume } from "../hooks/useUserResume";
import BuildingIcon from "../icons/BuildingIcon";
import CurrencyIcon from "../icons/CurrencyIcon";
import EditIconSolid from "../icons/EditIconSolid";
import SparklesIcon from "../icons/SparklesIcon";
import Divider from "../shared/Divider";
import Modal from "../shared/Modal";
import PhotoInput from "../shared/inputs/PhotoInput";
import Loader from "../shared/loader/Loader";
import {
  LanguageLevelEnum,
  LeaveNoticeEnum,
  QualificationTypeEnum,
  RATETYPE_ENUM,
} from "../utils/constants";
import { getFileType } from "../utils/helpers";
import ErrorPage from "./ErrorPage";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function CandidateMyResume() {
  const { isLoading, data, error } = useUserResume();

  const {
    profilePic,
    firstName,
    lastName,
    jobTitle,
    description,
    contactInfo,
    educations,
    experiences,
    languages,
    hardSkills,
    softSkills,
    educationLevel,
    yearsOfExperience,
    disponibility,
    rate,
  } = data || {};

  const formMethods = useForm({
    values: {
      firstName,
      lastName,
      jobTitle,
      countryId: contactInfo?.address?.country?.id,
      cityId: contactInfo?.address?.city?.id,
      email: contactInfo?.email,
      phone: contactInfo?.phone,
      description: description,
      experiences: experiences?.map((experience) => {
        return {
          ...experience,
          startDate: format(
            parse(experience?.startDate, "dd/MM/yyyy", new Date()).toString(),
            "MMM yyyy",
          ),

          endDate: format(
            parse(experience?.endDate, "dd/MM/yyyy", new Date()).toString(),
            "MMM yyyy",
          ),
        };
      }),
      educations: educations?.map((education) => {
        return {
          ...education,
          startTime: format(
            parse(education?.startTime, "dd/MM/yyyy", new Date()).toString(),
            "MMM yyyy",
          ),
          endTime: format(
            parse(education?.endTime, "dd/MM/yyyy", new Date()).toString(),
            "MMM yyyy",
          ),
        };
      }),
    },
  });

  const {
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  } = useUploadUserPicture();

  const handleUploadProfilePicture = (e) => {
    const file = e.target?.value;

    uploadUserPicture(file, {
      onSuccess: () => {
        formMethods?.resetField("profilePicture");
      },
      onError: (err) => {
        if (err?.code === 400)
          formMethods?.setError(
            "profilePicture",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
        resetUploadUserPicture();
      },
    });
  };
  const { updateUserResume, isPending, isSuccess } = useUpdateUserResume();

  const onSubmit = (payload) => {
    updateUserResume(payload);
  };

  const onError = (errs) => {
    console.log(errs);
  };

  if (error)
    return (
      <ErrorPage>
        <h1 className="text-[2rem] font-medium leading-10">{error?.message}</h1>
      </ErrorPage>
    );

  if (isLoading)
    return (
      <section>
        <div className="page-px py-16">
          <Loader />
        </div>
      </section>
    );

  return (
    <Modal>
      <section>
        <div className="page-px mb-12 ">
          <div className="mx-auto max-w-screen-lg rounded-3xl bg-black-g-23 px-6 py-12">
            <div className="-m-4 flex flex-wrap ">
              <div className="p-4">
                <PhotoInput
                  size={40}
                  control={formMethods?.control}
                  id="profilePicture"
                  controllerName="profilePicture"
                  rules={{
                    required:
                      uploadedUserPicture?.result || profilePic
                        ? false
                        : "Please upload your photo",
                    onChange: handleUploadProfilePicture,
                  }}
                  isUploading={isUploadingUserPicture}
                  disabled={isUploadingUserPicture || isPending}
                  src={uploadedUserPicture?.result || profilePic}
                />
              </div>

              <div className="w-full p-4 md:flex-1">
                <div className="relative -m-1 flex items-center text-xl font-bold">
                  <div className="p-1">
                    {firstName ? (
                      <span>{firstName}</span>
                    ) : (
                      <span className="italic text-slate-400">
                        [Add first name]
                      </span>
                    )}
                  </div>

                  <div className="p-1">
                    {lastName ? (
                      <span className="uppercase">{lastName}</span>
                    ) : (
                      <span className="italic text-slate-400">
                        [Add last name]
                      </span>
                    )}
                  </div>

                  <div className="p-1">
                    <Modal.Open windowId={"my_resume"}>
                      <div className="btn-edit-white" title="Edit">
                        <EditIconSolid className="h-full w-full" />
                      </div>
                    </Modal.Open>
                  </div>
                </div>

                <div className="my-2 text-xl">
                  {jobTitle ? (
                    jobTitle
                  ) : (
                    <span className="italic text-slate-400">
                      [Add job title]
                    </span>
                  )}
                </div>

                <div className="my-3 overflow-hidden">
                  <div className="-mx-3 -my-1.5 flex flex-wrap items-center text-black-g-100">
                    <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                      <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                        <div className="flex items-center space-x-1 px-3 py-1.5">
                          <HiOutlineMapPin className="h-5 w-5" />
                          {contactInfo?.address?.city?.name ? (
                            <span>{contactInfo?.address?.city?.name}</span>
                          ) : (
                            <span className="italic text-slate-400">
                              [Add city]
                            </span>
                          )}
                          <span>, </span>
                          {contactInfo?.address?.country?.name ? (
                            <span>{contactInfo?.address?.country?.name}</span>
                          ) : (
                            <span className="italic text-slate-400">
                              [Add country]
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                      <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                        {yearsOfExperience > 0 && (
                          <div className="flex items-center space-x-1 px-3 py-1.5">
                            <HiOutlineBriefcase className="h-5 w-5" />
                            <span>
                              {`${yearsOfExperience} ${yearsOfExperience <= 1 ? "year" : "years"}`}
                            </span>
                          </div>
                        )}

                        {rate?.max ? (
                          <div className="flex items-center space-x-1 px-3 py-1.5">
                            <CurrencyIcon className="h-5 w-5" />
                            <span>
                              {rate?.currency?.symbol === "$"
                                ? `$${rate?.max}`
                                : `${rate?.max}${rate?.currency?.symbol}`}{" "}
                              {RATETYPE_ENUM[rate?.type]}
                            </span>
                          </div>
                        ) : (
                          <span className="italic text-slate-400">
                            [Add salary]
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                      <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                        {educationLevel > 0 && (
                          <div className="flex items-center space-x-1 px-3 py-1.5">
                            <HiOutlineAcademicCap className="h-5 w-5" />
                            <span>{QualificationTypeEnum[educationLevel]}</span>
                          </div>
                        )}

                        {disponibility > 0 && (
                          <div className="flex items-center space-x-1 px-3 py-1.5">
                            <HiOutlineSquare3Stack3D className="h-5 w-5" />
                            <span>{LeaveNoticeEnum[disponibility]}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4 overflow-hidden"></div>

                {hardSkills?.length > 0 ? (
                  <div className="-m-1 flex flex-wrap">
                    {hardSkills?.map((skill) => (
                      <div key={skill?.id} className="p-1">
                        <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                          {skill?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex">
                    <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                      [Add hard skills]
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {description && (
        <section>
          <div className="page-px my-12">
            <div className="mx-auto max-w-screen-lg rounded-3xl bg-black-g-23 px-12 py-6">
              <div className="flex items-center space-x-6">
                <h1 className="text-xl font-semibold">About Candidate</h1>

                <div className="h-px flex-1 bg-slate-300"></div>
              </div>
              <p className="mt-6">{description}</p>
            </div>
          </div>
        </section>
      )}

      {(hardSkills?.length > 0 || softSkills?.length > 0) && (
        <section>
          <div className="page-px my-12">
            <div className="mx-auto max-w-screen-lg overflow-hidden rounded-3xl bg-[#F7F9FB] px-12 py-6">
              <div className="-m-16 flex flex-wrap">
                {hardSkills?.length > 0 && (
                  <div className="w-full p-16 md:w-1/2">
                    <div className="mb-12 flex items-center space-x-6">
                      <h1 className="text-xl font-semibold">Hard skills</h1>
                      <div className="h-px flex-1 bg-slate-300"></div>
                    </div>

                    <div>
                      <Radar
                        data={{
                          labels: hardSkills?.map((skill) => skill?.name),
                          datasets: [
                            {
                              label: "Hard skills",
                              data: hardSkills?.map(
                                (skill) => skill?.hardSkillProficiency,
                              ),
                              backgroundColor: "rgba(56,57,128,0.2)",
                              borderColor: "#c5d1e5",
                              borderWidth: 1,
                              pointRadius: 4,
                              pointBackgroundColor: "#0054ff",
                              pointHoverBackgroundColor: "#000",
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            r: {
                              grid: { circular: true, color: "#96b9ff" },
                              angleLines: { color: "#96b9ff" },
                              beginAtZero: true,
                              ticks: { stepSize: 1 },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {softSkills?.length > 0 && (
                  <div className="w-full p-16 md:w-1/2">
                    <div className="mb-12 flex items-center space-x-6">
                      <h1 className="text-xl font-semibold">Soft skills</h1>
                      <div className="h-px flex-1 bg-slate-300"></div>
                    </div>

                    <div>
                      <Radar
                        data={{
                          labels: softSkills?.map((skill) => skill?.name),
                          datasets: [
                            {
                              label: "Soft skills",
                              data: softSkills?.map(
                                (skill) => skill?.softSkillProficiency,
                              ),
                              backgroundColor: "rgba(0,134,74,0.2)",
                              borderColor: "#b1d9ce",
                              borderWidth: 1,
                              pointRadius: 4,
                              pointBackgroundColor: "#0ac3a9",
                              pointHoverBackgroundColor: "#000",
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            r: {
                              grid: { circular: true, color: "#9be6dc" },
                              angleLines: { color: "#9be6dc" },
                              beginAtZero: true,
                              ticks: { stepSize: 1 },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Experience</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {experiences?.length > 0 &&
              experiences?.map((experience) => (
                <div key={experience?.id} className="mt-6">
                  <div className="-m-3 flex">
                    <div className="p-3">
                      {getFileType(experience?.companyLogo) === "image" ? (
                        <img
                          className="h-20 w-20 object-cover object-center"
                          src={experience?.companyLogo}
                        />
                      ) : (
                        <BuildingIcon className="h-20 w-20 text-blue-b-75" />
                      )}
                    </div>

                    <div className="flex-1 p-3">
                      {experience?.name && (
                        <h2 className="text-xl font-semibold">
                          {experience?.name}
                        </h2>
                      )}

                      {experience?.companyName && (
                        <h3 className="text-xl">{experience?.companyName}</h3>
                      )}

                      {(experience?.startDate || experience?.endDate) && (
                        <div className="flex items-center space-x-1 text-lg font-light text-black-g-100">
                          <span>
                            {format(
                              parse(
                                experience?.startDate,
                                "dd/MM/yyyy",
                                new Date(),
                              ).toString(),
                              "MMM yyyy",
                            )}
                          </span>
                          {experience?.endDate && (
                            <>
                              <span>-</span>
                              <span>
                                {format(
                                  parse(
                                    experience?.endDate,
                                    "dd/MM/yyyy",
                                    new Date(),
                                  ).toString(),
                                  "MMM yyyy",
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      )}

                      <div className="text-lg font-light text-black-g-100">
                        Location
                      </div>

                      {experience?.description && (
                        <div className="my-2 text-sm">
                          {experience?.description}
                        </div>
                      )}

                      {experience?.hardSkills?.length > 0 && (
                        <div className="my-3">
                          <div className="-m-1 flex flex-wrap items-center">
                            <div className="p-1">
                              <SparklesIcon className="h-7 w-7 text-blue-b-primary" />
                            </div>
                            <div className="flex items-center whitespace-nowrap p-1 text-sm font-medium text-blue-b-primary">
                              {experience?.hardSkills[0]?.name}
                              {experience?.hardSkills?.length > 1 && `, `}
                              {experience?.hardSkills[1]?.name}
                              {experience?.hardSkills?.length > 2 &&
                                ` et ${experience?.hardSkills?.length - 2} more skills`}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Education</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {educations?.length > 0 &&
              educations?.map((education) => (
                <div key={education?.id} className="mt-6">
                  <div className="-m-3 flex">
                    {/* {getFileType(education?.image) === "image" && ( */}
                    <div className="p-3">
                      {/* <img
                          className="h-20 w-20 object-cover object-center"
                          src={education?.image}
                        /> */}
                      <div className="h-20 w-20 text-blue-b-75">
                        <HiOutlineAcademicCap className="h-full w-full" />
                      </div>
                    </div>
                    {/*  )} */}

                    <div className="flex-1 p-3">
                      {education?.schoolName && (
                        <h2 className="text-xl font-semibold">
                          {education?.schoolName}
                        </h2>
                      )}

                      {education?.name && (
                        <h3 className="text-xl">{education?.name}</h3>
                      )}

                      {(education?.startTime || education?.endTime) && (
                        <div className="flex items-center space-x-1 text-lg font-light text-black-g-100">
                          <span>
                            {format(
                              parse(
                                education?.startTime,
                                "dd/MM/yyyy",
                                new Date(),
                              ).toString(),
                              "MMM yyyy",
                            )}
                          </span>
                          {education?.endTime && (
                            <>
                              <span>-</span>
                              <span>
                                {format(
                                  parse(
                                    education?.endTime,
                                    "dd/MM/yyyy",
                                    new Date(),
                                  ).toString(),
                                  "MMM yyyy",
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      )}

                      <div className="text-lg font-light text-black-g-100">
                        Location
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Languages</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {languages?.length > 0 &&
              languages?.map((language) => (
                <div key={language?.id} className="mt-6">
                  <div className="-m-3 flex">
                    <div className="flex-1 p-3">
                      {language?.name && (
                        <h2 className="text-xl font-semibold">
                          {language?.name}
                        </h2>
                      )}

                      {language?.level && (
                        <h3 className="text-xl">
                          {LanguageLevelEnum[language?.level]}
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <Modal.Window id={"my_resume"} className="w-full max-w-screen-md p-6">
        <div className="flex space-x-8">
          <h5 className="flex-1 text-xl font-semibold">Edit your resume</h5>
          <Modal.Close className={"ml-auto"} />
        </div>

        <Divider />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods?.handleSubmit(onSubmit, onError)}>
            <CandidateMyResumeForm />
          </form>
        </FormProvider>
      </Modal.Window>
    </Modal>
  );
}

export default CandidateMyResume;
