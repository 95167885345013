import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { signupUser as signupUserApi } from "../services/apiAuth";

export function useSignupUser() {
  const {
    mutate: signupUser,
    isPending,
    isSuccess: isSignedUp,
  } = useMutation({
    mutationFn: ({
      email,
      password,
      role,
      firstName,
      lastName,
      profilePicture,
      company: { employerName, companyPhone, countryId, industry } = {},
    }) =>
      signupUserApi({
        email,
        password,
        role,
        firstName,
        lastName,
        profilePicture,
        company: { employerName, companyPhone, countryId, industry },
      }),
    onSuccess: () => {
      toast.success(
        `Account successfully created! Please verify your new account from your email address.`,
      );
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { signupUser, isPending, isSignedUp };
}
