import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postSkill } from "../services/apiSkills";
import { toast } from "react-hot-toast";

export const usePostSkill = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: saveSkill,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ keyword, type }) =>
      postSkill({
        keyword,
        type,
      }),
    onSuccess: () => {
      toast.success("Skill has been submitted successfully");
      queryClient.invalidateQueries({ queryKey: ["skills"] });
    },
    onError: () => {
      toast.error("Failed to save skill");
    },
  });

  return { saveSkill, isPending, isSuccess };
};
