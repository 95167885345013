import PropTypes from "prop-types";

function EyeCrossIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,2C6,2,1,6.3,0,12C1,18,6.8,22.5,13,22c5.5-0.4,10.1-4.6,11-10C23,6.3,18,2,12,2z M12,17.1c-2.8,0-5.1-2.3-5.1-5.1 c0.2-6.7,10-6.7,10.2,0C17.1,14.8,14.8,17.1,12,17.1z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,10c-1.1,0-2,0.9-2,2c0,0.2,0,0.5,0.1,0.7c0.3,0.7,1,1.3,1.9,1.3s1.6-0.5,1.9-1.3C14,12.5,14,12.2,14,12 C14,10.9,13.1,10,12,10z"
      />
    </svg>
  );
}

EyeCrossIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EyeCrossIcon;
