import { useQuery } from "@tanstack/react-query";

import { getTalentPoolActions } from "../services/apiResume";
import { useParams } from "react-router-dom";

export function useGetTalentPoolActions() {
  const { id } = useParams();

  const { isLoading, data, error } = useQuery({
    queryKey: ["talentPoolActions", id],
    queryFn: () => getTalentPoolActions(id),
    retry: false,
  });

  return { isLoading, data, error };
}
