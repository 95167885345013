import { useQuery } from "@tanstack/react-query";
import { getUserResume } from "../services/apiResume";

export function useUserResume() {
  const { isLoading, data, isPending } = useQuery({
    queryKey: ["userResume"],
    queryFn: getUserResume,
    retry: false,
  });

  return { isLoading, data, isPending };
}
