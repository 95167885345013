import { useForm } from "react-hook-form";
import { useGetTalentPoolActions } from "../../hooks/useGetTalentPoolActions";
import Accordion from "../../shared/Accordion";
import TextArea from "../../shared/inputs/TextArea";
import Input from "../../shared/inputs/Input";
import Loader from "../../shared/loader/Loader";
import { useParams } from "react-router-dom";
import { useUpdateTalentPoolActions } from "../../hooks/useUpdateTalentPoolActions";
import CustomDatePick from "../../shared/datepicker/CustomDatePick";
import { HiMiniArrowTopRightOnSquare, HiTrash } from "react-icons/hi2";
import { useDeleteTalentPoolAction } from "../../hooks/useDeleteTalentPoolAction";

function TalentPool() {
  const NOTE_TYPE = 1;
  const REMINDER_TYPE = 2;
  const LINK_TYPE = 3;

  const { isLoading, data } = useGetTalentPoolActions();
  const { results } = data || {};

  const { updateTalentPoolActions, isPending } = useUpdateTalentPoolActions();

  const formNotes = useForm();
  const formReminders = useForm();
  const formLinks = useForm();

  const { id: resumeId } = useParams();

  const onSubmitNotes = (data) => {
    updateTalentPoolActions(
      {
        ...data,
        resumeId,
        type: NOTE_TYPE,
      },
      { onSuccess: () => formNotes?.reset() },
    );
  };

  const onSubmitReminders = (data) => {
    updateTalentPoolActions(
      {
        resumeId,
        type: REMINDER_TYPE,
        content: data?.content,
        reminderDate: `${data.date} ${data?.time}`,
      },
      { onSuccess: () => formReminders?.reset() },
    );
  };

  const onSubmitLinks = (data) => {
    updateTalentPoolActions(
      {
        ...data,
        resumeId,
        type: LINK_TYPE,
      },
      { onSuccess: () => formLinks?.reset() },
    );
  };

  const { deleteTalentPoolAction, isPending: isDeletingTalentPoolAction } =
    useDeleteTalentPoolAction();

  if (isLoading) return <Loader />;

  return (
    <Accordion>
      <div className="border-b border-black-g-75 p-3">
        <div className="text-xl font-medium">Actions</div>
      </div>

      <Accordion.Item index={0} className="border-b border-black-g-75 p-3">
        <Accordion.Header>
          <div className="flex space-x-1 text-xl">
            <div>Notes</div>
            <div className="flex">
              (
              {isLoading ? (
                <Loader />
              ) : (
                results?.filter((item) => item?.type === NOTE_TYPE)?.length
              )}
              )
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Panel className="my-4">
          <div className="mb-4">
            <div className="-m-2 flex flex-col">
              {results
                ?.filter((item) => item?.type === NOTE_TYPE)
                ?.map((note) => (
                  <div key={note?.id} className="p-2">
                    <div className="rounded-md bg-slate-100 p-2">
                      <div className="-m-1 flex items-start">
                        <div className="flex-1 p-1">{note?.content}</div>

                        <div
                          className="cursor-pointer p-1 text-black-g-75 hover:text-red-o-primary"
                          onClick={() =>
                            deleteTalentPoolAction({
                              id: note?.id,
                              resumeId,
                              type: NOTE_TYPE,
                              content: note?.content,
                            })
                          }
                        >
                          <HiTrash className="h-5 w-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <form onSubmit={formNotes?.handleSubmit(onSubmitNotes)}>
            <TextArea
              placeholder="Add note ..."
              id="note"
              controllerName="content"
              control={formNotes?.control}
              resetField={formNotes?.resetField}
              containerClassName="border-2 border-black-g-50 bg-white"
              disabled={isPending}
            />

            <div className="mt-4 flex">
              <button
                className="btn-blue-b-primary ml-auto py-1.5 text-lg font-medium"
                disabled={isPending}
              >
                {isPending ? <Loader color="#ffffff" /> : "Add note"}
              </button>
            </div>
          </form>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item index={1} className="border-b border-black-g-75 p-3">
        <Accordion.Header>
          <div className="flex space-x-1 text-xl">
            <div>Reminders</div>
            <div className="flex">
              (
              {isLoading ? (
                <Loader />
              ) : (
                results?.filter((item) => item?.type === REMINDER_TYPE)?.length
              )}
              )
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Panel className="my-4">
          <div className="mb-4">
            <div className="-m-2 flex flex-col">
              {results
                ?.filter((item) => item?.type === REMINDER_TYPE)
                ?.map((reminder) => (
                  <div key={reminder?.id} className="p-2">
                    <div className="flex items-center">
                      <div className="flex flex-1 items-center space-x-2 rounded-md bg-slate-100 p-2">
                        <span>{reminder?.reminderDate}</span>
                        <span>{reminder?.content}</span>
                      </div>

                      <div
                        className="cursor-pointer p-1 text-black-g-75 hover:text-red-o-primary"
                        onClick={() =>
                          deleteTalentPoolAction({
                            id: reminder?.id,
                            resumeId,
                            type: REMINDER_TYPE,
                            content: reminder?.content,
                          })
                        }
                      >
                        <HiTrash className="h-5 w-5" />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <form onSubmit={formReminders?.handleSubmit(onSubmitReminders)}>
            <div className="-m-2 flex flex-wrap">
              <div className="w-full p-2">
                <Input
                  type="text"
                  id="name"
                  placeholder="Add a title ..."
                  autoComplete="name"
                  controllerName="content"
                  control={formReminders?.control}
                  resetField={formReminders?.resetField}
                  containerClassName="border-2 border-black-g-50 bg-white"
                  disabled={isPending}
                />
              </div>

              <div className="w-full p-2">
                <CustomDatePick
                  controllerName={"date"}
                  labelName={"Date"}
                  control={formReminders?.control}
                  className="flex-1"
                />
              </div>

              <div className="w-full p-2">
                <Input
                  type="time"
                  id="time"
                  placeholder="Add time ..."
                  controllerName="time"
                  control={formReminders?.control}
                  resetField={formReminders?.resetField}
                  containerClassName="border-2 border-black-g-50 bg-white"
                  disabled={isPending}
                />
              </div>

              <div className="flex w-full p-2">
                <button
                  className="btn-blue-b-primary ml-auto py-1.5 text-lg font-medium"
                  disabled={isPending}
                >
                  {isPending ? <Loader color="#ffffff" /> : "Add a reminder"}
                </button>
              </div>
            </div>
          </form>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item index={2} className="border-b border-black-g-75 p-3">
        <Accordion.Header>
          <div className="flex space-x-1 text-xl">
            <div>Links</div>
            <div className="flex">
              (
              {isLoading ? (
                <Loader />
              ) : (
                results?.filter((item) => item?.type === LINK_TYPE)?.length
              )}
              )
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Panel className="my-4">
          <div className="mb-4">
            <div className="-m-2 flex flex-col">
              {results
                ?.filter((item) => item?.type === LINK_TYPE)
                ?.map((link) => (
                  <div key={link?.id} className="p-2">
                    <div className="rounded-md bg-slate-100 p-2">
                      <div className="-m-1 flex items-start">
                        <a
                          className="flex flex-1 items-center space-x-1 p-1"
                          href={link?.content}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <HiMiniArrowTopRightOnSquare className="h-4 w-4" />
                          <span className="text-blue-b-primary hover:underline">
                            {link?.content}
                          </span>
                        </a>

                        <div
                          className="cursor-pointer p-1 text-black-g-75 hover:text-red-o-primary"
                          onClick={() =>
                            deleteTalentPoolAction({
                              id: link?.id,
                              resumeId,
                              type: LINK_TYPE,
                              content: link?.content,
                            })
                          }
                        >
                          <HiTrash className="h-5 w-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <form onSubmit={formLinks?.handleSubmit(onSubmitLinks)}>
            <Input
              type="text"
              id="name"
              placeholder="Add link ..."
              autoComplete="name"
              controllerName="content"
              control={formLinks?.control}
              rules={{
                pattern: {
                  value: /^(https?:\/\/)([^\s$.?#].[^\s]*)$/i,
                  message: "Invalid url",
                },
              }}
              resetField={formLinks?.resetField}
              containerBaseClassName="border-2 rounded-r-md h-full"
              containerClassName="bg-white"
              disabled={isPending}
            />

            <div className="mt-4 flex">
              <button
                className="btn-blue-b-primary ml-auto py-1.5 text-lg font-medium"
                disabled={isPending}
              >
                {isPending ? <Loader color="#ffffff" /> : "Add link"}
              </button>
            </div>
          </form>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export default TalentPool;
