import PropTypes from "prop-types";

function DocumentFilterIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M8.2597 18.783C7.83969 18.783 7.41971 18.678 7.03471 18.4563C6.25304 18.013 5.78637 17.2196 5.78637 16.333V12.168C5.78637 11.8646 5.58803 11.3513 5.34303 11.0596L2.40301 7.96795C1.85467 7.43128 1.45801 6.49796 1.45801 5.78629V3.97797C1.45801 2.5663 2.53131 1.44629 3.90798 1.44629H14.2913C15.6447 1.44629 16.7413 2.54296 16.7413 3.8963V5.62298C16.7413 6.53298 16.2164 7.53629 15.703 8.04963L12.2847 11.0713C12.0164 11.293 11.7947 11.8296 11.7947 12.2263V15.6096C11.7947 16.4146 11.3047 17.313 10.663 17.698L9.58964 18.398C9.16964 18.6546 8.71469 18.783 8.2597 18.783ZM3.90798 3.20797C3.51131 3.20797 3.20801 3.54632 3.20801 3.98965V5.79797C3.20801 5.9963 3.40636 6.49798 3.66303 6.75464L6.67297 9.9163C7.11631 10.4646 7.54797 11.363 7.54797 12.168V16.333C7.54797 16.683 7.76971 16.858 7.90971 16.928C8.09638 17.033 8.39965 17.0913 8.65632 16.928L9.74131 16.228C9.88131 16.1463 10.0447 15.8197 10.0447 15.6213V12.238C10.0447 11.3163 10.4997 10.3013 11.1413 9.75295L14.5013 6.77796C14.7113 6.56796 14.9913 5.99631 14.9913 5.62298V3.8963C14.9913 3.5113 14.6763 3.19629 14.2913 3.19629H3.90798V3.20797Z" />
      <path d="M17.4997 26.5415H10.4997C4.16467 26.5415 1.45801 23.8348 1.45801 17.4998V13.9998C1.45801 13.5215 1.85467 13.1248 2.33301 13.1248C2.81134 13.1248 3.20801 13.5215 3.20801 13.9998V17.4998C3.20801 22.8782 5.12134 24.7915 10.4997 24.7915H17.4997C22.878 24.7915 24.7913 22.8782 24.7913 17.4998V10.4998C24.7913 7.11649 23.998 5.15648 22.213 4.14148C22.0263 4.03648 21.408 3.7565 19.6347 3.4765C19.1564 3.4065 18.8297 2.95149 18.9114 2.47315C18.993 1.99482 19.448 1.66816 19.9147 1.74982C21.443 1.99482 22.4814 2.27481 23.0764 2.61315C25.433 3.95481 26.5413 6.46316 26.5413 10.4998V17.4998C26.5413 23.8348 23.8347 26.5415 17.4997 26.5415Z" />
      <path d="M21.0003 16.042H15.167C14.6887 16.042 14.292 15.6453 14.292 15.167C14.292 14.6887 14.6887 14.292 15.167 14.292H21.0003C21.4787 14.292 21.8753 14.6887 21.8753 15.167C21.8753 15.6453 21.4787 16.042 21.0003 16.042Z" />
      <path d="M20.9997 20.708H12.833C12.3547 20.708 11.958 20.3113 11.958 19.833C11.958 19.3547 12.3547 18.958 12.833 18.958H20.9997C21.478 18.958 21.8747 19.3547 21.8747 19.833C21.8747 20.3113 21.478 20.708 20.9997 20.708Z" />
    </svg>
  );
}

DocumentFilterIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default DocumentFilterIcon;
