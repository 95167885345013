import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AccountSetupFlow from "./components/authentication/signup/AccountSetupFlow";
import ProfileSetupFlow from "./components/authentication/signup/ProfileSetupFlow";
import SignupConfirmEmail from "./components/authentication/signup/SignupConfirmEmail";
import SignupSuccess from "./components/authentication/signup/SignupSuccess";
import About from "./pages/About";
import ConfirmEmail from "./pages/ConfirmEmail";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import HomeCandidate from "./pages/HomeCandidate";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import LayoutPublic from "./shared/LayoutPublic";
import ProtectedRoute from "./shared/ProtectedRoute";

import LoginFirstTime from "./components/candidate/LoginFirstTime";
import CandidateDashboard from "./components/user/CandidateDashboard";
import CandidateMyResume from "./pages/CandidateMyResume";
import CandidateResume from "./pages/CandidateResume";
import JobDescriptionSingle from "./pages/JobDescriptionSingle";
import JobDescriptionSinglePublic from "./pages/JobDescriptionSinglePublic";
import JobsPage from "./pages/JobsPage";
import LayoutPrivate from "./shared/LayoutPrivate";

import RecruiterDashboard from "./components/user/RecruiterDashboard";
import BrowseTalents from "./pages/BrowseTalents";

import BasicInformation from "./components/authentication/signup/recruiter/BasicInformation.jsx";
import CompanyProfile from "./components/authentication/signup/recruiter/CompanyProfile.jsx";
import ProfileSetup from "./components/authentication/signup/recruiter/ProfileSetup.jsx";
import SocialMedia from "./components/authentication/signup/recruiter/SocialMedia.jsx";
import Welcome from "./components/authentication/signup/recruiter/Welcome.jsx";
import CandidatePersonalInformationStep from "./components/candidate/CandidatePersonalInformationStep";
import CompanyProfileInformationStep from "./components/candidate/CompanyProfileInformationStep";
import CompanyUserInformationStep from "./components/candidate/CompanyUserInformationStep";
import ContactsInfoStep from "./components/candidate/ContactsInfoStep";
import EducationStep from "./components/candidate/EducationStep";
import SkillsStep from "./components/candidate/SkillsStep";
import SocialContactStep from "./components/candidate/SocialContactStep";
import WorkExperienceStep from "./components/candidate/WorkExperienceStep";
import { CondidateChatPage } from "./pages/CondidateChatPage.jsx";
import CreateJob from "./pages/CreateJob";
import CreateProfile from "./pages/CreateProfile";
import JobApplicants from "./pages/JobApplicants";
import RecruitmentBoard from "./pages/RecruitmentBoard";
import { RecruiterChatPage } from "./pages/RecruterChatPage.jsx";
import UserCandidateProfile from "./pages/UserCandidateProfile";
import UserCompanyProfile from "./pages/UserCompanyProfile";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 0, gcTime: 0 } },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Routes>
          {/* REDIRECTION */}
          <Route index element={<Navigate replace to={"home"} />} />

          {/* LAYOUT FOR PUBLIC USER */}

          <Route element={<LayoutPublic />}>
            <Route path="home" element={<Home />} />
            <Route path="for-candidate" element={<HomeCandidate />} />
            <Route path="get-in-touch" element={<Contact />} />
            <Route path="about-us" element={<About />} />
            <Route path="signup" element={<Signup />}>
              <Route index element={<AccountSetupFlow />} />
              <Route path="profile-setup" element={<ProfileSetupFlow />} />
              <Route path="confirm-email" element={<SignupConfirmEmail />} />
              <Route path="success" element={<SignupSuccess />} />
            </Route>

            <Route path="confirm-email" element={<ConfirmEmail />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route
              path="job-detail/:id"
              element={<JobDescriptionSinglePublic />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Route>

          <Route path="signup/recruiter" element={<ProfileSetup />}>
            <Route path="basic-information" element={<BasicInformation />} />
            <Route path="company-profile" element={<CompanyProfile />} />
            <Route path="social-media" element={<SocialMedia />} />
          </Route>

          {/* LAYOUT FOR RECRUITER PROTECTED INTERFACE */}
          <Route
            path="/recruiter"
            element={
              <ProtectedRoute allowedRoles={["admin", "recruiter"]}>
                <LayoutPrivate />
              </ProtectedRoute>
            }
          >
            <Route path="welcome" element={<Welcome />} />
            <Route index element={<Navigate replace to={"candidates"} />} />
            <Route path="dashboard" element={<RecruiterDashboard />} />
            <Route path="chat-box" element={<RecruiterChatPage />} />
            <Route path="candidates" element={<BrowseTalents />} />
            <Route path="candidate/:id" element={<CandidateResume />} />
            <Route path="applicants" element={<JobApplicants />} />
            <Route
              path="applicants/:jobDescriptionId"
              element={<JobApplicants />}
            />

            <Route path="jobs" element={<JobsPage />} />
            <Route path="job-detail/:id" element={<JobDescriptionSingle />} />

            <Route path="add-job" element={<CreateJob />} />
            <Route path="edit-job/:id" element={<CreateJob />} />
            <Route path="profile" element={<UserCompanyProfile />}>
              <Route index element={<CompanyUserInformationStep />} />
              <Route
                path="step=1"
                index
                element={<CompanyUserInformationStep />}
              />
              <Route
                path="step=2"
                index
                element={<CompanyProfileInformationStep />}
              />
              <Route
                path="step=3"
                element={
                  <SocialContactStep
                    hideClearSection={true}
                    hideHeader={true}
                  />
                }
              />
            </Route>
            <Route path="recruitment-board" element={<RecruitmentBoard />} />
          </Route>

          <Route
            path="add-profile"
            element={
              <ProtectedRoute allowedRoles={["admin", "candidate"]}>
                <CreateProfile />
              </ProtectedRoute>
            }
          >
            <Route index element={<CandidatePersonalInformationStep />} />

            <Route
              path="step=1"
              index
              element={<CandidatePersonalInformationStep />}
            />

            <Route path="step=2" element={<ContactsInfoStep />} />
            <Route path="step=3" element={<SocialContactStep />} />
            <Route path="step=4" element={<SkillsStep />} />
            <Route path="step=5" element={<WorkExperienceStep />} />
            <Route path="step=6" element={<EducationStep />} />
          </Route>

          {/* LAYOUT FOR CANDIDATE PROTECTED INTERFACE */}

          <Route
            path="/candidate/welcome"
            element={
              <ProtectedRoute allowedRoles={["admin", "candidate"]}>
                <LoginFirstTime />
              </ProtectedRoute>
            }
          />

          <Route
            path="/candidate"
            element={
              <ProtectedRoute allowedRoles={["admin", "candidate"]}>
                <LayoutPrivate />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate replace to={"jobs"} />} />
            <Route path="dashboard" element={<CandidateDashboard />} />
            <Route path="chat-box" element={<CondidateChatPage />} />
            <Route path="jobs" element={<JobsPage />} />
            <Route path="job-detail/:id" element={<JobDescriptionSingle />} />
            <Route path="myresume/:id" element={<CandidateResume />} />
            <Route path="resume" element={<CandidateMyResume />} />
            <Route path="profile" element={<UserCandidateProfile />}>
              <Route
                index
                element={
                  <CandidatePersonalInformationStep hideClearSection={true} />
                }
              />
              <Route
                path="step=1"
                index
                element={
                  <CandidatePersonalInformationStep hideClearSection={true} />
                }
              />
              <Route
                path="step=2"
                element={<ContactsInfoStep hideClearSection={true} />}
              />
              <Route
                path="step=3"
                element={<SocialContactStep hideClearSection={true} />}
              />
              <Route
                path="step=4"
                element={<SkillsStep hideClearSection={true} />}
              />
              <Route
                path="step=5"
                element={<WorkExperienceStep hideClearSection={true} />}
              />
              <Route
                path="step=6"
                element={<EducationStep hideClearSection={true} />}
              />
            </Route>
          </Route>

          {/* <Route path="docs" element={<Documentation />} /> */}
        </Routes>
      </BrowserRouter>

      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 5000 },
          error: { duration: 5000 },
          className: "border border-blue-b-primary",
        }}
      />
    </QueryClientProvider>
  );
}

export default App;
