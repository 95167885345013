import LogoIcon from "../shared/logo/LogoIcon";
import ContactForm from "../components/contacts/ContactForm";

function Contact() {
  return (
    <>
      <section>
        <div className="page-px bg-[#F7F9FB] py-16">
          <div className="flex justify-center">
            <div className="m-auto w-full max-w-[45rem] bg-white p-8 shadow-md shadow-slate-200">
              <div className="mb-6 flex justify-center">
                <LogoIcon size={18} className="mx-auto" />
              </div>

              <div className="mb-12 flex flex-col text-center">
                <h1 className="text-[2rem] font-medium">Activate Next Now</h1>
                <p className="text-black-g-100">
                  Please fill the form and we will guide you to the best
                  solution. Our experts will get in touch soon.
                </p>
              </div>

              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
