import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";

/**
 * to select only one option
  onSelect={(item) => {
            setSelectedOptions(item.value);
            }}
  isChecked={selectedOptions === item.value}
  --------------------------------------
  * to select multiple options
  onSelect={(item) => {
            setSelectedOptions([...prevOptions,item]);
            }}
  isChecked={selectedOptions.includes(item.value)}
 */
function CustomCheckbox({ labelName, inputId, onSelect, isChecked, value }) {
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    const updatedChecked = !checked;
    setChecked(updatedChecked);
    if (onSelect) {
      onSelect({ inputId, value, isChecked: updatedChecked });
    }
  };

  return (
    <div className="flex items-center">
      <label
        htmlFor={inputId}
        className={`relative h-4 w-4 cursor-pointer rounded border ${
          checked
            ? "border-blue-b-400 bg-blue-b-primary"
            : "border-black-g-100 bg-white"
        }`}
        onClick={handleCheckboxChange}
      >
        {checked && (
          <FaCheck
            size={10}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white"
          />
        )}
      </label>
      {labelName ? (
        <span className="ml-2 font-primary text-sm font-normal text-[#1C2436]">
          {labelName}
        </span>
      ) : null}
    </div>
  );
}

CustomCheckbox.propTypes = {
  inputId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelName: PropTypes.string,
  onSelect: PropTypes.func,
  isChecked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCheckbox.defaultProps = {
  labelName: null,
  onSelect: null,
  isChecked: false,
  value: null,
};

export default CustomCheckbox;
