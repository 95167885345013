import { useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";

function Signup() {
  const {
    control,
    setError,
    trigger,
    getValues,
    handleSubmit,
    resetField,
    reset,
    setValue,
  } = useForm();

  return (
    <section>
      <div className="page-px bg-[#F7F9FB] py-16">
        <div className="flex justify-center">
          <Outlet
            context={{
              control,
              setError,
              trigger,
              getValues,
              handleSubmit,
              resetField,
              reset,
              setValue,
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Signup;
