import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useForgotPassword } from "../../hooks/useForgotPassword";
import Loader from "../../shared/loader/Loader";
import Input from "../../shared/inputs/Input";
import Divider from "../../shared/Divider";

function ForgotPasswordForm() {
  const { control, handleSubmit, setError, resetField, reset } = useForm();

  const { forgotPassword, isPending, isLinkSent } = useForgotPassword();

  const onSubmit = ({ email }) => {
    forgotPassword(
      { email },
      {
        onSuccess: reset,
        onError: (err) => {
          if (err?.code === 400)
            setError(
              "email",
              { type: "not-registered", message: err?.message },
              { shouldFocus: true },
            );
        },
      },
    );
  };
  const onError = (errs) => {
    toast.error(errs?.email?.message);
  };

  return (
    <>
      {!isLinkSent ? (
        <>
          <p className="mb-8 text-slate-600">
            <span className="font-medium">Enter the email</span> that you used
            when you signed up to recover your password. You will receive a
            <span className="font-medium"> password reset link</span>.
          </p>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="-m-3 flex flex-col">
              <div className="p-3">
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  placeholder="example@domain.com"
                  autoComplete="email"
                  controllerName="email"
                  control={control}
                  rules={{
                    required: "Email address is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please provide a valid email address",
                    },
                  }}
                  resetField={resetField}
                  disabled={isPending}
                />
              </div>

              <div className="p-3">
                <button
                  className="btn-blue-b-primary w-full text-lg font-medium"
                  disabled={isPending}
                >
                  {isPending ? <Loader color="#ffffff" /> : "Send link"}
                </button>
              </div>
            </div>
          </form>

          <Divider className="my-8" />

          <div className="text-center">
            <p>If you need further assistance</p>
            <Link
              className="font-medium text-blue-b-primary"
              to={"/get-in-touch"}
            >
              contact our support team
            </Link>
          </div>
        </>
      ) : (
        <>
          <p className="text-center text-lg font-medium">
            We&apos;ve sent you an email with a link to reset your password.
          </p>

          <p className="mt-3 text-center text-sm">
            Check your spam and promotions folder if it doesn&apos;t appear in
            your main mailbox.
          </p>
        </>
      )}
    </>
  );
}

export default ForgotPasswordForm;
