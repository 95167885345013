import PropTypes from "prop-types";

function DocumentTextIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5166 22.75H9.5166C4.0866 22.75 1.7666 20.43 1.7666 15V9C1.7666 3.57 4.0866 1.25 9.5166 1.25H14.5166C14.9266 1.25 15.2666 1.59 15.2666 2C15.2666 2.41 14.9266 2.75 14.5166 2.75H9.5166C4.9066 2.75 3.2666 4.39 3.2666 9V15C3.2666 19.61 4.9066 21.25 9.5166 21.25H15.5166C20.1266 21.25 21.7666 19.61 21.7666 15V10C21.7666 9.59 22.1066 9.25 22.5166 9.25C22.9266 9.25 23.2666 9.59 23.2666 10V15C23.2666 20.43 20.9466 22.75 15.5166 22.75Z" />
      <path d="M22.5166 10.7505H18.5166C15.0966 10.7505 13.7666 9.42048 13.7666 6.00048V2.00048C13.7666 1.70048 13.9466 1.42048 14.2266 1.31048C14.5066 1.19048 14.8266 1.26048 15.0466 1.47048L23.0466 9.47048C23.2566 9.68048 23.3266 10.0105 23.2066 10.2905C23.0866 10.5705 22.8166 10.7505 22.5166 10.7505ZM15.2666 3.81048V6.00048C15.2666 8.58048 15.9366 9.25048 18.5166 9.25048H20.7066L15.2666 3.81048Z" />
      <path d="M13.5166 13.75H7.5166C7.1066 13.75 6.7666 13.41 6.7666 13C6.7666 12.59 7.1066 12.25 7.5166 12.25H13.5166C13.9266 12.25 14.2666 12.59 14.2666 13C14.2666 13.41 13.9266 13.75 13.5166 13.75Z" />
      <path d="M11.5166 17.75H7.5166C7.1066 17.75 6.7666 17.41 6.7666 17C6.7666 16.59 7.1066 16.25 7.5166 16.25H11.5166C11.9266 16.25 12.2666 16.59 12.2666 17C12.2666 17.41 11.9266 17.75 11.5166 17.75Z" />
    </svg>
  );
}

DocumentTextIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default DocumentTextIcon;
