import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { RiUserLine } from "react-icons/ri";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useSignupUser } from "../../../hooks/useSignupUser";
import { useUploadUserPicture } from "../../../hooks/useUploadUserPicture";
/* import { useGetLinkedInAccessToken } from "../../../hooks/useGetLinkedInAccessToken";
import { useGetLinkedInUserinfo } from "../../../hooks/useGetLinkedInUserinfo"; */
import { useGetIndustries } from "../../../hooks/useGetIndustries";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useSearchCountries } from "../../../hooks/useSearchCountries";
import BuildingIcon from "../../../icons/BuildingIcon";
import Input from "../../../shared/inputs/Input";
import PhotoInput from "../../../shared/inputs/PhotoInput";
import RadioButtons from "../../../shared/inputs/RadioButtons";
import SelectBox from "../../../shared/inputs/SelectBox";
import Loader from "../../../shared/loader/Loader";
import LogoIcon from "../../../shared/logo/LogoIcon";

function ProfileSetupFlow() {
  const LINKEDIN_STATE = "LinkedInOIDC";
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const navigate = useNavigate();

  const [showRecruiterProfileForm, setShowRecruiterProfileForm] =
    useState(false);

  const { isLoading: isLoadingIndustries, data: industries } =
    useGetIndustries();

  const {
    control,
    setError,
    getValues,
    handleSubmit,
    resetField,
    reset,
    setValue,
    trigger,
  } = useOutletContext();

  /////////////////
  useEffect(
    function () {
      if (
        state !== LINKEDIN_STATE &&
        !code &&
        (!getValues("email") || !getValues("password"))
      )
        navigate("/signup", { replace: true });
    },
    [state, code, getValues, navigate],
  );

  // Country autocomplete
  const [openCountryList, setOpenCountryList] = useState(false);
  const [countryName, setCountryName] = useState("");
  const countryList = useOutsideClick(() => setOpenCountryList(false));

  const { data: searchedCountries, isLoading: isLoadingSearchedCountries } =
    useSearchCountries({ name: countryName });

  const {
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  } = useUploadUserPicture();

  const { signupUser, isPending } = useSignupUser();

  /* const { isLoading: isLoadingAccessToken, data: dataAccessToken } =
    useGetLinkedInAccessToken({
      code,
    }); */

  /* const { isLoading: isLoadingLinkedInUserinfo, data: linkedInUserinfo } =
    useGetLinkedInUserinfo({
      accessToken: dataAccessToken?.id_token,
    }); */

  /* if (
    state !== LINKEDIN_STATE &&
    !code &&
    (!getValues("email") || !getValues("password"))
  )
    return null; */

  if (state === LINKEDIN_STATE && code /* && isLoadingAccessToken */)
    return <Loader />;

  const handleUploadProfilePicture = (e) => {
    const file = e.target?.value;

    uploadUserPicture(file, {
      onSuccess: () => {
        resetField("profilePicture");
      },
      onError: (err) => {
        if (err?.code === 400)
          setError(
            "profilePicture",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
        resetUploadUserPicture();
      },
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onSubmit = ({ confirm_password, ...data }) => {
    const payload =
      state === LINKEDIN_STATE
        ? { ...data }
        : { ...data, profilePicture: String(uploadedUserPicture?.result) };

    if (payload?.role === "recruiter") {
      navigate("/signup/recruiter/basic-information", { state: { payload } });
    }
    if (payload.role === "candidate")
      signupUser(payload, {
        onSuccess: () => {
          resetUploadUserPicture();
          reset();
          navigate("/signup/confirm-email", {
            state: {
              email: data?.email,
            },
            replace: true,
          });
        },
      });
  };

  const onError = () => {
    toast.error((t) => (
      <div className="flex items-start">
        <p className="mx-2">
          Oops! There are errors in your form submission. Please review and
          correct them.
        </p>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="rounded border bg-slate-200 px-2 py-1 text-xs hover:border-slate-200 hover:bg-white"
        >
          close
        </button>
      </div>
    ));
  };

  return (
    <div className="m-auto w-full max-w-[45rem] bg-white p-8 shadow-md shadow-slate-200">
      <div className="mb-6 flex justify-center">
        <LogoIcon size={18} className="mx-auto" />
      </div>

      <div className="mb-8 flex flex-col text-center">
        <h1 className="text-[2rem] font-medium">Set up profil</h1>
        <p className="text-black-g-100">Add a profile photo and your name</p>
      </div>

      <div className="mb-10 flex justify-center">
        <PhotoInput
          control={control}
          id="profilePicture"
          controllerName="profilePicture"
          rules={{
            onChange: handleUploadProfilePicture,
          }}
          isUploading={
            isUploadingUserPicture /* || isLoadingLinkedInUserinfo */
          }
          disabled={isUploadingUserPicture || isPending}
          src={uploadedUserPicture?.result /* || linkedInUserinfo?.picture */}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="-m-4 flex flex-wrap">
          <div className="w-1/2 p-4">
            <Input
              label="First name"
              type="text"
              id="firstName"
              placeholder="First name"
              autoComplete="given-name"
              controllerName="firstName"
              control={control}
              rules={{
                required: "First name is required",
                minLength: {
                  value: 2,
                  message: "This fisrt name is too short",
                },
              }}
              resetField={resetField}
              disabled={isPending}
            />
          </div>

          <div className="w-1/2 p-4">
            <Input
              label="Last name"
              type="text"
              id="lastName"
              placeholder="Last name"
              autoComplete="family-name"
              controllerName="lastName"
              control={control}
              rules={{
                required: "Last name is required",
                minLength: {
                  value: 2,
                  message: "This last name is too short",
                },
              }}
              resetField={resetField}
              disabled={isPending}
            />
          </div>

          <RadioButtons
            controllerName="role"
            control={control}
            rules={{
              required:
                "Please select the Please select your role as a candidate or recruiter",
            }}
          >
            <div className="w-1/2 p-4">
              <RadioButtons.Button
                value="candidate"
                className="flex h-full items-center space-x-2 border border-black-g-50 p-5 hover:border-blue-b-primary hover:bg-white"
                selectedClassName="border border-blue-b-primary"
                onClick={() => {
                  setShowRecruiterProfileForm(false);
                  resetField("employerName");
                  resetField("companyPhone");
                  resetField("countryId");
                  resetField("industry");
                }}
              >
                <RadioButtons.Label className="flex flex-1 items-center space-x-2 text-lg font-medium">
                  <RiUserLine className="h-6 w-6 text-blue-b-primary" />
                  <div>Freelancer/candidate</div>
                </RadioButtons.Label>
              </RadioButtons.Button>
            </div>

            <div className="w-1/2 p-4">
              <RadioButtons.Button
                value="recruiter"
                className="flex h-full items-center space-x-2 border border-black-g-50 p-5 hover:border-blue-b-primary hover:bg-white"
                selectedClassName="border border-blue-b-primary"
                onClick={() => setShowRecruiterProfileForm(false)}
              >
                <RadioButtons.Label className="flex flex-1 items-center space-x-2 text-lg font-medium">
                  <BuildingIcon className="h-6 w-6 text-blue-b-primary" />
                  <div>I am a company</div>
                </RadioButtons.Label>
              </RadioButtons.Button>
            </div>
            <RadioButtons.Error className="w-full px-4 pb-4" />
          </RadioButtons>

          {showRecruiterProfileForm && (
            <>
              <div className="w-1/2 p-4">
                <Input
                  label="Company name"
                  type="text"
                  id="employerName"
                  placeholder="Enter company name"
                  autoComplete="organization"
                  controllerName="employerName"
                  control={control}
                  rules={{
                    required: "Company name is required",
                    minLength: {
                      value: 2,
                      message: "This company name is too short",
                    },
                  }}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />
              </div>

              <div className="w-1/2 p-4">
                <SelectBox
                  label="Company industry"
                  placeholder="Select company industry"
                  id="industry"
                  control={control}
                  controllerName="industry"
                  rules={{
                    required: "Please select an industry",
                  }}
                  isLoading={isLoadingIndustries}
                  options={{
                    data: industries,
                    key: "id",
                    value: "id",
                    name: "name",
                  }}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />
              </div>

              <div className="w-1/2 p-4">
                {/* <SelectBox
                  label="Location"
                  placeholder="Select company location"
                  id="countryId"
                  control={control}
                  controllerName="countryId"
                  rules={{
                    required: "Please select the company location",
                  }}
                  isLoading={isLoadingCountries}
                  options={{
                    data: countries,
                    key: "id",
                    value: "id",
                    name: "name",
                  }}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                /> */}

                <div className="relative">
                  <Input
                    label={"Location"}
                    type={"hidden"}
                    control={control}
                    controllerName={"countryId"}
                    id={"countryId"}
                    resetField={resetField}
                  />

                  <Input
                    label={"Location"}
                    type={"text"}
                    control={control}
                    autoComplete={"off"}
                    controllerName={"countryName"}
                    id={"countryName"}
                    placeholder={"Search company location"}
                    rules={{
                      validate: () => {
                        return (
                          getValues("countryId") || "Please select a location"
                        );
                      },
                      onChange: (e) => {
                        setOpenCountryList(true);
                        setCountryName(e.target?.value);
                      },
                      onBlur: () => {
                        setValue(
                          "countryName",
                          searchedCountries?.[0]
                            ? searchedCountries?.[0]?.name
                            : "",
                        );
                        setValue(
                          "countryId",
                          searchedCountries?.[0]
                            ? searchedCountries?.[0]?.id
                            : "",
                        );
                        trigger("countryName");
                      },
                    }}
                    resetField={() => {
                      resetField("countryId");
                      resetField("countryName");
                    }}
                    disabled={isUploadingUserPicture || isPending}
                  />
                  {openCountryList && (
                    <div
                      ref={countryList}
                      className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                    >
                      {isLoadingSearchedCountries && (
                        <div className="p-4">
                          <Loader />
                        </div>
                      )}

                      {searchedCountries?.map((country) => (
                        <div
                          key={country?.id}
                          className="ingml-btn hover:bg-blue-b-50"
                          onClick={() => {
                            setValue("countryId", country?.id);
                            setValue("countryName", country?.name);

                            setOpenCountryList(false);
                          }}
                        >
                          {country?.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-1/2 p-4">
                <Input
                  label="Phone number"
                  type="text"
                  id="companyPhone"
                  placeholder="Enter company phone number"
                  autoComplete="tel"
                  controllerName="companyPhone"
                  control={control}
                  rules={{
                    required: "Company phone number is required",
                  }}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />
              </div>
            </>
          )}

          <div className="w-full p-4">
            <button
              className="btn-blue-b-primary w-full text-lg font-medium"
              disabled={isUploadingUserPicture || isPending}
            >
              {isUploadingUserPicture || isPending ? (
                <Loader color="#ffffff" />
              ) : (
                "Sign up"
              )}
            </button>
          </div>
          <div className="w-full p-4">
            <p className="text-center text-xs text-black-g-100">
              By signing up, you agree to the Terms of Service and Privacy
              Policy, including Cookie Use.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProfileSetupFlow;
