import { useFormContext } from "react-hook-form";
import Input from "../../shared/inputs/Input";

function CompanyUserInformationStep() {
  const { control, resetField } = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div className={`flex  w-full flex-col`}>
              <div className="flex w-full flex-col gap-4 py-4">
                <Input
                  column={true}
                  label={"First Name"}
                  type="text"
                  id={"name"}
                  placeholder="Enter First name"
                  controllerName={"firstName"}
                  control={control}
                  mainDivClassname={"flex-1"}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />
                <Input
                  column={true}
                  label={"Last Name"}
                  type="text"
                  id={"name"}
                  placeholder="Enter Last name"
                  controllerName={"lastName"}
                  control={control}
                  mainDivClassname={"flex-1"}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />
                <Input
                  column={true}
                  label={"Email Address"}
                  type="text"
                  id={"email"}
                  placeholder="Enter email adress"
                  controllerName={"contact.email"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                  resetField={resetField}
                />

                <Input
                  column={true}
                  label={"Phone number"}
                  type="tel"
                  id={"phone"}
                  placeholder="Enter your phone"
                  controllerName={"contact.phone"}
                  control={control}
                  containerClassName="border-black-g-50"
                  containerBaseClassName="border rounded-md"
                  mainDivClassname={"flex-1 "}
                  fieldClassName="input-transparent text-sm font-primary font-normal bg-white"
                  resetField={resetField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CompanyUserInformationStep.propTypes = {};

export default CompanyUserInformationStep;
