import { Outlet } from "react-router-dom";
import ScrollTo from "./ScrollTo";
import HeaderPrivate from "./HeaderPrivate";

function LayoutPrivate() {
  return (
    <>
      <ScrollTo />
      <div >
        <HeaderPrivate />

        <main className=" h-full">
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default LayoutPrivate;
