import PropTypes from "prop-types";

function DownloadIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path d="M16.125 9H13.875C13.2548 9 12.75 9.50475 12.75 10.125C12.75 11.1593 11.9093 12 10.875 12H7.125C6.09075 12 5.25 11.1593 5.25 10.125C5.25 9.50475 4.74525 9 4.125 9H1.875C0.84075 9 0 9.84075 0 10.875V14.625C0 16.4858 1.51425 18 3.375 18H14.625C16.4858 18 18 16.4858 18 14.625V10.875C18 9.84075 17.1593 9 16.125 9ZM17.25 14.625C17.25 16.0725 16.0725 17.25 14.625 17.25H3.375C1.9275 17.25 0.75 16.0725 0.75 14.625V10.875C0.75 10.2548 1.25475 9.75 1.875 9.75H4.125C4.33125 9.75 4.5 9.91875 4.5 10.125C4.5 11.5725 5.6775 12.75 7.125 12.75H10.875C12.3225 12.75 13.5 11.5725 13.5 10.125C13.5 9.91875 13.6687 9.75 13.875 9.75H16.125C16.7453 9.75 17.25 10.2548 17.25 10.875V14.625ZM5.7345 5.451C5.58825 5.30475 5.58825 5.067 5.7345 4.92075C5.88075 4.7745 6.1185 4.7745 6.26475 4.92075L8.62425 7.28025V0.375C8.62425 0.168 8.79225 0 8.99925 0C9.20625 0 9.37425 0.168 9.37425 0.375V7.28025L11.7338 4.92075C11.88 4.7745 12.1177 4.7745 12.264 4.92075C12.4102 5.067 12.4102 5.30475 12.264 5.451L9.79425 7.92075C9.6015 8.1135 9.3525 8.2065 9.1005 8.22975C9.0675 8.2395 9.03525 8.25 8.99925 8.25C8.96325 8.25 8.931 8.23875 8.898 8.22975C8.64525 8.2065 8.397 8.1135 8.20425 7.92075L5.7345 5.451Z" />
    </svg>
  );
}

DownloadIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default DownloadIcon;
