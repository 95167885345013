import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const FRONT_BASE_URL = import.meta.env.VITE_FRONT_BASE_URL;
const EMAIL_EXIST_URL = REQUEST_URL.email_exist;
const SIGNUP_USER_URL = REQUEST_URL.signup_user;
const CONFIRM_EMAIL_URL = REQUEST_URL.confirm_email;
const LOGIN_URL = REQUEST_URL.login;
const FORGOT_PASSWORD_URL = REQUEST_URL.forgot_password;
const RESET_PASSWORD_URL = REQUEST_URL.reset_password;
const ISAUTHENTICATED_URL = REQUEST_URL.isAuthenticated;
const LOGOUT_URL = REQUEST_URL.logout;

const LINKEDIN_CLIENT_ID = import.meta.env.VITE_LINKEDIN_CLIENT_ID;
const LINKEDIN_CLIENT_SECRET = import.meta.env.VITE_LINKEDIN_CLIENT_SECRET;
const LINKEDIN_REDIRECT_URI =
  FRONT_BASE_URL + REQUEST_URL.linkedin_redirect_uri;

export async function emailExist({ email }) {
  if (!email) return;

  if (!navigator.onLine) {
    const error = new Error("No internet connection");
    error.code = 503;
    throw error;
  }

  const response = await fetch(BASE_URL + EMAIL_EXIST_URL, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: { "Content-Type": "application/json" },
  });

  return response.json();
}

export async function signupUser({
  email,
  password,
  role,
  firstName,
  lastName,
  profilePicture,
  company: { employerName, companyPhone, countryId, industry },
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + SIGNUP_USER_URL, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      role,
      firstName,
      lastName,
      profilePicture,
      company: { employerName, companyPhone, countryId, industry },
    }),
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok)
    throw new Error("Failed to create an account, please try again later.");
  return response.json();
}

export async function confirmEmail({ token, email }) {
  if (!navigator.onLine) {
    const error = new Error("No internet connection");
    error.code = 503;
    throw error;
  }

  const response = await fetch(
    `${BASE_URL + CONFIRM_EMAIL_URL}?token=${token}&email=${email}`,
    {
      method: "GET",
      credentials: "include",
    },
  );

  if (response?.status === 400) throw new Error("Invalid confirmation link");

  if (!response.ok)
    throw new Error("Your email address has not been confirmed.");
  return response.json();
}

export async function forgotPassword({ email }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + FORGOT_PASSWORD_URL, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: { "Content-Type": "application/json" },
  });

  if (response.status === 400) {
    const error = new Error(
      "The email you entered does not match our records. Please double-check and try again",
    );
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to send the link, please try again later.");
  return response.json();
}

export async function resetPassword({ email, token, newPassword }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + RESET_PASSWORD_URL, {
    method: "POST",
    body: JSON.stringify({
      email,
      token,
      newPassword,
    }),
    headers: { "Content-Type": "application/json" },
  });

  if (response.status === 400) {
    const error = new Error(
      "Your password reset link has expired. Please request a new one.",
    );
    error.code = 400;
    throw error;
  }
  if (!response.ok)
    throw new Error("Failed to update the password, please try again later.");

  return response.json();
}

export async function login({ email, password }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + LOGIN_URL, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) throw new Error("Invalid login credentials");
  return response.json();
}

export async function isAuthenticated() {
  if (!navigator.onLine) {
    const error = new Error("No internet connection");
    error.code = 503;
    throw error;
  }

  const response = await fetch(BASE_URL + ISAUTHENTICATED_URL, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) throw new Error("Invalid login credentials");
  return response.json();
}

export async function logout() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + LOGOUT_URL, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) throw new Error("Unable to log out.");
}

export async function getLinkedInAccessToken({ code }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    "https://www.linkedin.com/oauth/v2/accessToken",
    {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code,
        client_id: LINKEDIN_CLIENT_ID,
        client_secret: LINKEDIN_CLIENT_SECRET,
        redirect_uri: LINKEDIN_REDIRECT_URI,
      }),
    },
  );

  if (!response.ok)
    throw new Error("Failed to create an account, please try again later.");
  return response.json();
}

export async function getLinkedUserinfo({ accessToken }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch("https://api.linkedin.com/v2/userinfo", {
    method: "GET",
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (!response.ok)
    throw new Error("Failed to create an account, please try again later.");
  return response.json();
}
