import ConversationItem from "./ConversationItem.jsx";
import { getCondidates } from "../../services/apiGetCondidates.js";

const Conversation = ({ condidates, handleCandidateClick }) => {
  return (
    <div className="max-h-full overflow-y-auto p-1">
      {condidates &&
        condidates.map((item, index) => (
          <ConversationItem
            key={item.id}
            id={item.id}
            handleCandidateClick={handleCandidateClick}
            message={""}
            time={item.time}
            name={`${item.firstName} ${item.lastName}`}
            active={item.active}
            profilePic={item.profilePic}
          />
        ))}
    </div>
  );
};

export default Conversation;
