import { useForm } from "react-hook-form";
import { useEmailExist } from "../../hooks/useEmailExist";
import { useLogin } from "../../hooks/useLogin";
import Input from "../../shared/inputs/Input";
import Loader from "../../shared/loader/Loader";

function LoginForm() {
  const { control, handleSubmit, resetField, reset } = useForm();

  const { emailExist, isPendingEmailExist, exist } = useEmailExist({
    newEmail: false,
  });

  const handleOnBlurEmail = (e) => {
    emailExist({ email: e.target.value });
  };

  const { login, isPending } = useLogin();

  const onSubmit = ({ email, password }) => {
    if (!email || !password) return;
    login(
      { email, password },
      {
        onSuccess: () => {
          reset();
        },
        onError: () => {
          resetField("password");
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="-m-3 flex flex-col">
        <div className="p-3">
          <Input
            label="Mail"
            type="text"
            id="email"
            placeholder="Your email"
            autoComplete="email"
            controllerName="email"
            control={control}
            rules={{
              required: "Email is required",

              onBlur: handleOnBlurEmail,
            }}
            isLoading={isPendingEmailExist}
            isAsyncSuccess={exist}
            resetField={resetField}
            disabled={isPendingEmailExist || isPending}
          />
        </div>

        <div className="p-3">
          <Input
            label="Password"
            type="password"
            id="password"
            placeholder="Your password"
            autoComplete="current-password"
            controllerName="password"
            control={control}
            rules={{ required: "Please enter your password" }}
            resetField={resetField}
            disabled={isPending}
          />
        </div>

        <div className="p-3">
          <button
            className="btn-blue-b-primary w-full text-lg font-medium"
            disabled={isPending}
          >
            {isPending ? <Loader color="#ffffff" /> : "Login"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
