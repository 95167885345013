import PropTypes from "prop-types";

function WalletIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M6.06664 12.2029C5.84497 12.2029 5.61164 12.1095 5.4483 11.9462C5.2033 11.6895 5.12164 11.3162 5.26164 10.9895L7.43164 5.80953C7.4783 5.70453 7.50164 5.63453 7.53664 5.5762C9.2633 1.59786 11.4683 0.629531 15.365 2.12286C15.5866 2.20453 15.7616 2.37953 15.855 2.6012C15.9483 2.82286 15.9483 3.06786 15.855 3.28953L12.4366 11.2229C12.2966 11.5495 11.9816 11.7479 11.6316 11.7479H8.30664C7.64164 11.7479 7.01164 11.8762 6.40497 12.1329C6.29997 12.1795 6.1833 12.2029 6.06664 12.2029ZM12.3783 3.20786C10.9316 3.20786 10.045 4.15286 9.1233 6.29953C9.11164 6.33453 9.0883 6.36953 9.07664 6.40453L7.5483 10.0329C7.80497 10.0095 8.04997 9.99786 8.30664 9.99786H11.0483L13.86 3.46453C13.3116 3.28953 12.8216 3.20786 12.3783 3.20786Z" />
      <path d="M21.3379 11.9819C21.2563 11.9819 21.1629 11.9703 21.0813 11.9469C20.6379 11.8186 20.1713 11.7486 19.6813 11.7486H11.6313C11.3396 11.7486 11.0596 11.5969 10.8963 11.3519C10.7446 11.1069 10.7096 10.7919 10.8263 10.5236L14.2096 2.67194C14.3846 2.25194 14.8979 1.96028 15.3296 2.11194C15.4696 2.15861 15.5979 2.21694 15.7379 2.27528L18.4913 3.43028C20.1013 4.09528 21.1746 4.79528 21.8746 5.63528C22.0146 5.79861 22.1313 5.97361 22.2479 6.16028C22.3763 6.35861 22.4929 6.59194 22.5746 6.83694C22.6096 6.91861 22.6679 7.07028 22.7029 7.23361C23.0296 8.34194 22.8663 9.69528 22.1663 11.4453C22.0146 11.7719 21.6879 11.9819 21.3379 11.9819ZM12.9613 9.99861H19.6929C20.0663 9.99861 20.4279 10.0336 20.7896 10.0919C21.1163 9.07694 21.1863 8.29528 20.9996 7.66528C20.9763 7.56028 20.9529 7.51361 20.9413 7.46694C20.8713 7.28028 20.8246 7.17528 20.7663 7.08194C20.6846 6.95361 20.6263 6.84861 20.5329 6.74361C20.0313 6.13694 19.1446 5.57694 17.8146 5.02861L15.5163 4.07194L12.9613 9.99861Z" />
      <path d="M18.55 26.5414H9.45C9.12333 26.5414 8.82 26.518 8.51667 26.483C4.42167 26.2147 2.08833 23.8697 1.80833 19.728C1.77333 19.4714 1.75 19.1564 1.75 18.8414V16.5664C1.75 13.9414 3.31333 11.573 5.72833 10.523C6.55667 10.173 7.42 9.99805 8.31833 9.99805H19.705C20.37 9.99805 21.0117 10.0914 21.6067 10.278C24.3483 11.1064 26.2733 13.6964 26.2733 16.5664V18.8414C26.2733 19.098 26.2617 19.343 26.25 19.5764C25.9933 24.138 23.3333 26.5414 18.55 26.5414ZM8.30667 11.748C7.64167 11.748 7.01167 11.8764 6.405 12.133C4.63167 12.903 3.48833 14.6414 3.48833 16.5664V18.8414C3.48833 19.0864 3.51167 19.3314 3.535 19.5647C3.75667 22.8897 5.39 24.523 8.66833 24.7447C8.96 24.7797 9.19333 24.803 9.43833 24.803H18.5383C22.3883 24.803 24.2783 23.1114 24.465 19.4947C24.4767 19.2847 24.4883 19.0747 24.4883 18.8414V16.5664C24.4883 14.4547 23.0767 12.5647 21.07 11.9464C20.6267 11.818 20.16 11.748 19.67 11.748H8.30667Z" />
      <path d="M2.61328 17.4414C2.13495 17.4414 1.73828 17.0448 1.73828 16.5664V13.1481C1.73828 9.4731 4.33995 6.29977 7.93328 5.59977C8.24828 5.54143 8.57495 5.6581 8.78495 5.9031C8.98328 6.1481 9.04161 6.4981 8.91328 6.78977L6.87161 11.6664C6.77828 11.8764 6.61495 12.0398 6.41662 12.1331C4.64328 12.9031 3.49995 14.6414 3.49995 16.5664C3.48828 17.0448 3.10328 17.4414 2.61328 17.4414ZM6.53328 7.95643C5.03995 8.79643 3.95495 10.2664 3.61661 11.9814C4.12995 11.4564 4.72495 11.0131 5.40161 10.6864L6.53328 7.95643Z" />
      <path d="M25.3863 17.4414C24.908 17.4414 24.5113 17.0447 24.5113 16.5664C24.5113 14.4547 23.0997 12.5647 21.093 11.9464C20.8597 11.8764 20.6613 11.7131 20.5563 11.4914C20.4513 11.2697 20.4397 11.0131 20.533 10.7914C21.0813 9.42641 21.2213 8.43474 20.9997 7.66474C20.9763 7.55974 20.953 7.51307 20.9413 7.46641C20.7897 7.12807 20.8713 6.73141 21.1397 6.47474C21.408 6.21807 21.8163 6.15974 22.143 6.33474C24.6863 7.66474 26.2613 10.2781 26.2613 13.1481V16.5664C26.2613 17.0447 25.8647 17.4414 25.3863 17.4414ZM22.458 10.6047C23.193 10.9431 23.8463 11.4214 24.3947 11.9931C24.173 10.8497 23.6247 9.81141 22.8197 8.99474C22.7613 9.48474 22.6447 10.0214 22.458 10.6047Z" />
    </svg>
  );
}

WalletIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default WalletIcon;
