import PropTypes from "prop-types";
import { useOutletContext } from "react-router-dom";
import Input from "../../../../shared/inputs/Input";

function SocialMedia() {
  const { formMethods, isUploadingUserPicture, isPending } = useOutletContext();
  const { control, resetField } = formMethods;

  const SOCIAL_MEDIA = [
    { id: 0, name: "LinkedIn", placeholder: "Enter LinkedIn profile" },
    { id: 3, name: "Website", placeholder: "Enter your website address" },
  ];

  return (
    <div className="max-w-screen-sm overflow-hidden">
      <h1 className="mb-16 text-2xl font-extrabold">3. On the web</h1>
      <div className="-m-3 flex flex-col">
        {SOCIAL_MEDIA?.map((item, index) => (
          <div key={item?.id} className="p-3">
            <Input
              type={"text"}
              label={item?.name}
              placeholder={item?.placeholder}
              control={control}
              controllerName={`socialPlatforms.${index}`}
              resetField={resetField}
              disabled={isUploadingUserPicture || isPending}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

SocialMedia.propTypes = {
  step: PropTypes.number,
};

export default SocialMedia;
