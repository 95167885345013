/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegClock } from "react-icons/fa";
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import { IoEllipseSharp } from "react-icons/io5";
import { TbChartCandle, TbExternalLink } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { useSearchJobDescription } from "../hooks/useSearchJobDescription";
import Divider from "../shared/Divider";
import Pagination from "../shared/Pagination";
import CustomCard from "../shared/cards/CustomCard";
import CustomCheckbox from "../shared/checkbox/CustomCheckbox";
import StatusFilter from "../shared/filters/StatusFilter";
import {
  jobsFilterStatusCandidat,
  jobsFilterStatusRecruiter,
} from "../shared/filters/data";
import Input from "../shared/inputs/Input";
import Loader from "../shared/loader/Loader";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  WorkLocationEnum,
  contractTypeArray,
  experienceLevelArray,
  workLocationArray,
} from "../utils/constants";
import { getFileType } from "../utils/helpers";

function JobsPage() {
  const { control, resetField, watch } = useForm();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);

  const { redirectionUrl, roles } = useIsAuthenticated();

  const JOB_POSTS_PER_PAGE = 10;
  const QUERY_STRING = "job_description_search_page";

  const { keyword, minSalary, maxSalary, jobDescriptionStatus } = watch();
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (keyword !== undefined) {
      // Clear the previous timer
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      // Set a new timer to trigger the API call after 500 milliseconds of inactivity
      const newTimeout = setTimeout(() => {
        setSelectedFilters((prevState) => ({
          ...prevState,
          keyword,
          minSalary,
          maxSalary,
          jobDescriptionStatus,
        }));
      }, 500);

      setTypingTimeout(newTimeout);
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        keyword: "",
        minSalary: 0,
        maxSalary: 0,
        jobDescriptionStatus: 0,
      }));
    }
  }, [keyword, minSalary, maxSalary, jobDescriptionStatus]);

  const handleFilterSelection = (item) => {
    if (item.id === "myjobs") {
      setSelectedFilters((prevState) => ({
        ...prevState,
        myJobs: true,
        jobDescriptionStatus: item?.value,
      }));
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        jobDescriptionStatus: item?.value,
        myJobs: false,
      }));
    }
  };

  const { isLoading, data } = useSearchJobDescription({
    queryString: QUERY_STRING,
    size: JOB_POSTS_PER_PAGE,
    keyword: selectedFilters?.keyword || "",
    /* industries,
    countryId,
    cityId, */
    minSalary: selectedFilters?.minSalary || 0,
    maxSalary: selectedFilters?.maxSalary || 0,
    jobDescriptionStatus: selectedFilters?.jobDescriptionStatus || 1,
    contractTypes: selectedFilters?.contractType || [],
    workLocations: selectedFilters?.workLocation || [],
    /* employmentTypes, */
    experienceLevels: selectedFilters?.expertise || [],
    leaveNotice: selectedFilters?.leaveNotice || 0,
    myJobs: selectedFilters?.myJobs || false,
    //skills,
  });

  const { results, totalCount } = data || {};

  return (
    <>
      <div className="page-px flex flex-col gap-4 py-4">
        <div className="flex items-center">
          <span className="title-primary-300-4xl flex-1 font-medium">Jobs</span>

          {roles?.includes("recruiter") ? (
            <Link
              to={`${redirectionUrl}/add-job`}
              className="btn-blue-b-primary rounded-md font-primary text-base font-medium"
            >
              Create New Job
            </Link>
          ) : null}
        </div>
        <div className="flex w-full flex-wrap items-center gap-2">
          <StatusFilter
            items={
              roles?.includes("recruiter")
                ? jobsFilterStatusRecruiter
                : jobsFilterStatusCandidat
            }
            dropdownMobileLabel="Job Status"
            onSelect={handleFilterSelection}
          />
          <Input
            type="text"
            id="keyword"
            placeholder="Simple Search or boolean"
            controllerName="keyword"
            control={control}
            fieldClassName="input-transparent py-2 text-sm font-primary font-normal w-[200px]"
            resetField={resetField}
          />

          {/* <Dropdown
            className="rounded-[4px] border border-black-g-50 px-2 font-primary text-sm font-medium"
            label="Availablity"
            optionsConfig={{
              options: leaveNoticeArray,
              idKey: "id",
              nameKey: "name",
            }}
            onSelect={(data) =>
              setSelectedFilters((prevState) => ({
                ...prevState,
                leaveNotice: data?.value,
              }))
            }
          /> */}

          <div
            className={`filter-tab flex items-center gap-2 rounded-[4px] px-3`}
            onClick={() => setShowFilters((prevState) => !prevState)}
          >
            <TbChartCandle />
            Advanced filters
          </div>
        </div>
        <div className="flex flex-wrap gap-8 md:flex-row md:gap-0 ">
          {showFilters ? (
            <div className="w-full pr-0 md:w-2/5 md:pr-4 lg:w-1/5 xl:w-1/5 2xl:w-1/5">
              <div className="filter-layout flex flex-col">
                <span className="text-primary-300 font-primary text-xl font-medium">
                  Advanced Filters
                </span>

                <div className="mb-2 mt-4">
                  <span className="text-primary-300 font-primary text-lg font-semibold">
                    Job Type
                  </span>
                  <div className="mt-2 flex flex-col gap-2">
                    {contractTypeArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        value={item.value}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const contractType = prevState?.contractType || []; // Initialize contractType as an array if it's undefined
                            const updatedcontractType = contractType.includes(
                              item.value,
                            )
                              ? contractType.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...contractType, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              contractType: updatedcontractType,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-primary-300  font-primary text-lg font-semibold">
                    Salary/Daily rate range
                  </span>

                  <div className="mb-2 mt-2 block">
                    <Input
                      label="From"
                      gap={0}
                      gapLabel={0}
                      column={false}
                      type="text"
                      id="mins"
                      placeholder="1200"
                      controllerName="minSalary"
                      control={control}
                      resetField={resetField}
                      labelClassName="w-[60px] p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                      fieldClassName="input-transparent h-auto p-3"
                      containerBaseClassName="border border-black-g-50 rounded-r-md"
                      containerClassName="bg-white"
                    />
                  </div>

                  <div className="block">
                    <Input
                      label="To"
                      gap={0}
                      gapLabel={0}
                      column={false}
                      type="text"
                      id="maxs"
                      placeholder="5000"
                      controllerName="maxSalary"
                      control={control}
                      resetField={resetField}
                      labelClassName="w-[60px] p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                      fieldClassName="input-transparent h-auto p-3"
                      containerBaseClassName="border border-black-g-50 rounded-r-md"
                      containerClassName="bg-white"
                    />
                  </div>
                </div>

                <div className="mb-2">
                  <span className="text-primary-300  font-primary text-lg font-semibold">
                    Expertise
                  </span>

                  <div className="mt-2 flex flex-col gap-2">
                    {experienceLevelArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const expertise = prevState?.expertise || []; // Initialize expertise as an array if it's undefined
                            const updatedexpertise = expertise.includes(
                              item.value,
                            )
                              ? expertise.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...expertise, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              expertise: updatedexpertise,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-primary-300 mb-3 font-primary text-lg font-semibold">
                    Work Location
                  </span>

                  <div className="mt-2 flex flex-col gap-2">
                    {workLocationArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const workLocation = prevState?.workLocation || []; // Initialize workLocation as an array if it's undefined
                            const updatedworkLocation = workLocation.includes(
                              item.value,
                            )
                              ? workLocation.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...workLocation, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              workLocation: updatedworkLocation,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {!isLoading ? (
            <>
              {totalCount > 0 ? (
                <div
                  className={`flex w-full flex-wrap gap-6  ${showFilters ? "gap-2 md:w-3/5 md:gap-6 lg:w-4/5 lg:gap-5 xl:w-4/5 xl:gap-6 2xl:w-4/5 2xl:gap-5" : "lg:gap-6 xl:gap-6 2xl:gap-6"} `}
                >
                  {results?.map((item) => (
                    <div
                      key={item.id}
                      className={`h-72 w-full pr-0 transition delay-150 duration-300 ease-in-out  md:w-full  ${!showFilters ? "lg:w-[48.7%] xl:w-[31.7%] 2xl:w-[32%]" : "lg:w-[47.7%] xl:w-[48.2%] 2xl:w-[32.3%] "} `} // Add padding-left to the last card in each row
                    >
                      <CustomCard
                        CardHeader={
                          <div className="flex items-center gap-1">
                            <div className="flex flex-1 items-center gap-2">
                              {getFileType(item?.company?.logo) === "image" && (
                                <img
                                  className={
                                    "h-16 w-16 object-contain object-center"
                                  }
                                  src={item?.company?.logo}
                                />
                              )}

                              <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <Link
                                    className="title-primary-300-sm w-[158px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold sm:w-full"
                                    to={`${redirectionUrl}/job-detail/${item.id}`}
                                  >
                                    {item?.title}
                                  </Link>
                                </div>

                                <div className="flex flex-wrap items-center gap-1">
                                  <a
                                    className="subtitle-blackG-100-xs flex items-center font-normal"
                                    // to={`${redirectionUrl}/profile`}
                                    href={
                                      item?.company?.socialPlatforms?.[0]?.url
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.company
                                      ? item?.company?.name
                                      : "N/A"}
                                    {item?.company?.socialPlatforms?.[0] ? (
                                      <TbExternalLink className="ml-1" />
                                    ) : null}
                                  </a>

                                  {roles?.includes("recruiter") &&
                                    item?.numberOfApplications > 0 && (
                                      <>
                                        <IoEllipseSharp size={8} />
                                        <Link
                                          to={`${redirectionUrl}/applicants/${item.id}`}
                                          className="text-xs text-blue-b-primary hover:underline"
                                        >
                                          {`${item?.numberOfApplications} ${item?.numberOfApplications <= 1 ? "applicant" : "applicants"}`}
                                        </Link>
                                      </>
                                    )}

                                  {item?.country?.name && (
                                    <>
                                      <IoEllipseSharp size={8} />
                                      <span className="subtitle-blackG-100-xs font-normal">
                                        {item?.country?.name}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex  gap-1">
                              <Link
                                to={`${redirectionUrl}/job-detail/${item.id}`}
                                className="btn-outline-blueB flex items-center rounded-[4px] px-5 py-2 text-sm font-medium"
                              >
                                Details
                              </Link>
                            </div>
                          </div>
                        }
                        CardContent={
                          <div className="flex flex-1 flex-col  gap-4 ">
                            <div className="flex items-center gap-1">
                              <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                                {ExperienceLevelEnum[item?.experienceLevel]}
                              </span>
                              <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                                {WorkLocationEnum[item?.workLocation]}
                              </span>
                              <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                                {EmploymentTypeEnum[item?.employmentType]}
                              </span>
                              {item?.skills?.length > 0 ? (
                                <Tooltip
                                  title={
                                    <div className="flex flex-col gap-2 p-2">
                                      {item?.skills?.map((skill) => (
                                        <span
                                          className="font-primary text-xs font-medium text-blue-b-primary"
                                          key={skill?.id}
                                        >
                                          {skill?.name}
                                        </span>
                                      ))}
                                    </div>
                                  }
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: "white",
                                        border: "0.5px solid #D1D1D1",
                                        borderRadius: "6px",
                                      },
                                    },
                                  }}
                                  placement="right"
                                >
                                  <div className="rounded bg-[#E6E7E9] px-2 py-1 font-primary text-xs font-medium text-black-g-500">
                                    +{item?.skills?.length}
                                  </div>
                                </Tooltip>
                              ) : null}
                            </div>
                            <div className="h-100  font-primary text-sm  font-normal text-black-g-100">
                              {item?.mainMission?.raw
                                ? item?.mainMission?.raw?.length > 175
                                  ? `${item?.mainMission?.raw?.substring(0, 175)}...`
                                  : item?.mainMission?.raw
                                : ""}
                            </div>
                          </div>
                        }
                        CardFooter={
                          <div className="flex flex-col">
                            <Divider className="mb-4 mt-1" />

                            <div className="flex items-center justify-between">
                              {item?.rate ? (
                                <span
                                  className={`text-primary-300 flex items-center gap-1 font-primary text-base font-medium`}
                                >
                                  {item?.rate?.min} - {item?.rate?.max}{" "}
                                  <span className="text-xs font-medium text-black-g-100">
                                    {item?.rate?.currency?.symbol}
                                  </span>
                                </span>
                              ) : null}

                              <div className="flex items-center gap-1">
                                <FaRegClock color="#0054FF" />
                                <span className="title-primary-300-sm font-normal">
                                  {item?.postedDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div>No Data Found</div>
              )}
            </>
          ) : (
            <div
              className={`flex ${showFilters ? "w-4/5" : "w-full"}  justify-center`}
            >
              <Loader />
            </div>
          )}
          {!isLoading && totalCount > 0 ? (
            <div className="mt-6 flex w-full items-center justify-center">
              <Pagination
                queryString={QUERY_STRING}
                totalCount={totalCount}
                size={JOB_POSTS_PER_PAGE}
              >
                <Pagination.PrevPage>
                  <div className="px-4 py-2 hover:bg-blue-b-50">
                    <HiMiniChevronLeft className="h-4 w-4 stroke-1" />
                  </div>
                </Pagination.PrevPage>

                <Pagination.Numbers />

                <Pagination.NextPage>
                  <div className="px-4 py-2 hover:bg-blue-b-50">
                    <HiMiniChevronRight className="h-4 w-4 stroke-1" />
                  </div>
                </Pagination.NextPage>
              </Pagination>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default JobsPage;
