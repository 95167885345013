import { HiCheck, HiOutlineDocumentText } from "react-icons/hi2";
import { Link, Navigate, useLocation } from "react-router-dom";
import UserIcon from "../../../../icons/UserIcon";
import LogoIcon from "../../../../shared/logo/LogoIcon";
import { useUser } from "../../../../hooks/useUser";
import { getFileType } from "../../../../utils/helpers";

function Welcome() {
  const location = useLocation();

  const { user, isLoading } = useUser();

  //if (!location?.state?.loginFirstTime) return <Navigate to={"/"} />;

  return (
    <section>
      <div className="page-px flex min-h-screen bg-[#F7F9FB] py-16">
        <div className="flex flex-1 items-center justify-center">
          <div className="m-auto w-full max-w-[45rem] bg-white p-8 shadow-md shadow-slate-200">
            <div className="mb-6 flex justify-center">
              <LogoIcon size={18} className="mx-auto" />
            </div>

            <div className="mb-8 flex flex-col text-center">
              <h1 className="text-[2rem] font-bold">Welcome !</h1>
            </div>

            <div className="mb-10 flex justify-center">
              <div className="h-36 w-36 rounded-full">
                <div className="h-full w-full overflow-hidden rounded-full">
                  {getFileType(user?.profilePic) === "image" ? (
                    <img
                      src={user?.profilePic}
                      alt="user"
                      className="h-full w-full object-cover object-center"
                    />
                  ) : (
                    <div className="h-full w-full bg-[#C4C4C4] p-2">
                      <UserIcon className={"text-black-g-50"} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <p className="my-10 text-center text-2xl font-medium">
              {`${user?.firstName} ${user?.lastName}`}
            </p>

            <div className="my-10">
              <ul className="-m-2 flex flex-col">
                <li className="flex space-x-2 p-2">
                  <HiCheck className="h-5 w-5 stroke-1 text-green-g-primary" />
                  <p className="font-light">
                    Be sure to enter all details related to the job, this will
                    help you find the right fit faster
                  </p>
                </li>

                <li className="flex space-x-2 p-2">
                  <HiCheck className="h-5 w-5 stroke-1 text-green-g-primary" />
                  <p className="font-light">
                    Upload your Job to make the recruitment process faster
                  </p>
                </li>

                <li className="flex space-x-2 p-2">
                  <HiCheck className="h-5 w-5 stroke-1 text-green-g-primary" />
                  <p className="font-light">
                    Search for the right fit for your needs
                  </p>
                </li>
              </ul>
            </div>

            <div className="my-10">
              <div className="-m-4 flex flex-wrap justify-center">
                <div className="w-1/2 p-4">
                  <label
                    htmlFor="parse-resume"
                    className={`btn-outline-primary flex h-full items-center justify-center space-x-4 rounded-md text-lg font-medium shadow-md`}
                  >
                    <div>Upload job</div>
                    <HiOutlineDocumentText className="h-8 w-8" />
                  </label>

                  <input type="file" hidden id="parse-resume" />
                </div>

                <div className="w-1/2 p-4">
                  <Link
                    type="button"
                    className="btn-blue-b-primary flex h-full items-center justify-center space-x-4 rounded-md border border-blue-b-primary text-lg font-medium shadow-md"
                    to={"/recruiter/add-job"}
                  >
                    Create job description manually
                  </Link>
                </div>

                <div className="w-1/2 p-4">
                  <Link
                    type="button"
                    className="btn-blue-b-primary flex h-full items-center justify-center space-x-4 rounded-md border border-blue-b-primary text-lg font-medium shadow-md"
                    to={"/recruiter/applicants"}
                  >
                    Access Talent pool
                  </Link>
                </div>

                <div className="w-1/2 p-4">
                  <Link
                    type="button"
                    className="btn-blue-b-primary flex h-full items-center justify-center space-x-4 rounded-md border border-blue-b-primary text-lg font-medium shadow-md"
                    to={"/tests"}
                  >
                    I&apos;ll need psychometric tests
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
