import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const MY_RESUME_URL = REQUEST_URL.my_resume;
const RESUME_URL = REQUEST_URL.resume;
const PDF_RESUME_URL = REQUEST_URL.pdf_resume;
const COMPANY_LOGO_URL = REQUEST_URL.company_logo;
const RESUME_ACTION_URL = REQUEST_URL.resume_action;
const RESUME_TALENT_POOL_URL = REQUEST_URL.resume_talent_pool;
const RESUME_PARSE_URL = REQUEST_URL.resume_parse;

export async function getUserResume() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + MY_RESUME_URL, {
    method: "GET",
    headers: { "Content-Type": "text/plain" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Cannot get user resume. Please try again later!");
  return response.json();
}

export async function getUserResumeById(id) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(`${BASE_URL}${RESUME_URL}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "text/plain" },
    credentials: "include",
  });

  if (response?.status === 404) throw new Error(`User not found`);
  if (response?.status === 400) throw new Error("Invalid user ID");

  if (!response.ok)
    throw new Error("Cannot get user resume. Please try again later!");
  return response.json();
}

export async function updateUserResume({
  step,
  firstName,
  lastName,
  jobTitle,
  description,
  birthDate,
  contactInfo: {
    email,
    phone,
    address: { address, address2, cityId, postalCode, countryId } = {},
    socialPlatforms,
  } = {},
  educations = [],
  experiences = [],
  languages = [],
  softSkills = [],
  hardSkills = [],
  preferredWorkLocation,
  experienceLevel,
  preferredEmploymentType,
  preferredContractType,
  educationLevel,
  certifications = [],
  industryId,
  yearsOfExperience,
  disponibility,
  rate: { currencyId, rateType, min, max },
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + RESUME_URL, {
    method: "POST",
    body: JSON.stringify({
      step,
      firstName,
      lastName,
      jobTitle,
      description,
      birthDate,
      contactInfo: {
        address:
          {
            address: address || "",
            address2: address2 || "",
            cityId: Number(cityId) || 0,
            postalCode: postalCode || "",
            countryId: Number(countryId) || 0,
          } || {},
        email: email || "",
        phone: phone || "",
        socialPlatforms: socialPlatforms || [],
      },
      educations: educations || [],
      experiences: experiences || [],
      languages: languages || [],
      softSkills: softSkills || [],
      hardSkills: hardSkills || [],
      preferredWorkLocation: Number(preferredWorkLocation) || 0,
      experienceLevel: Number(experienceLevel) || 0,
      preferredEmploymentType: Number(preferredEmploymentType) || 0,
      preferredContractType: Number(preferredContractType) || 0,
      educationLevel: Number(educationLevel) || 0,
      certifications: certifications || [],
      industryId: Number(industryId) || 0,
      yearsOfExperience: Number(yearsOfExperience) || 0,
      disponibility: Number(disponibility) || 0,
      rate: { currencyId: Number(currencyId) || 0, rateType, min, max },
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Failed to save resume, please try again later.");
  return response;
}

export async function uploadUserResume(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + PDF_RESUME_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (response.status === 400) {
    const error = new Error("Only PDF files are allowed");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to upload the resume, please try again later.");
  return response.json();
}

export async function uploadCompanyLogo(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + COMPANY_LOGO_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (response.status === 400) {
    const error = new Error("Only Image files are allowed");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to upload the resume, please try again later.");
  return response.json();
}

export async function getUserPdfResume({ page, size }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${PDF_RESUME_URL}?page=${Number(page)}&size=${Number(size)}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error("Cannot display PDF Resumes. Please try again later!");
  return response.json();
}

export async function deleteUserPdfResume(id) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(`${BASE_URL}${PDF_RESUME_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error(
      "Failed to delete the selected PDF Resume. Please try again later!",
    );
  return response;
}

export async function getTalentPoolActions(id) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${RESUME_URL}/${Number(id)}/actions`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error(
      "Cannot display Talent Pool actions. Please try again later!",
    );
  return response.json();
}

export async function updateTalentPoolActions({
  resumeId,
  type,
  content,
  reminderDate,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + RESUME_ACTION_URL, {
    method: "POST",
    body: JSON.stringify({ resumeId, type, content, reminderDate }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Failed to update Talent Pool, please try again later.");
  return response;
}

export async function generatePdfFile({ resumeId }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${RESUME_URL}/${Number(resumeId)}/generate_pdf`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error("Cannot display PDF Resumes. Please try again later!");
  return response.json();
}

export async function deleteTalentPoolAction({
  id,
  resumeId,
  type,
  content,
  reminderDate,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(`${BASE_URL}${RESUME_ACTION_URL}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ resumeId, type, content, reminderDate }),
  });

  if (!response.ok)
    throw new Error("Failed to delete this item. Please try again later!");
  return response;
}

export async function saveResumeTalentPool(resumeId) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${RESUME_TALENT_POOL_URL}/${resumeId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (response.status === 400) {
    const error = new Error(
      "The selected candidate is already saved in the Talent Pool",
    );
    error.code = 400;
    throw error;
  }

  if (!response.ok) throw new Error("Error, please try again later.");
  return response;
}

export async function parseResume(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + RESUME_PARSE_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Failed to parse the resume, please try again later.");
  return response.json();
}
