import PropTypes from "prop-types";

function RatingIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 56"
      fill="none"
      stroke="currentColor"
    >
      <path
        d="M37.4,49.8v-2.7c0,0,3.7-3.3,3.4-6c-0.3-3,0-9.3,0-10.2c0-0.9-3.7-3-4,1.5c0-6.3-5-6-5-2.1c0-4.4-3.4-5.7-4-1.2
	c0-4.5,0-9.1,0-9.3c0-3.3-4-3.3-4,0c0,0.1,0,19.2,0,19.2s-4.7-9.3-8.4-5.7c-0.9,1.1,3.4,8.1,4.7,9.3c1.2,1.2,3.4,3.6,5,4.8
	c0.9,1.5,0,2.7,0,2.7h-1.6v5.1h16.5v-5.1L37.4,49.8z"
      />
      <path
        d="M23.5,19.5H2.9c-1.3,0-2.3-1-2.3-2.3V3c0-1.3,1-2.3,2.3-2.3h50.1c1.3,0,2.3,1,2.3,2.3v14.3c0,1.3-1,2.3-2.3,2.3
	H28.2"
      />
      <polygon points="10.8,4.3 12.5,7.8 16.4,8.4 13.6,11.1 14.2,15 10.8,13.1 7.3,15 8,11.1 5.2,8.4 9.1,7.8 " />
      <polygon points="27.8,4.3 29.5,7.8 33.3,8.4 30.6,11.1 31.2,15 27.8,13.1 24.3,15 25,11.1 22.2,8.4 26,7.8 " />
      <polygon points="44.7,4.3 46.5,7.8 50.3,8.4 47.5,11.1 48.2,15 44.7,13.1 41.3,15 42,11.1 39.2,8.4 43,7.8 " />
    </svg>
  );
}

RatingIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default RatingIcon;
