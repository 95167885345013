import { Link } from "react-router-dom";
import { HiArrowUpRight, HiCheck, HiStar } from "react-icons/hi2";
import LogoIcon from "../shared/logo/LogoIcon";
import MatchCandidates from "../components/candidate/MatchCandidates";
import TalentsTax from "../components/candidate/TalentsTax";

function Home() {
  return (
    <>
      <section>
        <div className="page-px py-4">
          <div className="m-auto max-w-[74rem]">
            <div className="-m-4 flex flex-wrap items-center md:-m-16">
              <div className="flex-1 p-4 md:p-16">
                <p className="uppercase text-blue-b-primary">
                  Ready to take your business to the next level?
                </p>
                <h1 className="text-[2.75rem] font-medium leading-[3.25rem]">
                  Let us help you find the perfect talent for your projects
                </h1>

                <p className="my-6 text-lg text-black-g-200">
                  Let us help you grow your dream team!
                </p>

                <div className="mt-8">
                  <Link
                    className="btn-blue-b-primary text-lg font-medium"
                    to={"/recruiter/add-job"}
                    state={{
                      redirectionUrl: "recruiter/add-job",
                    }}
                  >
                    Create a job description
                  </Link>
                </div>

                <div className="mt-6 flex items-center space-x-2">
                  <div className="bg-green-g-primary px-2 py-1 text-white">
                    <HiStar className="h-4 w-4" />
                  </div>
                  <p>Rated 4.9/5 from over 600 reviews.</p>
                </div>
              </div>

              <div className="p-4 md:w-1/2 md:p-16">
                <div className="relative">
                  <div className="px-12 transition-all duration-300 sm:px-16 md:px-0">
                    <img
                      className="m-auto h-full w-full object-center"
                      src="/uploads/woman-1.png"
                    />
                  </div>

                  <div
                    className="absolute left-12 top-16 z-10 h-28 w-28 -translate-x-1/2 transition-all duration-300 sm:left-16 md:left-0"
                    style={{
                      backgroundImage:
                        "radial-gradient(#0054ff 2.5px,transparent 2.5px)",
                      backgroundSize: "1.75rem 1.75rem",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MatchCandidates />

      <section>
        <div className="page-px my-16 py-8">
          <div className="m-auto max-w-screen-lg">
            <div className="-m-4 flex flex-wrap items-center md:-m-16">
              <div className="flex-1 p-4 md:p-16">
                <div className="relative">
                  <LogoIcon className="absolute left-12 top-20 z-10 w-[6.25rem] -translate-x-1/2 transition-all duration-300 sm:left-16 md:left-0" />

                  <div className="px-12 transition-all duration-300 sm:px-16 md:px-0">
                    <img
                      className="m-auto h-full w-full object-center"
                      src="/uploads/woman-2.png"
                    />
                  </div>

                  <div
                    className="absolute right-12 top-16 z-10 h-28 w-28 translate-x-1/2 transition-all duration-300 sm:right-16 md:right-0"
                    style={{
                      backgroundImage:
                        "radial-gradient(#0054ff 2.5px,transparent 2.5px)",
                      backgroundSize: "1.75rem 1.75rem",
                    }}
                  ></div>
                </div>
              </div>

              <div className="p-4 md:w-1/2 md:p-16">
                <h1 className="text-[2.75rem] font-medium leading-[3.25rem]">
                  Make Data-Driven Hires with Psychometrics
                </h1>
                <p className="my-6 text-lg text-black-g-200">
                  Enhance your hiring process with the Nova Profile, a
                  scientifically-backed tool that reveals candidates&apos;
                  natural and adapted styles, behavioural preferences, and
                  motivations. Based on DISC, Carl Jung, and Eduard
                  Spranger&apos;s theories, the Nova Profile provides deep
                  insights in just three steps. Use this comprehensive analysis
                  to make data-driven hiring decisions, ensuring the perfect fit
                  for your team and boosting organizational success.
                </p>

                <div className="mt-8">
                  <Link
                    className="btn-blue-b-primary flex w-full items-center justify-center space-x-2 text-lg font-medium"
                    to={"/get-in-touch"}
                  >
                    <div>Get Started</div>
                    <HiArrowUpRight className="h-3.5 w-3.5 stroke-2" />
                  </Link>
                </div>

                <p className="mt-6 text-sm text-black-g-75">
                  14 day trial – No credit card required
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="page-px my-16">
          <div className="mb-12">
            <p className="mb-2 text-center uppercase text-blue-b-primary">
              Blog
            </p>
            <h1 className="text-center text-[2.75rem] font-medium leading-[3.25rem]">
              Advice & Insights
            </h1>
          </div>

          <div className="-m-6 flex flex-wrap justify-center">
            <div className="w-full p-6 sm:w-1/2 lg:w-1/3">
              <div className="flex h-full flex-col border border-black-g-50">
                <div className="relative pt-[73%]">
                  <img
                    className="absolute inset-0 h-full w-full object-cover object-center"
                    src="/uploads/blog-1.jpg"
                  />
                </div>

                <div className="flex flex-1 flex-col border-2 p-6">
                  <div className="font-secondary text-xs font-semibold uppercase text-black-g-100">
                    10 min to read
                  </div>

                  <h5 className="my-3 font-secondary text-2xl font-semibold">
                    The ability to easily find and contact employees.
                  </h5>

                  <div className="mt-auto flex items-center space-x-2 font-secondary text-xs font-semibold uppercase text-black-g-100">
                    <div className="h-6 w-6 rounded-full bg-slate-600"></div>
                    <div>Mike Mello</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-6 sm:w-1/2 lg:w-1/3">
              <div className="flex h-full flex-col border border-black-g-50">
                <div className="relative pt-[73%]">
                  <img
                    className="absolute inset-0 h-full w-full object-cover object-center"
                    src="/uploads/blog-2.jeg"
                  />
                </div>

                <div className="flex flex-1 flex-col border-2 p-6">
                  <div className="font-secondary text-xs font-semibold uppercase text-black-g-100">
                    8 min to read
                  </div>

                  <h5 className="my-3 font-secondary text-2xl font-semibold">
                    The ability to generate reports and analytics on employee
                    data
                  </h5>

                  <div className="mt-auto flex items-center space-x-2 font-secondary text-xs font-semibold uppercase text-black-g-100">
                    <div className="h-6 w-6 rounded-full bg-slate-600"></div>
                    <div>Mike Mello</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-6 sm:w-1/2 lg:w-1/3">
              <div className="flex h-full flex-col border border-black-g-50">
                <div className="relative pt-[73%]">
                  <img
                    className="absolute inset-0 h-full w-full object-cover object-center"
                    src="/uploads/blog-3.png"
                  />
                </div>

                <div className="flex flex-1 flex-col border-2 p-6">
                  <div className="font-secondary text-xs font-semibold uppercase text-black-g-100">
                    12 min to read
                  </div>

                  <h5 className="my-3 font-secondary text-2xl font-semibold">
                    The ability to manage employee benefits..
                  </h5>

                  <div className="mt-auto flex items-center space-x-2 font-secondary text-xs font-semibold uppercase text-black-g-100">
                    <div className="h-6 w-6 rounded-full bg-slate-600"></div>
                    <div>Mike Mello</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="page-px">
          <div className="page-torn-300"></div>

          <div className="bg-blue-b-primary px-12 py-20 text-white">
            <div className="-m-6 flex flex-wrap items-center">
              <div className="flex-1 p-6">
                <div className="-m-4 flex items-center">
                  <div className="p-4">
                    <LogoIcon src="/assets/logo-icon-white.svg" size={27} />
                  </div>
                  <div className="p-4">
                    <h2 className="text-[2.75rem] font-medium leading-[3.25rem]">
                      Welcome to Our Dynamic Recruitment Hub
                    </h2>
                    <p className="text-lg">Where Opportunities Meet Talent</p>
                  </div>
                </div>
              </div>

              <div className="w-full p-6 lg:w-2/5">
                <h5 className="mb-3 font-semibold uppercase">
                  Let&apos;s try!
                </h5>
                <ul className="-m-2 flex flex-col">
                  <li className="flex items-start space-x-2 p-2">
                    <div className="h-6 w-7 bg-green-g-primary p-1 text-white">
                      <HiCheck className="h-full w-full stroke-2" />
                    </div>
                    <div>
                      Fair and unbiased recruitment through data-driven
                      insights.
                    </div>
                  </li>

                  <li className="flex items-start space-x-2 p-2">
                    <div className="h-6 w-7 bg-green-g-primary p-1 text-white">
                      <HiCheck className="h-full w-full stroke-2" />
                    </div>
                    <div>
                      Time-saving automation for efficient pre-screening.
                    </div>
                  </li>

                  <li className="flex items-start space-x-2 p-2">
                    <div className="h-6 w-7 bg-green-g-primary p-1 text-white">
                      <HiCheck className="h-full w-full stroke-2" />
                    </div>
                    <div>
                      Personalized support and continuous improvement for better
                      matches and quality assurance.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="page-torn-300 -scale-100"></div>
        </div>
      </section>

      <TalentsTax />
    </>
  );
}

export default Home;
