import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateUserInformation as updateUserInformationApi } from "../services/apiUser";

export function useUpdateUserInformation() {
  const queryClient = useQueryClient();

  const {
    mutate: updateUserInformation,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({
      firstName,
      lastName,
      birthdate,
      contact: {
        email,
        phone,
        address: {
          address,
          address2,
          cityId,
          postalCode,
          countryId,
          countryName,
        } = {},
        socialPlatforms,
      },
      gender,
    }) =>
      updateUserInformationApi({
        firstName,
        lastName,
        birthdate,
        contact: {
          email,
          phone,
          address: {
            address,
            address2,
            cityId,
            postalCode,
            countryId,
            countryName,
          },
          socialPlatforms,
        },
        gender,
      }),
    onMutate: () => toast.loading("Saving user data..."),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["user"] });
      toast.dismiss();
      toast.success("User information has been submitted successfully");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { updateUserInformation, isPending, isSuccess };
}
