import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { HiPlusSmall, HiUser } from "react-icons/hi2";
import EditIconSolid from "../../icons/EditIconSolid";
import Loader from "../loader/Loader";
import { getFileType } from "../../utils/helpers";

function PhotoInput({
  size = 36,
  id,
  controllerName,
  control,
  rules,
  disabled,
  isUploading,
  src,
}) {
  const SIZE = `${parseFloat(size) / 4}rem`;

  if (isUploading)
    return (
      <div
        className="flex items-center justify-center overflow-hidden rounded-full bg-[#C4C4C4] text-black-g-50"
        style={{ width: SIZE, height: SIZE }}
      >
        <Loader />
      </div>
    );

  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div>
            <label
              htmlFor={id}
              className="relative block cursor-pointer rounded-full"
              style={{ width: SIZE, height: SIZE }}
            >
              {!src ? (
                <>
                  <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-[#C4C4C4] p-6 text-black-g-50">
                    <HiUser className="h-full w-full" />
                  </div>
                  <div className="absolute bottom-2 right-0 flex h-9 w-9 items-center justify-center rounded-full border-2 border-white bg-blue-b-primary text-white">
                    <HiPlusSmall className="h-full w-full" />
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full w-full overflow-hidden rounded-full">
                    {getFileType(src) === "image" ? (
                      <img
                        src={src}
                        className="h-full w-full object-cover object-center"
                      />
                    ) : (
                      <div className="h-full w-full bg-[#c4c4c4]"></div>
                    )}

                    <div className="absolute bottom-2 right-0 flex h-9 w-9 items-center justify-center rounded-full border-4 border-white bg-blue-b-primary text-white">
                      <EditIconSolid className="h-5 w-5 fill-white" />
                    </div>
                  </div>
                </>
              )}
            </label>

            <input
              hidden
              id={id}
              type="file"
              accept="image/*"
              value={value?.filename}
              onChange={(event) => onChange(event.target.files[0])}
              onBlur={onBlur}
              disabled={disabled || isUploading}
            />

            {invalid && error?.message && (
              <p className="mt-2 text-sm text-red-o-primary">
                {error?.message}
              </p>
            )}
          </div>
        );
      }}
    />
  );
}

PhotoInput.propTypes = {
  size: PropTypes.number,
  id: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  isUploading: PropTypes.bool,
  src: PropTypes.string,
};

export default PhotoInput;
