import {
  HiMiniArrowTopRightOnSquare,
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineEnvelope,
  HiOutlineMapPin,
  HiOutlinePhone,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2";

import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { format, parse } from "date-fns";
import download from "downloadjs";
import { useState } from "react";
import { Radar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { AiOutlineMessage } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa6";
import TalentPool from "../components/candidate/TalentPool";
import { useGeneratePdf } from "../hooks/useGeneratePdf";
import { useGetRecruiterJDescription } from "../hooks/useGetRecruiterJDescription";
import { useGetUserResumeById } from "../hooks/useGetUserResumeById";
import { useSubmitApplication } from "../hooks/useSubmitApplication";
import { useUser } from "../hooks/useUser";
import BuildingIcon from "../icons/BuildingIcon";
import CurrencyIcon from "../icons/CurrencyIcon";
import DownloadIcon from "../icons/DownloadIcon";
import SparklesIcon from "../icons/SparklesIcon";
import { SendMessage } from "../services/apiChat";
import Modal from "../shared/Modal";
import SelectBox from "../shared/inputs/SelectBox";
import Loader from "../shared/loader/Loader";
import {
  ADD_TO_PROJECT_APPLICATION_STATUS_ARRAY,
  LanguageLevelEnum,
  LeaveNoticeEnum,
  QualificationTypeEnum,
  RATETYPE_ENUM,
} from "../utils/constants";
import { getFileType } from "../utils/helpers";
import ErrorPage from "./ErrorPage";
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function CandidateResume() {
  const { isLoading, data, error } = useGetUserResumeById();

  const {
    id,
    isInTalentPool,
    profilePic,
    firstName,
    lastName,
    jobTitle,
    description,
    contactInfo,
    educations,
    experiences,
    languages,
    hardSkills,
    softSkills,
    pdFs,
    educationLevel,
    yearsOfExperience,
    disponibility,
    rate,
  } = data || {};
  const { user } = useUser();
  const { generatePDFLink, isPending: isGeneratingPDFLink } = useGeneratePdf();

  const {
    isLoading: isLoadingRecruiterJDescriptions,
    data: RecruiterJDescriptions,
  } = useGetRecruiterJDescription({ page: 1, size: 20 });
  const [message, SetMessage] = useState("");
  const { control, resetField, handleSubmit, reset } = useForm();

  const { submitApplication, isPending: isSubmittingApplication } =
    useSubmitApplication();

  const onSubmit = (data) => {
    submitApplication(
      { ...data, resumeId: id },
      { onSuccess: () => console.log("done") },
    );
  };

  const HandelSendMessage = async () => {
    try {
      if (message) {
        console.log(message);
        const response = await SendMessage(user.id, id, message);
        console.log(response);
        SetMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onError = (err) => {
    console.log(err);
  };

  if (error)
    return (
      <ErrorPage>
        <h1 className="text-[2rem] font-medium leading-10">{error?.message}</h1>
      </ErrorPage>
    );

  if (isLoading)
    return (
      <section>
        <div className="page-px py-16">
          <Loader />
        </div>
      </section>
    );

  return (
    <>
      <div className="page-px my-12">
        <div className="mx-auto max-w-screen-xl">
          <div className="-m-6 flex flex-wrap">
            <div className="flex-1 p-6">
              <section>
                <div className="mb-12">
                  <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-6 py-12">
                    <div className="-m-4 flex flex-wrap">
                      {getFileType(profilePic) === "image" && (
                        <div className="p-4">
                          <div className="h-48 w-48 overflow-hidden rounded-full">
                            <img
                              className="h-full w-full object-cover object-center"
                              src={profilePic}
                            />
                          </div>
                        </div>
                      )}

                      <div className="w-full p-4 md:flex-1">
                        <div className="relative -m-1 flex items-center text-xl font-bold">
                          {firstName && <div className="p-1">{firstName}</div>}

                          {lastName && (
                            <div className="p-1">
                              <span className="uppercase">{lastName}</span>
                            </div>
                          )}
                        </div>

                        {jobTitle && (
                          <div className="my-2 text-xl">{jobTitle}</div>
                        )}

                        <div className="my-3 overflow-hidden">
                          <div className="-mx-3 -my-1.5 flex flex-wrap items-center text-black-g-100">
                            {(contactInfo?.address?.city?.name ||
                              contactInfo?.address?.country?.name) && (
                              <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                                <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlineMapPin className="h-5 w-5" />
                                    {contactInfo?.address?.city?.name && (
                                      <span>
                                        {contactInfo?.address?.city?.name}
                                      </span>
                                    )}
                                    {contactInfo?.address?.city?.name &&
                                      contactInfo?.address?.country?.name && (
                                        <span>, </span>
                                      )}
                                    {contactInfo?.address?.country?.name && (
                                      <span>
                                        {contactInfo?.address?.country?.name}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                              <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                                {yearsOfExperience > 0 && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlineBriefcase className="h-5 w-5" />
                                    <span>
                                      {`${yearsOfExperience} ${yearsOfExperience <= 1 ? "year" : "years"}`}
                                    </span>
                                  </div>
                                )}

                                {rate?.max > 0 && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <CurrencyIcon className="h-5 w-5" />
                                    <span>
                                      {rate?.currency?.symbol === "$"
                                        ? `$${rate?.max}`
                                        : `${rate?.max}${rate?.currency?.symbol}`}{" "}
                                      {RATETYPE_ENUM[rate?.type]}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="flex w-full items-center space-x-1 px-3 py-1.5">
                              <div className="-mx-3 -my-1.5 flex flex-wrap items-center">
                                {educationLevel > 0 && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlineAcademicCap className="h-5 w-5" />
                                    <span>
                                      {QualificationTypeEnum[educationLevel]}
                                    </span>
                                  </div>
                                )}

                                {disponibility > 0 && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlineSquare3Stack3D className="h-5 w-5" />
                                    <span>
                                      {LeaveNoticeEnum[disponibility]}
                                    </span>
                                  </div>
                                )}

                                {contactInfo?.email && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlineEnvelope className="h-5 w-5" />
                                    <span>{contactInfo?.email}</span>
                                  </div>
                                )}

                                {contactInfo?.phone && (
                                  <div className="flex items-center space-x-1 px-3 py-1.5">
                                    <HiOutlinePhone className="h-5 w-5" />
                                    <span>{contactInfo?.phone}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="my-4">
                          <div className="-m-3 flex items-center transition-all duration-300 sm:-m-4">
                            <div className="p-3 transition-all duration-300 sm:p-4">
                              <Modal>
                                <Modal.Open>
                                  <button
                                    type="button"
                                    className="btn-blue-b-primary py-1.5 text-lg font-medium"
                                  >
                                    Add to project
                                  </button>
                                </Modal.Open>

                                <Modal.Window className="max-w-md overflow-hidden bg-[#F7F9FB] p-0">
                                  <div className="flex space-x-2 rounded-b-lg p-6 shadow-lg">
                                    <div className="flex-1 text-xl font-medium">
                                      Add {firstName} to existing projects
                                    </div>

                                    <Modal.Close />
                                  </div>

                                  <form
                                    onSubmit={handleSubmit(onSubmit, onError)}
                                  >
                                    <div className="m-8 rounded-lg border border-black-g-50 p-6 shadow-lg">
                                      <div className="-m-6 flex flex-wrap">
                                        <div className="w-full p-6">
                                          <div className="-m-4 flex flex-wrap">
                                            <div className="w-full p-4">
                                              <SelectBox
                                                control={control}
                                                id="jobDescriptionId"
                                                label="Project name"
                                                containerClassName="bg-white"
                                                placeholder="Select a project"
                                                rules={{
                                                  required:
                                                    "Please select a project",
                                                }}
                                                options={{
                                                  data: RecruiterJDescriptions?.results,
                                                  key: "id",
                                                  value: "id",
                                                  name: "title",
                                                }}
                                                resetField={resetField}
                                                isLoading={
                                                  isLoadingRecruiterJDescriptions
                                                }
                                                disabled={
                                                  isSubmittingApplication
                                                }
                                              />
                                            </div>

                                            <div className="w-full p-4">
                                              <SelectBox
                                                control={control}
                                                id="status"
                                                label="Status"
                                                containerClassName="bg-white"
                                                placeholder="Select a status"
                                                rules={{
                                                  required:
                                                    "Please select a status",
                                                }}
                                                options={{
                                                  data: ADD_TO_PROJECT_APPLICATION_STATUS_ARRAY,
                                                  key: "id",
                                                  value: "id",
                                                  name: "name",
                                                }}
                                                resetField={resetField}
                                                disabled={
                                                  isSubmittingApplication
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="w-full p-6">
                                          <button className="flex items-center space-x-2 font-medium text-blue-b-primary hover:underline">
                                            <a
                                              href={"/recruiter/add-job"}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Create a new project
                                            </a>
                                            <HiMiniArrowTopRightOnSquare className="h-4 w-4" />
                                          </button>
                                        </div>

                                        <div className="w-full p-6">
                                          <div className="flex items-center space-x-4">
                                            <Modal.Close
                                              className="btn-outline-primary flex-1 rounded-xl py-1.5 text-center"
                                              onClick={reset}
                                            >
                                              Cancel
                                            </Modal.Close>

                                            <button
                                              className="btn-blue-b-primary flex-1 rounded-xl py-1.5"
                                              disabled={isSubmittingApplication}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </Modal.Window>
                              </Modal>
                            </div>
                            <div className="p-3 transition-all duration-300 sm:p-4">
                              <div className="h-10 w-px bg-black-g-100"></div>
                            </div>
                            <div className="p-3 transition-all duration-300 sm:p-4">
                              <Modal>
                                <Modal.Open windowId={"SendMessage"}>
                                  <div className="p-3 transition-all duration-300 sm:p-4">
                                    <button className="cursor-pointer rounded-full border border-blue-b-75 bg-blue-b-50 p-2.5 shadow-md hover:shadow-none disabled:cursor-not-allowed disabled:shadow-none">
                                      <AiOutlineMessage className="h-6 w-6 stroke-1 text-black-g-100" />
                                    </button>
                                  </div>
                                </Modal.Open>
                                <Modal.Window
                                  id={"SendMessage"}
                                  className="fixed right-0 top-5 h-[90%] max-h-[90vh] overflow-y-auto"
                                  style={{ maxWidth: "400px" }} // Set a maximum width
                                >
                                  <div className="flex justify-end">
                                    <Modal.Close />
                                  </div>
                                  <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
                                    <h2 className="mb-4 text-center text-2xl font-extrabold text-gray-900">
                                      New Message
                                    </h2>
                                    <p className="mb-6 text-center text-lg text-gray-600">
                                      Sharing more details will increase your
                                      chances of receiving a reply.
                                    </p>
                                    <div className="mb-5">
                                      <label className="block text-lg font-medium text-gray-700">
                                        To:{" "}
                                        <span className="font-extrabold">
                                          {firstName + " " + lastName}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="mb-8">
                                      <label
                                        htmlFor="message"
                                        className="mb-5 block text-sm font-medium text-gray-700"
                                      >
                                        Your Message
                                      </label>
                                      <textarea
                                        id="message"
                                        rows="5"
                                        value={message}
                                        className="h-300 mt-1 block w-full resize-none rounded-sm border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-lg"
                                        placeholder="Type your message here..."
                                        onChange={(e) =>
                                          SetMessage(e.target.value)
                                        }
                                      ></textarea>
                                    </div>
                                    <button
                                      className="w-full rounded-full bg-blue-600 px-6 py-3 text-lg text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                      onClick={HandelSendMessage}
                                    >
                                      Send Message
                                    </button>
                                  </div>
                                </Modal.Window>
                              </Modal>
                            </div>
                            <div className="flex-grow p-3 transition-all duration-300 sm:p-4">
                              <button
                                className="cursor-pointer rounded-full border border-blue-b-75 bg-blue-b-50 p-2.5 shadow-md hover:shadow-none disabled:cursor-not-allowed disabled:shadow-none"
                                onClick={() =>
                                  generatePDFLink(
                                    { resumeId: id },
                                    {
                                      onSuccess: async (res) => {
                                        const response = await fetch(res?.url);
                                        const blob = await response.blob();

                                        download(
                                          blob,
                                          firstName +
                                            " " +
                                            lastName +
                                            " Resume",
                                        );
                                      },
                                    },
                                  )
                                }
                                disabled={isGeneratingPDFLink}
                              >
                                <DownloadIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {hardSkills?.length > 0 && (
                          <div className="-m-1 flex flex-wrap">
                            {hardSkills?.map((skill) => (
                              <div key={skill?.id} className="p-1">
                                <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                                  {skill?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="mt-12">
                  <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-12 py-6">
                    <div className="flex items-center space-x-6">
                      <h1 className="text-xl font-semibold">PDF files</h1>

                      <div className="h-px flex-1 bg-slate-300"></div>
                    </div>

                    <div className="mt-6">
                      <div className="-m-1 flex flex-wrap items-center">
                        {pdFs?.map((pdf) => (
                          <div key={pdf?.id} className="p-1">
                            <a
                              className="flex items-center space-x-1 rounded border border-transparent bg-blue-b-50 p-2 text-sm font-medium text-green-g-500 hover:border-green-g-75 hover:bg-teal-200"
                              href={pdf?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaRegFilePdf className="h-6 w-6" />
                              <div>{pdf?.fileName}</div>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {description && (
                <section>
                  <div className="mt-12">
                    <div className="mx-auto max-w-screen-lg rounded-3xl bg-[#F7F9FB] px-12 py-6">
                      <div className="flex items-center space-x-6">
                        <h1 className="text-xl font-semibold">
                          About Candidate
                        </h1>

                        <div className="h-px flex-1 bg-slate-300"></div>
                      </div>
                      <p className="mt-6">{description}</p>
                    </div>
                  </div>
                </section>
              )}
            </div>

            {isInTalentPool && (
              <div className="w-1/3 p-6">
                <section className="h-full">
                  <div className="h-full rounded-2xl bg-[#F7F9FB] py-6">
                    <TalentPool />
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>

      {(hardSkills?.length > 0 || softSkills?.length > 0) && (
        <section>
          <div className="page-px my-12">
            <div className="mx-auto max-w-screen-xl overflow-hidden rounded-3xl bg-[#F7F9FB] px-12 py-6">
              <div className="-m-16 flex flex-wrap">
                {hardSkills?.length > 0 && (
                  <div className="w-full p-16 md:w-1/2">
                    <div className="mb-12 flex items-center space-x-6">
                      <h1 className="text-xl font-semibold">Hard skills</h1>
                      <div className="h-px flex-1 bg-slate-300"></div>
                    </div>

                    <div>
                      <Radar
                        data={{
                          labels: hardSkills?.map((skill) => skill?.name),
                          datasets: [
                            {
                              label: "Hard skills",
                              data: hardSkills?.map(
                                (skill) => skill?.hardSkillProficiency,
                              ),
                              backgroundColor: "rgba(56,57,128,0.2)",
                              borderColor: "#c5d1e5",
                              borderWidth: 1,
                              pointRadius: 4,
                              pointBackgroundColor: "#0054ff",
                              pointHoverBackgroundColor: "#000",
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            r: {
                              grid: { circular: true, color: "#96b9ff" },
                              angleLines: { color: "#96b9ff" },
                              beginAtZero: true,
                              ticks: { stepSize: 1 },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {softSkills?.length > 0 && (
                  <div className="w-full p-16 md:w-1/2">
                    <div className="mb-12 flex items-center space-x-6">
                      <h1 className="text-xl font-semibold">Soft skills</h1>
                      <div className="h-px flex-1 bg-slate-300"></div>
                    </div>

                    <div>
                      <Radar
                        data={{
                          labels: softSkills?.map((skill) => skill?.name),
                          datasets: [
                            {
                              label: "Soft skills",
                              data: softSkills?.map(
                                (skill) => skill?.softSkillProficiency,
                              ),
                              backgroundColor: "rgba(0,134,74,0.2)",
                              borderColor: "#b1d9ce",
                              borderWidth: 1,
                              pointRadius: 4,
                              pointBackgroundColor: "#0ac3a9",
                              pointHoverBackgroundColor: "#000",
                            },
                          ],
                        }}
                        options={{
                          scales: {
                            r: {
                              grid: { circular: true, color: "#9be6dc" },
                              angleLines: { color: "#9be6dc" },
                              beginAtZero: true,
                              ticks: { stepSize: 1 },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-xl rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Experience</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {experiences?.length > 0 &&
              experiences?.map((experience) => (
                <div key={experience?.id} className="mt-6">
                  <div className="-m-3 flex">
                    <div className="p-3">
                      {getFileType(experience?.companyLogo) === "image" ? (
                        <img
                          className="h-20 w-20 object-cover object-center"
                          src={experience?.companyLogo}
                        />
                      ) : (
                        <BuildingIcon className="h-20 w-20 text-blue-b-75" />
                      )}
                    </div>

                    <div className="flex-1 p-3">
                      {experience?.name && (
                        <h2 className="text-xl font-semibold">
                          {experience?.name}
                        </h2>
                      )}

                      {experience?.companyName && (
                        <h3 className="text-xl">{experience?.companyName}</h3>
                      )}

                      {(experience?.startDate || experience?.endDate) && (
                        <div className="flex items-center space-x-1 text-lg font-light text-black-g-100">
                          <span>
                            {format(
                              parse(
                                experience?.startDate,
                                "dd/MM/yyyy",
                                new Date(),
                              ).toString(),
                              "MMM yyyy",
                            )}
                          </span>
                          {experience?.endDate && (
                            <>
                              <span>-</span>
                              <span>
                                {format(
                                  parse(
                                    experience?.endDate,
                                    "dd/MM/yyyy",
                                    new Date(),
                                  ).toString(),
                                  "MMM yyyy",
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      )}

                      <div className="text-lg font-light text-black-g-100">
                        Location
                      </div>

                      {experience?.description && (
                        <div className="my-2 text-sm">
                          {experience?.description}
                        </div>
                      )}

                      {experience?.hardSkills?.length > 0 && (
                        <div className="my-3">
                          <div className="-m-1 flex flex-wrap items-center">
                            <div className="p-1">
                              <SparklesIcon className="h-7 w-7 text-blue-b-primary" />
                            </div>
                            <div className="flex items-center whitespace-nowrap p-1 text-sm font-medium text-blue-b-primary">
                              {experience?.hardSkills[0]?.name}
                              {experience?.hardSkills?.length > 1 && `, `}
                              {experience?.hardSkills[1]?.name}
                              {experience?.hardSkills?.length > 2 &&
                                ` et ${experience?.hardSkills?.length - 2} more skills`}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-xl rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Education</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {educations?.length > 0 &&
              educations?.map((education) => (
                <div key={education?.id} className="mt-6">
                  <div className="-m-3 flex">
                    {/* {getFileType(education?.image) === "image" && ( */}
                    <div className="p-3">
                      {/* <img
                          className="h-20 w-20 object-cover object-center"
                          src={education?.image}
                        /> */}
                      <div className="h-20 w-20 text-blue-b-75">
                        <HiOutlineAcademicCap className="h-full w-full" />
                      </div>
                    </div>
                    {/*  )} */}

                    <div className="flex-1 p-3">
                      {education?.schoolName && (
                        <h2 className="text-xl font-semibold">
                          {education?.schoolName}
                        </h2>
                      )}

                      {education?.name && (
                        <h3 className="text-xl">{education?.name}</h3>
                      )}

                      {(education?.startTime || education?.endTime) && (
                        <div className="flex items-center space-x-1 text-lg font-light text-black-g-100">
                          <span>
                            {format(
                              parse(
                                education?.startTime,
                                "dd/MM/yyyy",
                                new Date(),
                              ).toString(),
                              "MMM yyyy",
                            )}
                          </span>
                          {education?.endTime && (
                            <>
                              <span>-</span>
                              <span>
                                {format(
                                  parse(
                                    education?.endTime,
                                    "dd/MM/yyyy",
                                    new Date(),
                                  ).toString(),
                                  "MMM yyyy",
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      )}

                      <div className="text-lg font-light text-black-g-100">
                        Location
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-12">
          <div className="mx-auto max-w-screen-xl rounded-3xl bg-[#F7F9FB] px-12 py-6">
            <div className="flex items-center space-x-6">
              <h1 className="text-xl font-semibold">Languages</h1>
              <div className="h-px flex-1 bg-slate-300"></div>
            </div>

            {languages?.length > 0 &&
              languages?.map((language) => (
                <div key={language?.id} className="mt-6">
                  <div className="-m-3 flex">
                    <div className="flex-1 p-3">
                      {language?.name && (
                        <h2 className="text-xl font-semibold">
                          {language?.name}
                        </h2>
                      )}

                      {language?.level && (
                        <h3 className="text-xl">
                          {LanguageLevelEnum[language?.level]}
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default CandidateResume;
