import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import LogoMainVersion from "./logo/LogoMainVersion";
import FooterMenu from "./FooterMenu";

function Footer() {
  return (
    <footer>
      <div className="page-px py-16">
        <div className="-m-6 flex items-center">
          <div className="p-6">
            <LogoMainVersion className="w-full max-w-48" />
          </div>

          <div className="flex flex-1 justify-end p-6">
            <FooterMenu />
          </div>
        </div>

        <div className="my-6 h-px bg-black-g-75"></div>

        <div className="-m-4 flex items-center">
          <div className="p-4">
            <p className="text-sm">© Devadoot 2023. All Rights Reserved</p>
          </div>

          <div className="flex flex-1 justify-end p-4">
            <div className="flex items-center space-x-3">
              <a
                className="border border-black-g-primary p-2"
                href={"https://www.facebook.com/Devadoot.tn"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="h-5 w-5" />
              </a>

              <a
                className="border border-black-g-primary p-2"
                href={"https://www.linkedin.com/company/devadoot/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="h-5 w-5" />
              </a>

              {/*  <div className="border border-black-g-primary p-2">
                <FaTwitter className="h-5 w-5" />
              </div> */}

              {/* <div className="border border-black-g-primary p-2">
                <FaInstagram className="h-5 w-5" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
