import { useQuery } from "@tanstack/react-query";
import { isAuthenticated as isAuthenticatedApi } from "../services/apiAuth";

export function useIsAuthenticated() {
  const {
    isLoading: isAuthenticating,
    data,
    isError,
    error,
    failureReason,
  } = useQuery({
    queryKey: ["isAuthenticated"],
    queryFn: isAuthenticatedApi,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    isAuthenticating,
    redirectionUrl: data?.redirectionUrl,
    roles: data?.roles,
    isError,
    error,
    failureReason,
  };
}
