import PropTypes from "prop-types";

function EyeIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13,17c-0.3,0.8-0.5,1.6-0.5,2.5s0.2,1.7,0.5,2.5h0c-0.3-0.8-0.5-1.6-0.5-2.5C12.5,18.6,12.6,17.8,13,17C13,17,13,17,13,17z
	"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5,12.5c-0.9,0-1.7,0.2-2.5,0.5c0,0,0-0.1,0-0.1C17.8,12.6,18.6,12.4,19.5,12.5c1.5-0.1,2.9,0.4,4,1.2c0,0,0,0.1,0,0.1
	C22.4,13,21,12.5,19.5,12.5z"
      />
      <polygon
        strokeLinecap="round"
        strokeLinejoin="round"
        points="21.5,19.5 24,22 22,24 19.5,21.5 17,24 15,22 17.5,19.5 15,17 17,14.9 19.5,17.4 22,14.9 24,17 "
      />

      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24,12c-0.2,0.6-0.3,1.1-0.5,1.7c0,0,0,0.1,0,0.1c-1.1-0.8-2.5-1.3-4-1.3c-0.9,0-1.7,0.2-2.5,0.5c0,0,0-0.1,0-0.1
		c0.1-0.3,0.1-0.5,0.1-0.8c0-0.7-0.1-1.4-0.4-2C15.9,8.3,14.1,7,12,7s-3.9,1.3-4.7,3c-0.3,0.6-0.4,1.3-0.4,2c0,2.8,2.3,5.1,5.1,5.1
		c0.4,0,0.7,0,0.9-0.1c0,0,0,0,0.1,0c-0.3,0.8-0.5,1.6-0.5,2.5s0.2,1.7,0.5,2.5h-1C6,22,1,17.7,0,12C1,6.3,6,2,12,2S23,6.3,24,12z"
      />

      <polygon
        strokeLinecap="round"
        strokeLinejoin="round"
        points="21.5,19.5 24,22 22,24 19.5,21.5 17,24 15,22 17.5,19.5 15,17 17,14.9 19.5,17.4 22,14.9 24,17 "
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12,10c-1.1,0-2,0.9-2,2c0,0.2,0,0.5,0.1,0.7c0.3,0.7,1,1.3,1.9,1.3s1.6-0.5,1.9-1.3C14,12.5,14,12.2,14,12 C14,10.9,13.1,10,12,10z"
      />
    </svg>
  );
}

EyeIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EyeIcon;
