const defaultPic = "../../../public/uploads/avatar.png";
const ConversationItem = ({
  active,
  time,
  name,
  message,
  id,
  profilePic,
  handleCandidateClick,
}) => {
  return (
    <div onClick={() => handleCandidateClick(id)} key={id}>
      <div
        className={"conversation-item m-1   rounded-md p-1 hover:bg-gray-200 "}
      >
        <div className={"flex cursor-pointer items-center  p-2  "}>
          <img
            src={profilePic || defaultPic} // Use default if profilePic is not provided
            alt="Profile"
            className="mr-3 h-10 w-10 rounded-full"
          />
          <div className="flex-grow p-2">
            <div className="text-md flex justify-between ">
              <div className="text-sm font-medium  ">{name}</div>
              <div className="text-xs  ">{time}</div>
            </div>
            <div className="w-40 truncate   text-sm text-gray-500">
              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationItem;
