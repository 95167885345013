/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  HiChevronLeft,
  HiChevronRight,
  HiMiniChevronLeft,
  HiMiniChevronRight,
} from "react-icons/hi2";
import { IoBookmarkOutline, IoEllipseSharp } from "react-icons/io5";
import { RxDownload } from "react-icons/rx";
import { TbChartCandle } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useSearchCandidate } from "../hooks/useSearchCandidate";
import Divider from "../shared/Divider";
import Pagination from "../shared/Pagination";
import CustomCard from "../shared/cards/CustomCard";
import CustomCheckbox from "../shared/checkbox/CustomCheckbox";
import StatusFilter from "../shared/filters/StatusFilter";
import { talentsFilterStatus } from "../shared/filters/data";
import Input from "../shared/inputs/Input";
import {
  ContractTypeEnum,
  ExperienceLevelEnum,
  LeaveNoticeEnum,
  contractTypeArray,
  experienceLevelArray,
  leaveNoticeArray,
  workLocationArray,
} from "../utils/constants";

import { Dialog } from "@mui/material";
import download from "downloadjs";
import CandidateModalPreview from "../components/candidate/CandidateModalPreview";
import { useGeneratePdf } from "../hooks/useGeneratePdf";
import { useSaveResumeTalentPool } from "../hooks/useSaveResumeTalentPool";
import { useUser } from "../hooks/useUser";
import { SendMessage } from "../services/apiChat";
import Modal from "../shared/Modal";
import Loader from "../shared/loader/Loader";

function BrowseTalents() {
  const { control, resetField, watch } = useForm();
  const [selectedFilters, setSelectedFilters] = useState(null);
  const { keyword, minSalary, maxSalary } = watch();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [message, SetMessage] = useState("");
  const { user } = useUser();

  useEffect(() => {
    if (keyword !== undefined) {
      // Clear the previous timer
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      // Set a new timer to trigger the API call after 500 milliseconds of inactivity
      const newTimeout = setTimeout(() => {
        setSelectedFilters((prevState) => ({
          ...prevState,
          keyword: keyword,
          minSalary: minSalary,
          maxSalary: maxSalary,
        }));
      }, 500);

      setTypingTimeout(newTimeout);
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        keyword: "",
        minSalary: 0,
        maxSalary: 0,
      }));
    }
  }, [keyword, minSalary, maxSalary]);

  const JOB_POSTS_PER_PAGE = 9;
  const QUERY_STRING = "candidate_search";
  const { isLoading, data } = useSearchCandidate({
    queryString: QUERY_STRING,
    size: JOB_POSTS_PER_PAGE,
    keywords: selectedFilters?.keyword || "",
    experienceLevels: selectedFilters?.expertise || [],
    workLocations: selectedFilters?.workLocation || [],
    employmentTypes: selectedFilters?.employmentType || [],
    minSalary: selectedFilters?.minSalary || 0,
    maxSalary: selectedFilters?.maxSalary || 0,
    leaveNotices: selectedFilters?.leaveNotice || [],
  });
  const { generatePDFLink, isPending: isGeneratingFile } = useGeneratePdf();

  const [showFilters, setShowFilters] = useState(false);

  const { results, totalCount } = data || {};
  const [isOpen, setIsOpen] = useState(false);

  // State to track the index of the currently selected item
  const [selectedItem, setSelectedItem] = useState(null);
  const [currenIndex, setCurrenIndex] = useState(0);

  // Function to handle clicking the next button
  const handleNext = (previewId) => {
    let obIndex = results.findIndex((x) => x.id === previewId);
    obIndex = obIndex + 1;
    setCurrenIndex(obIndex);
    setSelectedItem(results[obIndex]?.id);
  };

  // Function to handle clicking the previous button
  const handlePrevious = (previewId) => {
    let obIndex = results.findIndex((x) => x.id === previewId);
    obIndex = obIndex - 1;
    setCurrenIndex(obIndex);
    setSelectedItem(results[obIndex]?.id);
  };

  const handleOpenPopup = (id) => {
    setIsOpen(true);
    setSelectedItem(id);
    let obIndex = results.findIndex((x) => x.id === id);
    setCurrenIndex(obIndex);
  };

  const handledownload = (id) => {
    generatePDFLink(
      { resumeId: id },
      {
        onSuccess: async (res) => {
          const response = await fetch(res?.url);
          const blob = await response.blob();
          const obj = results.find((x) => x.id === id);
          download(blob, obj?.firstName + " " + obj?.lastName + " Resume");
        },
      },
    );
  };
  const getFileType = (url) => {
    if (url && /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url)) {
      return "image";
    }
    return "default"; // Return a default type if not an image
  };
  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "notInterested":
        return "text-red-o-500 bg-red-o-50";
      case "available":
        return "text-blue-b-primary bg-blue-b-50";
      case "progress":
        return "text-yellow-y-500 bg-yellow-y-50";
      case "archived":
        return "text-black-g-500 bg-black-g-50";
      default:
        return "text-blue-b-primary bg-blue-b-50";
    }
  };
  const HandelSendMessage = async (item) => {
    try {
      if (message) {
        const response = await SendMessage(user.id, item.candidate.id, message);
        console.log(response);
        SetMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { saveResumeTalentPool } = useSaveResumeTalentPool();

  return (
    <div className="page-px flex flex-col gap-4 py-4">
      <div className="flex items-center">
        <span className="title-primary-300-4xl flex-1 font-medium">
          Talents
        </span>

        {/* <div className="btn-blue-b-primary rounded-md font-primary text-base font-medium">
          Create New Candidate
        </div> */}
      </div>
      <div className="flex w-full flex-col flex-wrap gap-4 md:flex-row md:items-center">
        <StatusFilter
          items={talentsFilterStatus}
          dropdownMobileLabel="Talents Status"
        />
        <Input
          type="text"
          id="keyword"
          placeholder="React AND Angular"
          controllerName="keyword"
          control={control}
          fieldClassName="input-transparent py-2 text-sm font-primary font-normal w-[200px]"
          resetField={resetField}
        />

        <div
          className="filter-tab flex items-center rounded-[4px] px-3"
          onClick={() => setShowFilters((prevState) => !prevState)}
        >
          <TbChartCandle className="mr-2" />
          Advanced filters
        </div>
      </div>
      <>
        <div className="flex flex-col flex-wrap gap-2 md:flex-row md:gap-0">
          {showFilters ? (
            <div className="w-full pr-0 md:w-2/5 md:pr-4 lg:w-1/5 xl:w-1/5 2xl:w-1/5">
              <div className="filter-layout flex flex-col">
                <span className="text-primary-300 font-primary text-xl font-medium">
                  Advanced Filters
                </span>

                <div className="mb-2 mt-4">
                  <span className="text-primary-300 font-primary text-lg font-semibold">
                    Job Type
                  </span>
                  <div className="mt-2 flex flex-col gap-2">
                    {contractTypeArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        value={item.value}
                        onSelect={() => {
                          setSelectedFilters((prevState) => {
                            const employmentType =
                              prevState?.employmentType || []; // Initialize employmentType as an array if it's undefined
                            const updatedemploymentType =
                              employmentType.includes(item.value)
                                ? employmentType.filter(
                                    (value) => value !== item.value,
                                  ) // Remove item from array if it's already included
                                : [...employmentType, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              employmentType: updatedemploymentType,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-primary-300  font-primary text-lg font-semibold">
                    Salary/Daily rate range
                  </span>

                  <div className="mb-2 mt-2 block">
                    <Input
                      label="From"
                      gap={0}
                      gapLabel={0}
                      column={false}
                      type="text"
                      id="mins"
                      placeholder="1200"
                      controllerName="minSalary"
                      control={control}
                      resetField={resetField}
                      labelClassName="w-[60px] p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                      fieldClassName="input-transparent h-auto p-3"
                      containerBaseClassName="border border-black-g-50 rounded-r-md"
                      containerClassName="bg-white"
                    />
                  </div>

                  <div className="block">
                    <Input
                      label="To"
                      gap={0}
                      gapLabel={0}
                      column={false}
                      type="text"
                      id="maxs"
                      placeholder="5000"
                      controllerName="maxSalary"
                      control={control}
                      resetField={resetField}
                      labelClassName="w-[60px] text-center p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                      fieldClassName="input-transparent h-auto p-3"
                      containerBaseClassName="border border-black-g-50 rounded-r-md"
                      containerClassName="bg-white"
                    />
                  </div>
                </div>

                <div className="mb-2">
                  <span className="text-primary-300  font-primary text-lg font-semibold">
                    Expertise
                  </span>

                  <div className="mt-2 flex flex-col gap-2">
                    {experienceLevelArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const expertise = prevState?.expertise || []; // Initialize expertise as an array if it's undefined
                            const updatedexpertise = expertise.includes(
                              item.value,
                            )
                              ? expertise.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...expertise, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              expertise: updatedexpertise,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-primary-300 mb-3 font-primary text-lg font-semibold">
                    Work Location
                  </span>

                  <div className="mt-2 flex flex-col gap-2">
                    {workLocationArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const workLocation = prevState?.workLocation || []; // Initialize workLocation as an array if it's undefined
                            const updatedworkLocation = workLocation.includes(
                              item.value,
                            )
                              ? workLocation.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...workLocation, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              workLocation: updatedworkLocation,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="text-primary-300 mb-3 font-primary text-lg font-semibold">
                    Availablility
                  </span>

                  <div className="mt-2 flex flex-col gap-2">
                    {leaveNoticeArray?.map((item) => (
                      <CustomCheckbox
                        key={item.id}
                        inputId={item.id}
                        labelName={item.name}
                        onSelect={() => {
                          // console.log(item);
                          setSelectedFilters((prevState) => {
                            const leaveNotice = prevState?.leaveNotice || []; // Initialize leaveNotice as an array if it's undefined
                            const updatedleaveNotice = leaveNotice.includes(
                              item.value,
                            )
                              ? leaveNotice.filter(
                                  (value) => value !== item.value,
                                ) // Remove item from array if it's already included
                              : [...leaveNotice, item.value]; // Add item to array if it's not included
                            return {
                              ...prevState,
                              leaveNotice: updatedleaveNotice,
                            };
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {!isLoading ? (
            <>
              {totalCount > 0 ? (
                <div
                  className={`flex w-full flex-wrap gap-6  ${showFilters ? "gap-2 md:w-3/5 md:gap-6 lg:w-4/5 lg:gap-5 xl:w-4/5 xl:gap-7 2xl:w-4/5 2xl:gap-6" : "lg:gap-6 xl:gap-6 2xl:gap-6"} `}
                >
                  {results?.map((item) => (
                    <div
                      key={item.id}
                      className={`h-[19rem] w-full pr-0 transition delay-150 duration-300 ease-in-out  md:w-full  ${!showFilters ? "lg:w-[48.7%] xl:w-[31.7%] 2xl:w-[32%]" : "lg:w-[47.7%] xl:w-[48.5%] 2xl:w-[31.7%] "} `} // Add padding-left to the last card in each row
                    >
                      <CustomCard
                        CardHeader={
                          <div className="flex items-center gap-1">
                            <div className="flex flex-1 items-center gap-1">
                              <img
                                className={
                                  "image-layout h-16 w-16 rounded-full object-cover object-center"
                                }
                                src={
                                  getFileType(item?.candidate?.profilePic) ===
                                  "image"
                                    ? item?.candidate?.profilePic
                                    : "/uploads/avatar.png"
                                }
                              />
                              <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-1">
                                  <Link
                                    className="title-primary-300-sm w-[158px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold sm:w-full"
                                    onClick={() => {
                                      handleOpenPopup(item?.id);
                                    }}
                                  >
                                    {item?.candidate?.firstName
                                      ? item?.candidate?.firstName +
                                        " " +
                                        item?.candidate?.lastName
                                      : "No Username"}
                                  </Link>

                                  {/* <MdVerified color="#0054FF" size={16} /> */}
                                </div>

                                <div className="flex items-center gap-1">
                                  <span className="subtitle-blackG-100-xs font-normal">
                                    {item?.jobTitle ? item?.jobTitle : "N/A"}
                                  </span>

                                  <IoEllipseSharp size={8} />
                                  <span className="subtitle-blackG-100-xs font-normal">
                                    {item?.contactInfo?.address?.country
                                      ?.name ?? "No Country"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex  gap-1">
                              {/**if bookmareked use this class btn-blue-b-primary and this color for icon #0054ff */}
                              <button
                                className="btn-outline-blueB rounded-[4px] p-2"
                                onClick={() => saveResumeTalentPool(item?.id)}
                                title="Save in Talent Pool"
                              >
                                <IoBookmarkOutline size={20} />
                              </button>
                            </div>
                          </div>
                        }
                        CardContent={
                          <div className="flex flex-1 flex-col gap-4">
                            <div className="flex items-center gap-1">
                              <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                                {ExperienceLevelEnum[item?.experienceLevel]}
                              </span>
                              <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                                {ContractTypeEnum[item?.preferredContractType]}
                              </span>
                              <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                                {LeaveNoticeEnum[item?.disponibility]}
                              </span>
                            </div>
                            <div className="h-100  font-primary text-sm  font-normal text-black-g-100">
                              <div className="-m-1 flex flex-wrap items-center">
                                {item?.description ? (
                                  item?.hardSkills?.slice(0, 6)?.map((item) => (
                                    <div key={item?.id} className="p-1">
                                      <div className="bg-blue-b-50 text-sm text-blue-b-primary">
                                        {item?.name}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p>{item?.description?.substring(0, 250)}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        }
                        CardFooter={
                          <div className="flex flex-col">
                            <Divider className="my-4" />

                            <div className="flex items-center justify-between">
                              <span
                                className={`rounded p-2 font-primary text-xs font-medium ${getStatusBackgroundColor("available")}`}
                              >
                                Available
                              </span>
                              <div className="flex items-center space-x-4">
                                <div>
                                  <Modal>
                                    <Modal.Open windowId={"SendMessage"}>
                                      <button className="btn-outline-blueB flex items-center rounded-[4px] px-5 py-2 text-sm font-medium">
                                        Message
                                      </button>
                                    </Modal.Open>
                                    <Modal.Window
                                      id={"SendMessage"}
                                      className="fixed right-0 top-5 h-[90%] max-h-[90vh] overflow-y-auto"
                                      style={{ maxWidth: "400px" }} // Set a maximum width
                                    >
                                      <div className="flex justify-end">
                                        <Modal.Close />
                                      </div>
                                      <div className="h-full w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
                                        <h2 className="mb-4 text-center text-2xl font-extrabold text-gray-900">
                                          New Message
                                        </h2>
                                        <p className="mb-6 text-center text-lg text-gray-600">
                                          Sharing more details will increase
                                          your chances of receiving a reply.
                                        </p>
                                        <div className="mb-5">
                                          <label className="block text-lg font-medium text-gray-700">
                                            To:{" "}
                                            <span className="font-extrabold">
                                              {item?.candidate?.firstName
                                                ? item?.candidate?.firstName +
                                                  " " +
                                                  item?.candidate?.lastName
                                                : "No Username"}
                                            </span>
                                          </label>
                                        </div>
                                        <div className="mb-8">
                                          <label
                                            htmlFor="message"
                                            className="mb-5 block text-lg font-medium text-gray-700"
                                          >
                                            Your Message
                                          </label>
                                          <textarea
                                            id="message"
                                            rows="5"
                                            className="mt-1 block w-full resize-none rounded-sm border border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-lg"
                                            placeholder="Type your message here..."
                                            value={message}
                                            onChange={(e) =>
                                              SetMessage(e.target.value)
                                            }
                                          ></textarea>
                                        </div>
                                        <button
                                          className="w-full rounded-full bg-blue-600 px-6 py-3 text-lg text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                          onClick={() =>
                                            HandelSendMessage(item)
                                          }
                                        >
                                          Send Message
                                        </button>
                                      </div>
                                    </Modal.Window>
                                  </Modal>
                                </div>

                                <div className="flex ">
                                  <Link
                                    className="btn-outline-blueB flex items-center rounded-[4px] px-5 py-2 text-sm font-medium"
                                    to={`/recruiter/candidate/${item?.id}`}
                                  >
                                    Details
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div>No Data found</div>
              )}
            </>
          ) : (
            <div
              className={`flex ${showFilters ? "w-4/5" : "w-full"}  justify-center`}
            >
              <Loader />
            </div>
          )}

          {totalCount > 0 ? (
            <div className="mt-6 flex w-full items-center justify-center">
              <Pagination
                queryString={QUERY_STRING}
                totalCount={totalCount}
                size={JOB_POSTS_PER_PAGE}
              >
                <Pagination.PrevPage>
                  <div className="px-4 py-2 hover:bg-blue-b-50">
                    <HiMiniChevronLeft className="h-4 w-4 stroke-1" />
                  </div>
                </Pagination.PrevPage>

                <Pagination.Numbers />

                <Pagination.NextPage>
                  <div className="px-4 py-2 hover:bg-blue-b-50">
                    <HiMiniChevronRight className="h-4 w-4 stroke-1" />
                  </div>
                </Pagination.NextPage>
              </Pagination>
            </div>
          ) : null}
        </div>

        <Dialog
          open={isOpen}
          maxWidth="xl"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            },
          }}
          fullWidth
          onClose={() => setIsOpen(false)}
        >
          <div className="flex h-full w-full">
            <div className="mr-4 flex h-full w-[10%]  flex-col  items-center justify-end">
              <button
                className="h-fit items-end rounded-full bg-white p-4 disabled:cursor-not-allowed"
                onClick={() => handlePrevious(selectedItem)}
                disabled={currenIndex <= 0}
              >
                <div className="h-6 w-6" onClick={handlePrevious}>
                  <HiChevronLeft className="h-full w-full stroke-[1.5px]" />
                </div>
              </button>
            </div>

            <div className="flex  h-full w-full flex-col  overflow-auto rounded-xl  bg-white p-12 shadow-lg">
              <CandidateModalPreview previewId={selectedItem} />
            </div>
            <div className="ml-4 flex h-full w-[10%] flex-col  items-center justify-between">
              <div className="mt-8 flex flex-col items-center space-y-3">
                <button
                  className="flex flex-col items-center"
                  onClick={() => handledownload(selectedItem)}
                  disabled={isGeneratingFile}
                >
                  <div className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed">
                    <div>
                      {isGeneratingFile ? (
                        <Loader />
                      ) : (
                        <RxDownload className="h-6 w-6" />
                      )}
                    </div>
                  </div>
                  <span className="font-primary text-lg font-normal text-black-g-23">
                    Download
                  </span>
                </button>

                {/* <button
                  className="flex flex-col items-center"
                  onClick={() => saveResumeTalentPool(selectedItem)}
                >
                  <div className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed">
                    <div>
                      <BiBookmark className="h-6 w-6" />
                    </div>
                  </div>
                  <span className="w-max font-primary text-lg font-normal text-black-g-23">
                    Save
                  </span>
                </button> */}

                {/* <button className="flex flex-col items-center">
                  <div className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed">
                    <div>
                      <MdOutlineGroupAdd className="h-6 w-6" />
                    </div>
                  </div>
                  <span className="w-max font-primary text-lg font-normal text-black-g-23">
                    Add To Project
                  </span>
                </button> */}
              </div>

              <button
                className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed"
                onClick={() => handleNext(selectedItem)}
                disabled={currenIndex === results?.length - 1}
              >
                <div className="h-6 w-6">
                  <HiChevronRight className="h-full w-full stroke-[1.5px]" />
                </div>
              </button>
            </div>
          </div>
        </Dialog>
      </>
    </div>
  );
}

export default BrowseTalents;
