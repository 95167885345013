import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { useSearchTalentsTax } from "../../hooks/useSearchTalentsTax";
import Loader from "../../shared/loader/Loader";
import Pagination from "../../shared/Pagination";
import CodeIcon from "../../icons/CodeIcon";
import PentoolIcon from "../../icons/PentoolIcon";
import StatusupIcon from "../../icons/StatusupIcon";
import TasksquareIcon from "../../icons/TasksquareIcon";
import TaskIcon from "../../icons/TaskIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import ChartSquareIcon from "../../icons/ChartSquareIcon";
import PresentionChartIcon from "../../icons/PresentionChartIcon";
import GraphIcon from "../../icons/GraphIcon";
import BrushIcon from "../../icons/BrushIcon";
import PersonalCardIcon from "../../icons/PersonalCardIcon";
import BookIcon from "../../icons/BookIcon";
import ShieldTickIcon from "../../icons/ShieldTickIcon";
import TransactionMinusIcon from "../../icons/TransactionMinusIcon";
import EditIcon from "../../icons/EditIcon";
import ChartIcon from "../../icons/ChartIcon";

const icons = {
  CodeIcon: <CodeIcon />,
  PentoolIcon: <PentoolIcon />,
  StatusupIcon: <StatusupIcon />,
  TasksquareIcon: <TasksquareIcon />,
  ChartIcon: <ChartIcon />,
  PeopleIcon: <PeopleIcon />,
  ChartSquareIcon: <ChartSquareIcon />,
  PresentionChartIcon: <PresentionChartIcon />,
  GraphIcon: <GraphIcon />,
  BrushIcon: <BrushIcon />,
  PersonalCardIcon: <PersonalCardIcon />,
  BookIcon: <BookIcon />,
  ShieldTickIcon: <ShieldTickIcon />,
  TransactionMinusIcon: <TransactionMinusIcon />,
  EditIcon: <EditIcon />,
  TaskIcon: <TaskIcon />,
};

function TalentsTax() {
  const POSTS_PER_PAGE = 4;
  const QUERY_STRING = "talents_tax_page";

  const { isLoading, data } = useSearchTalentsTax({
    queryString: QUERY_STRING,
    size: POSTS_PER_PAGE,
  });

  const { results, totalCount } = data || {};

  if (isLoading)
    return (
      <section>
        <div className="page-px py-16">
          <Loader />
        </div>
      </section>
    );

  if (!results?.length) return null;

  if (results?.length > 0)
    return (
      <section>
        <div className="page-px my-16">
          <div className="mx-auto mb-12 max-w-screen-lg">
            <p className="mb-2 text-center uppercase text-blue-b-primary">
              «Maximize the potential of our platform to engage with a specially
              selected group of professionals, making your hiring faster,
              smoother, and more impactful»
            </p>
            <h1 className="text-center text-[2.75rem] font-medium leading-[3.25rem]">
              Leverage World-Class Talent
            </h1>
          </div>

          <Pagination
            queryString={QUERY_STRING}
            size={POSTS_PER_PAGE}
            totalCount={totalCount}
          >
            <div className="-m-3 flex items-center">
              <div className="p-3">
                <Pagination.PrevPage>
                  <div className="h-9 w-9">
                    <HiChevronLeft className="h-full w-full stroke-[1.5px]" />
                  </div>
                </Pagination.PrevPage>
              </div>

              <div className="flex-1 p-3">
                <div className="-m-2 flex flex-wrap justify-center transition-all duration-300 xl:-m-4">
                  {results?.map((item) => (
                    <div
                      key={item?.name}
                      className="w-full p-2 transition-all duration-300 sm:w-1/2 lg:w-1/4 xl:p-4"
                    >
                      <div className="h-full border border-black-g-50 bg-white p-6 shadow-2xl shadow-slate-200">
                        <div className="mb-6 h-12 w-12 text-blue-b-primary">
                          {icons?.[item?.icon]}
                        </div>
                        <h5 className="mb-2 text-2xl font-semibold">
                          {item?.name}
                        </h5>
                        <p className="text-sm text-black-g-100">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-3">
                <Pagination.NextPage>
                  <div className="h-9 w-9">
                    <HiChevronRight className="h-full w-full stroke-[1.5px]" />
                  </div>
                </Pagination.NextPage>
              </div>
            </div>
          </Pagination>
        </div>
      </section>
    );
}

export default TalentsTax;
