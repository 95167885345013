import Loader from "./Loader";

function LoaderFullPage() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="m-auto">
        <div className="mb-4 font-semibold uppercase text-blue-b-primary">
          Loading...
        </div>
        <Loader size={3} color="#0054ff" />
      </div>
    </div>
  );
}

export default LoaderFullPage;
