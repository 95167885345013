import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { emailExist as emailExistApi } from "../services/apiAuth";

export function useEmailExist({ newEmail = true } = {}) {
  const [exist, setExist] = useState(newEmail);

  const { mutate: emailExist, isPending: isPendingEmailExist } = useMutation({
    mutationFn: ({ email }) => emailExistApi({ email }),
    onSuccess: (response) => {
      setExist(response?.exist);

      if (newEmail && response?.exist)
        toast.error(
          `This email is already in use. Please try using a different email address or log in if you already have an account.`,
        );
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { emailExist, isPendingEmailExist, exist };
}
