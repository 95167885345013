import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function CustomEditor({
  placeholder,
  onHtmlValueChange,
  onPlainTextValueChange,
  controllerName,
  id,
  control,
  rules,
  htmlDefaultValue,
  plainTextDefaultValue,
  readOnly,
  toolbarHidden,
  editorClassName,
}) {
  const [editorState, setEditorState] = useState(() => {
    if (htmlDefaultValue) {
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(htmlDefaultValue),
      );
      return EditorState.createWithContent(contentState);
    } else return EditorState.createEmpty();
  });

  /* useEffect(() => {
    let isMounted = true;

    if (htmlDefaultValue) {
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(htmlDefaultValue),
      );
      const newEditorState = EditorState.createWithContent(contentState);
      if (isMounted) setEditorState(newEditorState);
    }

    return () => {
      isMounted = false;
    };
  }, [htmlDefaultValue]); */

  const handleEditorChange = (onChange) => (newEditorState) => {
    if (editorState !== newEditorState) setEditorState(newEditorState);

    // Convert the editor content to HTML with styles
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlText = draftToHtml(rawContentState);

    // Convert the editor content to plain text
    const plainText = contentState.getPlainText();

    // Trigger onChange provided by React Hook Form
    // and save the HTML content into React Hook Form object
    if (plainText != "") {
      onChange(htmlText);
      onHtmlValueChange(htmlText);
      onPlainTextValueChange(plainText);
    } else {
      onChange("");
      onHtmlValueChange("");
      onPlainTextValueChange("");
    }
  };

  const toolbarData = {
    options: [
      "inline",
      "blockType",
      "list",
      "fontSize",
      "fontFamily",
      "textAlign",
      "history",
    ],
    inline: { options: ["bold", "italic", "underline"] },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
    },
    list: { options: ["unordered", "ordered"] },
    textAlign: { options: ["left", "center", "right"] },
    history: { options: ["undo", "redo"] },
  };
  return (
    <>
      {controllerName ? (
        <Controller
          name={controllerName || id}
          control={control}
          rules={rules}
          defaultValue={plainTextDefaultValue || htmlDefaultValue}
          render={({ field: { onChange }, fieldState: { error } }) => {
            //Define the border style for the Editor based on the error state
            const editorBorderStyle = error
              ? { border: "1px solid red", borderRadius: "4px" }
              : {};

            return (
              <div className="flex flex-col gap-3">
                <Editor
                  editorStyle={editorBorderStyle}
                  editorState={editorState}
                  editorClassName={editorClassName}
                  onEditorStateChange={handleEditorChange(onChange)}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  toolbarHidden={toolbarHidden}
                  toolbar={toolbarData}
                />
                {error && (
                  <span className="text-sm italic text-red-600">
                    {error.message}
                  </span>
                )}
              </div>
            );
          }}
        />
      ) : (
        <Editor
          // editorStyle={editorBorderStyle}
          editorState={editorState}
          editorClassName={editorClassName}
          onEditorStateChange={handleEditorChange(() => {})}
          placeholder={placeholder}
          readOnly={readOnly}
          toolbarHidden={toolbarHidden}
          toolbar={toolbarData}
        />
      )}
    </>
  );
}

CustomEditor.propTypes = {
  placeholder: PropTypes.string,
  onHtmlValueChange: PropTypes.func,
  onPlainTextValueChange: PropTypes.func,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  id: PropTypes.string,
  htmlDefaultValue: PropTypes.string,
  plainTextDefaultValue: PropTypes.string,
  toolbarHidden: PropTypes.bool,
  readOnly: PropTypes.bool,
  editorClassName: PropTypes.string,
};

export default CustomEditor;
