import PropTypes from "prop-types";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { Controller } from "react-hook-form";

/**
 * to make this component support multiple selection 
 * @param selected prop check if selected item value is included in selection array
 * @param onclick function concat current item value within array of selected items
 * 
 * @example selected={selectedExpertise.includes(item.id)}
 *          onClick={() => {
 *                  const updatedExpertise = selectedExpertise.includes(item.id)
 *                  ? selectedExpertise.filter((id) => id !== item.id) // Remove if already selected
 *                  : [...selectedExpertise, item.id]; // Concat if not selected
 *                  setSelectedExpertise(updatedExpertise);
 *                  }}   
-----------------------------------------------------------------------------------------------------
 * to make this component support only one selection 
 * @param selected prop check if selected item is equal to old selected
 * @param onclick function save current item value with overriding the old value
 * @example    selected={selectedExpertise === item.value}
                onClick={() => setSelectedExpertise(item.value)}
 */
function CheckButton({
  text,
  selected,
  onClick,
  controllerName,
  control,
  rules,
  id,
}) {
  return (
    <>
      {control ? (
        <Controller
          name={controllerName || id}
          control={control}
          rules={rules}
          render={({ field: { value }, fieldState: { error } }) => {
            return (
              <div
                className={`w-fit cursor-pointer rounded-2xl border ${error && !value ? "border-red-o-primary" : "border-black-g-50"} bg-[#F7F9FB] ${selected && "border-blue-b-75 bg-blue-b-50"}  p-2.5`}
                onClick={onClick}
              >
                <div className="flex items-center gap-1">
                  {selected ? (
                    <FaCheckCircle size={16} color="#0054FF" />
                  ) : (
                    <IoIosAddCircleOutline size={16} />
                  )}
                  <span
                    className={`font-primary text-sm font-medium text-black-g-primary ${selected && "text-blue-b-primary"}`}
                  >
                    {text}
                  </span>
                </div>
              </div>
            );
          }}
        />
      ) : (
        <div
          className={`w-fit cursor-pointer rounded-2xl border  border-black-g-50 bg-[#F7F9FB] ${selected && "border-blue-b-75 bg-blue-b-50"}  p-2.5`}
          onClick={onClick}
        >
          <div className="flex items-center gap-1">
            {selected ? (
              <FaCheckCircle size={16} color="#0054FF" />
            ) : (
              <IoIosAddCircleOutline size={16} />
            )}
            <span
              className={`font-primary text-sm font-medium text-black-g-primary ${selected && "text-blue-b-primary"}`}
            >
              {text}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

CheckButton.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  controllerName: PropTypes.string,
  rules: PropTypes.any,
  control: PropTypes.any,
  id: PropTypes.string,
};

CheckButton.defaultProps = {
  text: "",
  selected: false,
  onClick: null,
  controllerName: null,
  control: null,
  rules: null,
  id: null,
};

export default CheckButton;
