/* eslint-disable react/prop-types */
import LogoIcon from "./logo/LogoIcon";

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  return (
    <div className="flex min-h-screen items-center justify-center bg-slate-100">
      <div className="m-auto max-w-screen-lg rounded bg-white p-4 shadow">
        <LogoIcon size={12} />

        <h2 className="my-4 text-3xl font-semibold text-red-600">
          Oops! Something went wrong.
        </h2>
        <p className="my-4">{error.message}</p>

        <button
          className="btn-blue-b-primary m-auto block"
          onClick={resetErrorBoundary}
        >
          Try again
        </button>
      </div>
    </div>
  );
}

export default ErrorBoundaryFallback;
