import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateTalentPoolActions as updateTalentPoolActionsApi } from "../services/apiResume";

export function useUpdateTalentPoolActions() {
  const queryClient = useQueryClient();
  const {
    mutate: updateTalentPoolActions,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ resumeId, type, content, reminderDate }) =>
      updateTalentPoolActionsApi({
        resumeId,
        type,
        content,
        reminderDate,
      }),
    onMutate: () => toast.loading("Updating Talent Pool..."),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["talentPoolActions"] });
      toast.dismiss();
      //toast.success("The Talent Pool has been updated successfully");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { updateTalentPoolActions, isPending, isSuccess };
}
