import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Controller } from "react-hook-form";
import { format, parse } from "date-fns";

function CustomDatePick({
  controllerName,
  control,
  labelName,
  placeholder,
  className = "flex-1",
  views,
  inputFormat,
  rules,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={controllerName}
        rules={rules}
        render={({ field }) => {
          return (
            <div className={className}>
              {labelName ? (
                <div className="py-1.5 font-primary text-sm font-semibold text-[#060F26]">
                  {labelName}
                </div>
              ) : null}
              <DatePicker
                views={views}
                inputFormat={inputFormat}
                format={inputFormat}
                closeOnSelect={true}
                value={
                  field.value
                    ? parse(field.value, inputFormat, new Date())
                    : null
                }
                onChange={(date) => {
                  field.onChange(date ? format(date, inputFormat) : "");
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    label: placeholder,
                    sx: {
                      display: "flex",
                      background: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #e6e7e9", // Set your desired border style here
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #e6e7e9",
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid black", // Set border to 1px solid to keep the default border color
                        },
                      "& .MuiOutlinedInput-input": {
                        padding: "0.68rem",
                        fontSize: "14px",
                        fontFamily: "Fira Sans, sans-serif",
                      },
                    },
                  },
                }}
              />
            </div>
          );
        }}
      />
    </LocalizationProvider>
  );
}

CustomDatePick.propTypes = {
  controllerName: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired, // Define error prop
  labelName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.string),
  inputFormat: PropTypes.string,
  rules: PropTypes.shape({}),
};
CustomDatePick.defaultProps = {
  controllerName: PropTypes.string,
  control: PropTypes.any, // Define error prop
  labelName: null,
  placeholder: null,
  className: null,
  views: ["year", "month", "day"],
  inputFormat: "dd/MM/yyyy",
  rules: null,
};

export default CustomDatePick;
