import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { HiCheck, HiMiniXCircle } from "react-icons/hi2";
import Loader from "../loader/Loader";

function TextArea({
  column = true,
  label,
  containerClassName = "bg-[#F7F9FB] border-transparent border-2",
  mainDivClassname = "-m-1",
  innerDivClassname = "flex-1 p-1",
  id,
  placeholder,
  autoComplete,
  fieldClassName = "input-transparent",
  controllerName,
  control,
  rules,
  defaultValue,
  children,
  disabled,
  resetField,
  isAsyncSuccess,
  isLoading,
  rows = 3,
}) {
  const inputRef = useRef();
  const [isValid, setIsValid] = useState(isAsyncSuccess);

  useEffect(
    function () {
      setIsValid(isAsyncSuccess);
    },
    [isAsyncSuccess],
  );

  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ""}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, isDirty, error },
      }) => {
        return (
          <div
            className={`flex ${mainDivClassname} ${column === true ? "flex-col" : "items-center"}`}
          >
            {label && (
              <div className="p-1">
                <label
                  className="font-primary font-semibold text-[#060F26] "
                  htmlFor={id}
                >
                  {label}
                </label>
              </div>
            )}

            <div className={`${innerDivClassname}`}>
              <div
                className={`overflow-hidden rounded-md  transition-all duration-200 ${
                  !invalid
                    ? `${containerClassName} focus-within:border-black-g-primary`
                    : "border-red-o-100 bg-red-o-50 focus-within:border-red-o-100"
                }`}
              >
                <div className="relative -m-0.5 flex items-center">
                  <div className="relative flex-1 p-0.5">
                    <textarea
                      ref={inputRef}
                      id={id}
                      placeholder={placeholder}
                      autoComplete={autoComplete}
                      className={fieldClassName}
                      value={value || ""}
                      rows={rows}
                      onChange={(e) => {
                        onChange?.(e);
                        setIsValid(false);
                      }}
                      onBlur={(e) => {
                        onBlur?.(e);
                        setIsValid(isAsyncSuccess);
                      }}
                      disabled={isLoading || disabled}
                    ></textarea>

                    {children}
                  </div>

                  {((isLoading && isDirty && !invalid) ||
                    (((isDirty && !isValid) || invalid) && value) ||
                    (value &&
                      isValid &&
                      isDirty &&
                      !invalid &&
                      !isLoading)) && (
                    <div className="absolute right-0 top-0 mt-3 p-0.5">
                      <div className="pr-3.5">
                        <div className="-m-1.5 flex items-center">
                          {isLoading && isDirty && !invalid && (
                            <div className="p-1.5">
                              <Loader />
                            </div>
                          )}

                          {((isDirty && !isValid) || invalid) && value && (
                            <div className="p-1.5">
                              <div
                                className="cursor-pointer overflow-hidden p-0.5 transition-all duration-200"
                                onClick={() => {
                                  resetField?.(controllerName || id);
                                  setIsValid(false);
                                }}
                              >
                                <HiMiniXCircle
                                  className={`h-6 w-6 text-black-g-primary`}
                                />
                              </div>
                            </div>
                          )}

                          {value &&
                            isValid &&
                            isDirty &&
                            !invalid &&
                            !isLoading && (
                              <div className="p-1.5">
                                <HiCheck className="h-6 w-6 stroke-2 text-green-g-400" />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {invalid && error?.message && (
                <p className="mt-2 text-sm text-red-o-primary">
                  {error?.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

TextArea.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  fieldClassName: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  resetField: PropTypes.func,
  isAsyncSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default TextArea;
