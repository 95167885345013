import FolderOpenIcon from "../../icons/FolderOpenIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import DocumentFilterIcon from "../../icons/DocumentFilterIcon";
import ClipboardCloseIcon from "../../icons/ClipboardCloseIcon";
import TimerIcon from "../../icons/TimerIcon";
import WalletIcon from "../../icons/WalletIcon";
import DocumentFavoriteIcon from "../../icons/DocumentFavoriteIcon";
import EmojiSadIcon from "../../icons/EmojiSadIcon";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  HiCheckCircle,
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineClock,
  HiOutlineCurrencyDollar,
  HiOutlineMapPin,
} from "react-icons/hi2";
import { useUserResume } from "../../hooks/useUserResume";
import { getFileType } from "../../utils/helpers";
import { QualificationTypeEnum, RATETYPE_ENUM } from "../../utils/constants";
import { Link } from "react-router-dom";

ChartJS.register(
  Tooltip,
  Legend,
  ArcElement,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
);

function CandidateDashboard() {
  const { isLoading, data: userResume } = useUserResume();

  const revenuPerEmploymentChartData = {
    labels: [
      "Freelance",
      "Part time",
      "Full time",
      "Per hour",
      "Per sprint",
      "Per project",
    ],
    datasets: [
      {
        label: "# hired in last 2 months",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "#3359dc",
          "#2ca79f",
          "#153070",
          "#972cf1",
          "#f7b60a",
          "#b00b43",
        ],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const jobTypeChartData = {
    labels: ["Remote", "Onsite"],
    datasets: [
      {
        label: "Job Type",
        data: ["6(60%)", "4(40%)"],
        backgroundColor: ["#3359dc", "#972cf1"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const offreAcceptanceRatioChartData = {
    datasets: [
      {
        data: [68, 100 - 68],
        backgroundColor: ["#3359dc", "#e4edfb"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="page-px py-8">
      <div className="flex items-center">
        <h1 className="text-[2.5rem] font-medium">Dashboard</h1>
      </div>

      <div className="my-16">
        <div className="-m-4 flex flex-wrap">
          <div className="w-2/6 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Obtained interview ratio
              </h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={offreAcceptanceRatioChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: { enabled: false },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-[1.75rem] font-medium">68%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Profile</h1>
              <div className="-m-2.5 flex">
                {getFileType(userResume?.profilePic) === "image" && (
                  <div className="p-2.5">
                    <Link
                      className="block h-24 w-24 overflow-hidden rounded-full hover:border hover:border-blue-b-75"
                      to={"/candidate/resume"}
                    >
                      <img
                        className="h-full w-full object-cover object-center"
                        src={userResume?.profilePic}
                      />
                    </Link>
                  </div>
                )}

                <div className="p-2.5">
                  <Link
                    className="text-xl font-extrabold hover:text-blue-b-primary"
                    to={"/candidate/resume"}
                  >{`${userResume?.firstName} ${userResume?.lastName}`}</Link>
                  <div className="text-xl">{userResume?.jobTitle}</div>

                  <div className="-mx-3 -my-1 flex flex-wrap text-black-g-100">
                    {(userResume?.contactInfo?.address?.city?.name ||
                      userResume?.contactInfo?.address?.country?.name) && (
                      <div className="flex items-center space-x-1 px-3 py-1">
                        <HiOutlineMapPin className="h-5 w-5" />
                        <span>
                          {userResume?.contactInfo?.address?.city?.name}
                        </span>
                        {userResume?.contactInfo?.address?.city?.name &&
                          userResume?.contactInfo?.address?.country?.name && (
                            <span>, </span>
                          )}
                        <span>
                          {userResume?.contactInfo?.address?.country?.name}
                        </span>
                      </div>
                    )}

                    {userResume?.yearsOfExperience > 0 && (
                      <div className="flex items-center space-x-1 px-3 py-1">
                        <HiOutlineBriefcase className="h-5 w-5" />
                        <span>
                          {`${userResume?.yearsOfExperience} ${userResume?.yearsOfExperience <= 1 ? "year" : "years"}`}
                        </span>
                      </div>
                    )}

                    <div className="flex items-center space-x-1 px-3 py-1">
                      <HiOutlineCurrencyDollar className="h-5 w-5" />
                      <span>{`${userResume?.rate?.max}${userResume?.rate?.currency?.symbol} ${RATETYPE_ENUM[userResume?.rate?.type]}`}</span>
                    </div>

                    <div className="flex items-center space-x-1 px-3 py-1">
                      <HiOutlineAcademicCap className="h-5 w-5" />
                      <div>
                        {QualificationTypeEnum[userResume?.educationLevel]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="text-xl">Profile completion</div>
                <div className="-m-3 flex items-center">
                  <div className="flex-1 p-3">
                    <div className="my-2 text-center text-sm font-medium text-blue-b-primary">
                      {`${100 - parseFloat(userResume?.completionStep) * 10}% to complete`}
                    </div>

                    <div className="relative h-3 w-full rounded-full bg-black-g-50">
                      <div
                        className="h-full rounded-full bg-blue-b-primary"
                        style={{
                          width: `${100 - parseFloat(userResume?.completionStep) * 10}%`,
                        }}
                      ></div>

                      <div className="absolute inset-0 flex items-center justify-around">
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                        <div className="h-1 w-1 rounded-full bg-white"></div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2 p-3">
                    <div className="flex w-12 justify-end rounded-full bg-blue-b-primary">
                      <HiCheckCircle className="h-7 w-7 text-white" />
                    </div>
                    <div className="text-lg">Open to work</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/4 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Upcoming events</h1>
              <div className="-m-1.5 flex flex-col">
                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-3/5 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Revenu per ( employment style )
              </h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={revenuPerEmploymentChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-lg font-medium">5 Job</div>
                    <div className="text-xs">in last 2 months</div>
                  </div>
                </div>

                <div className="text-center text-lg">
                  {revenuPerEmploymentChartData?.labels?.map((label, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-20 text-center font-medium">
                        {revenuPerEmploymentChartData?.datasets[0]?.data[index]}
                      </div>
                      <div
                        className="h-2.5 w-2.5 rounded-full"
                        style={{
                          backgroundColor:
                            revenuPerEmploymentChartData?.datasets[0]
                              ?.backgroundColor[index],
                        }}
                      ></div>
                      <div>{label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-2/5 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">(On site, Remote)</h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={jobTypeChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-lg font-medium">Total</div>
                    <div className="text-[1.75rem] font-medium">10</div>
                  </div>
                </div>

                <div className="text-center text-lg">
                  {jobTypeChartData?.labels?.map((label, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-20 text-center font-medium">
                        {jobTypeChartData?.datasets[0]?.data[index]}
                      </div>
                      <div
                        className="h-2.5 w-2.5 rounded-full"
                        style={{
                          backgroundColor:
                            jobTypeChartData?.datasets[0]?.backgroundColor[
                              index
                            ],
                        }}
                      ></div>
                      <div>{label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Total applications</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <FolderOpenIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  100
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Applications being processed
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <DocumentFilterIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  800
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Rejected candidatures</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <ClipboardCloseIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  50
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Hiring time</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <TimerIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  30 days
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Total job accomplished</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <PeopleIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  160
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Recruter satisfaction score
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <DocumentFavoriteIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  4.5/5
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Candidature withdraw rate
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <EmojiSadIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  15 %
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Revenu per job</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <WalletIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  2000$
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateDashboard;
