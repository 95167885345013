import { useMutation, useQueryClient } from "@tanstack/react-query";
import { publishJobDescription } from "../services/apiJobDescription";
import toast from "react-hot-toast";

export const usePublishJob = () => {
  const queryClient = useQueryClient();

  const {
    mutate: publishJob,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ id }) =>
      publishJobDescription({
        id,
      }),
    onSuccess: (response) => {
      toast.success("Job has been published successfully");
      queryClient.invalidateQueries({
        queryKey: ["jobDescriptionSearch"],
      });
      queryClient.invalidateQueries({
        queryKey: ["jobDescriptionSingle"],
      });
      return response;
    },
    onError: () => {
      toast.error("Failed to publish job");
    },
  });

  return { publishJob, isPending, isSuccess };
};
