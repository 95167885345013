import CollapsableCardContent from "../../shared/collapsable/CollapsableCardContent";
import Input from "../../shared/inputs/Input";
import { useFieldArray, useFormContext } from "react-hook-form";
import Divider from "../../shared/Divider";
import { RiAddCircleFill } from "react-icons/ri";
import TextArea from "../../shared/inputs/TextArea";
import CustomDatePick from "../../shared/datepicker/CustomDatePick";

function EducationStep() {
  const { control, resetField } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "educations",
  });

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
        5. Education
      </div>
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div className="mr-8 flex w-full flex-col">
              <div className="flex  items-center">
                <div className="text-black-g-300 font-primary text-xl font-bold">
                  Education
                </div>
                <Divider className="ml-4 flex-1" />
              </div>
              <div className="mt-4 flex w-full flex-wrap justify-between gap-4">
                {fields.map((card, index) => (
                  <CollapsableCardContent
                    key={index}
                    cardWrapperClassName="p-2 w-full h-fit"
                    title={"Education " + (index + 1)}
                    maxVisibleChildren={4}
                    childrenWrapperClassName="flex flex-1 flex-wrap gap-4 py-2"
                    removeCard={() => remove(index)}
                  >
                    <Input
                      type="text"
                      id={"education"}
                      placeholder="Education title"
                      controllerName={`educations.${index}.name`}
                      rules={{ required: "required" }}
                      control={control}
                      mainDivClassname={"w-1/2"}
                      fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                      resetField={resetField}
                    />
                    <Input
                      type="text"
                      id={"company"}
                      placeholder="School Name"
                      controllerName={`educations.${index}.schoolName`}
                      rules={{ required: "required" }}
                      control={control}
                      mainDivClassname={"w-1/2"}
                      fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                      resetField={resetField}
                    />
                    <CustomDatePick
                      controllerName={`educations.${index}.startTime`}
                      placeholder={"Start date"}
                      className={"flex-1"}
                      control={control}
                    />
                    <CustomDatePick
                      controllerName={`educations.${index}.endTime`}
                      placeholder={"End date"}
                      className={"flex-1"}
                      control={control}
                    />

                    <TextArea
                      mainDivClassname="w-full m-0"
                      innerDivClassname="flex-1 p-0"
                      containerClassName="bg-white"
                      id="description"
                      placeholder="Description ..."
                      autoComplete="off"
                      controllerName={`educations.${index}.description`}
                      control={control}
                      resetField={resetField}
                    />
                  </CollapsableCardContent>
                ))}

                <div
                  className="flex h-32 w-full  flex-col items-center justify-center gap-2 rounded-lg border border-black-g-50 bg-[#F7F9FB]"
                  role="button"
                  onClick={() => {
                    append({
                      name: "",
                      schoolName: "",
                      startTime: "",
                      endTime: "",
                      description: "",
                    });
                  }}
                >
                  <div className="font-primary text-base font-bold">
                    Add Education
                  </div>
                  <RiAddCircleFill size={25} color="#0054ff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EducationStep.propTypes = {};

export default EducationStep;
