import PropTypes from "prop-types";

function EditIconSolid({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z" />
      <path d="M14.2649 2.60926C12.8099 1.15426 11.3849 1.11676 9.89243 2.60926L8.98493 3.51676C8.90993 3.59176 8.87993 3.71176 8.90993 3.81676C9.47993 5.80426 11.0699 7.39426 13.0574 7.96426C13.0874 7.97176 13.1174 7.97926 13.1474 7.97926C13.2299 7.97926 13.3049 7.94926 13.3649 7.88926L14.2649 6.98176C15.0074 6.24676 15.3674 5.53426 15.3674 4.81426C15.3749 4.07176 15.0149 3.35176 14.2649 2.60926Z" />
      <path d="M11.7077 8.64711C11.4902 8.54211 11.2802 8.43711 11.0777 8.31711C10.9127 8.21961 10.7552 8.11461 10.5977 8.00211C10.4702 7.91961 10.3202 7.79961 10.1777 7.67961C10.1627 7.67211 10.1102 7.62711 10.0502 7.56711C9.80274 7.35711 9.52524 7.08711 9.27774 6.78711C9.25524 6.77211 9.21774 6.71961 9.16524 6.65211C9.09024 6.56211 8.96274 6.41211 8.85024 6.23961C8.76024 6.12711 8.65524 5.96211 8.55774 5.79711C8.43774 5.59461 8.33274 5.39211 8.22774 5.18211C8.09007 4.88711 7.70288 4.79947 7.47268 5.02966L3.25524 9.24711C3.15774 9.34461 3.06774 9.53211 3.04524 9.65961L2.64024 12.5321C2.56524 13.0421 2.70774 13.5221 3.02274 13.8446C3.29274 14.1071 3.66774 14.2496 4.07274 14.2496C4.16274 14.2496 4.25274 14.2421 4.34274 14.2271L7.22274 13.8221C7.35774 13.7996 7.54524 13.7096 7.63524 13.6121L11.8596 9.3877C12.0852 9.16211 12.0005 8.77396 11.7077 8.64711Z" />
    </svg>
  );
}

EditIconSolid.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EditIconSolid;
