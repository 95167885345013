import PropTypes from "prop-types";

function ShieldTickIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M24.4768 5.20363L12.8335 9.5903C10.1501 10.5936 7.95679 13.767 7.95679 16.6136V33.9503C7.95679 36.7036 9.77679 40.3203 11.9935 41.977L22.0268 49.467C25.3168 51.9403 30.7301 51.9403 34.0201 49.467L44.0535 41.977C46.2701 40.3203 48.0901 36.7036 48.0901 33.9503V16.6136C48.0901 13.7436 45.8968 10.5703 43.2135 9.56697L31.5701 5.20363C29.5868 4.4803 26.4135 4.4803 24.4768 5.20363Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1167 27.6966L24.8734 31.4533L34.9067 21.4199"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ShieldTickIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ShieldTickIcon;
