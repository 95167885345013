import PropTypes from "prop-types";

function Loader({ color = "#0054ff", size = 2 }) {
  return (
    <div className="flex items-center justify-center">
      <div className="sr-only mr-2">Loading...</div>

      <div className="relative">
        <div
          className="origin-center animate-[ring_2s_linear_infinite] rounded-full"
          style={{
            border: `solid 4px ${color}`,
            width: `${parseFloat(size)}rem`,
            height: `${parseFloat(size)}rem`,
          }}
        ></div>

        <div
          className="absolute inset-0 origin-center animate-[ring_1.2s_linear_infinite] rounded-full"
          style={{
            border: `solid 4px ${color}`,
            width: `${parseFloat(size)}rem`,
            height: `${parseFloat(size)}rem`,
          }}
        ></div>
      </div>
    </div>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Loader;
