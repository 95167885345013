import EmojiHappy from "../../icons/EmojiHappy";
import EmojiSad from "../../icons/EmojiSad";
import EmojiSatisfied from "../../icons/EmojiSatisfied";

export const filterStatus = [
  { id: "all", title: "All", name: "All", value: "1" },
  /* {
    id: "myboard",
    title: "My Board",
    name: "My Board",
    value: "2",
  }, */
  {
    id: "alist",
    title: "« A » Très près du profil recherché",
    name: "A List",
    value: "3",
    icon: EmojiHappy,
  },
  {
    id: "blist",
    title: "« B » Assez près du profil recherché",
    name: "B List",
    value: "4",
    icon: EmojiSatisfied,
  },
  {
    id: "clist",
    title: "« C » Non pertinents au poste affiché",
    name: "C List",
    value: "5",
    icon: EmojiSad,
  },
];

export const jobsFilterStatusCandidat = [
  { id: "all", title: "All", name: "All", value: "1" },
  { id: "best", title: "Best match", name: "Best match", value: "2" },
  { id: "myjobs", title: "My jobs", name: "My jobs", value: "3" },
];

export const jobsFilterStatusRecruiter = [
  {
    id: "published",
    key: "Published",
    title: "Open",
    name: "Published",
    value: "1",
  },
  {
    id: "reOpened",
    key: "ReOpened",
    title: "Re-Opened",
    name: "ReOpened",
    value: "2",
  },
  { id: "onHold", key: "OnHold", title: "OnHold", name: "OnHold", value: "3" },
  { id: "filled", key: "Filled", title: "Filled", name: "Filled", value: "4" },
  { id: "closed", key: "Closed", title: "Closed", name: "Closed", value: "5" },
  {
    id: "draft",
    key: "Draft",
    title: "Draft",
    name: "Draft",
    value: "0",
  },
];

export const talentsFilterStatus = [
  { id: "all", title: "All", name: "All", value: "1" },
  { id: "foryou", title: "For you", name: "For you", value: "2" },
  { id: "freelancers", title: "Freelancers", name: "Freelancers", value: "3" },
  { id: "fulltime", title: "Full time", name: "Full time", value: "4" },
  {
    id: "subcontracting",
    title: "Subcontracting",
    name: "Subcontracting",
    value: "5",
  },
];
