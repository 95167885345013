import { useFieldArray, useFormContext } from "react-hook-form";
import { HiPlus } from "react-icons/hi2";
import { useGetCountries } from "../../hooks/useGetCountries";
import { useGetCities } from "../../hooks/useGetCities";
import Input from "../../shared/inputs/Input";
import SelectBox from "../../shared/inputs/SelectBox";
import TextArea from "../../shared/inputs/TextArea";
import CandidateExperienceForm from "./CandidateExperienceForm";
import CandidateEducationForm from "./CandidateEducationForm";
import CandidateLanguageForm from "./CandidateLanguageForm";

function CandidateMyResumeForm() {
  const { control, watch, resetField } = useFormContext();

  const { isLoading: isLoadingCountries, data: countries } = useGetCountries();
  const { isLoading: isLoadingCities, data: cities } = useGetCities(
    watch("countryId"),
  );

  const {
    fields: experiencesFields,
    append: appendExperience,
    remove: removeExperience,
  } = useFieldArray({
    name: "experiences",
  });

  const {
    fields: educationsFields,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    name: "educations",
  });

  const {
    fields: languagesFields,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    name: "languages",
  });

  return (
    <div className="-m-4 flex flex-wrap">
      <div className="w-full p-4 sm:w-1/2">
        <Input
          label="firstName"
          type="text"
          id="firstName"
          controllerName="firstName"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <Input
          label="lastName"
          type="text"
          id="lastName"
          controllerName="lastName"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <Input
          label="jobTitle"
          type="text"
          id="jobTitle"
          controllerName="jobTitle"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <SelectBox
          label="Country"
          id="countryId"
          placeholder="Select a country"
          controllerName="countryId"
          control={control}
          resetField={resetField}
          rules={{ required: "required field" }}
          options={{ data: countries, key: "id", value: "id", name: "name" }}
          isLoading={isLoadingCountries}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <SelectBox
          label="City"
          id="cityId"
          placeholder="Select a city"
          controllerName="cityId"
          control={control}
          resetField={resetField}
          rules={{ required: "required field" }}
          options={{ data: cities, key: "id", value: "id", name: "name" }}
          isLoading={isLoadingCities}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <Input
          label="Email"
          type="email"
          id="email"
          controllerName="email"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <Input
          label="Phone"
          type="tel"
          id="phone"
          controllerName="phone"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4 sm:w-1/2">
        <TextArea
          label="ABOUT CANDIDATE"
          id="description"
          controllerName="description"
          control={control}
          resetField={resetField}
        />
      </div>

      <div className="w-full p-4">
        <h3 className="mb-3 text-lg font-medium uppercase">Experiences</h3>
        <div className="space-y-8">
          {experiencesFields?.map((expForm, index) => (
            <CandidateExperienceForm
              key={expForm?.id}
              className="bg-blue-b-50 p-4"
              index={index}
              onRemove={removeExperience}
            />
          ))}

          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2 shadow"
            onClick={() => appendExperience()}
          >
            <HiPlus className="h-6 w-6" />
            <div>Add experience</div>
          </button>
        </div>
      </div>

      <div className="w-full p-4">
        <h3 className="mb-3 text-lg font-medium uppercase">Educations</h3>
        <div className="space-y-8">
          {educationsFields?.map((eduForm, index) => (
            <CandidateEducationForm
              key={eduForm?.id}
              className="bg-blue-b-50 p-4"
              index={index}
              onRemove={removeEducation}
            />
          ))}

          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2 shadow"
            onClick={() => appendEducation()}
          >
            <HiPlus className="h-6 w-6" />
            <div>Add education</div>
          </button>
        </div>
      </div>

      <div className="w-full p-4">
        <h3 className="mb-3 text-lg font-medium uppercase">Languages</h3>
        <div className="space-y-8">
          {languagesFields?.map((langForm, index) => (
            <CandidateLanguageForm
              key={langForm?.id}
              className="bg-blue-b-50 p-4"
              index={index}
              onRemove={removeLanguage}
            />
          ))}

          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2 shadow"
            onClick={() => appendLanguage()}
          >
            <HiPlus className="h-6 w-6" />
            <div>Add language</div>
          </button>
        </div>
      </div>

      <div className="w-full p-4">
        <button className="btn-blue-b-primary w-full">Save</button>
      </div>
    </div>
  );
}

export default CandidateMyResumeForm;
