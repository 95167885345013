import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const USER_URL = REQUEST_URL.user;
const USER_UPLOAD_PICTURE_URL = REQUEST_URL.profilePic;

export async function getCurrentUser() {
  if (!navigator.onLine) {
    const error = new Error("No internet connection");
    error.code = 503;
    throw error;
  }

  const user = await fetch(BASE_URL + USER_URL, {
    method: "GET",
    credentials: "include",
  });

  if (!user.ok) throw new Error("Cannot fetch user data");

  return user.json();
}

export async function uploadUserPicture(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + USER_UPLOAD_PICTURE_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (response.status === 400) {
    const error = new Error("Only images are allowed");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to upload photo, please try again later.");
  return response.json();
}

export async function updateUserInformation({
  firstName,
  lastName,
  birthdate,
  contact: {
    email,
    phone,
    address: { address, address2, cityId, postalCode, countryId, countryName },
    socialPlatforms,
  },
  gender,
}) {
  if (!navigator?.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + USER_URL, {
    method: "PUT",
    body: JSON.stringify({
      firstName,
      lastName,
      birthdate,
      contact: {
        email,
        phone,
        address: {
          address,
          address2,
          cityId,
          postalCode,
          countryId,
          countryName,
        },
        socialPlatforms: socialPlatforms ?? [],
      },
      gender: Number(gender) || 0,
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error(
      "Failed to update user information, please try again later.",
    );
  return response;
}
