import PropTypes from "prop-types";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

function GoBack({ className }) {
  const isRTL = document.dir === "rtl";
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={`${className}`}>
      <button
        className="flex items-center space-x-2 text-blue-b-primary hover:underline"
        onClick={handleGoBack}
      >
        {isRTL ? (
          <HiArrowLongRight className="h-6 w-6" />
        ) : (
          <HiArrowLongLeft className="h-6 w-6" />
        )}

        <p>Go back</p>
      </button>
    </div>
  );
}

GoBack.propTypes = {
  className: PropTypes.string,
};

export default GoBack;
