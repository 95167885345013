import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { IoAddCircle } from "react-icons/io5";
import CustomImage from "../../shared/images/CustomImage";
import CustomEditor from "../../shared/inputs/CustomEditor";
import ScrollTo from "../../shared/ScrollTo";
import { EmploymentTypeEnum } from "../../utils/constants";
import { useGetMyCompanyProfile } from "./../../hooks/useGetMyCompanyProfile";

function StepThree(props) {
  const { currencies, handleBack } = props;
  const { getValues } = useFormContext();
  const {
    keywords,
    currencyId,
    salaryRange,
    mainMission,
    employmentType,
    responsibilities,
    profile,
    softSkills,
    countryName,
  } = getValues();

  const { data } = useGetMyCompanyProfile();

  console.log(data);

  const selectedCurrency = currencies?.find((item) => item.id === currencyId);

  return (
    <div className="flex w-full flex-col gap-3">
      <ScrollTo />

      <div className="flex  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
        <div className="flex flex-col gap-2 border-r border-black-g-50 pr-4">
          <span className="font-primary text-sm font-normal text-blue-b-primary">
            Company
          </span>
          <div className="flex items-center gap-2">
            <CustomImage
              imgClassName={"image-layout w-16 h-16"}
              imgSrc={data?.logo || "https://placehold.co/200x200"}
            />
            <div className="flex flex-col gap-1">
              <span className="font-primary text-base font-semibold text-[#1C2436]">
                {data?.name || "No company"}
              </span>

              {data?.location?.address?.country?.name && (
                <span className="font-primary text-xs font-normal text-black-g-100">
                  {data?.location?.address?.country?.name}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex  flex-col gap-4  px-4">
          <span className="font-primary text-sm font-normal text-blue-b-primary">
            Salary
          </span>
          <div className="flex items-center gap-2">
            {salaryRange ? (
              <div className="flex flex-col gap-1">
                <span className="font-primary text-base font-semibold text-[#1C2436]">
                  {salaryRange[0]} - {salaryRange[1]}{" "}
                  <span className="font-primary text-xs font-medium text-black-g-100">
                    {selectedCurrency?.symbol}
                  </span>
                </span>
                <span className="font-primary text-xs font-normal text-black-g-100">
                  {countryName}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        {mainMission?.raw != "" ? (
          <div className="flex-1  pl-0 md:flex md:border-l md:border-black-g-50 md:pl-4">
            <div className="flex justify-end md:flex-1">
              <div className=" hidden md:flex md:flex-1  md:flex-col md:gap-4">
                <span className="font-primary text-sm font-normal text-blue-b-primary">
                  Mission globale
                </span>
                <div className="flex items-center gap-2">
                  <div className="font-primary text-sm font-normal text-black-g-100">
                    {mainMission?.raw || "No mission found"}
                  </div>
                </div>
              </div>
              <div
                className="btn-outline-blueB flex items-center gap-1 rounded-[4px] px-3 font-medium md:h-fit"
                onClick={handleBack}
              >
                <BiSolidMessageSquareEdit />
                Edit
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/*this will be shown for mobile only */}
      <div className="flex  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5 md:hidden">
        {mainMission?.raw != "" ? (
          <div className="flex  flex-1 ">
            <div className="flex flex-1 justify-end">
              <div className="flex flex-1 flex-col gap-4">
                <span className="font-primary text-sm font-normal text-blue-b-primary">
                  Mission globale
                </span>
                <div className="flex items-center gap-2">
                  <div className="font-primary text-sm font-normal text-black-g-100">
                    {mainMission?.raw || "No mission found"}
                  </div>
                </div>
              </div>
              <div
                className="btn-outline-blueB flex h-fit items-center gap-1 rounded-[4px] px-3 font-medium"
                onClick={handleBack}
              >
                <BiSolidMessageSquareEdit />
                Edit
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
        <div className="flex flex-1 items-center">
          <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
            Responsibilities : Missions et activités
          </span>
          <div
            className="btn-outline-blueB flex items-center rounded-[4px] px-3 font-medium"
            onClick={handleBack}
          >
            {responsibilities?.raw != "" ? (
              <BiSolidMessageSquareEdit className="mr-2" />
            ) : (
              <IoAddCircle className="mr-2" />
            )}
            {responsibilities?.raw != "" ? "Edit" : "Add"}
          </div>
        </div>
        <CustomEditor
          editorClassName={`input-transparent p-0 bg-transprent border-transparent ${responsibilities?.raw == "" && "h-0"}`}
          readOnly
          toolbarHidden
          htmlDefaultValue={responsibilities?.html}
        />
      </div>
      <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
        <div className="flex flex-1 items-center">
          <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
            Profile
          </span>
          <div
            className="btn-outline-blueB flex items-center rounded-[4px] px-3 font-medium"
            onClick={handleBack}
          >
            {profile?.raw != "" ? (
              <BiSolidMessageSquareEdit className="mr-2" />
            ) : (
              <IoAddCircle className="mr-2" />
            )}
            {profile?.raw != "" ? "Edit" : "Add"}
          </div>
        </div>

        <CustomEditor
          editorClassName={`input-transparent p-0 bg-transprent border-transparent ${profile?.raw == "" && "h-0"}`}
          readOnly
          toolbarHidden
          htmlDefaultValue={profile.html}
        />
      </div>
      <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
        <div className="flex flex-1 items-center">
          <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
            Savoir etre
          </span>

          <div
            className="btn-outline-blueB flex items-center rounded-[4px] px-3 font-medium"
            onClick={handleBack}
          >
            {softSkills?.raw != "" ? (
              <BiSolidMessageSquareEdit className="mr-2" />
            ) : (
              <IoAddCircle className="mr-2" />
            )}
            {softSkills?.raw != "" ? "Edit" : "Add"}
          </div>
        </div>
        <CustomEditor
          editorClassName={`input-transparent p-0 bg-transprent border-transparent ${softSkills?.raw == "" && "h-0"}`}
          readOnly
          toolbarHidden
          htmlDefaultValue={softSkills?.html}
        />
      </div>
      <div className="flex flex-col  rounded-lg border-black-g-50 bg-[#F7F9FB] p-5">
        <div className="mb-4 flex flex-1 items-center">
          <span className="flex-1 font-primary text-xl font-medium text-[#1C2436]">
            Skills
          </span>
          <div
            className="btn-outline-blueB flex items-center rounded-[4px] px-3 font-medium"
            onClick={handleBack}
          >
            <BiSolidMessageSquareEdit className="mr-2" />
            Edit
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-3">
          {keywords?.length > 0 ? (
            <>
              {keywords?.map((item) => (
                <div
                  key={item}
                  className="mb-3 rounded bg-blue-b-50 p-2 font-primary text-sm font-medium text-blue-b-primary"
                >
                  {item}
                </div>
              ))}
            </>
          ) : (
            "No Skills found"
          )}
        </div>
      </div>
    </div>
  );
}

StepThree.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default StepThree;
