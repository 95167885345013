import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { resetPassword as resetPasswordApi } from "../services/apiAuth";

export function useResetPassword() {
  const {
    error,
    mutate: resetPassword,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: ({ email, token, newPassword }) =>
      resetPasswordApi({ email, token, newPassword }),
    onSuccess: () => {
      toast.success(`Your password was reset successfully.`);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });
  return { error, resetPassword, isPending, isSuccess, isError };
}
