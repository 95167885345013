import PropTypes from "prop-types";

function EmojiSatisfied({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M12.6667 14.6667C12.2987 14.6667 12 14.368 12 14C12 12.38 10.972 10.6667 10 10.6667C9.028 10.6667 8 12.38 8 14C8 14.368 7.70133 14.6667 7.33333 14.6667C6.96533 14.6667 6.66667 14.368 6.66667 14C6.66667 11.7973 8.092 9.33333 10 9.33333C11.908 9.33333 13.3333 11.7973 13.3333 14C13.3333 14.368 13.0347 14.6667 12.6667 14.6667ZM25.3333 14C25.3333 11.7973 23.908 9.33333 22 9.33333C20.092 9.33333 18.6667 11.7973 18.6667 14C18.6667 14.368 18.9653 14.6667 19.3333 14.6667C19.7013 14.6667 20 14.368 20 14C20 12.38 21.028 10.6667 22 10.6667C22.972 10.6667 24 12.38 24 14C24 14.368 24.2987 14.6667 24.6667 14.6667C25.0347 14.6667 25.3333 14.368 25.3333 14ZM32 16C32 7.17733 24.8227 0 16 0C7.17733 0 0 7.17733 0 16C0 24.8227 7.17733 32 16 32C24.8227 32 32 24.8227 32 16ZM30.6667 16C30.6667 24.0867 24.0867 30.6667 16 30.6667C7.91333 30.6667 1.33333 24.0867 1.33333 16C1.33333 7.91333 7.91333 1.33333 16 1.33333C24.0867 1.33333 30.6667 7.91333 30.6667 16ZM23.7533 21.184C24.0387 20.952 24.0813 20.5333 23.8507 20.248C23.6187 19.964 23.1987 19.92 22.9133 20.1493C22.8827 20.1747 19.7533 22.668 15.9987 22.668C12.244 22.668 9.116 20.1747 9.084 20.1493C8.796 19.92 8.38 19.964 8.14667 20.248C7.916 20.5333 7.95867 20.952 8.244 21.184C8.38533 21.3 11.7613 24 15.9973 24C20.2333 24 23.6093 21.3 23.7507 21.184H23.7533Z" />
    </svg>
  );
}

EmojiSatisfied.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EmojiSatisfied;
