import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { deleteTalentPoolAction as deleteTalentPoolActionApi } from "../services/apiResume";

export function useDeleteTalentPoolAction() {
  const queryClient = useQueryClient();

  const { mutate: deleteTalentPoolAction, isPending } = useMutation({
    mutationFn: ({ id, resumeId, type, content, reminderDate }) =>
      deleteTalentPoolActionApi({ id, resumeId, type, content, reminderDate }),
    onMutate: () => {
      toast.loading("Deleting action ...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["talentPoolActions", "3"] });
      toast.dismiss();
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { deleteTalentPoolAction, isPending };
}
