import PropTypes from "prop-types";
import { TiTick } from "react-icons/ti";

/**
 * this component support custom icons for the steps
 * @param steps if this props is filled with array of object you should have step icon & name as properties inside
 * @example   const steps = [{icon:<TickTick/>,name:"step1"}, {{icon:<TickTick/>,name:"step2"}}];
-----------------------------------------------------------------------------------------------------
 * @param steps if this props is filled with array of strings you will have the default display of this stepper with index of step inside
 * @example   const steps = ["step1", "step2"];
 */
const FormStepper = ({
  steps,
  currentStep,
  showStepLabel,
  isVertical,
  className,
}) => {
  const orientation = isVertical ? "vertical" : "horizontal";
  return (
    <div
      className={`flex ${className} ${
        isVertical ? "flex-col " : "flex-1 flex-row items-center"
      }`}
    >
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step-item ${isVertical ? "py-6" : `flex-1 justify-center`}  ${orientation} ${
            currentStep === index + 1
              ? "active step-animation slide-in"
              : isVertical
                ? "not-active-vertical"
                : "not-active"
          } ${index + 1 < currentStep && "complete step-animation slide-out"}`}
        >
          <div className={`step ${isVertical ? "mr-2" : "mb-2"}`}>
            {typeof step === "object" ? (
              <>{step?.icon}</>
            ) : (
              <> {index + 1 < currentStep ? <TiTick size={16} /> : index + 1}</>
            )}
          </div>
          {showStepLabel && (
            <p
              className={`font-primary font-normal text-[#4A5164] ${isVertical ? "" : "ml-2"}`}
            >
              {typeof step === "object" ? <>{step?.name}</> : { step }}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

FormStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  showStepLabel: PropTypes.bool,
  isVertical: PropTypes.bool,
  className: PropTypes.string,
};

FormStepper.defaultProps = {
  steps: [],
  currentStep: 1,
  showStepLabel: false,
  isVertical: false, // Default to horizontal
  className: null,
};

export default FormStepper;
