import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const SEARCH_SKILLS_URL = REQUEST_URL.searchSkills;

export async function searchSkills({ page, size, keyword }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const data = { page, size, keyword };

  const searchParams = Object.keys(data)
    .filter((key) => data[key] !== null)
    .map((key) => `${key}=${data[key]}`)
    .join("&");

  const response = await fetch(
    `${BASE_URL}${SEARCH_SKILLS_URL}?${searchParams}`,
    {
      method: "GET",
      headers: { "Content-Type": "text/plain" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error("Cannot get skills. Please try again later!");
  return response.json();
}

export async function getSkills({ keyword, type, page, size }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${SEARCH_SKILLS_URL}?keyword=${keyword}&type=${type}&page=${page}&size=${size}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );
  if (!response.ok) throw new Error("Dispalying skills failed!");
  return response.json();
}

export async function postSkill({ keyword, type }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${SEARCH_SKILLS_URL}?keyword=${keyword}&type=${type}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    },
  );

  if (!response.ok)
    throw new Error("Failed to save resume, please try again later.");
  return response;
}
