import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateMyCompanyProfile } from "../services/apiRecruiter";

export function useUpdateCompanyProfile() {
  const queryClient = useQueryClient();

  const {
    mutate: saveCompanyProfile,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({
      employerName,
      description,
      video,
      companyEmail,
      companyPhone,
      companyWebsite,
      companyFoundedYear,
      companySize,
      companyAbout,
      officeLocation: {
        address: {
          address,
          address2,
          cityId,
          postalCode,
          countryId,
          countryName,
        },
        latitude,
        longitude,
      },
      industries,
      socialPlatforms,
    }) =>
      updateMyCompanyProfile({
        employerName,
        description,
        video,
        companyEmail,
        companyPhone,
        companyWebsite,
        companyFoundedYear,
        companySize,
        companyAbout,
        officeLocation: {
          address:
            { address, address2, cityId, postalCode, countryId, countryName } ||
            null,
          latitude,
          longitude,
        },
        industries,
        socialPlatforms,
      }),
    onMutate: () => toast.loading("Saving company data..."),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["my_company_profile"] });
      toast.dismiss();
      toast.success("Company information has been updated successfully");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { saveCompanyProfile, isPending, isSuccess };
}
