/* eslint-disable react/no-unescaped-entities */
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { RiAddCircleFill } from "react-icons/ri";
import Input from "../../../shared/inputs/Input";
import { useFieldArray } from "react-hook-form";
import Divider from "../../../shared/Divider";
import { TiDelete } from "react-icons/ti";

function Certifications({ watch, control, resetField, hideClearSection }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "certifications",
  });
  const [isToggleAdd, setIsToggleAdd] = useState(false);
  const certifRef = useRef(null);
  const { certificateName, certificateLink } = watch();
  useEffect(() => {
    if (isToggleAdd) {
      certifRef.current.scrollIntoView();
    }
  }, [isToggleAdd]);

  return (
    <div className="flex w-full ">
      <div className="flex w-full flex-col">
        <div className="flex  items-center">
          <div className="font-primary text-xl font-bold text-black-g-500">
            Certifications
          </div>
          <Divider className="ml-4 flex-1" />
        </div>
        <div className="flex w-full flex-col gap-4 py-4">
          {fields?.map((field, index) => (
            <div className="flex items-center gap-4" key={field.id}>
              <div className="flex-1 rounded border px-4 py-2">
                {field?.name}
              </div>
              <div className="flex-1 rounded border px-4 py-2">
                {field?.url}
              </div>

              <TiDelete
                size={24}
                className="cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              />
            </div>
          ))}
          <div
            className="btn-outline-blueB flex w-fit items-center gap-1 rounded-[4px] px-2 text-sm font-medium"
            onClick={() => {
              setIsToggleAdd(true);
            }}
          >
            <RiAddCircleFill size={18} />
            Add Certification
          </div>

          {isToggleAdd ? (
            <div className="flex items-center gap-4" ref={certifRef}>
              <Input
                type="text"
                id={"certificateName"}
                placeholder="Certfication Name"
                controllerName={`certificateName`}
                control={control}
                mainDivClassname={"flex-1"}
                containerClassName="border-black-g-50"
                containerBaseClassName="border rounded-md"
                fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                resetField={resetField}
              />
              <Input
                type="text"
                id={"certificateLink"}
                placeholder="Certfication Link"
                controllerName={`certificateLink`}
                control={control}
                mainDivClassname={"flex-1"}
                containerClassName="border-black-g-50"
                containerBaseClassName="border rounded-md"
                fieldClassName="input-transparent  text-sm font-primary font-normal bg-white "
                resetField={resetField}
              />
              <button
                className="btn-blue-b-primary flex w-[90px] items-center justify-center rounded-md py-2 font-primary text-base font-medium "
                disabled={!certificateName || !certificateLink}
                onClick={() => {
                  append({
                    name: certificateName,
                    url: certificateLink,
                  });
                  setIsToggleAdd(false);
                  resetField("certificateName");
                  resetField("certificateLink");
                }}
              >
                Add
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {!hideClearSection ? (
        <div className="ml-4 flex w-5/12 flex-col">
          <div className="flex items-center">
            <div className="font-primary text-base font-light text-blue-b-primary">
              Clear section
            </div>
            <Divider className="ml-4 flex-1" />
          </div>
          <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
            Here, you'll highlight your proficiency in various spoken languages
            that directly align with the job requirements.
          </div>
        </div>
      ) : null}
    </div>
  );
}

Certifications.propTypes = {
  watch: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  hideClearSection: PropTypes.bool,
};
Certifications.defaultProps = {
  hideClearSection: false,
};

export default Certifications;
