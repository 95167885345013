/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import { Slider } from "@mui/material";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetCurrencies } from "../../hooks/useGetCurrencies";
import { useGetIndustries } from "../../hooks/useGetIndustries";
import Divider from "../../shared/Divider";
import Input from "../../shared/inputs/Input";
import SelectBox from "../../shared/inputs/SelectBox";
import TextArea from "../../shared/inputs/TextArea";
import {
  EmploymentTypeArray,
  leaveNoticeArray,
  qualificationArray,
} from "../../utils/constants";

function CandidatePersonalInformationStep({ hideClearSection }) {
  const { data: industries, isLoading: isLoadingIndustries } =
    useGetIndustries();
  const { data: currencies, isLoading: isLoadingCurrencies } =
    useGetCurrencies();

  const { control, resetField, setValue, watch } = useFormContext();
  const [salaryValue, setSalaryValue] = useState(null);
  const [tjmValue, setTjmValue] = useState(null);
  const { salaryRange, tjmRange } = watch();

  useEffect(() => {
    if (salaryRange) {
      setSalaryValue([salaryRange[0], salaryRange[1]]);
    }
  }, [salaryRange]);

  useEffect(() => {
    if (tjmRange) {
      setTjmValue([tjmRange[0], tjmRange[1]]);
    }
  }, [tjmRange]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
        1. Personal Information
      </div>
      <div className="mt-4 h-full">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div
              className={`flex ${!hideClearSection ? "w-full" : "w-7/12"} flex-col overflow-hidden`}
            >
              <div className="flex  items-center">
                <div className="font-primary text-xl font-bold text-black-g-500">
                  Basic details
                </div>
                <Divider className="ml-4 flex-1" />
              </div>
              <div className="flex w-full flex-col gap-4 py-4">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Input
                      type={"text"}
                      label={"Your first name"}
                      placeholder={"Enter your first name"}
                      control={control}
                      controllerName={"firstName"}
                      rules={{ required: "Please enter your first name" }}
                      resetField={resetField}
                    />
                  </div>

                  <div className="flex-1">
                    <Input
                      type={"text"}
                      label={"Your last name"}
                      placeholder={"Enter your last name"}
                      control={control}
                      controllerName={"lastName"}
                      rules={{ required: "Please enter your last name" }}
                      resetField={resetField}
                    />
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className="flex-1">
                    <SelectBox
                      label="Education"
                      placeholder="Select your education level"
                      id="educationLevel"
                      control={control}
                      controllerName="educationLevel"
                      rules={{
                        required: "Please select an education level",
                      }}
                      options={{
                        data: qualificationArray,
                        key: "id",
                        value: "value",
                        name: "name",
                      }}
                      resetField={resetField}
                    />
                  </div>
                  <div className="flex-1"></div>
                </div>

                <div className="flex gap-2">
                  <div className="flex-1">
                    <Input
                      type={"text"}
                      label={"Job Title"}
                      placeholder={"Enter job Title"}
                      control={control}
                      controllerName={"jobTitle"}
                      rules={{ required: "Please enter your job title" }}
                      resetField={resetField}
                    />
                  </div>

                  <div className="flex-1">
                    <SelectBox
                      label="Job Sought"
                      placeholder="Select job industry"
                      id="industryId"
                      control={control}
                      controllerName="industryId"
                      rules={{
                        required: "Please select an industry",
                      }}
                      isLoading={isLoadingIndustries}
                      options={{
                        data: industries,
                        key: "id",
                        value: "id",
                        name: "name",
                      }}
                      resetField={resetField}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <Input
                      type={"text"}
                      label={"Experience"}
                      placeholder="Enter number of years of Experience"
                      control={control}
                      controllerName={"yearsOfExperience"}
                      rules={{
                        required:
                          "Please enter the number of years of Experience",
                      }}
                      resetField={resetField}
                    />
                  </div>

                  <div className="flex-1">
                    <SelectBox
                      label="Your disponibility"
                      placeholder="Select your disponibility"
                      id="disponibility"
                      control={control}
                      controllerName="disponibility"
                      rules={{
                        required: "Please select your disponibility",
                      }}
                      options={{
                        data: leaveNoticeArray,
                        key: "id",
                        value: "value",
                        name: "name",
                      }}
                      resetField={resetField}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <SelectBox
                      label="Status"
                      placeholder="Select your preferred employement type"
                      id="preferredEmploymentType"
                      control={control}
                      controllerName="preferredEmploymentType"
                      rules={{
                        required: "Please select status",
                      }}
                      options={{
                        data: EmploymentTypeArray,
                        key: "id",
                        value: "value",
                        name: "name",
                      }}
                      resetField={resetField}
                    />
                  </div>

                  <div className="flex flex-1 flex-col flex-wrap gap-3">
                    <div className="py-1.5 font-primary text-sm font-semibold">
                      TJM
                    </div>
                    <div className="px-3">
                      <Slider
                        value={tjmValue}
                        onChange={(event, newValue) => {
                          setValue("tjmRange", newValue);
                        }}
                        valueLabelDisplay="auto"
                        sx={{
                          color: "#0054FF",
                          height: "8px",
                          padding: "0px",
                        }}
                        step={100}
                        min={0}
                        max={9000}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="flex flex-1 flex-col flex-wrap gap-3">
                      <div className="py-1.5 font-primary text-sm font-semibold">
                        Salary Expectation
                      </div>
                      <div className="px-3">
                        <Slider
                          value={salaryValue}
                          onChange={(event, newValue) => {
                            setValue("salaryRange", newValue);
                          }}
                          valueLabelDisplay="auto"
                          sx={{
                            color: "#0054FF",
                            height: "8px",
                            padding: "0px",
                          }}
                          step={100}
                          min={0}
                          max={9000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex-1">
                    <SelectBox
                      label="Currency"
                      placeholder="Select a currency"
                      id="currencyId"
                      control={control}
                      controllerName="rate.currencyId"
                      rules={{
                        required: "Please select currency",
                      }}
                      options={{
                        data: currencies,
                        key: "id",
                        value: "id",
                        name: "name",
                      }}
                      isLoading={isLoadingCurrencies}
                      resetField={resetField}
                    />
                  </div>
                </div>

                <TextArea
                  label="About Info"
                  id="about"
                  rows={5}
                  placeholder="Describe yourself ..."
                  autoComplete="off"
                  controllerName="description"
                  control={control}
                  containerClassName="bg-white border"
                  resetField={resetField}
                />
              </div>
            </div>

            {/* {!hideClearSection ? (
              <div className="ml-4 flex w-5/12 flex-col">
                <div className="flex items-center">
                  <div className="font-primary text-base font-light text-blue-b-primary">
                    Clear section
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
                <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
                  Here, you'll highlight basic details such as your educational
                  background, work experience
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

CandidatePersonalInformationStep.propTypes = {
  hideClearSection: PropTypes.bool,
};
CandidatePersonalInformationStep.defaultProps = {
  hideClearSection: false,
};

export default CandidatePersonalInformationStep;
