import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { HiMinus } from "react-icons/hi2";
import Input from "../../shared/inputs/Input";
import SelectBox from "../../shared/inputs/SelectBox";

function CandidateLanguageForm({ index, className, onRemove }) {
  const { control, resetField } = useFormContext();

  return (
    <div className={className}>
      <div className="-m-4 flex flex-wrap">
        <div className="flex w-full p-4">
          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2"
            onClick={onRemove}
          >
            <HiMinus className="h-6 w-6" />
            <div>Remove</div>
          </button>
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <SelectBox
            label="Language"
            id={`languages.${index}.languageId`}
            placeholder="Select language"
            controllerName={`languages.${index}.languageId`}
            control={control}
            resetField={resetField}
            rules={{ required: "Required field" }}
            /* options={{
              data: EmploymentTypeArray,
              key: "id",
              value: "id",
              name: "name",
            }} */
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <SelectBox
            label="Level"
            id={`languages.${index}.level`}
            placeholder="Select a level"
            controllerName={`languages.${index}.level`}
            control={control}
            resetField={resetField}
            rules={{ required: "Required field" }}
            /* options={{
              data: EmploymentTypeArray,
              key: "id",
              value: "id",
              name: "name",
            }} */
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Certificate"
            type="text"
            id={`languages.${index}.certificate`}
            placeholder="Certificate"
            controllerName={`languages.${index}.certificate`}
            control={control}
            resetField={resetField}
          />
        </div>
      </div>
    </div>
  );
}

CandidateLanguageForm.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  onRemove: PropTypes.func,
};

export default CandidateLanguageForm;
