import { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "../dropdown/Dropdown";
import { Tooltip } from "react-tooltip";

function StatusFilter({
  items,
  dropdownMobileLabel,
  mainContainerClassname = "flex w-full flex-1 items-center",
  onSelect,
}) {
  const [selectedTab, setSelectedTab] = useState("1");
  const handleClick = (item) => {
    setSelectedTab(item?.value);
    onSelect?.(item);
  };
  return (
    <div className={mainContainerClassname}>
      <div className="hidden md:flex md:w-full md:flex-1 md:items-center">
        {items?.map((item, index) => (
          <div
            key={item.id}
            className={`filter-tab flex items-center md:px-2 lg:px-5  ${selectedTab == item?.value ? "bg-black-g-50" : ""} ${index == 0 ? "rounded-l-lg" : ""} ${index == items?.length - 1 ? "rounded-r-lg" : ""}`}
            onClick={() => handleClick(item)}
            data-tooltip-id={item?.id}
            data-tooltip-content={item?.title}
          >
            {item?.name}
            {item?.icon && (
              <item.icon
                className={`ml-2 w-4 ${selectedTab == item?.value ? "text-black-g-primary" : ""}`}
              />
            )}

            <Tooltip id={item?.id} />
          </div>
        ))}
      </div>

      {/* Dropdown component for mobile view only */}
      <div className="flex-1 md:hidden">
        <Dropdown
          className="rounded-[4px] border border-black-g-50 px-2 font-primary text-sm font-medium"
          label={dropdownMobileLabel}
          optionsConfig={{
            options: items,
            idKey: "id",
            nameKey: "name",
          }}
          onSelect={(value) => handleClick(value)}
        />
      </div>
    </div>
  );
}

StatusFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dropdownMobileLabel: PropTypes.string,
  mainContainerClassname: PropTypes.string,
  onSelect: PropTypes.func,
};

export default StatusFilter;
