import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

const MENU = {
  recruiter: [
    {
      name: "I am candidate",
      url: "/for-candidate",
      state: { role: "candidate" },
    },
    {
      name: "Get in touch",
      url: "get-in-touch",
      state: { role: "recruiter" },
    },
    {
      name: "About us",
      url: "about-us",
      state: { role: "recruiter" },
    },
    { name: "Blog", url: "blog", state: { role: "recruiter" } },
  ],

  candidate: [
    {
      name: "I am company",
      url: "/",
      state: { role: "recruiter" },
    },
    {
      name: "Get in touch",
      url: "get-in-touch",
      state: { role: "candidate" },
    },
    {
      name: "About us",
      url: "about-us",
      state: { role: "candidate" },
    },
    { name: "Blog", url: "blog", state: { role: "candidate" } },
  ],
};

function FooterMenu({ className }) {
  const location = useLocation();

  const currentRole = location?.state?.role || "recruiter";

  return (
    <nav className={className}>
      <ul className="-m-4 flex items-center text-sm">
        {MENU?.[currentRole].map((item) => (
          <li key={item?.name} className="p-4">
            <NavLink
              className={({ isActive }) => {
                return `transition-all duration-300 hover:text-blue-b-primary ${isActive ? "text-blue-b-primary" : ""}`;
              }}
              to={item?.url}
              state={item?.state}
            >
              {item?.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

FooterMenu.propTypes = {
  className: PropTypes.string,
};

export default FooterMenu;
