import { useLocation, useNavigate } from "react-router-dom";
import LogoIcon from "../../../shared/logo/LogoIcon";
import { useEffect } from "react";

function SignupConfirmEmail() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (!location?.state?.email) navigate("/signup", { replace: true });
    },
    [location, navigate],
  );

  if (!location?.state?.email) return null;

  return (
    <div className="m-auto w-full max-w-xl bg-white p-8 shadow-md shadow-slate-200">
      <div className="mb-6 flex justify-center">
        <LogoIcon size={18} className="mx-auto" />
      </div>

      <div className="mb-8 flex flex-col text-center">
        <h1 className="text-[2rem] font-medium">Verify email address</h1>
        <p className="text-black-g-100">
          To verify your email, we have sent a confirmation link to
        </p>
        {location?.state?.email && (
          <p className="text-black-g-200"> {location?.state?.email}</p>
        )}
      </div>
    </div>
  );
}

export default SignupConfirmEmail;
