import { useEffect } from "react";

const useScrollToError = ({ errorsFromState, isSubmitting }) => {
  useEffect(() => {
    if (!isSubmitting && Object.values(errorsFromState).some(Boolean)) {
      const firstError = errorsFromState[Object.keys(errorsFromState)[0]]; // Get the first error
      const sectionId = firstError?.ref?.name || firstError?.name;
      const section = document.getElementById(sectionId);

      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [errorsFromState, isSubmitting]); // Include isSubmitting as a dependency
};

export default useScrollToError;
