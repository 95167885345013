import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { parseResume as parseResumeApi } from "../services/apiResume";

export function useParseResume() {
  const {
    mutate: parseResume,
    isPending,
    isSuccess,
    data,
    reset,
  } = useMutation({
    mutationFn: (file) => parseResumeApi(file),
    onMutate: () => {
      toast.loading("Processing data, please wait ...");
    },
    onSuccess: () => {
      toast.dismiss();
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return {
    parseResume,
    isPending,
    isSuccess,
    data,
    reset,
  };
}
