import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const GET_LANGUAGES_URL = REQUEST_URL.getLanguages;

export async function getLanguages() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + GET_LANGUAGES_URL, {
    method: "GET",
    headers: { "Content-Type": "text/plain" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Cannot get languages list. Please try again later!");
  return response.json();
}
