import PropTypes from "prop-types";

function FolderOpenIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M21.3384 26.5413H6.66177C2.6951 26.5413 2.4851 24.3597 2.3101 22.598L1.84344 16.753C1.73844 15.6213 2.0651 14.4897 2.78844 13.5797C3.65177 12.5297 4.87677 11.958 6.1951 11.958H21.8051C23.1001 11.958 24.3251 12.5297 25.1534 13.5213L25.3518 13.7897C25.9818 14.653 26.2618 15.703 26.1568 16.7647L25.6901 22.5863C25.5151 24.3597 25.3051 26.5413 21.3384 26.5413ZM6.1951 13.708C5.41344 13.708 4.66677 14.058 4.17677 14.6647L4.0951 14.7463C3.72177 15.2247 3.52344 15.9013 3.59344 16.6013L4.0601 22.4463C4.22344 24.1497 4.29344 24.7913 6.66177 24.7913H21.3384C23.7184 24.7913 23.7768 24.1497 23.9401 22.4347L24.4068 16.5897C24.4768 15.9013 24.2784 15.213 23.8234 14.6763L23.7068 14.5363C23.1818 13.988 22.5168 13.708 21.7934 13.708H6.1951Z" />
      <path d="M23.9163 14.2565C23.438 14.2565 23.0413 13.8598 23.0413 13.3815V11.2932C23.0413 7.8165 22.4347 7.20983 18.958 7.20983H15.983C14.6647 7.20983 14.2097 6.74316 13.708 6.07816L12.203 4.08316C11.6897 3.4065 11.573 3.24316 10.523 3.24316H9.04134C5.56467 3.24316 4.95801 3.84983 4.95801 7.3265V13.3348C4.95801 13.8132 4.56134 14.2098 4.08301 14.2098C3.60467 14.2098 3.20801 13.8132 3.20801 13.3348V7.3265C3.20801 2.85816 4.57301 1.49316 9.04134 1.49316H10.5347C12.3313 1.49316 12.8913 2.0765 13.6147 3.03316L15.108 5.0165C15.423 5.4365 15.4463 5.45983 15.9947 5.45983H18.9697C23.438 5.45983 24.803 6.82483 24.803 11.2932V13.3815C24.7913 13.8598 24.3947 14.2565 23.9163 14.2565Z" />
      <path d="M16.9986 20.708H11.002C10.5236 20.708 10.127 20.3113 10.127 19.833C10.127 19.3547 10.5236 18.958 11.002 18.958H16.9986C17.477 18.958 17.8736 19.3547 17.8736 19.833C17.8736 20.3113 17.4886 20.708 16.9986 20.708Z" />
    </svg>
  );
}

FolderOpenIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default FolderOpenIcon;
