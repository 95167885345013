import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function SignupSuccess() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (!location?.state?.email_verified)
        navigate("/signup", { replace: true });
    },
    [location, navigate],
  );

  if (!location?.state?.email_verified) return null;

  return (
    <div className="m-auto w-full max-w-[45rem] bg-white p-8 shadow-md shadow-slate-200">
      <div>
        <img
          className="mx-auto h-48 w-48 object-contain object-center"
          src="/assets/success.svg"
        />

        <div className="mt-3 flex flex-col text-center">
          <h1 className="text-[2rem] font-medium">Success</h1>
          <p className="text-black-g-100">Your account has been created</p>
        </div>
      </div>

      <Link
        className="btn-blue-b-primary mt-8 block w-full text-center text-lg font-medium"
        to={"/login"}
        state={{ loginFirstTime: true }}
      >
        Get in
      </Link>
    </div>
  );
}

export default SignupSuccess;
