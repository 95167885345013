import PropTypes from "prop-types";

function LogoMainVersion({ className }) {
  return (
    <img
      className={`block transition-all duration-300 ${className}`}
      src="/assets/logo-main-version.svg"
      alt="Logo"
    />
  );
}

LogoMainVersion.propTypes = {
  className: PropTypes.string,
};

export default LogoMainVersion;
