import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { searchJobDescription } from "../services/apiJobDescription";

export function useSearchJobDescription({
  queryString,
  size,
  keyword,
  industries,
  countryId,
  cityId,
  minSalary,
  maxSalary,
  jobDescriptionStatus,
  contractTypes,
  workLocations,
  employmentTypes,
  experienceLevels,
  leaveNotice,
  myJobs,
  skills,
}) {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const page = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));

  const { isLoading, data, error } = useQuery({
    queryKey: [
      "jobDescriptionSearch",
      page,
      size,
      keyword,
      industries,
      countryId,
      cityId,
      minSalary,
      maxSalary,
      jobDescriptionStatus,
      contractTypes,
      workLocations,
      employmentTypes,
      experienceLevels,
      leaveNotice,
      myJobs,
      skills,
    ],
    queryFn: () =>
      searchJobDescription({
        page,
        size,
        keyword,
        industries,
        countryId,
        cityId,
        minSalary,
        maxSalary,
        jobDescriptionStatus,
        contractTypes,
        workLocations,
        employmentTypes,
        experienceLevels,
        leaveNotice,
        myJobs,
        skills,
      }),
    retry: false,
  });

  //PRE-FETCHING
  const pageCount = Math.ceil(data?.totalCount / size);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: [
        "jobDescriptionSearch",
        page + 1,
        size,
        keyword,
        industries,
        countryId,
        cityId,
        minSalary,
        maxSalary,
        jobDescriptionStatus,
        contractTypes,
        workLocations,
        employmentTypes,
        experienceLevels,
        leaveNotice,
        myJobs,
        skills,
      ],
      queryFn: () =>
        searchJobDescription({
          page: page + 1,
          size,
          keyword,
          industries,
          countryId,
          cityId,
          minSalary,
          maxSalary,
          jobDescriptionStatus,
          contractTypes,
          workLocations,
          employmentTypes,
          experienceLevels,
          leaveNotice,
          myJobs,
          skills,
        }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: [
        "jobDescriptionSearch",
        page - 1,
        size,
        keyword,
        industries,
        countryId,
        cityId,
        minSalary,
        maxSalary,
        jobDescriptionStatus,
        contractTypes,
        workLocations,
        employmentTypes,
        experienceLevels,
        leaveNotice,
        myJobs,
        skills,
      ],
      queryFn: () =>
        searchJobDescription({
          page: page - 1,
          size,
          keyword,
          industries,
          countryId,
          cityId,
          minSalary,
          maxSalary,
          jobDescriptionStatus,
          contractTypes,
          workLocations,
          employmentTypes,
          experienceLevels,
          leaveNotice,
          myJobs,
          skills,
        }),
    });

  return { isLoading, data, error };
}
