import React, { useState } from "react";

const MessageInput = ({ handleSendMessage }) => {
  const [messageInput, setMessageInput] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const onSend = () => {
    handleSendMessage(messageInput, selectedFile);
    setMessageInput("");
    setSelectedFile(null);
  };

  return (
    <section className="flex w-full max-w-[815px] flex-col bg-white px-3 pb-5 pt-3 shadow-sm">
      <form className="flex w-full items-start">
        <label htmlFor="replyInput" className="sr-only">
          Type your reply
        </label>
        <textarea
          id="replyInput"
          className="flex-grow resize-none rounded-md border border-solid border-neutral-800 border-opacity-10 bg-white pl-3.5 pr-16 pt-2.5" // Adjusted padding-right
          placeholder="Type your reply"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onSend();
            }
          }}
        />
        <input
          id="fileInput"
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
        <label htmlFor="fileInput" className="ml-2 cursor-pointer">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5fa6ba66f234f2660e9c761a683702f7265501aff58afacc0f194ab5b40ab39?placeholderIfAbsent=true&apiKey=3b5e69abf33f49fcaa760965ccda0d80"
            alt="Upload Icon"
            className="aspect-square w-6 object-contain"
          />
        </label>
        {selectedFile && (
          <div className="ml-2 flex items-center rounded-md bg-gray-200 p-1">
            <span>{selectedFile.name}</span>
            <button
              type="button"
              className="ml-2 text-gray-600 hover:text-gray-800"
              onClick={handleFileRemove}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
      </form>
      <section className="mt-3.5 flex items-center justify-end">
        <button
          className="flex min-h-[40px] items-center justify-center rounded-md bg-blue-600 px-5 py-2 text-lg font-medium text-white"
          type="button"
          onClick={onSend}
        >
          Send
        </button>
      </section>
    </section>
  );
};

export default MessageInput;
