/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import PropTypes from "prop-types";
import Divider from "../../../shared/Divider";
import CheckButton from "../../../shared/buttons/CheckButton";
import Input from "../../../shared/inputs/Input";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import { useGetSkills } from "../../../hooks/useGetSkills";
import { HardSkillsArray } from "../../../utils/constants";
import { usePostSkill } from "../../../hooks/usePostSkill";

function HardSkills({
  watch,
  control,
  resetField,
  setValue,
  hideClearSection,
}) {
  const [selectedSkill, setSelectedSkill] = useState(null);

  // console.log(watch());

  const selectedHardSkills = watch("hardSkills");

  const payload = {
    keyword: watch("hard-input"),
    type: 2,
    page: 1,
    size: 5,
  };
  const { data } = useGetSkills(payload);
  const { results: suggestedSkills } = data || {};

  const { saveSkill } = usePostSkill();

  const handleKeyDown = async (event) => {
    saveSkill({ keyword: event.target.value, type: payload.type });
  };

  // console.log("skills", suggestedSkills);
  return (
    <div className="flex w-full ">
      <div className="flex w-full flex-col">
        <div className="flex  items-center">
          <div className="font-primary text-xl font-bold text-black-g-500">
            Hard skills
          </div>
          <Divider className="ml-4 flex-1" />
        </div>
        <div className="flex w-full flex-col gap-4 py-4">
          <div className="flex flex-wrap items-center gap-2">
            {selectedHardSkills?.map((item) => (
              <CheckButton
                key={item.id || item.skillId}
                text={item?.name}
                selected={true}
                onClick={() => {
                  const updatedKeywords = selectedHardSkills?.filter(
                    (obj) => obj.id !== item.id || obj.skillId !== item.skillId,
                  );
                  setValue("hardSkills", updatedKeywords); // Update the form value
                }}
              />
            ))}
          </div>

          <div className="flex  gap-4">
            <Input
              type="text"
              id={"hard-input"}
              placeholder="Add skill"
              controllerName={"hard-input"}
              control={control}
              mainDivClassname={"flex-1 "}
              onKeyDown={handleKeyDown}
              containerClassName="border-black-g-50"
              containerBaseClassName="border rounded-md"
              fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
              resetField={resetField}
            />
            <div className="flex flex-1 flex-wrap items-center gap-2">
              {suggestedSkills?.map((item) => (
                <CheckButton
                  key={item.id}
                  text={item?.name}
                  onClick={() => {
                    setSelectedSkill(item);
                  }}
                />
              ))}
            </div>
          </div>
          {selectedSkill ? (
            <div className="flex items-center justify-between gap-4">
              <div className="flex-1 rounded-2xl border p-2 text-center font-primary text-sm font-medium text-black-g-primary">
                {selectedSkill?.name}
              </div>
              <Slider
                // value={watch("salaryRange")}
                onChange={(event, newValue) =>
                  setSelectedSkill((prev) => ({
                    ...prev,
                    hardSkillProficiencyLevel: newValue,
                  }))
                }
                marks={HardSkillsArray}
                shiftStep={1}
                step={1}
                valueLabelDisplay="off"
                sx={{
                  color: "#0054FF",
                  height: "8px",
                  width: "50%",
                  marginBottom: "0px",
                  paddingY: "0px",
                }}
                min={1}
                max={4}
              />
              <div
                className="btn-blue-b-primary flex w-[110px] items-center justify-center rounded-md py-2 font-primary text-base font-medium"
                role="button"
                onClick={() => {
                  setValue("hardSkills", [
                    ...selectedHardSkills,
                    {
                      skillId: selectedSkill.id,
                      name: selectedSkill.name,
                      hardSkillProficiencyLevel:
                        selectedSkill.hardSkillProficiencyLevel ?? 1,
                    },
                  ]);
                  setSelectedSkill(null);
                }}
              >
                Add skill
              </div>
              <Tooltip
                title={
                  <div className="flex flex-col gap-4 p-3">
                    <div className="text-center font-primary text-xs font-black leading-6 text-[#4A5164]">
                      Level of expertise
                    </div>
                    <div className="font-primary text-xs font-normal leading-4 text-black">
                      <span className="font-primary text-xs font-bold">
                        Débutant :{" "}
                      </span>
                      S’entend bien avec les autres et ne crée par de conflits
                    </div>
                    <div className="font-primary text-xs font-normal leading-4 text-black">
                      <span className="font-primary text-xs font-bold">
                        Experimenté confirmé :{" "}
                      </span>
                      Interagit de manière constructive avec ses collègues
                      Résout ses conflits avec les autres
                    </div>
                    <div className="font-primary text-xs font-normal leading-4 text-black">
                      <span className="font-primary text-xs font-bold">
                        Senior autonome :{" "}
                      </span>
                      Interagit de manière constructive avec ses collègues
                      Résout ses conflits avec les autres
                    </div>
                    <div className="font-primary text-xs font-normal leading-4 text-black">
                      <span className="font-primary text-xs font-bold">
                        Modéle/expert :{" "}
                      </span>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat.
                    </div>
                  </div>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "white",
                      border: "0.5px solid #D1D1D1",
                      borderRadius: "10px",
                    },
                  },
                }}
                placement="right"
              >
                <div>
                  <HiQuestionMarkCircle size={24} color="#8E8E8E" />
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>

      {!hideClearSection ? (
        <div className="ml-4 flex w-5/12 flex-col">
          <div className="flex items-center">
            <div className="font-primary text-base font-light text-blue-b-primary">
              Clear section
            </div>
            <Divider className="ml-4 flex-1" />
          </div>
          <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
            Here, you'll highlight your technical competencies, expertise, and
            qualifications that directly relate to the job requirements.
          </div>
        </div>
      ) : null}
    </div>
  );
}

HardSkills.propTypes = {
  watch: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  hideClearSection: PropTypes.bool,
};
HardSkills.defaultProps = {
  hideClearSection: false,
};

export default HardSkills;
