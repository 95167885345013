import PropTypes from "prop-types";
import { useLogout } from "../../hooks/useLogout";
import Loader from "../../shared/loader/Loader";

function Logout({ className, children, goToRoute }) {
  const { logout, isPending } = useLogout({ goToRoute });

  return (
    <div
      onClick={logout}
      className={`${className} disabled:cursor-not-allowed`}
      disabled={isPending}
    >
      {isPending ? <Loader color="#0054ff" size={1.25} /> : children}
    </div>
  );
}

Logout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  goToRoute: PropTypes.string,
};

export default Logout;
