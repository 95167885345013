import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { uploadUserPicture as uploadUserPictureApi } from "../services/apiUser";

export function useUploadUserPicture() {
  const {
    mutate: uploadUserPicture,
    isPending: isUploadingUserPicture,
    isSuccess: isUploadedUserPicture,
    data: uploadedUserPicture,
    reset: resetUploadUserPicture,
  } = useMutation({
    mutationFn: (file) => uploadUserPictureApi(file),
    onMutate: () => {
      toast.loading("Uploading picture ...");
    },
    onSuccess: () => {
      toast.dismiss();
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return {
    uploadUserPicture,
    isUploadingUserPicture,
    isUploadedUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  };
}
