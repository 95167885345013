import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateUserResume as updateUserResumeApi } from "../services/apiResume";

export function useUpdateUserResume() {
  const queryClient = useQueryClient();
  const {
    mutate: updateUserResume,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({
      step,
      firstName,
      lastName,
      jobTitle,
      description,
      birthDate,
      contactInfo: {
        email,
        phone,
        address: { address, address2, cityId, postalCode, countryId },

        socialPlatforms,
      },
      educations,
      experiences,
      languages,
      softSkills,
      hardSkills,
      preferredWorkLocation,
      experienceLevel,
      preferredEmploymentType,
      preferredContractType,
      educationLevel,
      certifications,
      industryId,
      yearsOfExperience,
      disponibility,
      rate: { currencyId, rateType, min, max },
    }) =>
      updateUserResumeApi({
        step,
        firstName,
        lastName,
        jobTitle,
        description,
        birthDate,
        contactInfo: {
          email,
          phone,
          address: { address, address2, cityId, postalCode, countryId },
          socialPlatforms,
        },
        educations,
        experiences,
        languages,
        softSkills,
        hardSkills,
        preferredWorkLocation,
        experienceLevel,
        preferredEmploymentType,
        preferredContractType,
        educationLevel,
        certifications,
        industryId,
        yearsOfExperience,
        disponibility,
        rate: { currencyId, rateType, min, max },
      }),
    onMutate: () => toast.loading("Saving resume data..."),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["userResume"] });
      toast.dismiss();
      toast.success("The resume has been submitted successfully");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.message);
    },
  });

  return { updateUserResume, isPending, isSuccess };
}
