import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const MY_COMPANY_URL = REQUEST_URL.my_company_profile;
const UPDATE_COMPANY_PROFILE_URL = REQUEST_URL.company_profile;
const RECRUITER_LOGO_URL = REQUEST_URL.recruiter_logo;

export async function getMyCompnayProfile() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + MY_COMPANY_URL, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  if (!response.ok) throw new Error("Dispalying company profile failed!");
  return response.json();
}

export async function updateMyCompanyProfile({
  employerName,
  description,
  video,
  companyEmail,
  companyPhone,
  companyWebsite,
  companyFoundedYear,
  companySize,
  companyAbout,
  officeLocation: {
    address: { address, address2, cityId, postalCode, countryId, countryName },
    latitude,
    longitude,
  },
  industries,
  socialPlatforms,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");
  const response = await fetch(BASE_URL + UPDATE_COMPANY_PROFILE_URL, {
    method: "POST",
    body: JSON.stringify({
      employerName: employerName || "",
      description: description || "",
      video: video || "",
      companyEmail: companyEmail || "",
      companyPhone: companyPhone || "",
      companyWebsite: companyWebsite || "",
      companyFoundedYear: companyFoundedYear || "",
      companySize: companySize || "",
      companyAbout: companyAbout || "",
      officeLocation: {
        address: {
          address: address || "",
          address2: address2 || "",
          cityId: Number(cityId) || 0,
          postalCode: postalCode || "",
          countryId: Number(countryId) || "",
          countryName: countryName || "",
        },
        latitude: latitude || 0,
        longitude: longitude || 0,
      },
      industries: industries || [],
      socialPlatforms: socialPlatforms || [],
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  if (!response.ok) throw new Error("Updating company profile failed!");
  return null;
}

export async function uploadRecruiterLogo(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + RECRUITER_LOGO_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (response.status === 400) {
    const error = new Error("Only Image files are allowed");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to upload the resume, please try again later.");
  return response.json();
}
