import PropTypes from "prop-types";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";

import LoaderFullPage from "./loader/LoaderFullPage";

function ProtectedRoute({ children, allowedRoles }) {
  const location = useLocation();
  const { id } = useParams();

  const { isAuthenticating, redirectionUrl, roles } = useIsAuthenticated();

  if (
    location?.pathname === `/recruiter/job-detail/${id}` ||
    location?.pathname === `/candidate/job-detail/${id}`
  )
    return children;

  if (isAuthenticating) return <LoaderFullPage />;

  if (!isAuthenticating && !redirectionUrl)
    return (
      <Navigate
        to={"/login"}
        replace={true}
        state={{ redirectionUrl: location?.state?.redirectionUrl }}
      />
    );

  if (redirectionUrl && roles?.find((role) => allowedRoles?.includes(role)))
    return children;

  return <Navigate to={redirectionUrl} replace={true} />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  allowedRoles: PropTypes.array,
};

export default ProtectedRoute;
