import PropTypes from "prop-types";

function BuildingIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth={1.5}
    >
      <path
        d="M12.4997 22.5003H4.07973C2.91973 22.5003 1.96973 21.5703 1.96973 20.4303V5.59035C1.96973 2.97035 3.91973 1.78035 6.30973 2.95035L10.7497 5.13035C11.7097 5.60035 12.4997 6.85035 12.4997 7.91035V22.5003Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.97 15.5599V19.3399C21.97 21.4999 20.97 22.4999 18.81 22.4999H12.5V10.9199L12.97 11.0199L17.47 12.0299L19.5 12.4799C20.82 12.7699 21.9 13.4499 21.96 15.3699C21.97 15.4299 21.97 15.4899 21.97 15.5599Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.5 9.5H8.97" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 13.5H8.97" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.4697 12.0305V15.2505C17.4697 16.4905 16.4597 17.5005 15.2197 17.5005C13.9797 17.5005 12.9697 16.4905 12.9697 15.2505V11.0205L17.4697 12.0305Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9597 15.3703C21.8997 16.5503 20.9197 17.5003 19.7197 17.5003C18.4797 17.5003 17.4697 16.4903 17.4697 15.2503V12.0303L19.4997 12.4803C20.8197 12.7703 21.8997 13.4503 21.9597 15.3703Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

BuildingIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default BuildingIcon;
