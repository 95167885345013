import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  HiMiniChevronDown,
  HiMiniChevronUp,
  HiOutlineEnvelope,
} from "react-icons/hi2";
import { useOutsideClick } from "../hooks/useOutsideClick";
import NotificationIcon from "../icons/NotificationIcon";
import ArchiveIcon from "../icons/ArchiveIcon";
import UserIcon from "../icons/UserIcon";
import Logout from "../components/authentication/Logout";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "../hooks/useUser";
import { getFileType } from "../utils/helpers";

function UserTopSideMenu({ className, menuRef }) {
  const isRTL = document.dir === "rtl";

  const { isLoading: isLoadingUser, user } = useUser();

  const USER_TOPSIDE_MENU = [
    /* {
      name: "iconmessage",
      icon: <HiOutlineEnvelope className="h-6 w-6" />,
      url: "messages",
      roles: ["candidate", "recruiter"],
    }, */
    /* {
      name: "icon2",
      icon: <ArchiveIcon className="h-6 w-6" />,
      url: "alerts",
    }, */
    /* {
      name: "icon3",
      icon: <NotificationIcon className="h-6 w-6" />,
      children: {
        //style: "",
        list: [
          {
            id: `notif-${uuidv4()}`,
            element: (
              <div className="min-w-72 bg-blue-b-50 p-2 shadow-md">
                <div className="flex items-center space-x-3">
                  <div className="h-9 w-9 max-w-full overflow-hidden rounded-full">
                    <img
                      className="h-full w-full object-cover object-center"
                      src="/uploads/user1.png"
                    />
                  </div>

                  <div className="flex-1">
                    <b className="text-sm font-semibold">Salim Sinapera </b>
                    <span className="text-sm">
                      invited you to a technical test
                    </span>

                    <div className="text-xs font-medium text-blue-b-primary">
                      2 hours ago
                    </div>
                  </div>
                </div>
              </div>
            ),
            roles: ["candidate", "recruiter"],
          },
          {
            id: `notif-${uuidv4()}`,
            element: (
              <div className="min-w-72 bg-blue-b-50 p-2 shadow-md">
                <div className="flex items-center space-x-3">
                  <div className="h-9 w-9 max-w-full overflow-hidden rounded-full">
                    <img
                      className="h-full w-full object-cover object-center"
                      src="/uploads/user1.png"
                    />
                  </div>

                  <div className="flex-1">
                    <b className="text-sm font-semibold">Salim Sinapera </b>
                    <span className="text-sm">
                      invited you to a technical test
                    </span>

                    <div className="text-xs font-medium text-blue-b-primary">
                      2 hours ago
                    </div>
                  </div>
                </div>
              </div>
            ),
            roles: ["candidate", "recruiter"],
          },
        ],
      },
    }, */
    {
      name: "icon1",
      icon: <UserIcon className="h-6 w-6" />,
      children: {
        //style: "",
        list: [
          {
            id: `notif-${uuidv4()}`,
            element: (
              <div className="flex items-center space-x-4">
                <div className="h-12 w-12 overflow-hidden rounded-full">
                  <img
                    className="block h-full w-full object-cover object-center"
                    src={
                      getFileType(user?.profilePic) === "image"
                        ? user?.profilePic
                        : "/uploads/avatar.png"
                    }
                  />
                </div>

                <div className="whitespace-nowrap font-normal text-black-g-100">{`${user?.firstName} ${user?.lastName}`}</div>
              </div>
            ),
            style: "p-4 bg-blue-b-50 mb-4",
            roles: ["candidate", "recruiter"],
          },
          {
            id: `notif-${uuidv4()}`,
            name: "My profile",
            url: "profile",
            roles: ["candidate", "recruiter"],
          },
          { name: "My resume", url: "resume", roles: ["candidate"] },
          {
            name: "Settings & privacy",
            url: "",
            roles: ["candidate", "recruiter"],
          },
          {
            id: `notif-${uuidv4()}`,
            element: <Logout>Log out</Logout>,
            roles: ["candidate", "recruiter"],
          },
        ],
      },
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const initialClassName = "scale-x-50 scale-y-0 opacity-0";
  const animationClassName = "transform-none opacity-100";
  const [openClassName, setOpenClassName] = useState(initialClassName);

  const toggleSubMenu = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    setOpenClassName((prevIndex) =>
      prevIndex === index ? animationClassName : initialClassName,
    );
  };

  useEffect(
    function () {
      if (openIndex !== null) setOpenClassName(animationClassName);
      else setOpenClassName(initialClassName);
    },
    [openIndex],
  );

  const itemRef = useOutsideClick(() => setOpenIndex(null), false);
  const { roles } = useIsAuthenticated();

  return (
    <div ref={menuRef} className={className}>
      <div>
        <ul className="flex items-center font-primary text-lg font-medium transition-all duration-300">
          {USER_TOPSIDE_MENU?.map((item, index) => (
            <li
              key={item?.id || item?.name}
              className="group relative transition-all duration-200"
              onClick={(e) => {
                e.stopPropagation();
                toggleSubMenu(index);
              }}
              ref={itemRef}
            >
              {item?.url ? (
                <NavLink
                  className={({ isActive }) => {
                    return `flex items-center space-x-1 p-2.5 transition-all duration-300 hover:text-blue-b-primary ${isActive ? "text-blue-b-primary" : ""}`;
                  }}
                  to={item?.url}
                  state={item?.state}
                  aria-label={item.name}
                >
                  <div>{item?.icon}</div>
                  {item?.children && (
                    <HiMiniChevronDown className="block h-2 w-2 group-hover:hidden" />
                  )}

                  {item?.children && (
                    <HiMiniChevronUp className="hidden h-2 w-2 group-hover:block" />
                  )}
                </NavLink>
              ) : (
                <div
                  className={
                    "flex items-center p-2.5 transition-all duration-300 hover:text-blue-b-primary"
                  }
                >
                  <div className="flex-1">{item?.icon}</div>
                  {item?.children && (
                    <HiMiniChevronDown
                      className={openIndex === index ? "hidden" : "block"}
                    />
                  )}

                  {item?.children && (
                    <HiMiniChevronUp
                      className={openIndex === index ? "block" : "hidden"}
                    />
                  )}
                </div>
              )}

              {item?.children && openIndex === index && (
                <ul
                  className={`absolute top-full z-10 min-w-60 ${isRTL ? "left-0 origin-top-left" : "right-0 origin-top-right"} rounded-md border border-slate-300 bg-white shadow-lg backdrop-blur transition-all duration-200 ${openClassName}`}
                >
                  {item?.children?.list
                    ?.filter((menuItem) => {
                      return roles?.some((role) =>
                        menuItem?.roles?.includes(role),
                      );
                    })
                    .map((child) => (
                      <li
                        key={child?.id || child?.name}
                        className={
                          child?.style
                            ? child?.style
                            : "mx-4 cursor-pointer border-b border-slate-200 transition-all duration-200 first:mt-4 last:mb-4 last:border-none hover:bg-blue-b-50 hover:px-4 hover:text-blue-b-primary"
                        }
                      >
                        {child?.url ? (
                          <NavLink
                            className={({ isActive }) => {
                              return `block p-2 transition-all duration-200 hover:px-4 hover:text-blue-b-primary ${isActive ? "text-blue-b-primary" : ""}`;
                            }}
                            to={child?.url}
                            state={item?.state}
                            aria-label={child?.id || child.name}
                          >
                            {child?.element ? child?.element : child?.name}
                          </NavLink>
                        ) : (
                          <div
                            className={"block p-2 transition-all duration-200"}
                          >
                            {child?.element ? child?.element : child?.name}
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

UserTopSideMenu.propTypes = {
  className: PropTypes.string,
  menuRef: PropTypes.any,
  menu: PropTypes.array,
};

export default UserTopSideMenu;
