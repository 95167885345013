import PropTypes from "prop-types";
import { createContext, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaginationContext = createContext();

function Pagination({ children, queryString, totalCount, size }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));
  const pageCount = Math.ceil(totalCount / size);

  useEffect(
    function () {
      if (currentPage > 1 && (currentPage - 1) * size >= totalCount) {
        searchParams.set(queryString, currentPage - 1);
        setSearchParams(searchParams);
      }
    },
    [currentPage, size, totalCount, queryString, setSearchParams, searchParams],
  );

  return (
    <PaginationContext.Provider
      value={{
        searchParams,
        setSearchParams,
        currentPage,
        pageCount,
        queryString,
        totalCount,
        size,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}

function Label() {
  const { currentPage, pageCount, totalCount, size } =
    useContext(PaginationContext);

  if (pageCount <= 1) return null;

  return (
    <p className="text-sm text-slate-600">
      Showing {(currentPage - 1) * size + 1} to {currentPage * size} of{" "}
      <span className="font-semibold">{totalCount}</span> results
    </p>
  );
}

function NextPage({ children }) {
  const { searchParams, setSearchParams, currentPage, pageCount, queryString } =
    useContext(PaginationContext);

  if (pageCount <= 1) return null;

  const handleNextPage = () => {
    const next = currentPage === pageCount ? currentPage : currentPage + 1;
    searchParams.set(queryString, next);
    setSearchParams(searchParams);
  };

  return (
    <button
      type="button"
      className="flex cursor-pointer items-center space-x-1 font-semibold hover:text-blue-b-primary disabled:cursor-default disabled:text-black-g-50"
      onClick={handleNextPage}
      disabled={currentPage >= pageCount}
      aria-label="next-page"
      id="next-page"
    >
      {children}
    </button>
  );
}

function PrevPage({ children }) {
  const { searchParams, setSearchParams, currentPage, pageCount, queryString } =
    useContext(PaginationContext);

  if (pageCount <= 1) return null;

  const handlePrevPage = () => {
    const prev = currentPage === 1 ? currentPage : currentPage - 1;
    searchParams.set(queryString, prev);
    setSearchParams(searchParams);
  };

  return (
    <button
      type="button"
      className="flex cursor-pointer items-center space-x-1 font-semibold hover:text-blue-b-primary disabled:cursor-default disabled:text-black-g-50"
      onClick={handlePrevPage}
      disabled={currentPage <= 1}
      aria-label="prev-page"
      id="prev-page"
    >
      {children}
    </button>
  );
}

function Numbers() {
  const { searchParams, setSearchParams, currentPage, pageCount, queryString } =
    useContext(PaginationContext);

  const numbers = [...Array(pageCount + 1).keys()].slice(1);

  if (pageCount <= 1) return null;

  const handleCurrentPage = (number) => {
    searchParams.set(queryString, number);
    setSearchParams(searchParams);
  };

  return (
    <nav>
      <ul className="flex items-center">
        {numbers?.map((number, index) => (
          <li key={index} className={"px-1.5"}>
            <div
              className={`${currentPage === number ? "bg-blue-b-primary text-white" : ""} cursor-pointer rounded-lg px-4 py-2 text-sm transition-all duration-200 hover:bg-blue-b-primary hover:text-white`}
              onClick={() => handleCurrentPage(number)}
            >
              {number}
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
}

Pagination.Label = Label;
Pagination.NextPage = NextPage;
Pagination.PrevPage = PrevPage;
Pagination.Numbers = Numbers;

NextPage.propTypes = {
  children: PropTypes.node,
};

PrevPage.propTypes = {
  children: PropTypes.node,
};

Pagination.propTypes = {
  children: PropTypes.node,
  queryString: PropTypes.string,
  size: PropTypes.number,
  totalCount: PropTypes.number,
};

export default Pagination;
