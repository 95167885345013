import PropTypes from "prop-types";

function TransactionMinusIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M15.6991 45.967C17.6125 43.9137 20.5292 44.077 22.2092 46.317L24.5658 49.467C26.4558 51.9637 29.5125 51.9637 31.4025 49.467L33.7592 46.317C35.4392 44.077 38.3558 43.9137 40.2691 45.967C44.4225 50.4003 47.8058 48.9303 47.8058 42.7237V16.427C47.8058 7.02366 45.6125 4.66699 36.7925 4.66699H19.1525C10.3325 4.66699 8.13916 7.02366 8.13916 16.427V42.7003C8.16249 48.9303 11.5691 50.377 15.6991 45.967Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5835 23.333H34.4168"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

TransactionMinusIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default TransactionMinusIcon;
