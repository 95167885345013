import PropTypes from "prop-types";

function EmojiSad({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M12 22.6667H20V24H12V22.6667ZM13.4813 16.1027C12.3533 17.2413 11.1613 17.4147 10.3427 17.3627C9.336 17.2947 8.344 16.8467 7.62133 16.132L6.684 17.08C7.63067 18.016 8.93333 18.6053 10.2547 18.692C10.368 18.7 10.4853 18.7053 10.6093 18.7053C11.6747 18.7053 13.1027 18.3827 14.4307 17.04L13.4827 16.1027H13.4813ZM21.7627 17.336C20.96 17.384 19.7547 17.2147 18.6307 16.076L17.6827 17.0133C19.0093 18.3573 20.4387 18.6787 21.5013 18.6787C21.6227 18.6787 21.74 18.6747 21.8507 18.6667C23.1707 18.58 24.4707 17.9933 25.4173 17.0587L24.48 16.1107C23.7587 16.8227 22.7693 17.2693 21.7627 17.336ZM32 16C32 24.8227 24.8227 32 16 32C7.17733 32 0 24.8227 0 16C0 7.17733 7.17733 0 16 0C24.8227 0 32 7.17733 32 16ZM30.6667 16C30.6667 7.91333 24.0867 1.33333 16 1.33333C7.91333 1.33333 1.33333 7.91333 1.33333 16C1.33333 24.0867 7.91333 30.6667 16 30.6667C24.0867 30.6667 30.6667 24.0867 30.6667 16ZM12.2227 7.69067L11.0973 6.97733C9.99067 8.72267 8.248 10.1667 6.19067 11.044L6.71467 12.2707C9.05067 11.2747 10.956 9.69067 12.2227 7.69067ZM20.8893 6.96667L19.7773 7.70133C21.0907 9.688 23.0267 11.268 25.3733 12.2707L25.8973 11.044C23.7907 10.1453 22.0587 8.736 20.8893 6.96667Z" />
    </svg>
  );
}

EmojiSad.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EmojiSad;
