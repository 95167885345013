import React, { useEffect } from "react";
const defaultPic = "../../../public/uploads/defaultProfilePic.png";
const MessageList = ({ messages, currentUser, chatEndRef, users }) => {
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const getFileName = (url) => {
    return url ? url.split("/").pop() : "Unknown File";
  };

  const getUserProfilePic = (userId) => {
    const user = users.find((user) => user.condidate.id === userId);

    return user ? user.condidate.profilePic : defaultPic;
  };

  return (
    <div className="my-2 w-full flex-grow overflow-y-auto p-2">
      {Array.isArray(messages) && messages.length > 0 ? (
        messages.map((msg, index) => (
          <section
            key={index}
            className={`flex ${
              msg.senderId === currentUser.id ? "justify-end" : "justify-start"
            } mb-4  gap-2`}
            ref={index === messages.length - 1 ? chatEndRef : null}
          >
            {/* Show profile picture for messages from other users */}
            {msg.senderId !== currentUser.id && (
              <img
                loading="lazy"
                src={getUserProfilePic(msg.senderId)}
                className="mt-8 aspect-square w-[35px] shrink-0 self-end object-contain"
                alt="Receiver's profile pic"
              />
            )}
            <article
              className={`flex flex-col ${
                msg.senderId === currentUser.id
                  ? "max-w-[659px] rounded-md bg-gray-200 text-neutral-800"
                  : "max-w-[480px] rounded-md border border-solid border-neutral-800 border-opacity-10 bg-white text-neutral-800"
              }`}
            >
              <p
                className={`overflow-hidden py-3 pl-3 pr-5 text-base ${
                  msg.senderId === currentUser.id
                    ? "text-neutral-800"
                    : "text-neutral-800"
                }`}
              >
                {msg.type === 2 ? (
                  <a
                    href={msg.content}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 underline"
                  >
                    {getFileName(msg.content)}
                  </a>
                ) : (
                  <span>{msg.content || msg.contents}</span>
                )}
              </p>
            </article>
            {/* Optionally, show profile picture for messages from the current user */}
            {/* {msg.senderId === currentUser.id && (
              <img
                loading="lazy"
                src={getUserProfilePic(msg.senderId)}
                className="object-contain shrink-0 self-end mt-8 aspect-square w-[35px]"
                alt="Sender's profile pic"
              />
            )} */}
          </section>
        ))
      ) : (
        <div>No messages found</div>
      )}
    </div>
  );
};

export default MessageList;
