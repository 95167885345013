import { useQuery } from "@tanstack/react-query";

import { getLanguages } from "../services/apiLanguages";

export function useGetLanguages() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["languages"],
    queryFn: getLanguages,
    retry: false,
  });

  return { isLoading, data, error };
}
