import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { searchTalentsTax } from "../services/apiCandidate";

export function useSearchTalentsTax({ queryString, size }) {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const page = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));

  const { isLoading, data, error } = useQuery({
    queryKey: ["talentsTaxSearch", page, size],
    queryFn: () =>
      searchTalentsTax({
        page,
        size,
      }),
    select: (data) => ({
      ...data,
      results: data?.results?.slice((page - 1) * size, size * page),
    }),
    retry: false,
  });

  //PRE-FETCHING
  const pageCount = Math.ceil(data?.totalCount / size);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["talentsTaxSearch", page + 1, size],
      queryFn: () =>
        searchTalentsTax({
          page: page + 1,
          size,
        }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["talentsTaxSearch", page - 1, size],
      queryFn: () =>
        searchTalentsTax({
          page: page - 1,
          size,
        }),
    });

  return { isLoading, data, error };
}
