import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { HiCheck, HiMiniXCircle } from "react-icons/hi2";
import EyeCrossIcon from "../../icons/EyeCrossIcon";
import EyeIcon from "../../icons/EyeIcon";
import Loader from "../loader/Loader";
import PasswordStrength from "./PasswordStrength";

function Input({
  labelClassName = "font-primary font-semibold cursor-pointer py-3 text-base text-[#060F26]",
  containerBaseClassName = "rounded-md border-2",
  containerClassName = "bg-[#F7F9FB] border-transparent",
  fieldClassName = "input-transparent",
  gap = 1,
  gapLabel = 1,
  column = true,
  label,
  type,
  id,
  placeholder,
  autoComplete,
  controllerName,
  control,
  rules,
  defaultValue,
  children,
  disabled,
  showPasswordStrength = false,
  resetField,
  isAsyncSuccess,
  isLoading,
  onKeyDown,
  mainDivClassname,
  step,
}) {
  const inputRef = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(isAsyncSuccess);

  useEffect(
    function () {
      setIsValid(isAsyncSuccess);
    },
    [isAsyncSuccess],
  );

  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ""}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, isDirty, error },
      }) => {
        if (type === "hidden")
          return (
            <input
              ref={inputRef}
              type={"hidden"}
              id={id}
              placeholder={placeholder}
              autoComplete={"off"}
              className={fieldClassName}
              value={value || ""}
              onChange={(e) => {
                onChange?.(e);
              }}
            />
          );

        return (
          <div
            className={`${column === true ? "flex-col" : ""} flex ${mainDivClassname}`}
            style={{ margin: `${-parseFloat(gap) / 4}rem` }}
          >
            {label && (
              <div style={{ padding: `${parseFloat(gapLabel) / 4}rem` }}>
                <label
                  className={`${labelClassName} ${!column ? "flex h-full w-full" : ""}`}
                  htmlFor={id}
                >
                  {label}
                </label>
              </div>
            )}

            <div
              className="flex-1"
              style={{ padding: `${parseFloat(gap) / 4}rem` }}
            >
              <div
                className={`overflow-hidden ${containerBaseClassName} transition-all duration-200 ${
                  !invalid
                    ? `${containerClassName} focus-within:border-black-g-primary`
                    : `border-red-o-100 bg-red-o-50 focus-within:border-red-o-100`
                }`}
              >
                <div className="relative -m-0.5 flex items-center">
                  <div className="relative flex-1 p-0.5">
                    <input
                      ref={inputRef}
                      type={showPassword ? "text" : type}
                      id={id}
                      placeholder={placeholder}
                      autoComplete={autoComplete}
                      className={fieldClassName}
                      value={value || ""}
                      onChange={(e) => {
                        onChange?.(e);
                        setIsValid(false);
                        if (type === "password" && showPasswordStrength)
                          setPassword?.(e.target.value);
                      }}
                      onBlur={(e) => {
                        onBlur?.(e);
                        setIsValid(isAsyncSuccess);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onKeyDown?.(e);
                        }
                      }}
                      onWheel={
                        type === "number" ? (e) => e.target.blur() : null
                      }
                      disabled={isLoading || disabled}
                      step={step}
                    />
                    {children}
                  </div>

                  {((isLoading && isDirty && !invalid) ||
                    (((isDirty && !isValid) || invalid) && value) ||
                    (value && isValid && isDirty && !invalid && !isLoading) ||
                    type === "password") && (
                    <div className="absolute right-0 top-1/2 -translate-y-1/2 p-0.5">
                      <div className="pr-3.5">
                        <div className="-m-1.5 flex items-center">
                          {isLoading && isDirty && !invalid && (
                            <div className="p-1.5">
                              <Loader />
                            </div>
                          )}

                          {((isDirty && !isValid) || invalid) && value && (
                            <div className="p-1.5">
                              <div
                                className="cursor-pointer overflow-hidden p-0.5 transition-all duration-200"
                                onClick={() => {
                                  setPassword?.("");
                                  resetField?.(controllerName || id);
                                  setIsValid(false);
                                }}
                              >
                                <HiMiniXCircle
                                  className={`h-6 w-6 text-black-g-primary`}
                                />
                              </div>
                            </div>
                          )}

                          {value &&
                            isValid &&
                            isDirty &&
                            !invalid &&
                            !isLoading && (
                              <div className="p-1.5">
                                <HiCheck className="h-6 w-6 stroke-2 text-green-g-400" />
                              </div>
                            )}

                          {type === "password" && (
                            <div className="p-1.5">
                              <div
                                className="cursor-pointer p-0.5 text-black-g-primary"
                                onClick={() =>
                                  setShowPassword?.((show) => !show)
                                }
                              >
                                <div className="h-5 w-5">
                                  {showPassword ? (
                                    <EyeCrossIcon className="h-full w-full" />
                                  ) : (
                                    <EyeIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {type === "password" && showPasswordStrength && (
                  <PasswordStrength password={password} />
                )}
              </div>

              {invalid && error?.message && (
                <p className="mt-2 text-sm text-red-o-primary">
                  {error?.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

Input.propTypes = {
  labelClassName: PropTypes.string,
  containerBaseClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  gap: PropTypes.number,
  gapLabel: PropTypes.number,
  column: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  showPasswordStrength: PropTypes.bool,
  resetField: PropTypes.func,
  isAsyncSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  onKeyDown: PropTypes.func,
  mainDivClassname: PropTypes.string,
  step: PropTypes.number,
};

export default Input;
