import { useQuery, useQueryClient } from "@tanstack/react-query";
import { applicationSearch } from "../services/apiApplication";
import { useSearchParams } from "react-router-dom";

export const useApplicationSearch = ({
  jobDescriptionId,
  resumeId,
  rate,
  jdApplicationStatus,
  queryString,
  size,
} = {}) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const page = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));

  const { isLoading, data, error } = useQuery({
    queryKey: [
      "ApplicationSearch",
      jobDescriptionId,
      resumeId,
      rate,
      jdApplicationStatus,
      page,
      size,
    ],
    queryFn: () =>
      applicationSearch({
        jobDescriptionId,
        resumeId,
        rate,
        jdApplicationStatus,
        page,
        size,
      }),
    retry: false,
  });

  // Pre-fetching
  const pageCount = Math.ceil(data?.totalCount / size);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: [
        "ApplicationSearch",
        jobDescriptionId,
        resumeId,
        rate,
        jdApplicationStatus,
        page + 1,
        size,
      ],
      queryFn: () =>
        applicationSearch({
          jobDescriptionId,
          resumeId,
          rate,
          jdApplicationStatus,
          page: page + 1,
          size,
        }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: [
        "ApplicationSearch",
        jobDescriptionId,
        resumeId,
        rate,
        jdApplicationStatus,
        page - 1,
        size,
      ],
      queryFn: () =>
        applicationSearch({
          jobDescriptionId,
          resumeId,
          rate,
          jdApplicationStatus,
          page: page - 1,
          size,
        }),
    });

  return { isLoading, data, error };
};
