import PropTypes from "prop-types";

function PresentionChartIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M13.7666 39.667H42.2099C46.6432 39.667 48.9766 37.3337 48.9766 32.9003V4.66699H6.97656V32.9003C6.9999 37.3337 9.33324 39.667 13.7666 39.667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 4.66699H51.3334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 51.3337L28.0001 46.667V39.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3333 51.3337L28 46.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 25.667L24.85 19.5303C25.4333 19.0403 26.2033 19.1803 26.6 19.8337L29.4 24.5003C29.7967 25.1537 30.5667 25.2703 31.15 24.8037L38.5 18.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PresentionChartIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default PresentionChartIcon;
