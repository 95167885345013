import PropTypes from "prop-types";

function PersonalCardIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M39.6668 49H16.3334C7.00008 49 4.66675 46.6667 4.66675 37.3333V18.6667C4.66675 9.33333 7.00008 7 16.3334 7H39.6668C49.0001 7 51.3334 9.33333 51.3334 18.6667V37.3333C51.3334 46.6667 49.0001 49 39.6668 49Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6667 18.667H44.3334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35 28H44.3333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M39.6667 37.333H44.3334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8334 26.3432C22.1659 26.3432 24.0568 24.4523 24.0568 22.1198C24.0568 19.7873 22.1659 17.8965 19.8334 17.8965C17.501 17.8965 15.6101 19.7873 15.6101 22.1198C15.6101 24.4523 17.501 26.3432 19.8334 26.3432Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0001 38.1031C27.6734 34.7198 24.9901 32.0598 21.6067 31.7564C20.4401 31.6398 19.2501 31.6398 18.0601 31.7564C14.6767 32.0831 11.9934 34.7198 11.6667 38.1031"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PersonalCardIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default PersonalCardIcon;
