import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { confirmEmail as confirmEmailApi } from "../services/apiAuth";

export function useConfirmEmail() {
  const {
    error,
    mutate: confirmEmail,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: ({ token, email }) => confirmEmailApi({ token, email }),
    onSuccess: () => {
      toast.success(
        "Congratulations! Your email has been successfully confirmed.",
      );
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });
  return { error, confirmEmail, isPending, isSuccess, isError };
}
