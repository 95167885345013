import PropTypes from "prop-types";

function PeopleIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M42 16.707C41.86 16.6837 41.6967 16.6837 41.5567 16.707C38.3367 16.5903 35.77 13.9537 35.77 10.687C35.77 7.35033 38.4533 4.66699 41.79 4.66699C45.1267 4.66699 47.81 7.37366 47.81 10.687C47.7867 13.9537 45.22 16.5903 42 16.707Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5967 33.694C42.7934 34.2307 46.3167 33.6707 48.7901 32.014C52.0801 29.8207 52.0801 26.2274 48.7901 24.034C46.2934 22.3774 42.7234 21.8174 39.5267 22.3774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.93 16.707C14.07 16.6837 14.2333 16.6837 14.3733 16.707C17.5933 16.5903 20.16 13.9537 20.16 10.687C20.16 7.35033 17.4767 4.66699 14.14 4.66699C10.8033 4.66699 8.12 7.37366 8.12 10.687C8.14333 13.9537 10.71 16.5903 13.93 16.707Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3333 33.694C13.1366 34.2307 9.61332 33.6707 7.13999 32.014C3.84999 29.8207 3.84999 26.2274 7.13999 24.034C9.63665 22.3774 13.2066 21.8174 16.4033 22.3774"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 34.1367C27.86 34.1134 27.6967 34.1134 27.5567 34.1367C24.3367 34.02 21.77 31.3834 21.77 28.1167C21.77 24.78 24.4533 22.0967 27.79 22.0967C31.1267 22.0967 33.81 24.8034 33.81 28.1167C33.7867 31.3834 31.22 34.0434 28 34.1367Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2101 41.4868C17.9201 43.6802 17.9201 47.2735 21.2101 49.4668C24.9434 51.9635 31.0567 51.9635 34.7901 49.4668C38.0801 47.2735 38.0801 43.6802 34.7901 41.4868C31.0801 39.0135 24.9434 39.0135 21.2101 41.4868Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PeopleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default PeopleIcon;
