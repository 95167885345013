import { useQuery } from "@tanstack/react-query";
import { searchCountries } from "../services/apiInformation";

export function useSearchCountries({ name } = {}) {
  const { isLoading, data, error } = useQuery({
    enabled: !!name,
    queryKey: ["searchCountries", name],
    queryFn: () => searchCountries({ name }),
    retry: false,
  });

  return { isLoading, data, error };
}
