import { useFormContext } from "react-hook-form";
import HardSkills from "./skills-step-sections/HardSkills";
import SoftSkills from "./skills-step-sections/SoftSkills";
import Languages from "./skills-step-sections/Languages";
import Certifications from "./skills-step-sections/Certifications";
import { PropTypes } from "prop-types";

function SkillsStep({ hideClearSection }) {
  const { watch, getValues, setValue, control, resetField } = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
        4. What Skills do you bring to the table ?
      </div>
      <div className="mt-4 h-full">
        <div className="flex  flex-col gap-8">
          <HardSkills
            hideClearSection={true}
            watch={watch}
            control={control}
            resetField={resetField}
            setValue={setValue}
            getValues={getValues}
          />
          <SoftSkills
            hideClearSection={true}
            watch={watch}
            control={control}
            resetField={resetField}
            setValue={setValue}
            getValues={getValues}
          />

          <Languages
            hideClearSection={true}
            watch={watch}
            control={control}
            resetField={resetField}
            setValue={setValue}
            getValues={getValues}
          />
          <Certifications
            hideClearSection={true}
            watch={watch}
            control={control}
            resetField={resetField}
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </div>
    </div>
  );
}

SkillsStep.propTypes = {
  hideClearSection: PropTypes.bool,
};
SkillsStep.defaultProps = {
  hideClearSection: false,
};

export default SkillsStep;
