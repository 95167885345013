import { Link } from "react-router-dom";
import { useIsAuthenticated } from "../../hooks/useIsAuthenticated";
import FolderOpenIcon from "../../icons/FolderOpenIcon";
import PeopleIcon from "../../icons/PeopleIcon";
import DocumentFilterIcon from "../../icons/DocumentFilterIcon";
import ClipboardCloseIcon from "../../icons/ClipboardCloseIcon";
import TimerIcon from "../../icons/TimerIcon";
import WalletIcon from "../../icons/WalletIcon";
import DocumentFavoriteIcon from "../../icons/DocumentFavoriteIcon";
import EmojiSadIcon from "../../icons/EmojiSadIcon";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { HiOutlineClock } from "react-icons/hi2";

ChartJS.register(
  Tooltip,
  Legend,
  ArcElement,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
);

function RecruiterDashboard() {
  const { redirectionUrl, roles } = useIsAuthenticated();

  const jobByDepartementChartData = {
    labels: [
      "Departement 1",
      "Departement 2",
      "Departement 3",
      "Departement 4",
      "Departement 5",
      "Departement 6",
    ],
    datasets: [
      {
        label: "# hired in last 2 months",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "#3359dc",
          "#2ca79f",
          "#153070",
          "#972cf1",
          "#f7b60a",
          "#b00b43",
        ],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const genderRatioChartData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "# hired in last 2 months",
        data: [650, 450],
        backgroundColor: ["#3359dc", "#972cf1"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const offreAcceptanceRatioChartData = {
    datasets: [
      {
        data: [68, 100 - 68],
        backgroundColor: ["#3359dc", "#e4edfb"],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const salaryChartData = {
    labels: [
      "Frontend",
      "Backend",
      "Devops",
      "Finance",
      "HRs",
      "Admins",
      "Designers",
    ],
    datasets: [
      {
        data: [1200, 900, 600, 1200, 1100, 200, 1400],
        backgroundColor: [
          "#3359dc",
          "#2ca79f",
          "#153070",
          "#972cf1",
          "#b00b43",
          "#f7b60a",
          "#eb730b",
        ],
        borderColor: ["#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="page-px py-8">
      <div className="flex items-center">
        <h1 className="text-[2.5rem] font-medium">Dashboard</h1>
        <div className="flex flex-1 justify-end">
          {roles?.includes("recruiter") ? (
            <Link
              to={`${redirectionUrl}/add-job`}
              className="btn-blue-b-primary rounded-md font-primary text-base font-medium"
            >
              Create New Job
            </Link>
          ) : null}
        </div>
      </div>

      <div className="my-16">
        <div className="-m-4 flex flex-wrap">
          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Total projects</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <FolderOpenIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  100
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Total number of candidates
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <PeopleIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  1000
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Applications being processed
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <DocumentFilterIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  800
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Rejected candidates</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <ClipboardCloseIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  50
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Hiring time</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <TimerIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  30 days
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Cost per hire</h1>
              <div className="flex items-center space-x-4">
                <div>
                  <WalletIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  2000$
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Candidates satisfaction score
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <DocumentFavoriteIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  4.5/5
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-4 transition-all duration-300 sm:w-1/2 lg:w-1/4">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Candidates withdraw rate
              </h1>
              <div className="flex items-center space-x-4">
                <div>
                  <EmojiSadIcon className="h-7 w-7 text-blue-b-primary" />
                </div>
                <div className="flex-1 text-center text-2xl font-medium">
                  15 %
                </div>
              </div>
            </div>
          </div>

          <div className="w-3/5 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Job by departments</h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={jobByDepartementChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-lg font-medium">150 hired</div>
                    <div className="text-xs">in last 2 months</div>
                  </div>
                </div>

                <div className="text-center text-lg">
                  {jobByDepartementChartData?.labels?.map((label, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-20 text-center font-medium">
                        {jobByDepartementChartData?.datasets[0]?.data[index]}
                      </div>
                      <div
                        className="h-2.5 w-2.5 rounded-full"
                        style={{
                          backgroundColor:
                            jobByDepartementChartData?.datasets[0]
                              ?.backgroundColor[index],
                        }}
                      ></div>
                      <div>{label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-2/5 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Gender ratio</h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={genderRatioChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-lg font-medium">Total</div>
                    <div className="text-[1.75rem] font-medium">1000</div>
                  </div>
                </div>

                <div className="text-center text-lg">
                  {genderRatioChartData?.labels?.map((label, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-20 text-center font-medium">
                        {genderRatioChartData?.datasets[0]?.data[index]}
                      </div>
                      <div
                        className="h-2.5 w-2.5 rounded-full"
                        style={{
                          backgroundColor:
                            genderRatioChartData?.datasets[0]?.backgroundColor[
                              index
                            ],
                        }}
                      ></div>
                      <div>{label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-2/6 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Offre acceptance ratio</h1>
              <div className="flex items-center justify-around space-x-4">
                <div className="relative max-w-56">
                  <Doughnut
                    data={offreAcceptanceRatioChartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: { enabled: false },
                      },
                      cutout: "80%",
                    }}
                  />
                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="text-[1.75rem] font-medium">68</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">
                Expected salary per job
              </h1>
              <div>
                <Bar
                  data={salaryChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    datasets: {
                      bar: {
                        barThickness: 25,
                      },
                    },
                    scales: {
                      x: {
                        beginAtZero: true,
                        grid: { display: false },
                        ticks: {
                          minRotation: 45,
                          maxRotation: 45,
                        },
                      },
                      y: {
                        grid: { display: false },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-1/4 p-4 transition-all duration-300">
            <div className="bg-[#F7F9FB] p-4">
              <h1 className="mb-4 text-xl font-bold">Upcoming events</h1>
              <div className="-m-1.5 flex flex-col">
                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-1.5">
                  <div className="bg-blue-b-50 p-2 shadow-md">
                    <div className="flex items-center space-x-3">
                      <div className="h-9 w-9 overflow-hidden rounded-full">
                        <img
                          className="h-full w-full object-cover object-center"
                          src="/uploads/user1.png"
                        />
                      </div>

                      <div>
                        <div className="text-sm font-semibold">
                          Salim Sinapera
                        </div>
                        <div className="text-xs text-black-g-75">
                          Product Designer
                        </div>
                        <div className="text-xs font-medium text-blue-b-primary">
                          Technical test
                        </div>
                      </div>

                      <div className="flex items-center space-x-1 text-black-g-100">
                        <HiOutlineClock className="h-3.5 w-3.5" />
                        <div className="text-xs">15:30-16:15</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruiterDashboard;
