import React from "react";
import PropTypes from "prop-types";

function CustomButton({
  title,
  btnStyle,
  onClick,
  icon,
  type,
  disabled,
  isLoading,
  loadingLoader,
}) {
  return (
    <button
      className={btnStyle}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && (
        <div
          className={`flex h-full items-center justify-center ${title && "pr-1"}`}
        >
          {icon}
        </div>
      )}
      <div className="flex items-center">
        {title}
        {isLoading && loadingLoader}
      </div>
    </button>
  );
}

CustomButton.propTypes = {
  title: PropTypes.string,
  btnStyle: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CustomButton.defaultProps = {
  title: null,
  btnStyle: "btn-blue-b-primary",
  onClick: null,
  type: "button",
  disabled: false,
  isLoading: false,
};

export default CustomButton;
