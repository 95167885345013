import PropTypes from "prop-types";

function ClipboardCloseIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M16.3329 19.7284C16.1112 19.7284 15.8896 19.6467 15.7146 19.4717L11.0946 14.8517C10.7562 14.5134 10.7562 13.9534 11.0946 13.6151C11.4329 13.2767 11.9929 13.2767 12.3312 13.6151L16.9512 18.2351C17.2896 18.5734 17.2896 19.1334 16.9512 19.4717C16.7762 19.6351 16.5546 19.7284 16.3329 19.7284Z" />
      <path d="M11.667 19.7753C11.4453 19.7753 11.2237 19.6936 11.0487 19.5186C10.7103 19.1803 10.7103 18.6203 11.0487 18.282L15.6687 13.662C16.007 13.3236 16.567 13.3236 16.9053 13.662C17.2437 14.0003 17.2437 14.5603 16.9053 14.8986L12.2853 19.5186C12.1103 19.6936 11.8887 19.7753 11.667 19.7753Z" />
      <path d="M16.333 7.87467H11.6663C10.5463 7.87467 8.45801 7.87467 8.45801 4.66634C8.45801 1.45801 10.5463 1.45801 11.6663 1.45801H16.333C17.453 1.45801 19.5413 1.45801 19.5413 4.66634C19.5413 5.78634 19.5413 7.87467 16.333 7.87467ZM11.6663 3.20801C10.5113 3.20801 10.208 3.20801 10.208 4.66634C10.208 6.12467 10.5113 6.12467 11.6663 6.12467H16.333C17.7913 6.12467 17.7913 5.82134 17.7913 4.66634C17.7913 3.20801 17.488 3.20801 16.333 3.20801H11.6663Z" />
      <path d="M17.5 26.5412H10.5C3.94333 26.5412 2.625 23.5312 2.625 18.6662V11.6662C2.625 6.34618 4.55 4.07118 9.28667 3.82618C9.77667 3.80285 10.185 4.16451 10.2083 4.65451C10.2317 5.14451 9.85833 5.54118 9.38 5.56451C6.06667 5.75118 4.375 6.74285 4.375 11.6662V18.6662C4.375 22.9828 5.22667 24.7912 10.5 24.7912H17.5C22.7733 24.7912 23.625 22.9828 23.625 18.6662V11.6662C23.625 6.74285 21.9333 5.75118 18.62 5.56451C18.1417 5.54118 17.7683 5.12118 17.7917 4.64285C17.815 4.16451 18.235 3.79118 18.7133 3.81451C23.45 4.07118 25.375 6.34618 25.375 11.6545V18.6545C25.375 23.5312 24.0567 26.5412 17.5 26.5412Z" />
    </svg>
  );
}

ClipboardCloseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ClipboardCloseIcon;
