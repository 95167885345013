import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { HiMiniChevronDown } from "react-icons/hi2";

function DropdownField({
  items,
  onSelect,
  controllerName,
  control,
  rules,
  id,
  className,
  maxHeight = "14rem",
  defaultValue,
  placeholder,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const initialAnimationClassname = "scale-x-0 scale-y-0 opacity-0";

  const [animationClassName, setAnimationClassName] = useState(
    initialAnimationClassname,
  );
  const dropdownRef = useOutsideClick(() => setIsOpen(false), true);

  useEffect(() => {
    if (isOpen) {
      setAnimationClassName("transform-none opacity-100");
    } else {
      setAnimationClassName(initialAnimationClassname);
    }
  }, [isOpen]);
  const toggleDropdown = (event) => {
    event.stopPropagation(); // Prevent the event from propagating up to document click
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleItemClick = (item, event) => {
    toggleDropdown(event);
    onSelect(item); // Invoke the callback with the selected item
  };

  const displayDefaultValue = (fieldData, defaultValue) => {
    try {
      const searchValue = defaultValue
        ? parseInt(defaultValue)
        : parseInt(fieldData);

      // Search for matching item in the 'items' list
      const res =
        items.length > 0 && items?.find((obj) => obj?.id === searchValue);

      if (res && res !== undefined) {
        return res.name; // Return name if a matching item is found
      } else {
        return (
          <label className="font-primary  text-sm font-light text-black-g-75">
            {placeholder || "Select an option"}
          </label>
        ); // Return default message if no match found
      }
    } catch (error) {
      console.error("Error parsing value:", error);
      // Handle parsing error (optional: return a specific error message or default value)
      return "Invalid input"; // Example error message
    }
  };
  return (
    <Controller
      name={controllerName}
      control={control}
      rules={rules}
      render={({
        field: { value: fieldData, onChange },
        fieldState: { error },
      }) => {
        return (
          <>
            <div
              id={id}
              className={`${className} relative  ${
                error && !fieldData && "border border-red-o-100"
              }`}
              ref={dropdownRef}
              onClick={toggleDropdown}
            >
              <div className="flex w-full items-center">
                <div
                  className={`flex-1 p-1.5 text-start ${error && !fieldData ? "text-red-o-primary" : "text-black-g-primary"}`}
                >
                  {displayDefaultValue(fieldData, defaultValue)}
                </div>
                <div className="p-1">
                  <HiMiniChevronDown
                    className={`h-4 w-4 ${isOpen ? "-rotate-180" : ""} origin-center transition-all duration-200`}
                  />
                </div>
              </div>
              {isOpen && (
                <ul
                  className={`absolute left-0 top-full z-50 my-4 min-w-full origin-top overflow-hidden overflow-y-auto rounded bg-white shadow-dropdown-shadow transition-all duration-200 ${animationClassName}`}
                  style={{ maxHeight: maxHeight, width: "max-content" }}
                >
                  {items && items?.length > 0 ? (
                    items?.map((item, index) => (
                      <li
                        key={index}
                        className={`text-primary-300 flex cursor-pointer items-center gap-1 font-primary text-base font-normal ${item?.id === parseInt(fieldData) ? "bg-blue-b-100 text-white" : ""} p-4 transition-all duration-200 hover:bg-blue-b-primary hover:text-white`}
                        onClick={(event) => {
                          handleItemClick?.(item, event);
                          onChange?.(item.id);
                        }}
                      >
                        {item?.name}
                      </li>
                    ))
                  ) : (
                    <div className="p-2 text-center" onClick={toggleDropdown}>
                      No data available
                    </div>
                  )}
                </ul>
              )}
            </div>
            {error && !fieldData ? (
              <p className="mt-2 text-sm text-red-o-primary">
                {error?.message}
              </p>
            ) : null}
          </>
        );
      }}
    />
  );
}

DropdownField.propTypes = {
  id: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
    PropTypes.shape({}),
  ]),
  onSelect: PropTypes.func.isRequired,
  controllerName: PropTypes.string,
  rules: PropTypes.any,
  control: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  maxHeight: PropTypes.string,
  className: PropTypes.string,
};
DropdownField.defaultProps = {
  id: "",
  items: [],
  onSelect: () => {},
  controllerName: null,
  rules: null,
  control: {},
  defaultValue: null,
  placeholder: null,
};

export default DropdownField;
