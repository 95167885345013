import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { HiMiniChevronDown } from "react-icons/hi2";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import Loader from "../loader/Loader";
import CustomCheckbox from "../checkbox/CustomCheckbox";

function Dropdown({
  className,
  labelClassName = "p-2",
  icon,
  label,
  maxHeight = "14rem",
  optionsConfig,
  isLoading,
  onSelect,
  defaulLabel = "",
  isMultiple = false,
  defaultSelected = null,
  hoverItemColor = "hover:bg-blue-b-primary",
  selectedItemBg = "bg-blue-b-100",
}) {
  const { options = [], idKey, nameKey } = optionsConfig || {};

  const [isOpen, setIsOpen] = useState(false);
  const initialAnimationClassname = "scale-x-0 scale-y-0 opacity-0";
  const [animationClassName, setAnimationClassName] = useState(
    initialAnimationClassname,
  );

  const dropdownRef = useOutsideClick(() => setIsOpen(false), true);

  useEffect(
    function () {
      if (isOpen) {
        setAnimationClassName("transform-none opacity-100");
      } else {
        setAnimationClassName(initialAnimationClassname);
      }
    },
    [isOpen],
  );

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [labelValue, setLabelValue] = useState(
    defaulLabel ? defaulLabel : label,
  );

  const handleOptionClick = (option) => {
    if (isMultiple) {
      const isSelected = selectedOptions.includes(option);

      const updatedOptions = isSelected
        ? selectedOptions.filter((selected) => selected !== option)
        : [...selectedOptions, option];

      setSelectedOptions(updatedOptions);
      onSelect(updatedOptions);
    } else {
      setSelectedOptions([option]);
      onSelect(option);
      setLabelValue(option[nameKey]);
      setIsOpen(false);
    }
  };

  useEffect(
    function () {
      if (!isLoading) setLabelValue(defaulLabel ? defaulLabel : label);
      if (defaultSelected !== null) {
        const obj = options?.find(
          (item) => Number(item.value) === defaultSelected,
        );
        setSelectedOptions([obj]);
        onSelect(obj);
        setLabelValue(obj[nameKey]);
      }
    },
    [isLoading, defaulLabel, label, defaultSelected],
  );

  return (
    <div
      className={className}
      style={{ position: "relative" }}
      ref={dropdownRef}
    >
      <div
        className="flex w-full items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {icon && <div className="p-1 text-slate-500">{icon}</div>}

        <button type="button" className={`${labelClassName} flex-1 text-start`}>
          {labelValue}
        </button>

        <div className="p-1">
          <HiMiniChevronDown
            className={`h-4 w-4 ${isOpen ? "-rotate-180" : ""} origin-center transition-all duration-200`}
          />
        </div>
      </div>

      {isOpen && options?.length > 0 && (
        <ul
          className={`absolute left-0 top-full z-50 my-4 min-w-full origin-top overflow-hidden overflow-y-auto rounded bg-white shadow transition-all duration-200 ${animationClassName}`}
          style={{ maxHeight: maxHeight, width: "max-content" }}
        >
          {isLoading ? (
            <div className="p-4">
              <Loader />
            </div>
          ) : (
            options?.map((option) => (
              <li
                key={option[idKey]}
                className={`flex cursor-pointer items-center gap-1 ${selectedOptions.includes(option) ? `${selectedItemBg} text-white` : ""} p-4 transition-all duration-200 ${hoverItemColor} hover:text-white`}
                onClick={() => handleOptionClick(option)}
              >
                {isMultiple ? (
                  <CustomCheckbox
                    inputId={option[nameKey]}
                    isChecked={selectedOptions.includes(option)}
                  />
                ) : null}

                <span className="text-primary-300 font-primary text-base font-normal">
                  {option[nameKey]}
                </span>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node,
  maxHeight: PropTypes.string,
  optionsConfig: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
    idKey: PropTypes.string.isRequired,
    nameKey: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  defaulLabel: PropTypes.any,
  isMultiple: PropTypes.bool,
  defaultSelected: PropTypes.number,
  hoverItemColor: PropTypes.string,
  selectedItemBg: PropTypes.string,
};

export default Dropdown;
