import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { sendMessage as sendMessageApi } from "../services/apiContact";

export function useContact() {
  const {
    mutate: sendMessage,
    isPending,
    isSuccess: isMessageSent,
  } = useMutation({
    mutationFn: ({ name, email, subject, phone, message }) =>
      sendMessageApi({ name, email, subject, phone, message }),
    onSuccess: () => {
      toast.success(
        `Thank you for submitting your message! We'll get back to you as soon as possible.`,
      );
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { sendMessage, isPending, isMessageSent };
}
