/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import PropTypes from "prop-types";
import Divider from "../../../shared/Divider";
import ControlledDropdown from "../../../shared/dropdown/ControlledDropdown";
import { useFieldArray } from "react-hook-form";
import { TiDelete } from "react-icons/ti";
import { useGetLanguages } from "../../../hooks/useGetLanguages";
import Slider from "@mui/material/Slider";
import { RiAddCircleFill } from "react-icons/ri";
import {
  LanguageLevelEnum,
  languageLevelArray,
} from "../../../utils/constants";

function Languages({
  watch,
  control,
  resetField,
  setValue,
  getValues,
  hideClearSection,
}) {
  const { data: languages } = useGetLanguages();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages",
  });

  const { languageId, level, languageName } = watch();

  const [isToggleAdd, setIsToggleAdd] = useState(false);
  // console.log(watch());

  return (
    <div className="flex w-full ">
      <div className="flex w-full flex-col">
        <div className="flex  items-center">
          <div className="font-primary text-xl font-bold text-black-g-500">
            Language
          </div>
          <Divider className="mx-4 flex-1" />
        </div>
        <div className="flex w-4/5 flex-col gap-4 py-4">
          {fields?.map((field, index) => (
            <div className="flex items-center gap-4" key={field.id}>
              <div className="flex-1 rounded border px-4 py-2">
                {field?.name}
              </div>
              <div className="w-1/3 rounded border px-4 py-2">
                {LanguageLevelEnum[field.level]}
              </div>
              <TiDelete
                size={24}
                className="cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              />
            </div>
          ))}
          <div
            className="btn-outline-blueB flex w-fit items-center gap-1 rounded-[4px] px-2 text-sm font-medium"
            onClick={() => {
              setIsToggleAdd(true);
            }}
          >
            <RiAddCircleFill size={18} />
            Add language
          </div>

          {isToggleAdd ? (
            <div className="flex items-center gap-4">
              <ControlledDropdown
                controllerName={`languageId`}
                id={`languageId`}
                control={control}
                items={languages}
                defaultValue={getValues(`languageId`)}
                placeholder={"Select language"}
                onSelect={(data) => setValue(`languageName`, data?.name)}
                className="rounded-md border border-black-g-50 px-2 py-1 font-primary text-sm font-medium"
              />
              <Slider
                // value={watch("salaryRange")}
                onChange={(event, newValue) => setValue("level", newValue)}
                marks={languageLevelArray}
                shiftStep={1}
                step={1}
                valueLabelDisplay="off"
                sx={{
                  color: "#0054FF",
                  height: "8px",
                  marginBottom: "0px",
                  marginX: "14px",
                  paddingY: "0px",
                  flex: "1",
                }}
                min={1}
                max={4}
              />
              <button
                className="btn-blue-b-primary flex w-[90px] items-center justify-center rounded-md py-2 font-primary text-base font-medium "
                disabled={!languageId}
                onClick={() => {
                  append({
                    languageId: languageId,
                    name: languageName,
                    level: !level ? 1 : level,
                  });
                  setIsToggleAdd(false);
                  setValue("level", null);
                  resetField("languageId");
                }}
              >
                Add
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {!hideClearSection ? (
        <div className="ml-4 flex w-5/12 flex-col">
          <div className="flex items-center">
            <div className="font-primary text-base font-light text-blue-b-primary">
              Clear section
            </div>
            <Divider className="ml-4 flex-1" />
          </div>
          <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
            Here, you'll highlight your proficiency in various spoken languages
            that directly align with the job requirements.
          </div>
        </div>
      ) : null}
    </div>
  );
}

Languages.propTypes = {
  watch: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  hideClearSection: PropTypes.bool,
};

Languages.defaultProps = {
  hideClearSection: false,
};
export default Languages;
