import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { saveResumeTalentPool as saveResumeTalentPoolApi } from "../services/apiResume";

export function useSaveResumeTalentPool() {
  const {
    mutate: saveResumeTalentPool,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: (resumeId) => saveResumeTalentPoolApi(resumeId),
    onSuccess: () => {
      toast.success(`The candidate is successfully saved in the Talent Pool.`);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { saveResumeTalentPool, isPending, isSuccess };
}
