import PropTypes from "prop-types";

function ErrorPage({ children }) {
  return (
    <section>
      <div className="page-px bg-[#F7F9FB] py-16">
        <div className="flex justify-center">
          <div className="m-auto w-full max-w-xl bg-white px-8 py-20 shadow-md shadow-slate-200">
            <img className="m-auto w-72 max-w-full" src="/assets/error.svg" />

            <div className="mt-12 text-center">
              {children ? (
                children
              ) : (
                <>
                  <h1 className="text-[2rem] font-medium leading-10">
                    Oops! Something went wrong.
                  </h1>
                  <p className="text-black-g-100">
                    We&apos;re sorry, but an unexpected error has occurred.
                    Please try again later. If the problem persists, contact
                    support for assistance.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ErrorPage.propTypes = {
  children: PropTypes.node,
};

export default ErrorPage;
