/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsPersonVcard } from "react-icons/bs";
import { FaUser } from "react-icons/fa6";
import { IoSchoolOutline, IoStarOutline } from "react-icons/io5";
import { MdWorkOutline } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserResume } from "../hooks/useUserResume";
import FormStepper from "../shared/stepper/FormStepper";
import { transformSentSocialPlatforms } from "../utils/helpers";
import { useUpdateUserResume } from "./../hooks/useUpdateUserResume";

function CreateProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [stepid, setStepid] = useState(1);

  const { updateUserResume } = useUpdateUserResume();
  const { data: userResume } = useUserResume();

  const parsedResumeData = location?.state?.parsedResumeData;

  const formObj = useForm({
    values: {
      step: stepid,
      id: parsedResumeData?.id || userResume?.id,
      firstName: parsedResumeData?.firstName || userResume?.firstName,
      lastName: parsedResumeData?.lastName || userResume?.lastName,
      name: `${parsedResumeData?.firstName || userResume?.firstName} ${parsedResumeData?.lastName || userResume?.lastName}`,
      jobTitle: parsedResumeData?.jobTitle || userResume?.jobTitle,
      description:
        parsedResumeData?.description || userResume?.description || "",
      birthDate: parsedResumeData?.birthDate || userResume?.birthDate || "",
      contactInfo: {
        email:
          parsedResumeData?.contactInfo?.email ||
          userResume?.contactInfo?.email ||
          "",
        phone:
          parsedResumeData?.contactInfo?.phone ||
          userResume?.contactInfo?.phone ||
          "",
        address: {
          address:
            parsedResumeData?.contactInfo?.address?.address ||
            userResume?.contactInfo?.address?.address ||
            "",
          address2:
            parsedResumeData?.contactInfo?.address?.address2 ||
            userResume?.contactInfo?.address?.address2 ||
            "",
          cityId:
            parsedResumeData?.contactInfo?.address?.city?.id ||
            userResume?.contactInfo?.address?.city?.id ||
            0,
          cityName:
            parsedResumeData?.contactInfo?.address?.city?.name ||
            userResume?.contactInfo?.address?.city?.name ||
            "",
          postalCode:
            parsedResumeData?.contactInfo?.address?.postalCode ||
            userResume?.contactInfo?.address?.postalCode ||
            "",
          countryId:
            parsedResumeData?.contactInfo?.address?.country?.id ||
            userResume?.contactInfo?.address?.country?.id ||
            0,

          countryName:
            parsedResumeData?.contactInfo?.address?.country?.name ||
            userResume?.contactInfo?.address?.country?.name ||
            "",
        },
        socialPlatforms:
          parsedResumeData?.contactInfo?.socialPlatforms ||
          userResume?.contactInfo?.socialPlatforms ||
          [],
      },
      educations: parsedResumeData?.educations || userResume?.educations || [],
      experiences:
        parsedResumeData?.experiences || userResume?.experiences || [],
      languages: parsedResumeData?.languages || userResume?.languages || [],
      softSkills:
        parsedResumeData?.softSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            softSkillProficiencyLevel: skill.softSkillProficiency,
          };
        }) ||
        userResume?.softSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            softSkillProficiencyLevel: skill.softSkillProficiency,
          };
        }) ||
        [],
      hardSkills:
        parsedResumeData?.hardSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            hardSkillProficiencyLevel: skill.hardSkillProficiency,
          };
        }) ||
        userResume?.hardSkills.map((skill) => {
          return {
            ...skill,
            skillId: skill.id,
            hardSkillProficiencyLevel: skill.hardSkillProficiency,
          };
        }) ||
        [],
      preferredWorkLocation:
        parsedResumeData?.preferredWorkLocation ||
        userResume?.preferredWorkLocation,
      experienceLevel:
        parsedResumeData?.experienceLevel || userResume?.experienceLevel,
      preferredEmploymentType:
        parsedResumeData?.preferredEmploymentType ||
        userResume?.preferredEmploymentType ||
        0,
      preferredContractType:
        parsedResumeData?.preferredContractType ||
        userResume?.preferredContractType ||
        0,
      educationLevel:
        parsedResumeData?.educationLevel || userResume?.educationLevel || 0,
      certifications:
        parsedResumeData?.certifications || userResume?.certifications || [],
      industryId:
        parsedResumeData?.industry?.id || userResume?.industry?.id || 0,
      yearsOfExperience:
        parsedResumeData?.yearsOfExperience ||
        userResume?.yearsOfExperience ||
        0,
      disponibility:
        parsedResumeData?.disponibility || userResume?.disponibility || 0,
      salaryRange: [
        parsedResumeData?.rate?.min || userResume?.rate?.min,
        parsedResumeData?.rate?.max || userResume?.rate?.max,
      ],
      tjmRange: [
        parsedResumeData?.rate?.min || userResume?.rate?.min,
        parsedResumeData?.rate?.max || userResume?.rate?.max,
      ],
      rate: {
        currencyId:
          parsedResumeData?.rate?.currency?.id ||
          userResume?.rate?.currency?.id ||
          0,
        rateType: 1,
      },
    },
  });

  useEffect(() => {
    // Extract stepid from pathname, if no id exists, return 1
    if (location?.pathname && location.pathname.includes("step=")) {
      const stepId = location.pathname.split("=")[1];
      if (stepId !== undefined && stepId !== "") {
        setStepid(Number(stepId));
      } else {
        navigate("step=1", { replace: true }); // Redirect to step 1
      }
    } else {
      navigate("step=1", { replace: true }); // Redirect to step 1 if path doesn't contain step information
    }
  }, [location.pathname]);

  const stepperSteps = [
    { name: "Personal information", icon: <FaUser size={20} /> },
    { name: "Contacts", icon: <BsPersonVcard size={20} /> },
    { name: "Professional profiles", icon: <TfiWorld size={20} /> },
    { name: "Skills", icon: <IoStarOutline size={20} /> },
    { name: "Work experience", icon: <MdWorkOutline size={20} /> },
    { name: "Education", icon: <IoSchoolOutline size={20} /> },
    // { name: "Recommendations", icon: <FaUser /> },
  ];

  const onSubmit = (data) => {
    const socials = transformSentSocialPlatforms(
      data.contactInfo.socialPlatforms,
    );

    const contactInfo = {
      ...data.contactInfo,
      socialPlatforms: socials?.filter((item) => !!item.url),
    };

    const experiences = data?.experiences?.map((experience) => ({
      ...experience,
      hardSkills: experience?.hardSkills?.map((item) => item.id),
      softSkills: experience?.softSkills?.map((item) => item.id),
    }));

    const rate = {
      ...data.rate,
      min: data?.salaryRange[0],
      max: data?.salaryRange[1],
    };

    updateUserResume(
      { ...data, contactInfo, experiences, rate, step: stepid },
      {
        onSuccess: () =>
          stepid != stepperSteps?.length
            ? navigate(`/add-profile/step=${stepid + 1}`)
            : navigate(`/candidate/myresume/${data?.id}`),
      },
    );
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div>
          <FormStepper
            steps={stepperSteps}
            currentStep={stepid}
            showStepLabel={true}
            className={
              "justify-center gap-6 rounded-br-md rounded-tr-md p-16 shadow-vertical-stepper"
            }
            isVertical
          />
        </div>

        <div className="flex-1 p-16">
          <div>
            <FormProvider {...formObj}>
              <Outlet />
            </FormProvider>
          </div>

          <div className="-m-3 mt-8 flex items-center justify-center">
            <div className="p-3">
              <Link
                className="btn-white rounded-md px-16 font-primary text-base font-medium"
                to={stepid != 1 ? `/add-profile/step=${stepid - 1}` : null}
                onClick={(e) => {
                  if (stepid === 1) {
                    e.preventDefault();
                  }
                }}
              >
                Back
              </Link>
            </div>

            <div className="p-3">
              <Link
                onClick={() => formObj.handleSubmit(onSubmit)()}
                className="btn-blue-b-primary rounded-md px-16 font-primary text-base font-medium"
                // to={`/add-profile/step=${stepid + 1}`}
              >
                <button type="submit">
                  {stepid !== stepperSteps?.length ? "Next" : "Save"}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* 
        
           <SliderInfo
            currentStep={stepid}
            totalSteps={stepperSteps.length}
            formObj={formObj}
            user={user}
            {...formObj}
          /> 
      
      </div> */}
    </>
  );
}

export default CreateProfile;
