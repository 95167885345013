import { useQuery } from "@tanstack/react-query";

import { getUserResumeById } from "../services/apiResume";
import { useParams } from "react-router-dom";

export function useGetUserResumeById() {
  const { id } = useParams();

  const { isLoading, data, error } = useQuery({
    queryKey: ["userResumeById", id],
    queryFn: () => getUserResumeById(id),
    retry: false,
  });

  return { isLoading, data, error };
}

export function useGetCandidateResumeById({ previewId }) {
  const { isLoading, data, error, isPending } = useQuery({
    queryKey: ["CandidateResumeById", previewId],
    queryFn: () => getUserResumeById(previewId),
    retry: false,
  });

  return { isLoading, data, error, isPending };
}
