import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./shared/ErrorBoundaryFallback.jsx";
import GoogleTagManager from "./components/GoogleTagManager.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => window.location.replace("/")}
    >
      <GoogleTagManager />
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
