import PropTypes from "prop-types";

function SparklesIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <g clipPath="url(#clip0_1526_19507)">
        <path d="M9.91626 23.3368L6.60993 16.723L-0.00390625 13.4167L6.60993 10.1103L9.91626 3.49649L13.2226 10.1103L19.8364 13.4167L13.2226 16.723L9.91626 23.3368ZM2.60476 13.4167L7.47909 15.8538L9.91626 20.7282L12.3534 15.8538L17.2278 13.4167L12.3534 10.9795L9.91626 6.10516L7.47909 10.9795L2.60476 13.4167ZM21.5829 27.9872L19.4526 23.7277L15.1931 21.5973L19.4526 19.4682L21.5829 15.2075L23.7133 19.4682L27.9728 21.5973L23.7133 23.7277L21.5829 27.9872ZM17.8018 21.5973L20.3229 22.8585L21.5829 25.3797L22.8429 22.8585L25.3641 21.5973L22.8429 20.3373L21.5829 17.8162L20.3229 20.3373L17.8018 21.5973ZM22.7648 10.5175L21.0183 7.02449L17.5264 5.27916L21.0183 3.53266L22.7648 0.0419922L24.5113 3.53499L28.0031 5.28149L24.5113 7.02683L22.7648 10.5198V10.5175ZM20.1351 5.27916L21.8886 6.15533L22.7648 7.90766L23.6409 6.15533L25.3944 5.27916L23.6409 4.40299L22.7648 2.65066L21.8886 4.40299L20.1351 5.27916Z" />
      </g>
      <defs>
        <clipPath id="clip0_1526_19507">
          <rect width="28" height="28" />
        </clipPath>
      </defs>
    </svg>
  );
}

SparklesIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default SparklesIcon;
