import axios from "axios";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

//export const getCandidates = async (page, size) => {
//const response = await axios.get(
//`${BASE_URL}/GetAllCondidates?page=${page}&size=${size}`,
//);

// console.log(response)
//return response.data;
//};

export const getSessionsByUserId = async (userId) => {
  const response = await axios.get(
    `${BASE_URL}/GetSessionsByUser?userId=${userId}`,
  );
  //console.log(response.data);

  return response.data;
};

export const sendMessageFile = async (file, senderId) => {
  try {
    const formData = new FormData();
    formData.append("file", file); // Only append the file

    const response = await axios.post(
      `${BASE_URL}/SendFileMessage?senderId=${senderId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error; // Throw error to be handled by the calling function
  }
};
//export const getMessages = async (
// currentUserId,
//selectedCandidateId,
// page,
//size,
//) => {
//const response = await axios.get(
//`${BASE_URL}/GetAllMessages?senderId=${currentUserId}&receiverId=${selectedCandidateId}&page=${page}&size=${size}`,
//);
//console.log(response);

// return response.data;
//};
export const SendMessage = async (sender, receiver, content, type) => {
  try {
    const body = {
      senderId: sender,
      receiverId: receiver,
      content: content,
      type: 1,
    };

    const response = await axios.post(`${BASE_URL}/RecruterSendMessage`, body, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
  }
};
