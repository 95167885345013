import { useQuery } from "@tanstack/react-query";
import { applicationCheck } from "../services/apiApplication";

export function useApplicationCheck(jobdescriptionId) {
  const { isLoading, data, error } = useQuery({
    queryKey: ["application_check", jobdescriptionId],
    queryFn: () => applicationCheck(jobdescriptionId),
    enabled: !!(jobdescriptionId > 0), //The query will not execute until countryID > 0
    retry: false,
  });

  return { isLoading, data, error };
}
