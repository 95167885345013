import PropTypes from "prop-types";

function DocumentFavoriteIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M17.4997 26.5413H10.4997C4.16467 26.5413 1.45801 23.8347 1.45801 17.4997V10.4997C1.45801 4.16467 4.16467 1.45801 10.4997 1.45801H16.333C16.8113 1.45801 17.208 1.85467 17.208 2.33301C17.208 2.81134 16.8113 3.20801 16.333 3.20801H10.4997C5.12134 3.20801 3.20801 5.12134 3.20801 10.4997V17.4997C3.20801 22.878 5.12134 24.7913 10.4997 24.7913H17.4997C22.878 24.7913 24.7913 22.878 24.7913 17.4997V11.6663C24.7913 11.188 25.188 10.7913 25.6663 10.7913C26.1447 10.7913 26.5413 11.188 26.5413 11.6663V17.4997C26.5413 23.8347 23.8347 26.5413 17.4997 26.5413Z" />
      <path d="M25.6663 12.5416H20.9997C17.0097 12.5416 15.458 10.9899 15.458 6.99991V2.33324C15.458 1.98324 15.668 1.65657 15.9947 1.52824C16.3213 1.38824 16.6947 1.46991 16.9513 1.71491L26.2847 11.0482C26.5297 11.2932 26.6113 11.6782 26.4713 12.0049C26.3313 12.3316 26.0163 12.5416 25.6663 12.5416ZM17.208 4.44491V6.99991C17.208 10.0099 17.9897 10.7916 20.9997 10.7916H23.5547L17.208 4.44491Z" />
      <path d="M10.9899 22.1086C9.97493 22.1086 7.61826 20.872 6.89493 18.6086C6.39326 17.0453 6.9766 15.0036 8.78493 14.4203C9.54326 14.1753 10.3483 14.2803 10.9899 14.677C11.6199 14.2803 12.4366 14.1753 13.2066 14.4203C15.0149 15.0036 15.5983 17.0453 15.0966 18.6086C14.3616 20.9186 11.8883 22.1086 10.9899 22.1086ZM8.5516 18.0836C9.0416 19.612 10.6983 20.3353 11.0016 20.3586C11.3283 20.3236 12.9616 19.5186 13.4166 18.0836C13.6616 17.3136 13.4166 16.3336 12.6583 16.0886C12.3433 15.9836 11.9116 16.042 11.7133 16.3453C11.5499 16.5903 11.2933 16.7303 11.0016 16.742C10.6866 16.742 10.4416 16.6136 10.2783 16.3803C10.0333 16.042 9.61326 15.9953 9.30993 16.1003C8.56326 16.3336 8.3066 17.3136 8.5516 18.0836Z" />
    </svg>
  );
}

DocumentFavoriteIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default DocumentFavoriteIcon;
