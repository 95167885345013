import { useQuery } from "@tanstack/react-query";
import { getSkills } from "../services/apiSkills";

export function useGetSkills({ keyword, type, page, size }) {
  const { isLoading, data, error } = useQuery({
    queryKey: ["skills", keyword, type, page, size],
    queryFn: () => (keyword ? getSkills({ keyword, type, page, size }) : {}),
    retry: false,
  });

  return { isLoading, data, error };
}
