import PropTypes from "prop-types";
import CustomCard from "../../../shared/cards/CustomCard";
import Divider from "../../../shared/Divider";
import CustomImage from "../../../shared/images/CustomImage";
import { IoEllipseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useApplicationSearch } from "../../../hooks/useApplicationSearch";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  WorkLocationEnum,
} from "../../../utils/constants";
function CandidateCards({ redirectionUrl, jobId }) {
  const JOB_POSTS_PER_PAGE = 5;
  const QUERY_STRING = "jobsingle_application_search";
  const { data } = useApplicationSearch({
    queryString: QUERY_STRING,
    jobDescriptionId: jobId,
    jdApplicationStatus: 1,
    page: 1,
    size: JOB_POSTS_PER_PAGE,
  });

  const { results, totalCount } = data || {};
  return (
    <>
      {totalCount > 0 ? (
        <div className={`flex flex-col`}>
          {results?.map((item) => (
            <div
              key={item.id}
              className={`mb-4 w-full transition delay-150 duration-300 ease-in-out`} // Add padding-left to the last card in each row
            >
              <CustomCard
                CardHeader={
                  <div className="flex items-center gap-1">
                    <div className="flex flex-1 items-center gap-1">
                      <CustomImage
                        imgClassName={"image-layout w-16 h-16"}
                        imgSrc={
                          item?.resume?.candidate?.profilePic ||
                          "https://placehold.co/200x200"
                        }
                      />
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <Link
                            className="title-primary-300-sm w-[158px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold sm:w-full"
                            to={`/recruiter/candidate/${item?.resume?.id}`}
                          >
                            {item?.resume?.candidate?.firstName
                              ? item?.resume?.candidate?.firstName +
                                " " +
                                item?.resume?.candidate?.lastName
                              : "No Title"}
                          </Link>
                        </div>

                        <div className="flex items-center gap-1">
                          <Link
                            className="subtitle-blackG-100-xs font-normal"
                            // to={`${redirectionUrl}/profile`}
                          >
                            {item?.jobDescription
                              ? item?.jobDescription?.title
                              : "N/A"}
                          </Link>

                          <IoEllipseSharp size={8} />
                          <span className="subtitle-blackG-100-xs font-normal">
                            {item?.resume?.candidate?.contact?.address?.country
                              ? item?.resume?.candidate?.contact?.address
                                  ?.country?.name
                              : "No country"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center rounded bg-blue-b-50 p-2.5 font-primary text-xs font-normal text-blue-b-primary">
                      <span>Match</span>
                      <span className="text-base font-bold">93%</span>
                    </div>
                  </div>
                }
                CardContent={
                  <div className="flex flex-1 flex-col gap-4">
                    <div className="flex items-center gap-1">
                      <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                        {ExperienceLevelEnum[
                          item?.jobDescription?.experienceLevel
                        ] ?? ExperienceLevelEnum[0]}
                      </span>
                      <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                        {WorkLocationEnum[item?.jobDescription?.workLocation] ??
                          WorkLocationEnum[0]}
                      </span>
                      <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                        {EmploymentTypeEnum[
                          item?.jobDescription?.employmentType
                        ] ?? EmploymentTypeEnum[0]}
                      </span>
                    </div>
                    <div className="h-100  font-primary text-sm  font-normal text-black-g-100">
                      {item?.message
                        ? item?.message?.length > 175
                          ? `${item?.message?.substring(0, 175)}...`
                          : item?.message
                        : "No message available"}
                    </div>
                  </div>
                }
                CardFooter={
                  <div className="flex flex-col">
                    <Divider className="my-4" />

                    <div className="flex items-center justify-between">
                      <div
                        className={`flex items-center gap-1 rounded bg-green-g-50 px-2 py-1 `}
                      >
                        <CustomImage
                          imgClassName={"image-layout w-7 h-7"}
                          imgSrc={
                            item?.jobDescription?.company?.logo ||
                            "https://placehold.co/200x200"
                          }
                        />
                        <span className="font-primary text-sm font-medium text-green-g-500">
                          {item?.jobDescription?.company
                            ? item?.jobDescription?.company?.name
                            : "No company info"}
                        </span>
                      </div>

                      <div className="flex items-center gap-1">
                        <Link
                          to={`/recruiter/candidate/${item?.resume?.id}`}
                          className="btn-outline-blueB flex items-center rounded-[4px] px-5 py-2 text-sm font-medium"
                        >
                          Details
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          ))}

          <Link
            to={`${redirectionUrl}/applicants/${jobId}`}
            className="btn-outline-blueB flex items-center justify-center rounded-[4px] p-3 font-medium uppercase"
          >
            See all candidates
          </Link>
        </div>
      ) : null}
    </>
  );
}

CandidateCards.propTypes = {
  redirectionUrl: PropTypes.string,
  roles: PropTypes.array,
  jobId: PropTypes.number,
};

export default CandidateCards;
