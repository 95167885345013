import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { logout as logoutApi } from "../services/apiAuth";

export function useLogout({ goToRoute } = {}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const goTo = goToRoute === undefined ? "/login" : goToRoute;

  const { mutate: logout, isPending } = useMutation({
    mutationFn: logoutApi,
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["isAuthenticated"] });
      queryClient.resetQueries({ queryKey: ["user"] });
      navigate(goTo, { replace: true });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return { logout, isPending };
}
