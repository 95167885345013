import PropTypes from "prop-types";

function BrushIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M50.8899 9.19305C47.2965 18.153 38.2899 30.333 30.7532 36.3764L26.1565 40.063C25.5732 40.483 24.9899 40.8564 24.3365 41.113C24.3365 40.693 24.3132 40.2264 24.2432 39.783C23.9866 37.823 23.0999 36.003 21.5365 34.4397C19.9499 32.853 18.0132 31.9197 16.0299 31.663C15.5632 31.6397 15.0965 31.593 14.6299 31.6397C14.8865 30.9164 15.2832 30.2397 15.7732 29.6797L19.4132 25.083C25.4332 17.5464 37.6599 8.49305 46.5965 4.92305C47.9732 4.40971 49.3032 4.78305 50.1432 5.64638C51.0299 6.50971 51.4499 7.83971 50.8899 9.19305Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3367 41.1129C24.3367 43.6795 23.3567 46.1295 21.5133 47.9962C20.09 49.4195 18.1533 50.3995 15.8433 50.7029L10.1033 51.3329C6.97665 51.6829 4.29332 49.0229 4.66665 45.8495L5.29665 40.1095C5.85665 34.9995 10.1267 31.7329 14.6533 31.6395C15.12 31.6162 15.61 31.6395 16.0533 31.6629C18.0367 31.9195 19.9733 32.8295 21.56 34.4395C23.1233 36.0029 24.01 37.8229 24.2667 39.7829C24.29 40.2262 24.3367 40.6695 24.3367 41.1129Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2266 33.7632C33.2266 27.6732 28.2799 22.7266 22.1899 22.7266"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

BrushIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default BrushIcon;
