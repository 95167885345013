import PropTypes from "prop-types";

function BookIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M51.3334 39.0596V10.8963C51.3334 8.09629 49.0468 6.01963 46.2701 6.25296H46.1301C41.2301 6.67296 33.7867 9.16963 29.6334 11.783L29.2367 12.0396C28.5601 12.4596 27.4401 12.4596 26.7634 12.0396L26.1801 11.6896C22.0267 9.09963 14.6067 6.62629 9.70675 6.22963C6.93008 5.99629 4.66675 8.09629 4.66675 10.873V39.0596C4.66675 41.2996 6.48675 43.3996 8.72675 43.6796L9.40342 43.773C14.4667 44.4496 22.2834 47.0163 26.7634 49.4663L26.8568 49.513C27.4868 49.863 28.4901 49.863 29.0968 49.513C33.5768 47.0396 41.4168 44.4496 46.5034 43.773L47.2734 43.6796C49.5134 43.3996 51.3334 41.2996 51.3334 39.0596Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 12.8096V47.8096"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0833 19.8096H12.8333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8333 26.8096H12.8333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

BookIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default BookIcon;
