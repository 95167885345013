/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { HiMiniChevronDown } from "react-icons/hi2";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import CustomEditor from "../inputs/CustomEditor";

const CollapsableEditorContent = ({
  title,
  value,
  children,
  isChildrenShown = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    if (isChildrenShown) {
      setIsExpanded(!isExpanded);
    }
  }, [isChildrenShown]);

  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const containerRef = useOutsideClick(() => setIsExpanded(false), true);

  return (
    <div
      ref={containerRef}
      className={`flex flex-col gap-3 rounded-[10px] border border-black-g-50 bg-[#F7F9FB] p-5 `}
    >
      <div
        className="flex w-full cursor-pointer items-center"
        onClick={toggleExpansion}
      >
        <div className="flex-1 font-primary text-lg font-semibold text-blue-b-primary">
          {title}
        </div>
        <div className="p-1">
          <HiMiniChevronDown
            className={`h-4 w-4 ${isExpanded ? "-rotate-180" : ""} origin-center transition-all duration-200`}
          />
        </div>
      </div>
      {value && !isExpanded ? (
        <div className="font-primary text-sm font-normal text-black-g-primary">
          <CustomEditor
            editorClassName={`input-transparent h-auto  p-0 bg-transprent border-transparent `}
            readOnly
            toolbarHidden
            htmlDefaultValue={value}
          />
        </div>
      ) : null}
      {isExpanded ? <div>{children}</div> : null}
    </div>
  );
};

CollapsableEditorContent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  isChildrenShown: PropTypes.bool,
};

export default CollapsableEditorContent;
