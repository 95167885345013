import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";

const AccordionContext = createContext();
const ItemContext = createContext();

function Accordion({ children }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleIndex = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <AccordionContext.Provider value={{ openIndex, toggleIndex }}>
      {children}
    </AccordionContext.Provider>
  );
}

function Item({ children, className, index }) {
  return (
    <ItemContext.Provider value={{ index }}>
      <div className={className}>{children}</div>
    </ItemContext.Provider>
  );
}

function Header({ children, className }) {
  const { index } = useContext(ItemContext);
  const { openIndex, toggleIndex } = useContext(AccordionContext);

  return (
    <div className={className} onClick={() => toggleIndex(index)}>
      <div className="cursor-pointer overflow-hidden">
        <div className="-m-2 flex items-center">
          <div className="flex-1 p-2">{children}</div>
          <div className="p-2">
            {openIndex === index ? (
              <HiChevronUp className="h-5 w-5 stroke-[3] text-blue-b-primary" />
            ) : (
              <HiChevronDown className="h-5 w-5 stroke-[3] text-blue-b-primary" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Panel({ children, className }) {
  const { index } = useContext(ItemContext);
  const { openIndex } = useContext(AccordionContext);

  const panelContentRef = useRef(null);
  const [panelContentHeight, setPanelContentHeight] = useState(0);

  useEffect(
    function () {
      setPanelContentHeight(
        openIndex === index
          ? parseFloat(panelContentRef.current.scrollHeight) + "px"
          : 0,
      );
    },
    [openIndex, index],
  );

  return (
    <div
      ref={panelContentRef}
      className="overflow-hidden transition-all duration-200"
      style={{
        maxHeight: panelContentHeight,
        opacity: openIndex === index ? 1 : 0,
      }}
    >
      <div className={className}>{children}</div>
    </div>
  );
}

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Panel = Panel;

Accordion.propTypes = {
  children: PropTypes.node,
};

Item.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Accordion;
