import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { HiChevronUpDown, HiMiniXCircle } from "react-icons/hi2";
import Loader from "../loader/Loader";
import { useOutsideClick } from "../../hooks/useOutsideClick";

function SelectBox({
  labelClassName = "font-primary font-semibold cursor-pointer py-3 text-base text-[#060F26]",
  containerBaseClassName = "rounded-md border-2",
  containerClassName = "bg-[#F7F9FB] border-transparent",
  fieldClassName = "text-start input-transparent",
  gap = 1,
  column = true,
  label,
  placeholder,
  id,
  controllerName,
  control,
  rules,
  defaultValue,
  children,
  disabled,
  resetField,
  isLoading,
  options,
  onClick,
}) {
  const buttonRef = useRef();
  const listRef = useOutsideClick(() => setShowList(false), false);

  const [showList, setShowList] = useState(false);

  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ""}
      render={({
        field: { value, onChange },
        fieldState: { invalid, isDirty, error },
      }) => {
        return (
          <div
            className={`${column === true ? "flex-col" : ""} flex`}
            style={{ margin: `${-parseFloat(gap) / 4}rem` }}
          >
            {label && (
              <div style={{ padding: `${parseFloat(gap) / 4}rem` }}>
                <label
                  className={`${labelClassName} ${!column ? "flex h-full w-full" : ""}`}
                  htmlFor={id}
                >
                  {label}
                </label>
              </div>
            )}

            <div
              className="flex-1"
              style={{ padding: `${parseFloat(gap) / 4}rem` }}
            >
              <div
                className={`relative ${containerBaseClassName} transition-all duration-200 ${
                  !invalid
                    ? `${containerClassName} focus-within:border-black-g-primary`
                    : `border-red-o-100 bg-red-o-50 focus-within:border-red-o-100`
                }`}
                ref={listRef}
              >
                <div className="relative -m-0.5 flex items-center">
                  <div className="relative flex-1 p-0.5">
                    <button
                      ref={buttonRef}
                      type="button"
                      id={id}
                      className={fieldClassName}
                      onClick={() => setShowList((show) => !show)}
                      disabled={isLoading || disabled}
                    >
                      <div className="flex items-center">
                        <div className="flex-1">
                          {options?.data?.find((item) => {
                            return item?.[options?.value] === value;
                          })?.[options?.name] || placeholder}
                        </div>
                        {options?.data?.filter((item) => {
                          return item?.[options?.value] !== value;
                        })?.length > 0 && (
                          <HiChevronUpDown className="h-6 w-6" />
                        )}
                      </div>
                    </button>
                    {children}
                  </div>

                  {(isLoading || ((isDirty || invalid) && value)) && (
                    <div className="absolute right-11 top-1/2 -translate-y-1/2 p-0.5">
                      <div className="-m-1.5 flex items-center">
                        {isLoading && (
                          <div className="p-1.5">
                            <Loader />
                          </div>
                        )}

                        {(isDirty || invalid) && value && (
                          <div className="p-1.5">
                            <div
                              className="cursor-pointer overflow-hidden p-0.5 transition-all duration-200"
                              onClick={() => {
                                resetField?.(controllerName || id);
                              }}
                            >
                              <HiMiniXCircle
                                className={`h-6 w-6 text-black-g-primary`}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {options?.data?.filter((item) => {
                  return item?.[options?.value] !== value;
                })?.length > 0 &&
                  showList &&
                  !isLoading && (
                    <ul className="absolute left-0 top-full z-[1000] -mx-0.5 max-h-60 min-w-full overflow-auto rounded-md border border-black-g-50 bg-white shadow-xl">
                      {options?.data
                        ?.filter((item) => {
                          return item?.[options?.value] !== value;
                        })
                        .map((item) => (
                          <li
                            key={item?.[options?.key]}
                            className={
                              "cursor-pointer whitespace-nowrap px-5 py-3 hover:bg-blue-b-50 hover:text-blue-b-primary"
                            }
                            onClick={() => {
                              onChange(item?.[options?.value]);
                              setShowList(false);
                              onClick?.(item?.[options?.value]);
                            }}
                          >
                            {item?.[options?.name]}
                          </li>
                        ))}
                    </ul>
                  )}
              </div>

              {invalid && error?.message && (
                <p className="mt-2 text-sm text-red-o-primary">
                  {error?.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

SelectBox.propTypes = {
  labelClassName: PropTypes.string,
  containerBaseClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  gap: PropTypes.number,
  column: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  resetField: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
};

export default SelectBox;
