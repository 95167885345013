import PropTypes from "prop-types";

function CurrencyIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 13"
      fill="currentColor"
    >
      <path d="M16.0319 4.67884L14.1309 4.20058V0.722317C14.1309 0.548404 14.0121 0.417969 13.8537 0.417969H0.546759C0.388343 0.417969 0.269531 0.548404 0.269531 0.722317V8.5484C0.269531 8.72232 0.388343 8.85275 0.546759 8.85275H1.69527V8.89623C1.69527 8.98319 1.69527 9.07014 1.73488 9.11362C1.77448 9.20058 1.85369 9.24405 1.89329 9.24405L2.05171 9.28753L14.4477 12.418H14.4874C14.6062 12.418 14.725 12.331 14.7646 12.2006L15.9923 6.28753L16.2695 5.02666C16.2695 4.93971 16.2695 4.85275 16.2299 4.80927C16.1903 4.76579 16.1111 4.67884 16.0319 4.67884ZM9.02201 9.24405C9.41805 9.24405 9.77448 9.11362 10.1309 8.89623C10.1705 8.89623 10.1705 8.85275 10.1705 8.85275H13.6953L13.5765 9.50492C13.5369 9.50492 13.4973 9.50492 13.4576 9.50492C12.9032 9.50492 12.3883 9.80927 12.1507 10.331C12.1111 10.418 12.0715 10.5049 12.0715 10.5484L5.33884 8.85275H7.91309C7.9527 8.89623 8.03191 8.93971 8.07151 8.93971C8.11111 8.98319 8.15072 8.98319 8.19032 9.02666C8.30913 9.11362 8.46755 9.1571 8.58636 9.20058C8.74478 9.24406 8.90319 9.24405 9.02201 9.24405ZM8.70517 8.85275H9.37844C9.14082 8.89623 8.9428 8.89623 8.70517 8.85275ZM0.823987 8.24406V1.02666H13.5765V8.24406H0.823987ZM14.2893 11.7658L2.80418 8.85275H4.54676C4.54676 8.89623 4.58636 8.93971 4.62597 8.98319L12.0715 10.8528C12.0715 10.8528 12.0715 10.8528 12.1111 10.8528C12.1507 10.8528 12.2299 10.8093 12.2299 10.7658C12.3883 10.1571 12.8636 9.76579 13.418 9.76579C13.4973 9.76579 13.5765 9.76579 13.6557 9.76579C13.7349 9.76579 13.7745 9.72232 13.8141 9.67884L14.0121 8.80927L14.0517 8.76579C14.0913 8.72232 14.1309 8.63536 14.1309 8.5484V8.1571L14.4081 6.80927C14.4081 6.72232 14.3685 6.67884 14.3289 6.63536C14.2497 6.63536 14.2101 6.59188 14.1309 6.5484V4.76579L15.6755 5.1571L14.2893 11.7658Z" />
      <path d="M12.5067 2.93971C11.8334 2.89623 11.3185 2.28753 11.2789 1.5484C11.2789 1.46145 11.1997 1.41797 11.1601 1.41797H3.23933C3.16012 1.41797 3.12051 1.46145 3.12051 1.5484C3.04131 2.28753 2.52645 2.89623 1.85319 2.93971C1.77398 2.93971 1.73438 3.02666 1.73438 3.07014V6.20058C1.73438 6.28753 1.77398 6.33101 1.85319 6.33101C2.52645 6.37449 3.04131 6.98319 3.08091 7.72232C3.08091 7.80927 3.16012 7.85275 3.19972 7.85275H11.1205C11.1997 7.85275 11.2393 7.80927 11.2393 7.72232C11.2789 6.98319 11.7938 6.37449 12.467 6.33101C12.5463 6.33101 12.5859 6.24406 12.5859 6.20058V3.07014C12.6255 2.98319 12.5859 2.93971 12.5067 2.93971ZM12.3878 6.07014C11.675 6.1571 11.1601 6.80927 11.0413 7.59188H3.35814C3.27893 6.80927 2.72447 6.20058 2.0116 6.07014V3.20058C2.72447 3.11362 3.23933 2.46145 3.35814 1.67884H11.0413C11.1205 2.46145 11.675 3.07014 12.3878 3.20058V6.07014Z" />
      <path d="M7.27924 2.2002C6.09112 2.2002 5.14062 3.28715 5.14062 4.63498C5.14062 5.9828 6.09112 7.06976 7.27924 7.06976C8.46736 7.06976 9.41785 5.9828 9.41785 4.63498C9.41785 3.28715 8.42775 2.2002 7.27924 2.2002ZM7.27924 6.67846C6.28914 6.67846 5.49706 5.76541 5.49706 4.63498C5.49706 3.50454 6.28914 2.5915 7.27924 2.5915C8.26934 2.5915 9.06142 3.50454 9.06142 4.63498C9.06142 5.76541 8.26934 6.67846 7.27924 6.67846Z" />
    </svg>
  );
}

CurrencyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default CurrencyIcon;
