import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import EmojiHappy from "../icons/EmojiHappy";
import EmojiSad from "../icons/EmojiSad";
import EmojiSatisfied from "../icons/EmojiSatisfied";
import RatingIcon from "../icons/RatingIcon";

function RatingEmoji({
  items = [
    { id: 1, icon: EmojiHappy, value: "A" },
    { id: 2, icon: EmojiSatisfied, value: "B" },
    { id: 3, icon: EmojiSad, value: "C" },
  ],
  onRate,
  defaultSelected = null,
}) {
  const [showIcons, setShowIcons] = useState(false);
  const initialAnimation = "w-0 scale-x-0 px-0 opacity-0";
  const [animation, setAnimation] = useState(initialAnimation);
  const [selectedItem, setSelectedItem] = useState(defaultSelected || null);

  useEffect(
    function () {
      if (showIcons) {
        setAnimation("pl-16 pr-4");
      } else setAnimation(initialAnimation);
    },
    [showIcons],
  );

  const setupDefaultIcon = () => {
    const selected = items.find((item) => item.id === selectedItem);
    return selected ? (
      <selected.icon className="h-6 w-6 stroke-[1.5]" />
    ) : (
      <RatingIcon className="h-6 w-6 stroke-[1.5]" />
    );
  };

  return (
    <div className="relative flex items-center rounded-full">
      <div
        className="absolute left-0 top-1/2 z-10 -translate-y-1/2 cursor-pointer rounded-full border border-blue-b-75 bg-blue-b-50 p-2.5 shadow-md transition-all duration-200 hover:shadow-none"
        onClick={() => {
          setShowIcons((show) => !show);
        }}
      >
        {setupDefaultIcon()}
      </div>

      {showIcons && (
        <div
          className={`z-0 origin-left rounded-full bg-blue-b-75 py-1 ${animation} transition-all duration-200`}
        >
          <div className="flex items-center space-x-2">
            {items?.map((item) => (
              <div
                key={item?.id}
                className="group cursor-pointer"
                onClick={() => {
                  setSelectedItem((prevSelected) =>
                    prevSelected === item?.id ? null : item?.id,
                  );
                  onRate?.(item?.id);
                  setShowIcons(false);
                }}
              >
                <div
                  className={`h-5 w-5 group-hover:-translate-y-1/2 group-hover:scale-110 ${selectedItem === item?.id ? "-translate-y-1/2 scale-110" : ""}`}
                >
                  <item.icon />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

RatingEmoji.propTypes = {
  items: PropTypes.array,
  onRate: PropTypes.func,
  defaultSelected: PropTypes.number,
};

export default RatingEmoji;
