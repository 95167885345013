import PropTypes from "prop-types";

function Divider({ className = "my-8", children }) {
  return (
    <div className={`${className} overflow-hidden`}>
      {children ? (
        <div className="-m-2 flex items-center">
          <div className="flex-1 p-2">
            <div className="bg-black-g-50 h-px w-full"></div>
          </div>

          <div className="p-2">{children}</div>

          <div className="flex-1 p-2">
            <div className="bg-black-g-50 h-px w-full"></div>
          </div>
        </div>
      ) : (
        <div className="bg-black-g-50 h-px w-full"></div>
      )}
    </div>
  );
}

Divider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Divider;
