import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const CONTACT_URL = REQUEST_URL.contact;

export async function sendMessage({ name, email, subject, phone, message }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + CONTACT_URL, {
    method: "POST",
    body: JSON.stringify({
      name,
      email,
      subject,
      phone,
      message,
    }),
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok)
    throw new Error("Failed to send message, please try again later.");
  return response;
}
