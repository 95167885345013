import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { forgotPassword as forgotPasswordApi } from "../services/apiAuth";

export function useForgotPassword() {
  const {
    mutate: forgotPassword,
    isPending,
    isSuccess: isLinkSent,
  } = useMutation({
    mutationFn: ({ email }) => forgotPasswordApi({ email }),
    onSuccess: () => {
      toast.success(
        `We've sent you an email with a link to reset your password.`,
      );
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  return { forgotPassword, isPending, isLinkSent };
}
