import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getJobDescriptionById } from "../services/apiJobDescription";

export function useJobDescriptionSingle() {
  const { id, jobId } = useParams();
  const param = id || jobId; // Choose the non-null parameter
  const { isLoading, data, error } = useQuery({
    queryKey: ["jobDescriptionSingle", param],
    enabled: !!param,
    queryFn: () => getJobDescriptionById({ id: param }),
    retry: false,
  });

  return { isLoading, data, error };
}
