import PropTypes from "prop-types";

const CustomCard = ({
  CardHeader,
  CardContent,
  CardFooter,
  cardWrapperClassName,
}) => {
  return (
    <div className={cardWrapperClassName}>
      {CardHeader && CardHeader}
      {CardContent}
      {CardFooter && CardFooter}
    </div>
  );
};

CustomCard.propTypes = {
  CardHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  CardFooter: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  CardContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  cardWrapperClassName: PropTypes.string,
};
CustomCard.defaultProps = {
  cardWrapperClassName: "card-layout",
};

export default CustomCard;
