import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { HiMiniChevronDown } from "react-icons/hi2";
import CustomCheckbox from "../checkbox/CustomCheckbox";

function MultipleDropdownField({
  items,
  controllerName,
  control,
  rules,
  id,
  className,
  maxHeight = "14rem",
  defaultValue,
  placeholder,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(defaultValue || []);
  const initialAnimationClassname = "scale-x-0 scale-y-0 opacity-0";
  const [animationClassName, setAnimationClassName] = useState(
    initialAnimationClassname,
  );
  const dropdownRef = useOutsideClick(() => setIsOpen(false), true);

  useEffect(() => {
    if (isOpen) {
      setAnimationClassName("transform-none opacity-100");
    } else {
      setAnimationClassName(initialAnimationClassname);
    }
  }, [isOpen]);

  const toggleDropdown = (event) => {
    event.stopPropagation(); // Prevent the event from propagating up to document click
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleItemClick = (item, event, onChange) => {
    event.stopPropagation();
    const isSelected = selectedItems.includes(item.id);

    const updatedOptions = isSelected
      ? selectedItems.filter((selected) => selected !== item.id)
      : [...selectedItems, item.id];

    setSelectedItems(updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      rules={rules}
      render={({
        field: { value: fieldData, onChange, onBlur },
        fieldState: { error },
      }) => {
        return (
          <>
            <div
              ref={dropdownRef}
              id={id}
              className={`${className} relative  ${
                error && "border border-red-o-100"
              }`}
              onClick={toggleDropdown}
            >
              <div className="flex w-full items-center">
                <div
                  className={`flex-1 p-1 text-start ${error && "text-red-o-primary"}`}
                >
                  <label className="font-primary text-sm font-light text-black-g-75">
                    {(selectedItems?.length > 0 &&
                      selectedItems?.length + " options selected") ||
                      placeholder}
                  </label>
                </div>
                <div className="p-1.5">
                  <HiMiniChevronDown
                    className={`h-4 w-4 ${isOpen ? "-rotate-180" : ""} origin-center transition-all duration-200`}
                  />
                </div>
              </div>
              {isOpen && (
                <ul
                  className={`absolute left-0 top-full z-50 my-4 min-w-full origin-top overflow-hidden overflow-y-auto rounded bg-white shadow-dropdown-shadow transition-all duration-200 ${animationClassName}`}
                  style={{ maxHeight: maxHeight, width: "max-content" }}
                >
                  {items && items.length > 0 ? (
                    items?.map((item, index) => (
                      <li
                        key={index}
                        className={`text-primary-300 flex cursor-pointer items-center gap-1 font-primary text-base font-normal ${selectedItems?.includes(item.id) ? "bg-blue-b-100 text-white" : "ok"} p-4 transition-all duration-200 hover:bg-blue-b-primary hover:text-white`}
                        onClick={(event) => {
                          handleItemClick(item, event, onChange);
                        }}
                      >
                        <CustomCheckbox
                          inputId={`checkbox_${item?.id}`}
                          isChecked={selectedItems?.includes(item.id)}
                        />

                        <span className="text-primary-300 font-primary text-base font-normal">
                          {item?.name}
                        </span>
                      </li>
                    ))
                  ) : (
                    <div className="p-2 text-center" onClick={toggleDropdown}>
                      No data available
                    </div>
                  )}
                </ul>
              )}
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-o-primary">
                {error?.message}
              </p>
            )}
          </>
        );
      }}
    />
  );
}

MultipleDropdownField.propTypes = {
  id: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
    PropTypes.shape({}),
  ]),
  controllerName: PropTypes.string,
  rules: PropTypes.any,
  control: PropTypes.any,
  placeholder: PropTypes.string,
};
MultipleDropdownField.defaultProps = {
  id: "",
  items: [],
  controllerName: null,
  rules: null,
  control: {},
  placeholder: "Select an option",
};

export default MultipleDropdownField;
