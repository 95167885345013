import PropTypes from "prop-types";
import CustomCard from "../../../shared/cards/CustomCard";
import Divider from "../../../shared/Divider";
import CustomImage from "../../../shared/images/CustomImage";
import { IoEllipseSharp } from "react-icons/io5";
import { useSearchJobDescription } from "../../../hooks/useSearchJobDescription";
import { Link } from "react-router-dom";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  WorkLocationEnum,
} from "../../../utils/constants";
import { FaRegClock } from "react-icons/fa";

function JobsCards({ redirectionUrl, roles }) {
  const JOB_POSTS_PER_PAGE = 4;
  const QUERY_STRING = "job_description_search_page";

  const { data } = useSearchJobDescription({
    queryString: QUERY_STRING,
    size: JOB_POSTS_PER_PAGE,
    keyword: "",
    employmentType: 0,
  });
  const { results } = data || {};

  return (
    <div className={`flex flex-col`}>
      {results?.map((item) => (
        <div
          key={item.id}
          className={`mb-4 w-full transition delay-150 duration-300 ease-in-out`} // Add padding-left to the last card in each row
        >
          <CustomCard
            CardHeader={
              <div className="flex items-center gap-1">
                <div className="flex flex-1 items-center gap-2">
                  <CustomImage
                    imgClassName={"image-layout w-16 h-16"}
                    imgSrc={
                      item?.company?.logo || "https://placehold.co/200x200"
                    }
                  />
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <span className="title-primary-300-sm font-semibold">
                        {item?.title}
                      </span>
                    </div>

                    <div className="flex items-center gap-1">
                      <span className="subtitle-blackG-100-xs font-normal">
                        {item?.company ? item?.company?.name : "N/A"}
                      </span>

                      {roles?.includes("recruiter") ? (
                        <>
                          <IoEllipseSharp size={8} />
                          <Link
                            to={`${redirectionUrl}/applicants/${item.id}`}
                            className="subtitle-blackG-100-xs font-normal"
                          >
                            {item?.numberOfApplications + " " + "applicants"}
                          </Link>
                        </>
                      ) : null}

                      <IoEllipseSharp size={8} />
                      <span className="subtitle-blackG-100-xs font-normal">
                        {item?.company?.location
                          ? item.company?.location?.address?.country?.name
                          : "No country"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex h-10 gap-1">
                  <Link
                    to={`${redirectionUrl}/job-detail/${item.id}`}
                    className="btn-outline-blueB flex items-center rounded-[4px] px-3 text-sm font-medium"
                  >
                    Details
                  </Link>
                </div>
              </div>
            }
            CardContent={
              <div className="flex flex-1 flex-col  gap-4 ">
                <div className="flex items-center gap-1">
                  <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                    {ExperienceLevelEnum[item?.experienceLevel]}
                  </span>
                  <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                    {WorkLocationEnum[item?.workLocation]}
                  </span>
                  <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                    {EmploymentTypeEnum[item?.employmentType]}
                  </span>
                </div>
                <div className="h-100  font-primary text-sm  font-normal text-black-g-100">
                  {item?.mainMission && item?.mainMission?.raw
                    ? item?.mainMission?.raw?.length > 175
                      ? `${item?.mainMission?.raw?.substring(0, 175)}...`
                      : item?.mainMission?.raw
                    : "No headline available"}
                </div>
              </div>
            }
            CardFooter={
              <div className="flex flex-col">
                <Divider className="mb-4 mt-1" />

                <div className="flex items-center justify-between">
                  {item?.rate ? (
                    <span
                      className={`text-primary-300 flex items-center gap-1 font-primary text-base font-medium`}
                    >
                      {item?.rate?.min} - {item?.rate?.max}{" "}
                      <span className="text-xs font-medium text-black-g-100">
                        {item?.rate?.currency?.symbol}
                      </span>
                    </span>
                  ) : null}

                  <div className="flex items-center gap-1">
                    <FaRegClock color="#0054FF" />
                    <span className="title-primary-300-sm font-normal">
                      {item?.postedDate}
                    </span>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ))}

      <Link
        to={`${redirectionUrl}/jobs`}
        className="btn-outline-blueB flex items-center justify-center rounded-[4px] p-3 font-medium uppercase"
      >
        See all jobs
      </Link>
    </div>
  );
}

JobsCards.propTypes = {
  redirectionUrl: PropTypes.string,
  roles: PropTypes.array,
};

export default JobsCards;
