import PropTypes from "prop-types";

function TaskIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path d="M25.6666 45.5H49" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M25.6666 29.167H49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6666 12.833H49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.8337L9.33333 15.167L16.3333 8.16699"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 29.1667L9.33333 31.5L16.3333 24.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 45.4997L9.33333 47.833L16.3333 40.833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

TaskIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default TaskIcon;
