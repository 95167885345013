import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { HiBars3 } from "react-icons/hi2";
import { useOutsideClick } from "../hooks/useOutsideClick";
import Logo from "./logo/Logo";
import PrimaryMenu from "./PrimaryMenu";
import UserAuthMenu from "./UserAuthMenu";

function Header() {
  const isRTL = document.dir === "rtl";

  const navRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const initialMenuClassName =
    "-translate-x-full xl:transform-none invisible xl:visible opacity-0 xl:opacity-100";
  const [menuClassName, setMenuClassname] = useState(initialMenuClassName);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen((show) => !show);
  };

  const menuRef = useOutsideClick(() => setIsMenuOpen(false), true);

  useEffect(
    function () {
      isMenuOpen
        ? setMenuClassname("transform-none visible opacity-100")
        : setMenuClassname(initialMenuClassName);
    },
    [isMenuOpen],
  );

  useEffect(() => {
    function updateHeaderHeight() {
      setHeaderHeight(Number(navRef.current.offsetHeight));
    }
    updateHeaderHeight();

    window.addEventListener("resize", updateHeaderHeight);

    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, [headerHeight]);

  return (
    <>
      <header className="relative z-[100] w-full">
        <nav
          className="page-px fixed inset-x-0 top-0 z-[100] bg-white/90 py-7 backdrop-blur"
          ref={navRef}
        >
          <div className="-m-4 flex items-center">
            <div className="p-4 xl:hidden">
              <div
                className="cursor-pointer rounded transition-all duration-300 hover:text-blue-b-primary"
                onClick={toggleMenu}
              >
                <HiBars3 className="h-8 w-8 transition-all duration-300 sm:h-9 sm:w-9" />
              </div>
            </div>

            <div className="p-4">
              <Link to={"/"} className="block">
                <Logo className="w-11 max-w-full" />
              </Link>
            </div>

            <PrimaryMenu
              menuRef={menuRef}
              className={`absolute ${isRTL ? "right-0" : "left-0"} top-0 z-[180] h-screen min-w-64 flex-initial overflow-y-auto bg-white/90 p-4 backdrop-blur transition-transform duration-300 xl:static xl:h-auto xl:min-w-0 xl:flex-1 xl:overflow-visible xl:bg-transparent xl:p-0 xl:backdrop-filter-none xl:transition-none ${menuClassName}`}
              onClose={toggleMenu}
            />

            {isMenuOpen && (
              <div className="fixed inset-0 z-[170] h-screen bg-black/50 xl:hidden"></div>
            )}
            <UserAuthMenu className={`${isRTL ? "mr-auto" : "ml-auto"} p-4`} />
          </div>
        </nav>
      </header>

      <div
        style={{ width: "100%", height: `${parseFloat(headerHeight)}px` }}
      ></div>
    </>
  );
}

export default Header;
