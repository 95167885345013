import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { removeJobDescription } from "../services/apiJobDescription";

export const useRemoveJobDescription = () => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteJob,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ id }) =>
      removeJobDescription({
        id,
      }),
    onSuccess: (response) => {
      toast.success("Job has been deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["jobDescriptionSearch"] });
      return response;
    },
    onError: () => {
      toast.error("Failed to delete job");
    },
  });

  return { deleteJob, isPending, isSuccess };
};
