import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import ScrollTo from "./ScrollTo";
import Header from "./Header";
import Footer from "./Footer";
import LoaderFullPage from "./loader/LoaderFullPage";
import toast from "react-hot-toast";
import { useEffect } from "react";

function LayoutPublic() {
  const location = useLocation();
  const { isAuthenticating, redirectionUrl, isError, error, failureReason } =
    useIsAuthenticated();

  useEffect(
    function () {
      if (!isAuthenticating)
        if (isError)
          if (error?.code === 503) toast.error(failureReason?.message);
    },
    [isAuthenticating, redirectionUrl, isError, error, failureReason],
  );

  if (isAuthenticating) return <LoaderFullPage />;

  if (redirectionUrl)
    return (
      <Navigate
        to={
          location?.state?.loginFirstTime
            ? `${redirectionUrl}/welcome`
            : location?.state?.redirectionUrl || redirectionUrl
        }
        state={{ loginFirstTime: location?.state?.loginFirstTime }}
        replace={true}
      />
    );

  return (
    <>
      <ScrollTo />
      <div className="overflow-hidden">
        <Header />

        <main className="h-full">
          <Outlet />
        </main>

        <Footer />
      </div>
    </>
  );
}

export default LayoutPublic;
