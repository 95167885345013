/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from "react";
import Input from "../../shared/inputs/Input";
import CheckButton from "../../shared/buttons/CheckButton";
import CollapsableEditorContent from "../../shared/collapsable/CollapsableEditorContent";

import CustomEditor from "../../shared/inputs/CustomEditor";
import ScrollTo from "../../shared/ScrollTo";
import { useFormContext } from "react-hook-form";

function StepTwo() {
  const {
    control,
    setValue,
    resetField,
    watch,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [keywordsData, setKeywordsData] = useState([]);

  useEffect(() => {
    const storedKeywords = getValues("keywords");
    if (storedKeywords && storedKeywords.length > 0) {
      const keywordObjects = storedKeywords.map((keyword, index) => ({
        id: index,
        name: keyword,
        value: keyword,
      }));
      setKeywordsData(keywordObjects);
    }
  }, [getValues]);

  useEffect(() => {
    const keywords = keywordsData.map((keyword) => keyword?.value);
    setValue("keywords", keywords);
    setValue("keyword", "");
  }, [keywordsData, setValue]);

  const handleAddKeyword = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const keyword = event.target.value.trim();
      if (keyword && !keywordsData.some((item) => item.value === keyword)) {
        const newKeyword = {
          id: Math.floor(Math.random() * 1000000),
          name: keyword,
          value: keyword,
        };

        setKeywordsData((prevKeywords) => [...prevKeywords, newKeyword]);

        // Reset the input field
        resetField("keyword");
      }
    }
  };

  const handleRemoveKeyword = (id) => {
    const updatedKeywords = keywordsData.filter((item) => item.id !== id);
    setKeywordsData(updatedKeywords);

    // Update the form state
    setValue("keywords", updatedKeywords);
  };

  return (
    <>
      <ScrollTo />
      <div className="my-4 flex flex-col items-center">
        <div className="mb-1 flex font-primary text-2xl font-medium text-black-g-primary">
          Jobs descriptions
        </div>
        <div className="font-primary text-base font-normal text-black-g-primary">
          let's fill the description of the job
        </div>
      </div>
      <div className="flex w-full flex-col gap-4">
        <CollapsableEditorContent
          title={"Main mission"}
          value={watch("mainMission.html")}
        >
          <div className="flex flex-col gap-4">
            <div className="p-1 font-primary text-sm font-semibold">
              Description
            </div>
            <CustomEditor
              placeholder={"add description"}
              controllerName={"mainMission"}
              editorClassName={"input-transparent bg-white border-transparent"}
              control={control}
              onHtmlValueChange={(data) => setValue("mainMission.html", data)}
              onPlainTextValueChange={(plaintext) => {
                setValue("mainMission.raw", plaintext);
                setValue("mainMission.culture", "FR");
              }}
              htmlDefaultValue={watch("mainMission.html")}
              plainTextDefaultValue={watch("mainMission.raw")}
            />
            {/* <CustomButton
              btnStyle={`btn-outline-blueB w-fit self-end font-medium px-3 rounded-[4px] flex items-center`}
              icon={<BsMagic />}
              title={"Generate with AI"}
              // onClick={() => handleClick(item)}
            /> */}
          </div>
        </CollapsableEditorContent>

        <CollapsableEditorContent
          title={"Responsibilities : Missions and activities"}
          value={watch("responsibilities.html")}
          isChildrenShown={errors?.responsibilities !== undefined}
        >
          <div className="flex flex-col gap-4">
            <div className="p-1 font-primary text-sm font-semibold">
              Description
            </div>
            <CustomEditor
              placeholder={"add description"}
              controllerName={"responsibilities"}
              editorClassName={"input-transparent bg-white "}
              control={control}
              onHtmlValueChange={(data) =>
                setValue("responsibilities.html", data)
              }
              onPlainTextValueChange={(plaintext) => {
                setValue("responsibilities.raw", plaintext);
                setValue("responsibilities.culture", "FR");
                clearErrors("responsibilities");
              }}
              htmlDefaultValue={watch("responsibilities.html")}
              plainTextDefaultValue={watch("responsibilities.raw")}
            />
            {/* <CustomButton
              btnStyle={`btn-outline-blueB w-fit self-end font-medium px-3 rounded-[4px] flex items-center`}
              icon={<BsMagic />}
              title={"Generate with AI"}
              // onClick={() => handleClick(item)}
            /> */}
          </div>
        </CollapsableEditorContent>

        <CollapsableEditorContent
          title={"Profile"}
          value={watch("profile.html")}
          isChildrenShown={errors?.profile !== undefined}
        >
          <div className="flex flex-col gap-4">
            <div className="p-1 font-primary text-sm font-semibold">
              Description
            </div>
            <CustomEditor
              placeholder={"add description"}
              controllerName={"profile"}
              editorClassName={"input-transparent bg-white border-transparent"}
              control={control}
              onHtmlValueChange={(data) => setValue("profile.html", data)}
              onPlainTextValueChange={(plaintext) => {
                setValue("profile.raw", plaintext);
                setValue("profile.culture", "FR");
                clearErrors("profile");
              }}
              htmlDefaultValue={watch("profile.html")}
              plainTextDefaultValue={watch("profile.raw")}
            />
            {/* <CustomButton
              btnStyle={`btn-outline-blueB w-fit self-end font-medium px-3 rounded-[4px] flex items-center`}
              icon={<BsMagic />}
              title={"Generate with AI"}
              // onClick={() => handleClick(item)}
            /> */}
          </div>
        </CollapsableEditorContent>

        <CollapsableEditorContent
          title={"Savoir-etre"}
          value={watch("softSkills.html")}
        >
          <div className="flex flex-col gap-4">
            <div className="p-1 font-primary text-sm font-semibold">
              Description
            </div>
            <CustomEditor
              placeholder={"add description"}
              editorClassName={"input-transparent bg-white border-transparent"}
              controllerName={"softSkills"}
              control={control}
              onHtmlValueChange={(data) => setValue("softSkills.html", data)}
              onPlainTextValueChange={(plaintext) => {
                setValue("softSkills.raw", plaintext);
                setValue("softSkills.culture", "FR");
              }}
              htmlDefaultValue={watch("softSkills.html")}
              plainTextDefaultValue={watch("softSkills.raw")}
            />
            {/* <CustomButton
              btnStyle={`btn-outline-blueB w-fit self-end font-medium px-3 rounded-[4px] flex items-center`}
              icon={<BsMagic />}
              title={"Generate with AI"}
              // onClick={() => handleClick(item)}
            /> */}
          </div>
        </CollapsableEditorContent>

        <Input
          column={true}
          label={"Keywords"}
          type="text"
          id="keyword"
          placeholder="Please enter a keyword and press ENTER"
          controllerName="keyword"
          onKeyDown={handleAddKeyword}
          control={control}
          fieldClassName="input-transparent text-sm font-primary font-normal bg-white"
          resetField={resetField}
        />
        <div className="flex flex-wrap items-center gap-4">
          {keywordsData?.map((item) => (
            <CheckButton
              text={item?.name}
              control={control}
              controllerName={`keyword.${item?.name}`}
              key={item?.id}
              //selected={watch("keywords")?.some((obj) => obj.id === item.id)}
              onClick={() => handleRemoveKeyword(item?.id)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

StepTwo.propTypes = {};

export default StepTwo;
