import { Link } from "react-router-dom";
import { useParseResume } from "../hooks/useParseResume";
import FileInput from "../shared/inputs/FileInput";
import { HiArrowUpRight } from "react-icons/hi2";

function About() {
  const { parseResume, isPending, isSuccess, data, reset } = useParseResume();

  const uploadResume = (e) => {
    const file = e.target.files[0];

    parseResume(file, {
      onSuccess: (data) => {
        console.log(data);
      },
    });
  };

  return (
    <>
      <section>
        <div className="relative">
          <img
            className="absolute inset-0 z-0 h-full w-full object-cover object-center"
            src="uploads/about.jpg"
            alt="banner"
          />

          <div className="page-px relative z-10 flex min-h-screen items-center bg-black-g-primary/50 py-16">
            <div className="w-full max-w-screen-lg">
              <div className="mb-16 text-white">
                <h1 className="text-5xl font-semibold">
                  Who We are & Our Smart Mission
                </h1>
                <p className="mt-4 text-xl">
                  At Devadoot, our mission is to transform the world of work by
                  offering an intuitive and intelligent platform. We simplify
                  recruitment and project management for freelancers, job
                  seekers and companies through advanced data analysis. As a
                  trusted partner, we believe that innovation, intelligent
                  collaboration and the judicious use of data shape a more
                  productive and inclusive future of work for all.
                </p>
              </div>

              <Link className="btn-blue-b-primary" to={"/get-in-touch"}>
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-16 overflow-hidden">
          <div className="-m-8 flex flex-wrap">
            <div className="flex-1 p-8">
              <h1 className="mb-4 text-3xl font-bold">Our Mission & Story</h1>

              <p className="mb-8 whitespace-pre-line text-justify text-lg">
                We support you throughout your recruitment process, from
                identifying needs to managing various projects. We merge HR
                expertise with IT expertise to offer you an intelligent solution
                that saves you time and enhances efficiency. At devadoot, we are
                committed to sourcing and collaborating with the most
                exceptional individuals globally. Through a meticulous screening
                process, we identify experts in their fields—individuals
                characterized by their unwavering passion and determination.
              </p>

              <div className="flex">
                <Link
                  className="btn-blue-b-primary flex items-center space-x-2"
                  to={"/signup"}
                >
                  <div>Start your free trial</div>
                  <HiArrowUpRight className="h-3.5 w-3.5 stroke-2" />
                </Link>
              </div>
            </div>

            <div className="p-8 md:w-2/5">
              <div className="overflow-hidden">
                <img
                  className="h-full w-full object-cover object-center"
                  src="/uploads/our-mission.png"
                  alt="our mission"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-16 bg-blue-b-primary py-16 text-white">
          <h1 className="whitespace-pre-line text-center text-3xl font-bold capitalize text-white">
            Choose what you need
          </h1>
          <p className="mt-4 whitespace-pre-line text-center text-white">
            Our HR specialists are at your service to assist you in addressing
            your HR challenges and achieving your goals. Get access to the best
            1% of skilled professionals through a complete platform designed
            just for you.
          </p>

          <div className="mt-8 overflow-hidden">
            <div className="-m-2 flex flex-wrap">
              <div className="w-full p-2 md:w-1/3">
                <div className="group h-full rounded-lg bg-white/30 p-8 transition-all duration-300 hover:bg-white/40">
                  <h1 className="mb-4 text-5xl font-bold transition-all duration-300 group-hover:text-blue-b-primary">
                    01.
                  </h1>
                  <h5 className="my-2 whitespace-pre-line text-xl font-bold transition-all duration-300">
                    HR Services
                  </h5>
                  <p className="whitespace-pre-line">
                    Gain insight into the services and expertise you require. We
                    provide a range of HR services to support you in your
                    various HR missions.
                  </p>
                </div>
              </div>

              <div className="w-full p-2 md:w-1/3">
                <div className="group h-full rounded-lg bg-white/30 p-8 transition-all duration-300 hover:bg-white/40">
                  <h1 className="mb-4 text-5xl font-bold text-white transition-all duration-300 group-hover:text-blue-b-primary">
                    02.
                  </h1>
                  <h5 className="my-2 whitespace-pre-line text-xl font-bold transition-all duration-300">
                    Coaching / Team Building
                  </h5>
                  <p className="whitespace-pre-line">
                    A diverse array of services to assist you and your teams in
                    identifying your personal values, strengths, and abilities
                    to help achieve your personal and professional goals.
                  </p>
                </div>
              </div>

              <div className="w-full p-2 md:w-1/3">
                <div className="group h-full rounded-lg bg-white/30 p-8 transition-all duration-300 hover:bg-white/40">
                  <h1 className="mb-4 text-5xl font-bold text-white transition-all duration-300 group-hover:text-blue-b-primary">
                    03.
                  </h1>
                  <h5 className="my-2 whitespace-pre-line text-xl font-bold transition-all duration-300">
                    Data & AI
                  </h5>
                  <p className="whitespace-pre-line">
                    Data analysis for unbiased decision-making.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="page-px my-16 bg-blue-b-primary py-16 text-white">
          <div className="text-center">
            <h1 className="mb-4 text-4xl font-bold">
              Find The Perfect job that fits your skills
            </h1>

            <p>
              We believe everyone deserves a job that allows them to thrive.
              <br />
              Whether you&apos;re a candidate looking for a stimulating career
              or a freelancer seeking exciting projects, <br />
              we&apos;re here to help you find the opportunity that perfectly
              matches your skills and aspirations.
            </p>
          </div>

          <div className="mt-16 flex items-center justify-center">
            <div className="mr-4">
              <Link
                className={
                  "block cursor-pointer rounded bg-blue-b-400 p-4 text-white"
                }
                to={"/candidate/welcome"}
                state={{ redirectionUrl: "/candidate/welcome" }}
              >
                Upload Resume
              </Link>
            </div>

            <Link
              className={"block rounded bg-white p-4 text-blue-b-primary"}
              to={"/signup"}
            >
              Join our team
            </Link>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="page-px">
          <div className="mx-auto max-w-screen-xl">
            <h1 className="mb-4 text-center text-3xl font-bold">
              Choose your Package
            </h1>
            <p className="text-center">
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum
              <br /> deleniti atque corrupti quos dolores
            </p>

            <div className="mt-8 overflow-hidden">
              <div className="-m-3 flex flex-wrap">
                <div className="w-1/3 p-3">
                  <div className="rounded border border-slate-400 p-2">
                    <div className="rounded bg-blue-b-500 px-4 py-8 text-white">
                      <h5 className="text-center text-2xl font-bold uppercase">
                        Basic
                      </h5>

                      <div className="mt-8 text-center font-bold">
                        <span className="text-2xl font-semibold">$</span>
                        <span className="text-7xl font-bold">29</span>
                        <span className="text-2xl font-semibold">/Mon</span>
                      </div>
                    </div>

                    <div className="p-6">
                      <ul className="text-center">
                        <li className="my-3">1 GB Ram</li>
                        <li className="my-3">2 GB Memory</li>
                        <li className="my-3">1 Core Processor</li>
                        <li className="my-3">32 GB SSD Disk</li>
                        <li className="my-3">1 TB Transfer</li>
                      </ul>

                      <button className="mt-6 w-full rounded border border-blue-b-primary p-4 text-blue-b-primary transition-all duration-300 hover:bg-blue-b-500 hover:text-white">
                        Get started
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-1/3 p-3">
                  <div className="rounded border border-slate-400 p-2">
                    <div className="rounded bg-blue-b-200 px-4 py-8 text-white">
                      <h5 className="text-center text-2xl font-bold uppercase">
                        PREMIUM
                      </h5>

                      <div className="mt-8 text-center font-bold">
                        <span className="text-2xl font-semibold">$</span>
                        <span className="text-7xl font-bold">40</span>
                        <span className="text-2xl font-semibold">/Mon</span>
                      </div>
                    </div>

                    <div className="p-6">
                      <ul className="text-center">
                        <li className="my-3">1 GB Ram</li>
                        <li className="my-3">2 GB Memory</li>
                        <li className="my-3">1 Core Processor</li>
                        <li className="my-3">32 GB SSD Disk</li>
                        <li className="my-3">1 TB Transfer</li>
                      </ul>

                      <button className="mt-6 w-full rounded border border-blue-b-200 bg-blue-b-200 p-4 text-white transition-all duration-300">
                        Get started
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-1/3 p-3">
                  <div className="rounded border border-slate-400 p-2">
                    <div className="rounded bg-blue-b-500 px-4 py-8 text-white">
                      <h5 className="text-center text-2xl font-bold uppercase">
                        ULTIMATE
                      </h5>

                      <div className="mt-8 text-center font-bold">
                        <span className="text-2xl font-semibold">$</span>
                        <span className="text-7xl font-bold">120</span>
                        <span className="text-2xl font-semibold">/Mon</span>
                      </div>
                    </div>

                    <div className="p-6">
                      <ul className="text-center">
                        <li className="my-3">1 GB Ram</li>
                        <li className="my-3">2 GB Memory</li>
                        <li className="my-3">1 Core Processor</li>
                        <li className="my-3">32 GB SSD Disk</li>
                        <li className="my-3">1 TB Transfer</li>
                      </ul>

                      <button className="mt-6 w-full rounded border border-blue-b-primary p-4 text-blue-b-primary transition-all duration-300 hover:bg-blue-b-500 hover:text-white">
                        Get started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default About;
