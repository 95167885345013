/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import StepOne from "../components/jobDescription/StepOne";
import StepThree from "../components/jobDescription/StepThree";
import StepTwo from "../components/jobDescription/StepTwo";
import { useGetCurrencies } from "../hooks/useGetCurrencies";
import { useGetIndustries } from "../hooks/useGetIndustries";
import { useJobDescriptionSingle } from "../hooks/useJobDescriptionSingle";
import useScrollToError from "../hooks/useScrollToError";
import { useSubmitJobDescription } from "../hooks/useSubmitJobDescription";
import CustomButton from "../shared/buttons/CustomButton";
import Divider from "../shared/Divider";
import Loader from "../shared/loader/Loader";
import FormStepper from "../shared/stepper/FormStepper";

function CreateJob() {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const { data } = useJobDescriptionSingle();

  const formObj = useForm({
    values: {
      id: data?.id,
      title: data?.title,
      imageURL: data?.imageURL,
      education: data?.education,
      contractType: data?.contractType,
      workLocation: data?.workLocation,
      experienceLevel: data?.experienceLevel,
      employmentType: data?.employmentType,
      mainMission: {
        culture: data?.mainMission?.culture,
        html: data?.mainMission?.html,
        raw: data?.mainMission?.raw,
      },
      profile: {
        culture: data?.profile?.culture,
        html: data?.profile?.html,
        raw: data?.profile?.raw,
      },
      responsibilities: {
        culture: data?.responsibilities?.culture,
        html: data?.responsibilities?.html,
        raw: data?.responsibilities?.raw,
      },
      additionalInformation: {
        culture: data?.additionalInformation?.culture,
        raw: data?.additionalInformation?.raw,
        html: data?.additionalInformation?.html,
      },
      softSkills: {
        culture: data?.softSkills?.culture,
        raw: data?.softSkills?.raw,
        html: data?.softSkills?.html,
      },
      currencyId: data?.rate?.currency?.id,
      salaryRange: [data?.rate?.min, data?.rate?.max],
      /* rate: {
        currencyId: data?.rate?.currency?.id,
        rateType: data?.rate?.type,
        min: data?.rate?.min,
        max: data?.rate?.max,
      }, */
      expirationDate: data?.expirationDate,
      keyword: "",
      keywords: data?.skills?.map((skill) => skill?.name),
      industryId: data?.industry?.id,
      countryId: data?.country?.id,
      countryName: data?.country?.name,
      isDraft: !data?.isPublished,
    },
  });

  const { submitJobDescription, isPending } = useSubmitJobDescription();

  const { data: currencies } = useGetCurrencies();
  const { data: industries } = useGetIndustries();

  useScrollToError({
    errorsFromState: formObj.formState.errors,
    isSubmitting: formObj.formState.isValidating,
  });

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (currentStep === 1) return;
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const steps = ["step1", "step2", "step3"];

  const onSubmit = (data) => {
    if (data?.isDraft === false && currentStep !== 3) {
      handleNext();
      return;
    }

    const payLoad = {
      ...data,
      rate: {
        currencyId: data?.currencyId,
        rateType: 1,
        min: parseFloat(data?.salaryRange[0]),
        max: parseFloat(data?.salaryRange[1]),
      },
    };

    submitJobDescription(
      { ...payLoad },
      {
        onSuccess: () => {
          navigate("/recruiter/jobs");
        },
        onError: (err) => {
          console.log(err);
        },
      },
    );
  };

  const onError = (errs) => {
    console.log(errs);
  };

  return (
    <div className="page-px flex flex-col gap-4 py-4">
      <div className="flex items-center">
        <span className="w-1/4 font-primary text-3xl font-medium text-black-g-primary">
          Create new job
        </span>
        <FormStepper
          steps={steps}
          currentStep={currentStep}
          showStepLabel={false}
        />

        <div className="flex w-1/4  justify-end">
          <div
            className="btn-white w-fit cursor-pointer rounded-md font-primary text-base font-medium"
            onClick={() => navigate("/recruiter/jobs")}
          >
            Cancel
          </div>
        </div>
      </div>
      <Divider className="mt-2" />
      <div className="flex w-full flex-col items-center justify-center">
        <div
          className={`flex flex-wrap ${(currentStep == 2 || currentStep == 3) && "w-full md:w-2/3"} flex-col`}
        >
          <FormProvider {...formObj}>
            {currentStep == 1 ? (
              <StepOne currencies={currencies} industries={industries} />
            ) : currentStep == 2 ? (
              <StepTwo />
            ) : (
              <StepThree handleBack={handleBack} currencies={currencies} />
            )}
          </FormProvider>

          <div className="my-6 flex items-center justify-between">
            {currentStep > 1 && (
              <CustomButton
                title="Back"
                btnStyle={
                  "btn-white rounded-md text-base font-primary font-medium"
                }
                onClick={handleBack}
              />
            )}
            <div className="ml-auto flex items-center gap-6 pr-0.5">
              <CustomButton
                title="Save as draft"
                disabled={
                  Object.keys(formObj.formState.errors).length > 0 || isPending
                }
                isLoading={isPending}
                loadingLoader={<Loader size={1.2} color="#ffffff" />}
                btnStyle={
                  "cursor-pointer rounded-md font-primary text-base font-medium disabled:cursor-not-allowed disabled:text-black-g-75"
                }
                onClick={() => {
                  formObj.setValue("isDraft", true);
                  formObj.handleSubmit(onSubmit, onError)();
                }}
              />

              <CustomButton
                title={`${currentStep != 3 ? "Next" : "Save"} `}
                disabled={
                  Object.keys(formObj.formState.errors).length > 0 || isPending
                }
                isLoading={isPending}
                loadingLoader={<Loader size={1.2} color="#ffffff" />}
                btnStyle={
                  "btn-blue-b-primary py-2 rounded-md text-base font-primary font-medium"
                }
                onClick={() => {
                  formObj.setValue("isDraft", false);
                  formObj.handleSubmit(onSubmit, onError)();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateJob;
