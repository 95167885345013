import Conversation from "./Conversation.jsx";
import Messages from "./Messages";
import { useUser } from "../../hooks/useUser.js";
import { useEffect, useMemo, useState } from "react";
import { getSessionsByUserId } from "../../services/apiChat.js";

export const ChatContent = () => {
  const { isLoading, user } = useUser();
  const [sessions, SetSessions] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recrutersResponse = await getSessionsByUserId(user.id);
        SetSessions(recrutersResponse);
      } catch (error) {
        console.error("Error fetching recruters:", error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [isLoading, user]);

  const handleCandidateClick = (candidateId) => {
    setSelectedCandidate(
      sessions.filter((session) => session.condidate.id === candidateId),
    );
  };

  const filteredCandidates = sessions.filter((candidate) =>
    `${candidate.condidate.firstName} ${candidate.condidate.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const condidatesDisplay = useMemo(() => {
    //console.log("Sessions:", sessions);
    // console.log("Filtered Candidates:", filteredCandidates);
    if (searchTerm) {
      return filteredCandidates;
    }
    return sessions;
  }, [searchTerm, sessions]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-[calc(100vh-115px)] overflow-y-auto    ">
      <div className="hidden h-[calc(100vh-115px)] w-80 min-w-80    overflow-y-auto p-2 md:block">
        <div className="  overflow-y-auto">
          <div className="search-chat flex p-3">
            <input
              className="input w-full   rounded-l-md p-3 text-sm   text-gray-700 focus:outline-none"
              type="text"
              placeholder="Search Messages"
              onChange={(e) => {
                // console.log("Search term:", e.target.value);
                setSearchTerm(e.target.value);
              }}
            />
          </div>

          <Conversation
            condidates={condidatesDisplay.map((session) => session.condidate)}
            handleCandidateClick={handleCandidateClick}
          />
        </div>
      </div>
      <div className="flex-grow    rounded-md p-2">
        {selectedCandidate && (
          <Messages
            selectedCandidateId={selectedCandidate}
            candidates={sessions}
            sessions={sessions}
          />
        )}
      </div>
    </div>
  );
};
