import PropTypes from "prop-types";
import { createContext, useContext } from "react";
import { Controller } from "react-hook-form";

const RadioButtonsContext = createContext();

function RadioButtons({ children, id, controllerName, control, rules }) {
  return (
    <Controller
      name={controllerName || id}
      control={control}
      rules={rules}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => {
        return (
          <RadioButtonsContext.Provider
            value={{ value, onChange, invalid, error }}
          >
            {children}
          </RadioButtonsContext.Provider>
        );
      }}
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useRadioButtons() {
  const context = useContext(RadioButtonsContext);

  if (context === undefined)
    throw new Error("useRadioButtons was used outside the RadioButtons.");
  return context;
}

function Button({ children, className, value, onClick, selectedClassName }) {
  const { value: selectedValue, onChange } = useRadioButtons();

  const handleClick = () => {
    onChange(value);
    onClick?.();
  };

  return (
    <div
      className={`group ${className} ${selectedValue === value ? selectedClassName : ""} cursor-pointer transition-all duration-200`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

function Label({ children, className }) {
  return (
    <div className={`${className} transition-all duration-200`}>{children}</div>
  );
}

function Radio({ size = 1.5, value }) {
  const { value: selectedValue } = useRadioButtons();

  return (
    <div
      style={{
        width: `${parseFloat(size)}rem`,
        height: `${parseFloat(size)}rem`,
      }}
      className="overflow-hidden rounded-full border-4 border-blue-b-primary"
    >
      <div
        className={`h-full w-full scale-0 ${selectedValue === value ? "transform-none" : ""} rounded-full border-2 border-white bg-blue-b-primary transition-all duration-200`}
      ></div>
    </div>
  );
}

function Error({ className }) {
  const { invalid, error } = useRadioButtons();

  if (!invalid) return null;

  if (invalid && error?.message)
    return (
      <div className={className}>
        <p className="text-sm text-red-o-primary">{error?.message}</p>
      </div>
    );
}

RadioButtons.Button = Button;
RadioButtons.Label = Label;
RadioButtons.Radio = Radio;
RadioButtons.Error = Error;

RadioButtons.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  controllerName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selectedClassName: PropTypes.string,
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Radio.propTypes = {
  size: PropTypes.number,
  value: PropTypes.string.isRequired,
};

Error.propTypes = {
  className: PropTypes.string,
};

export default RadioButtons;
