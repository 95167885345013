import { useQuery } from "@tanstack/react-query";
import { getCountries } from "../services/apiInformation";

export function useGetCountries() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
    retry: false,
  });

  return { isLoading, data, error };
}
