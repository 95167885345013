import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useOutsideClick } from "../hooks/useOutsideClick";

const MenusContext = createContext();

function Menus({ children }) {
  const [openId, setOpenId] = useState("");
  const [currentToggle, setCurrentToggle] = useState(null);
  const [positionTop, setPositionTop] = useState(null);
  const [positionLeft, setPositionLeft] = useState(null);
  const [positionRight, setPositionRight] = useState(null);
  const [animationClassName, setAnimationClassName] = useState("");

  const open = setOpenId;

  const close = () => {
    setOpenId("");
    setCurrentToggle(null);
  };

  return (
    <MenusContext.Provider
      value={{
        openId,
        currentToggle,
        setCurrentToggle,
        open,
        close,
        positionTop,
        positionLeft,
        positionRight,
        setPositionTop,
        setPositionLeft,
        setPositionRight,
        animationClassName,
        setAnimationClassName,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
}

function Menu({ children }) {
  return <div className="relative">{children}</div>;
}

function Toggle({ id, children }) {
  const {
    openId,
    currentToggle,
    setCurrentToggle,
    open,
    close,
    setPositionTop,
    setPositionLeft,
    setPositionRight,
  } = useContext(MenusContext);

  useEffect(function () {
    if (!currentToggle) return;

    const { top, left, right, height } = currentToggle.getBoundingClientRect();

    setPositionTop(`${parseFloat(top) + parseFloat(height)}px`);
    setPositionLeft(`${parseFloat(left)}px`);
    setPositionRight(
      `${parseFloat(document.documentElement.clientWidth) - parseFloat(right)}px`,
    );
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setCurrentToggle(e.currentTarget);

    openId === "" || openId !== id ? open(id) : close();
  };

  return (
    <div id={id} className="cursor-pointer" onClick={handleClick}>
      {children}
    </div>
  );
}

function List({ id, children, position }) {
  const isRTL = document.dir === "rtl";
  const listPosition = position ? position : isRTL ? "right" : "left";
  const transformOrigin =
    listPosition === "left" ? "origin-top-left" : "origin-top-right";

  const {
    openId,
    close,
    positionTop,
    positionLeft,
    positionRight,
    animationClassName,
    setAnimationClassName,
  } = useContext(MenusContext);

  const listRef = useOutsideClick(close, false);

  useEffect(
    function () {
      openId === ""
        ? setAnimationClassName("scale-x-50 scale-y-0 opacity-0")
        : setAnimationClassName("transform-none opacity-100");
    },
    [openId, id, setAnimationClassName],
  );

  if (openId !== id) return null;

  return createPortal(
    <div
      ref={listRef}
      className={`absolute z-[1000] rounded border border-slate-200 bg-white/85 shadow-lg backdrop-blur transition-all duration-200 ${animationClassName} ${transformOrigin}`}
      style={{
        top: positionTop,
        left: listPosition === "left" ? positionLeft : "auto",
        right: listPosition === "right" ? positionRight : "auto",
      }}
    >
      {children}
    </div>,
    document.body,
  );
}

function Button({ children, onClick, className }) {
  const { close } = useContext(MenusContext);
  const handleClick = () => {
    onClick?.();
    close();
  };

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
}

Menus.Menu = Menu;
Menus.Toggle = Toggle;
Menus.List = List;
Menus.Button = Button;

Menus.propTypes = {
  children: PropTypes.node,
};

Menu.propTypes = {
  children: PropTypes.node,
};

Toggle.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

List.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.string,
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Menus;
