/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetMyCompanyProfile } from "../hooks/useGetMyCompanyProfile";
import { useUpdateCompanyProfile } from "../hooks/useUpdateCompanyProfile";
import { useUpdateUserInformation } from "../hooks/useUpdateUserInformation";
import { useUploadUserPicture } from "../hooks/useUploadUserPicture";
import { useUser } from "../hooks/useUser";
import PhotoInput from "../shared/inputs/PhotoInput";

function UserCompanyProfile() {
  const [stepid, setStepid] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { updateUserInformation } = useUpdateUserInformation();
  // this api will be triggered only for candidate role
  const { data: userCompany } = useGetMyCompanyProfile();

  const { saveCompanyProfile } = useUpdateCompanyProfile();

  const {
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  } = useUploadUserPicture();

  const formObj = useForm({
    values: {
      //step: stepid,
      //id: userCompany?.id || 0,
      //name: userCompany?.name || "Company name",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      contact: {
        email: user?.contact.email || "",
        phone: user?.contact?.phone || "",
      },

      employerName: userCompany?.name || "",
      companyEmail: userCompany?.email || "",
      industries:
        userCompany?.industries?.map((industry) => industry?.id)?.[0] || [],
      companyPhone: userCompany?.phone || "",
      companySize: userCompany?.size || "",
      companyFoundedYear: userCompany?.foundedYear || "",
      officeLocation: {
        address: {
          address: userCompany?.location?.address?.address || "",
          address2: userCompany?.location?.address?.address2 || "",
          cityId: userCompany?.location?.address?.city?.id || 0,
          cityName: userCompany?.location?.address?.city?.name || "",
          postalCode: userCompany?.location?.address?.postalCode || 0,
          countryId: userCompany?.location?.address?.country?.id || 0,
          countryName: userCompany?.location?.address?.country?.name || 0,
        },
      },
      description: userCompany?.description || "",
      /*  socialPlatforms: userCompany?.socialPlatforms?.map((sp) => sp.url), */
    },
  });

  const sections = [
    {
      name: "Basic information",
      url: "step=1",
      id: 1,
    },
    {
      name: "Company information",
      url: "step=2",
      id: 2,
    },
    {
      name: "Professional profiles",
      url: "step=3",
      id: 3,
    },
  ];
  useEffect(() => {
    if (location?.pathname && location.pathname.includes("step=")) {
      const stepId = location.pathname.split("=")[1];
      if (stepId !== undefined && stepId !== "") {
        setStepid(Number(stepId));
      } else {
        navigate("step=1", { replace: true }); // Redirect to step 1
      }
    } else {
      navigate("step=1", { replace: true }); // Redirect to step 1 if path doesn't contain step information
    }
  }, [location.pathname]);

  const handleUploadProfilePicture = (e) => {
    const file = e.target?.value;

    uploadUserPicture(file, {
      onSuccess: () => {
        // resetField("profilePicture");
      },
      onError: (err) => {
        if (err?.code === 400)
          formObj.setError(
            "profilePicture",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
        resetUploadUserPicture();
      },
    });
  };

  const saveData = (data) => {
    const { firstName, lastName, contact, ...newData } = data;

    if (stepid != 1) {
      const payload = {
        ...newData,
        industries: [data?.industries],
      };

      saveCompanyProfile(payload);
    } else {
      const userPayload = {
        firstName,
        lastName,
        contact,
      };

      updateUserInformation(userPayload);
    }
  };
  return (
    <>
      <div className="flex  flex-col ">
        <div className="flex  flex-wrap  md:flex md:w-full ">
          <div className="h-[calc(100vh-101px)] bg-[#f9fafc] md:flex md:w-[20%] md:flex-col">
            <div className="my-10 flex justify-center">
              <PhotoInput
                control={formObj.control}
                id="profilePicture"
                controllerName="profilePicture"
                rules={{
                  onChange: handleUploadProfilePicture,
                }}
                isUploading={isUploadingUserPicture}
                disabled={isUploadingUserPicture}
                src={
                  uploadedUserPicture?.result ||
                  user?.profilePic ||
                  "https://placehold.co/200x200"
                }
              />
            </div>
            <div className="mx-4 bg-white md:flex md:flex-col md:justify-center">
              {sections.map((section, index) => (
                <Link
                  key={section.id}
                  to={section.url}
                  className={`border-r border-t ${index == sections.length - 1 && "border-b"} ${stepid === section.id ? "border-l-4 border-l-blue-b-primary " : "border-l"} font-primary text-base font-normal text-black-g-primary md:p-3 `}
                >
                  {section.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="h-[calc(100vh-120px)] w-[80%] overflow-y-auto overflow-x-hidden">
            <div className="md:w-3/5 md:px-8">
              <FormProvider {...formObj}>
                <div className="md:w-full">
                  <Outlet />
                  <div className="my-4 flex w-full justify-end">
                    <button
                      onClick={() => formObj.handleSubmit(saveData)()}
                      className="btn-blue-b-primary flex w-fit items-center justify-center rounded-md py-2 font-primary text-base font-medium"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserCompanyProfile;
