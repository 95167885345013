import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getFileType } from "../../../utils/helpers";
import { HiUser } from "react-icons/hi2";
import { RiDraggable } from "react-icons/ri";
import { Link } from "react-router-dom";
import { RATETYPE_ENUM } from "../../../utils/constants";

function Item({ id, data }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, data: { type: "item" } });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ transition, transform: CSS.Translate.toString(transform) }}
      className={`relative p-2 ${isDragging ? "z-[1000] opacity-50" : ""}`}
    >
      <div
        className={`flex h-full flex-col overflow-hidden rounded-lg bg-white p-3 ${isDragging ? "shadow-2xl shadow-slate-400" : ""}`}
      >
        <div className="overflow-hidden">
          <div className="-m-1.5 flex items-center">
            <div className="p-1.5">
              <div className="h-14 w-14 overflow-hidden rounded">
                {getFileType(data?.resume?.candidate?.profilePic) ===
                "image" ? (
                  <img
                    className="w-ful h-full object-cover object-center"
                    src={data?.resume?.candidate?.profilePic}
                  />
                ) : (
                  <div className="h-full w-full bg-black-g-50 p-2 text-black-g-75">
                    <HiUser className="h-full w-full" />
                  </div>
                )}
              </div>
            </div>

            <div className="flex-1 p-1.5">
              <div className="font-semibold">{`${data?.resume?.candidate?.firstName} ${data?.resume?.candidate?.lastName}`}</div>
              <div className="flex items-center space-x-2 text-black-g-100">
                <div className="text-xs">{data?.resume?.jobTitle}</div>
                <div className="flex items-center space-x-1">
                  <div className="h-1 w-1 rounded-full bg-black-g-400"></div>
                  <div className="text-xs">
                    {data?.resume?.candidate?.contact?.address?.country?.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-1.5">
              <RiDraggable className="h-8 w-8 text-black-g-75" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between py-3">
          <div className="text-xs font-medium text-blue-b-primary">
            {`${data?.resume?.rate?.max}${data?.resume?.rate?.currency?.symbol} ${RATETYPE_ENUM[data?.resume?.rate?.type]}`}
          </div>

          <div className="flex items-center space-x-2 text-xs font-medium">
            <div className="rounded bg-green-g-50 px-2 py-1.5 text-green-g-500">
              Senior
            </div>
            <div className="rounded bg-purple-100 px-2 py-1.5 text-purple-900">
              Freelance
            </div>
            <div className="rounded bg-yellow-y-50 px-2 py-1.5 text-yellow-y-500">
              ASAP
            </div>
          </div>
        </div>

        <p className="py-3 text-sm text-black-g-100">
          Here, you can add your note about this candidate.
        </p>

        <div className="mt-auto">
          <div className="flex items-center justify-end">
            <Link
              to={"../candidate/3"}
              relative="path"
              className="btn-outline-blue-b-50 rounded py-1.5 text-sm font-medium"
            >
              See more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
};

export default Item;
