import Conversation from "./Conversation";
import Messages from "./Messages";
import { useUser } from "../../hooks/useUser.js";
import { useEffect, useMemo, useState } from "react";
import { getSessionsByUserId } from "../../services/apiChat.js";

export const CondidateChatContent = () => {
  const { isLoading, user } = useUser();
  const [sessions, SetSessions] = useState([]);

  const [selectedRecruter, setSelectedRecruter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recrutersResponse = await getSessionsByUserId(user.id);
        SetSessions(recrutersResponse);
      } catch (error) {
        console.error("Error fetching recruters:", error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [isLoading, user]);

  const handleCandidateClick = (recruterId) => {
    setSelectedRecruter(
      sessions.filter((session) => session.recruter.id === recruterId),
    );
  };

  const filteredCandidates = sessions.filter((candidate) =>
    `${candidate.recruter.firstName} ${candidate.recruter.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const condidatesDisplay = useMemo(() => {
    if (searchTerm) {
      return filteredCandidates;
    }

    return sessions;
  }, [searchTerm, sessions]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-[calc(100vh-115px)] overflow-y-auto    ">
      <div className="hidden h-[calc(100vh-115px)] w-80 min-w-80    overflow-y-auto p-2 md:block">
        <div className="  overflow-y-auto">
          <div className="search-chat flex p-3">
            <input
              className="input w-full   rounded-l-md p-3 text-sm   text-gray-700 focus:outline-none"
              type="text"
              placeholder="Search Messages"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <Conversation
            recruters={condidatesDisplay.map((session) => session.recruter)}
            handleCandidateClick={handleCandidateClick}
          />
        </div>
      </div>
      <div className="flex-grow    rounded-md p-2">
        {selectedRecruter && (
          <Messages
            selectedCandidateId={selectedRecruter}
            candidates={sessions}
            sessions={sessions}
          />
        )}
      </div>
    </div>
  );
};
