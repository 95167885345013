import LogoIcon from "../shared/logo/LogoIcon";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm";

function ResetPassword() {
  return (
    <section>
      <div className="page-px bg-[#F7F9FB] py-16">
        <div className="m-auto max-w-xl bg-white p-8 shadow-md shadow-slate-200">
          <div className="mb-8 flex flex-col text-center">
            <LogoIcon size={18} className="mx-auto" />

            <h1 className="mt-6 text-[2rem] font-medium">Update password</h1>
          </div>

          <ResetPasswordForm />
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
