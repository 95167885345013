import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { HiMinus } from "react-icons/hi2";
import Input from "../../shared/inputs/Input";

function CandidateEducationForm({ index, className, onRemove }) {
  const { control, resetField } = useFormContext();

  return (
    <div className={className}>
      <div className="-m-4 flex flex-wrap">
        <div className="flex w-full p-4">
          <button
            type="button"
            className="btn-outline-primary flex items-center space-x-2"
            onClick={onRemove}
          >
            <HiMinus className="h-6 w-6" />
            <div>Remove</div>
          </button>
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Start date"
            type="date"
            id={`educations.${index}.startTime`}
            placeholder="Start date"
            controllerName={`educations.${index}.startTime`}
            control={control}
            rules={{
              required: "Please enter the start date",
              valueAsDate: true,
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="End date"
            type="date"
            id={`educations.${index}.endTime`}
            placeholder="End date"
            controllerName={`educations.${index}.endTime`}
            control={control}
            rules={{
              required: "Please enter the end date",
              valueAsDate: true,
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="School name"
            type="text"
            id={`educations.${index}.schoolName`}
            placeholder="School name"
            controllerName={`educations.${index}.schoolName`}
            control={control}
            rules={{
              required: "Please enter the name of the school or institution",
            }}
            resetField={resetField}
          />
        </div>

        <div className="w-full p-4 sm:w-1/2">
          <Input
            label="Degree/Diploma"
            type="text"
            id={`educations.${index}.name`}
            placeholder="Degree or diploma"
            controllerName={`educations.${index}.name`}
            control={control}
            rules={{
              required: "Please enter the degree",
            }}
            resetField={resetField}
          />
        </div>
      </div>
    </div>
  );
}

CandidateEducationForm.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  onRemove: PropTypes.func,
};

export default CandidateEducationForm;
