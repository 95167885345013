import { useQuery } from "@tanstack/react-query";
import { getCurrencies } from "../services/apiInformation";

export function useGetCurrencies() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["currencies"],
    queryFn: getCurrencies,
    retry: false,
  });

  return { isLoading, data, error };
}
