import PropTypes from "prop-types";

function PentoolIcon({ height, width, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        d="M25.0833 52.5004H30.9633C33.2033 52.5004 34.65 50.9138 34.23 48.9771L33.2733 44.7539H22.7733L21.8167 48.9771C21.3967 50.7971 22.9833 52.5004 25.0833 52.5004Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2733 44.7304L37.31 41.137C39.5733 39.1303 39.6667 37.7302 37.87 35.4669L30.7533 26.437C29.26 24.547 26.81 24.547 25.3167 26.437L18.2 35.4669C16.4033 37.7302 16.4033 39.2003 18.76 41.137L22.7967 44.7304"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0233 25.9473V31.8507"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0166 12.1099L24.1966 10.2898C23.1 9.19312 23.1 7.4199 24.1966 6.32323L26.0166 4.50316C27.1133 3.4065 28.8866 3.4065 29.9833 4.50316L31.8033 6.32323C32.9 7.4199 32.9 9.19312 31.8033 10.2898L29.9833 12.1099C28.8866 13.2065 27.1133 13.2065 26.0166 12.1099Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3834 22.8896H47.95C49.49 22.8896 50.75 24.1497 50.75 25.6897V28.2563C50.75 29.7963 49.49 31.0565 47.95 31.0565H45.3834C43.8434 31.0565 42.5834 29.7963 42.5834 28.2563V25.6897C42.5834 24.1497 43.8434 22.8896 45.3834 22.8896Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6167 22.8896H8.04999C6.50999 22.8896 5.25 24.1497 5.25 25.6897V28.2563C5.25 29.7963 6.50999 31.0565 8.04999 31.0565H10.6167C12.1567 31.0565 13.4167 29.7963 13.4167 28.2563V25.6897C13.4167 24.1497 12.1567 22.8896 10.6167 22.8896Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.26 23.5668L30.8933 11.2002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.74 23.5668L25.1067 11.2002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PentoolIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default PentoolIcon;
