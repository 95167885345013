import PropTypes from "prop-types";

function ProfileUserIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.67617 11.62C9.64617 11.62 9.62617 11.62 9.59617 11.62C9.54617 11.61 9.47617 11.61 9.41617 11.62C6.51617 11.53 4.32617 9.25 4.32617 6.44C4.32617 3.58 6.65617 1.25 9.51617 1.25C12.3762 1.25 14.7062 3.58 14.7062 6.44C14.6962 9.25 12.4962 11.53 9.70617 11.62C9.69617 11.62 9.68617 11.62 9.67617 11.62ZM9.51617 2.75C7.48617 2.75 5.82617 4.41 5.82617 6.44C5.82617 8.44 7.38617 10.05 9.37617 10.12C9.43617 10.11 9.56617 10.11 9.69617 10.12C11.6562 10.03 13.1962 8.42 13.2062 6.44C13.2062 4.41 11.5462 2.75 9.51617 2.75Z" />
      <path d="M17.056 11.75C17.026 11.75 16.996 11.75 16.966 11.74C16.556 11.78 16.136 11.49 16.096 11.08C16.056 10.67 16.306 10.3 16.716 10.25C16.836 10.24 16.966 10.24 17.076 10.24C18.536 10.16 19.676 8.96 19.676 7.49C19.676 5.97 18.446 4.74 16.926 4.74C16.516 4.75 16.176 4.41 16.176 4C16.176 3.59 16.516 3.25 16.926 3.25C19.266 3.25 21.176 5.16 21.176 7.5C21.176 9.8 19.376 11.66 17.086 11.75C17.076 11.75 17.066 11.75 17.056 11.75Z" />
      <path d="M9.68621 22.55C7.72621 22.55 5.75621 22.05 4.26621 21.05C2.87621 20.13 2.11621 18.87 2.11621 17.5C2.11621 16.13 2.87621 14.86 4.26621 13.93C7.26621 11.94 12.1262 11.94 15.1062 13.93C16.4862 14.85 17.2562 16.11 17.2562 17.48C17.2562 18.85 16.4962 20.12 15.1062 21.05C13.6062 22.05 11.6462 22.55 9.68621 22.55ZM5.09621 15.19C4.13621 15.83 3.61621 16.65 3.61621 17.51C3.61621 18.36 4.14621 19.18 5.09621 19.81C7.58621 21.48 11.7862 21.48 14.2762 19.81C15.2362 19.17 15.7562 18.35 15.7562 17.49C15.7562 16.64 15.2262 15.82 14.2762 15.19C11.7862 13.53 7.58621 13.53 5.09621 15.19Z" />
      <path d="M18.8558 20.75C18.5058 20.75 18.1958 20.51 18.1258 20.15C18.0458 19.74 18.3058 19.35 18.7058 19.26C19.3358 19.13 19.9158 18.88 20.3658 18.53C20.9358 18.1 21.2458 17.56 21.2458 16.99C21.2458 16.42 20.9358 15.88 20.3758 15.46C19.9358 15.12 19.3858 14.88 18.7358 14.73C18.3358 14.64 18.0758 14.24 18.1658 13.83C18.2558 13.43 18.6558 13.17 19.0658 13.26C19.9258 13.45 20.6758 13.79 21.2858 14.26C22.2158 14.96 22.7458 15.95 22.7458 16.99C22.7458 18.03 22.2058 19.02 21.2758 19.73C20.6558 20.21 19.8758 20.56 19.0158 20.73C18.9558 20.75 18.9058 20.75 18.8558 20.75Z" />
    </svg>
  );
}

ProfileUserIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ProfileUserIcon;
