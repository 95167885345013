import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { login as loginApi } from "../services/apiAuth";

export function useLogin() {
  const queryClient = useQueryClient();

  const { mutate: login, isPending } = useMutation({
    mutationFn: ({ email, password }) => loginApi({ email, password }),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["isAuthenticated"] });

      /* toast.success("Your are logged in"); */
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return { login, isPending };
}
