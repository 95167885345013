import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { useSearchCountries } from "../../../../hooks/useSearchCountries";
import Input from "../../../../shared/inputs/Input";
import PhotoInput from "../../../../shared/inputs/PhotoInput";
import Loader from "../../../../shared/loader/Loader";

function BasicInformation() {
  const {
    formMethods,
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
    isPending,
  } = useOutletContext();

  const { control, setError, resetField, getValues, setValue, trigger } =
    formMethods;

  // Country autocomplete
  const [openCountryList, setOpenCountryList] = useState(false);
  const [countryName, setCountryName] = useState("");
  const countryList = useOutsideClick(() => setOpenCountryList(false));

  const { data: searchedCountries, isLoading: isLoadingSearchedCountries } =
    useSearchCountries({ name: countryName });

  const handleUploadProfilePicture = (e) => {
    const file = e.target?.value;

    uploadUserPicture(file, {
      onSuccess: () => {
        resetField("profilePicture");
      },
      onError: (err) => {
        if (err?.code === 400)
          setError(
            "profilePicture",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
        resetUploadUserPicture();
      },
    });
  };

  const location = useLocation();
  const initialPayload = location?.state?.payload;

  return (
    <div>
      <div className="-m-16 flex">
        <div className="p-16">
          <h1 className="text-2xl font-extrabold">1. Basic Information</h1>
          <div className="mt-8">
            <PhotoInput
              size={48}
              control={control}
              id="profilePicture"
              controllerName="profilePicture"
              rules={{
                onChange: handleUploadProfilePicture,
              }}
              isUploading={isUploadingUserPicture}
              disabled={isUploadingUserPicture || isPending}
              //src={uploadedUserPicture?.result}
              src={initialPayload?.profilePicture}
            />
          </div>
        </div>

        <div className="p-16">
          <div className="-m-3 flex flex-col">
            <div className="p-3">
              <div className="flex">
                <Input
                  type={"text"}
                  label={"First name"}
                  placeholder={"First name"}
                  control={control}
                  controllerName={"firstName"}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />

                <Input
                  type={"text"}
                  label={"Last name"}
                  placeholder={"Last name"}
                  control={control}
                  controllerName={"lastName"}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />
              </div>
            </div>

            <div className="p-3">
              <Input
                type={"email"}
                label={"E-mail"}
                placeholder={"Enter company email address"}
                control={control}
                controllerName={"email"}
                resetField={resetField}
                disabled={isUploadingUserPicture || isPending}
              />
            </div>

            <div className="p-3">
              <Input
                type={"tel"}
                label={"Phone number"}
                placeholder={"Enter company phone number"}
                control={control}
                controllerName={"company.companyPhone"}
                resetField={resetField}
                disabled={isUploadingUserPicture || isPending}
              />
            </div>

            <div className="p-3">
              <Input
                type={"text"}
                label={"Occupation"}
                placeholder={"Enter company occupation"}
                control={control}
                controllerName={"Occupation"}
                resetField={resetField}
                disabled={isUploadingUserPicture || isPending}
              />
            </div>

            <div className="p-3">
              <div className="relative">
                <Input
                  label={"Location"}
                  type={"hidden"}
                  control={control}
                  controllerName={"company.countryId"}
                  id={"countryId"}
                  resetField={resetField}
                  disabled={isUploadingUserPicture || isPending}
                />

                <Input
                  label={"Location"}
                  type={"text"}
                  control={control}
                  autoComplete={"off"}
                  controllerName={"company.countryName"}
                  id={"countryName"}
                  placeholder={"Search company location"}
                  rules={{
                    validate: () => {
                      return (
                        getValues("company.countryId") ||
                        "Please select a location"
                      );
                    },
                    onChange: (e) => {
                      setOpenCountryList(true);
                      setCountryName(e.target?.value);
                    },
                    onBlur: () => {
                      setValue(
                        "company.countryName",
                        searchedCountries?.[0]
                          ? searchedCountries?.[0]?.name
                          : "",
                      );
                      setValue(
                        "company.countryId",
                        searchedCountries?.[0]
                          ? searchedCountries?.[0]?.id
                          : "",
                      );
                      trigger("company.countryName");
                    },
                  }}
                  resetField={() => {
                    resetField("company.countryId");
                    resetField("company.countryName");
                  }}
                  //disabled={isUploadingUserPicture || isPending}
                  disabled={isUploadingUserPicture || isPending}
                />
                {openCountryList && (
                  <div
                    ref={countryList}
                    className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                  >
                    {isLoadingSearchedCountries && (
                      <div className="p-4">
                        <Loader />
                      </div>
                    )}

                    {searchedCountries?.map((country) => (
                      <div
                        key={country?.id}
                        className="ingml-btn hover:bg-blue-b-50"
                        onClick={() => {
                          setValue("company.countryId", country?.id);
                          setValue("company.countryName", country?.name);

                          setOpenCountryList(false);
                        }}
                      >
                        {country?.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BasicInformation.propTypes = {
  step: PropTypes.number,
};

export default BasicInformation;
