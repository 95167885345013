export const EmploymentTypeEnum = {
  0: "None",
  1: "FullTime",
  2: "PartTime",
  3: "Internship",
  4: "Freelance",
  5: "Subcontracting",
};

export const EmploymentTypeArray = [
  { id: 0, value: 0, name: "None" },
  { id: 1, value: 1, name: "Full Time" },
  { id: 2, value: 2, name: "Part Time" },
  { id: 3, value: 3, name: "Internship" },
  { id: 4, value: 4, name: "Freelance" },
  { id: 5, value: 5, name: "Subcontracting" },
];

export const ExperienceLevelEnum = {
  0: "None",
  1: "Internship",
  2: "Junior",
  3: "Confirmed",
  4: "Senior",
  5: "Expert",
  6: "Director",
  7: "Executive",
};

export const GenderEnum = {
  0: "Unknown",
  1: "Male",
  2: "Female",
};

export const genderArray = [
  { id: 0, name: "Unknown", value: 0 },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 },
];

export const LanguageLevelEnum = {
  0: "None",
  1: "Basic",
  2: "Intermediate",
  3: "Fluent",
  4: "Native",
};

export const languageLevelArray = [
  { value: 1, name: "Basic", label: "Basic" },
  { value: 2, name: "Intermediate", label: "Intermediate" },
  { value: 3, name: "Fluent", label: "Fluent" },
  { value: 4, name: "Native", label: "Native" },
];

export const LanguageLevelTypeEnum = {
  0: "None",
  1: "Debutant",
  2: "Intermediate",
  3: "Avancé",
  4: "Courant",
};

export const scoresArray = [
  { id: 0, value: "6.0", name: "6.0" },
  { id: 1, value: "6.5", name: "6.5" },
  { id: 2, value: "7.0", name: "7.0" },
  { id: 3, value: "7.5", name: "7.5" },
  { id: 4, value: "8.0", name: "8.0" },
  { id: 5, value: "8.5", name: "8.5" },
  { id: 6, value: "9.0", name: "9.0" },
  { id: 7, value: "9.5", name: "9.5" },
  { id: 8, value: "10", name: "10" },
];

export const WorkLocationEnum = {
  0: "None",
  1: "On Site",
  2: "Full Remote",
  3: "Hybrid",
};

export const workLocationArray = [
  { id: "wl-on-site", value: 1, name: "On Site" },
  { id: "wl-full-remote", value: 2, name: "Full Remote" },
  { id: "wl-hybrid", value: 3, name: "Hybrid" },
];

export const ContractTypeEnum = {
  0: "None",
  1: "CDI",
  2: "CDD",
  3: "Freelance",
};

export const contractTypeArray = [
  { id: 1, value: 1, name: "CDI" },
  { id: 2, value: 2, name: "CDD" },
  { id: 3, value: 3, name: "Contrat de prestation de services" },
];

export const leaveNoticeArray = [
  { id: 1, value: 1, key: "ASAP", name: "ASAP" },
  { id: 2, value: 2, key: "One month", name: "One month" },
  { id: 3, value: 3, key: "Thee months", name: "Three months" },
];
export const LeaveNoticeEnum = {
  0: "None",
  1: "ASAP",
  2: "1 month",
  3: "3 months",
};

export const experienceLevelArray = [
  { id: "expl-none", value: 0, key: "None", name: "None", count: "32" },
  {
    id: "expl-internship",
    value: 1,
    key: "Internship",
    name: "Internship",
    count: "32",
  },
  { id: "expl-junior", value: 2, key: "Junior", name: "Junior", count: "32" },
  {
    id: "expl-confirmed",
    value: 3,
    key: "Confirmed",
    name: "Confirmed",
    count: "32",
  },
  { id: "expl-senior", value: 4, key: "Senior", name: "Senior", count: "32" },
  { id: "expl-expert", value: 5, key: "Expert", name: "Expert", count: "32" },
  {
    id: "expl-director",
    value: 6,
    key: "Director",
    name: "Director",
    count: "32",
  },
  {
    id: "expl-executive",
    value: 7,
    key: "Executive",
    name: "Executive",
    count: "32",
  },
];

export const mobilitiesArray = [
  { id: "mob-local", value: 1, key: "Local", name: "Local" },
  {
    id: "mob-international",
    value: 2,
    key: "International",
    name: "International",
  },
];

// filter list
export const filterByArray = [
  { id: 0, value: 0, key: "Sort by (Default)", name: "Sort by (Default)" },
  { id: 1, value: 1, key: "Sort by (Featured)", name: "Sort by (Featured)" },
  { id: 2, value: 2, key: "Sort by (Urgent)", name: "Sort by (Urgent)" },
  { id: 3, value: 3, key: "Sort by (Post Date)", name: "Sort by (Post Date)" },
];

export const jobStatusAvailabilty = [
  {
    id: 0,
    value: 0,
    key: "betweeen today & 15 days",
    name: "betweeen today & 15 days",
  },
  {
    id: 1,
    value: 1,
    key: "between today & 1 month",
    name: "between today & 1 month",
  },
  {
    id: 2,
    value: 2,
    key: "between today & 2 months",
    name: "between today & 2 months",
  },
  {
    id: 3,
    value: 3,
    key: "between today & 3 months",
    name: "between today & 3 months",
  },
  {
    id: 4,
    value: 4,
    key: "between today & 6 months",
    name: "between today & 6 months",
  },
  {
    id: 5,
    value: 5,
    key: "> 6 months",
    name: "> 6 months",
  },
];
export const jobStatusStep = [
  { id: 0, value: 0, key: "Shortlisted", name: "Shortlisted" },
  { id: 1, value: 1, key: "Interview", name: "Interview" },
  { id: 2, value: 2, key: "Assessment", name: "Assessment" },
  { id: 3, value: 3, key: "Psychometric Test", name: "Psychometric Test" },
  { id: 4, value: 4, key: "Technical Test", name: "Technical Test" },
  { id: 5, value: 5, key: "Client Interview", name: "Client Interview" },
  { id: 6, value: 6, key: "Offer", name: "Offer" },
  { id: 7, value: 7, key: "Integration", name: "Integration" },
  {
    id: 8,
    value: 8,
    key: "Rejected or Archived",
    name: "Rejected or Archived",
  },
];

export const keywordsArray = [
  { id: 0, value: 0, key: "User-Centric Design", name: "User-Centric Design" },
  {
    id: 1,
    value: 1,
    key: "Design Tools Proficiency",
    name: "Design Tools Proficiency",
  },
  { id: 2, value: 2, key: "Visual Design", name: "Visual Design" },
  { id: 3, value: 3, key: "Interaction Design", name: "Interaction Design" },
  { id: 4, value: 4, key: "Prototyping", name: "Prototyping" },
  { id: 5, value: 5, key: "User research", name: "User research" },
];
export const applicationQuestions = [
  {
    question: "Are you allowed to work in this country?",
    key: "allowedToWorkInCounty",
    answers: [
      { id: "allowed-to-work", name: "Yes", value: true },
      { id: "not-allowed-to-work", name: "No", value: false },
    ],
  },
  {
    question: "Are you ?",
    key: "preferredContractType",
    answers: contractTypeArray,
  },
  {
    question: "Work Location",
    key: "preferredWorkLocation",
    answers: workLocationArray,
  },
  {
    question: "Availability",
    key: "notice",
    answers: leaveNoticeArray,
  },
  {
    question: "Experience Level",
    key: "experienceLevel",
    answers: experienceLevelArray.filter((item) => item.value != 0),
  },
  {
    question: "Mobility",
    key: "mobility",
    answers: mobilitiesArray,
  },
];

export const socialMediaArray = [
  {
    id: 1,
    value: 1,
    name: "Website",
    placeholder: "Website url",
  },

  {
    id: 2,
    value: 2,
    name: "Linkedin",
    placeholder: "Linkedin url",
  },
  {
    id: 3,
    value: 3,
    name: "Github",
    placeholder: "Github url",
  },
];

// Qualification
export const qualificationArray = [
  { id: 0, value: 0, key: "none", name: "None" },
  {
    id: 1,
    value: 1,
    key: "High school or equivalent",
    name: "High school or equivalent",
  },
  { id: 2, value: 2, key: "Certification", name: "Certification" },
  { id: 3, value: 3, key: "Bachelor", name: "Bachelor" },
  { id: 4, value: 4, key: "Engineering Degree", name: "Engineering Degree" },
  { id: 5, value: 5, key: "Master", name: "Master" },
  { id: 6, value: 6, key: "Other", name: "Other" },
];

export const QualificationTypeEnum = {
  0: "None",
  1: "High School or Equivalent",
  2: "Certification",
  3: "Bachelor",
  4: "Engineering Degree",
  5: "Master",
  6: "Other",
};
export const HardSkillsArray = [
  { value: 1, name: "Beginner", label: "Beginner" },
  { value: 2, name: "Confirmed", label: "Confirmed" },
  { value: 3, name: "Senior", label: "Senior" },
  { value: 4, name: "Expert", label: "Expert" },
];

export const HardSkillsTypeEnum = {
  0: "None",
  1: "Beginner",
  2: "Confirmed",
  3: "Senior",
  4: "Expert",
};

export const SoftSkillsArray = [
  { value: 1, name: "Apprentice", label: "Apprentice" },
  { value: 2, name: "Experienced", label: "Experienced" },
  { value: 3, name: "Autonomous", label: "Autonomous" },
  { value: 4, name: "Model", label: "Model" },
];

export const SoftSkillsTypeEnum = {
  0: "None",
  1: "Apprentice",
  2: "Experienced",
  3: "Autonomous",
  4: "Model",
};

export const SkillTypeEnum = {
  0: "Undefined",
  1: "SoftSkill",
  2: "HardSkill",
};

export const companySizeArray = [
  { id: 1, value: 1, key: "Small", name: "0-1 employee" },
  { id: 2, value: 2, key: "Medium", name: "2-10 employees" },
  { id: 3, value: 3, key: "Large", name: "11-50 employees" },
  { id: 4, value: 4, key: "xl-Large", name: "51-200 employees" },
  { id: 5, value: 5, key: "2xl-Large", name: "201-500 employees" },
  { id: 6, value: 6, key: "3xl-Large", name: "> 500 employees" },
];

export const CompanySizeEnum = {
  0: "Undefined",
  1: "0-1 employee",
  2: "2-10 employees",
  3: "11-50 employees",
  4: "51-200 employees",
  5: "201-500 employees",
  6: "> 500 employees",
};

export const RATETYPE_ENUM = {
  1: "Monthly",
  2: "Daily",
  3: "Hourly",
};

export const RESUME_ACTION_TYPES_ENUM = {
  1: "Note",
  2: "Reminder",
  3: "Link",
};

export const APPLICATION_STATUS_ARRAY = [
  { id: "1", value: 1, name: "New" },
  { id: "2", value: 2, name: "Shortlisted" },
  { id: "3", value: 3, name: "Interview" },
  { id: "4", value: 4, name: "Assessment" },
  { id: "5", value: 5, name: "Client Interview" },
  { id: "6", value: 6, name: "Approved" },
  { id: "7", value: 7, name: "Rejected" },
];

export const ADD_TO_PROJECT_APPLICATION_STATUS_ARRAY = [
  { id: "2", value: 2, name: "Shortlisted" },
  { id: "3", value: 3, name: "Interview" },
  { id: "4", value: 4, name: "Assessment" },
  { id: "5", value: 5, name: "Client Interview" },
  { id: "6", value: 6, name: "Approved" },
  { id: "7", value: 7, name: "Rejected" },
];

export const jobDetailStatusFilter = [
  { id: "draft", key: "Draft", title: "Draft", name: "Draft", value: "0" },
  {
    id: "published",
    key: "Published",
    title: "Published",
    name: "Published",
    value: "1",
  },
  {
    id: "reOpened",
    key: "ReOpened",
    title: "ReOpened",
    name: "ReOpened",
    value: "2",
  },
  { id: "onHold", key: "OnHold", title: "OnHold", name: "OnHold", value: "3" },
  { id: "filled", key: "Filled", title: "Filled", name: "Filled", value: "4" },
  { id: "closed", key: "Closed", title: "Closed", name: "Closed", value: "5" },
  {
    id: "unpublished",
    key: "Unpublished",
    title: "Unpublished",
    name: "Unpublished",
    value: "6",
  },
  {
    id: "expired",
    key: "Expired",
    title: "Expired",
    name: "Expired",
    value: "7",
  },
];
