import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { useSearchJobDescription } from "../../hooks/useSearchJobDescription";
import Divider from "../../shared/Divider";
import Loader from "../../shared/loader/Loader";
import Pagination from "../../shared/Pagination";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  WorkLocationEnum,
} from "../../utils/constants";
import { getFileType } from "../../utils/helpers";

function MatchJobs() {
  const POSTS_PER_PAGE = 4;
  const QUERY_STRING = "jobDescription_search_page";

  const { isLoading, data } = useSearchJobDescription({
    queryString: QUERY_STRING,
    size: POSTS_PER_PAGE,
  });

  const { results, totalCount } = data || {};

  if (isLoading)
    return (
      <section>
        <div className="page-px py-16">
          <Loader />
        </div>
      </section>
    );

  if (!results?.length) return null;

  if (results?.length > 0)
    return (
      <section>
        <div className="page-px my-16">
          <div className="page-torn-25"></div>
          <div className="bg-[#F7F9FB] py-16">
            <div className="mb-12">
              <p className="mb-2 text-center uppercase text-blue-b-primary">
                Explore your
              </p>
              <h1 className="text-center text-[2.75rem] font-medium leading-[3.25rem]">
                Perfect Career Match
              </h1>
            </div>

            <Pagination
              queryString={QUERY_STRING}
              size={POSTS_PER_PAGE}
              totalCount={totalCount}
            >
              <div className="-m-3 flex items-center">
                <div className="p-3">
                  <Pagination.PrevPage>
                    <div className="h-9 w-9">
                      <HiChevronLeft className="h-full w-full stroke-[1.5px]" />
                    </div>
                  </Pagination.PrevPage>
                </div>

                <div className="flex-1 p-3">
                  <div className="-m-3 flex flex-wrap justify-center">
                    {results?.map((item) => (
                      <div key={item?.id} className="w-1/3 p-3">
                        <div className="flex h-full flex-col bg-white p-6 shadow-2xl shadow-slate-300">
                          <div className="overflow-hidden">
                            <div className="-m-1.5 flex items-center">
                              {getFileType(item?.imageURL) === "image" && (
                                <div className="p-1.5">
                                  <div className="h-14 w-14 overflow-hidden rounded-md border border-black-g-50">
                                    <img
                                      className="w-ful h-full object-cover object-center"
                                      src={item?.imageURL}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="flex-1 p-1.5">
                                {item?.title && (
                                  <div className="font-semibold">
                                    {item?.title}
                                  </div>
                                )}
                                <div className="flex items-center space-x-2 text-black-g-100">
                                  {item?.company?.name && (
                                    <div className="text-xs">
                                      {item?.company?.name}
                                    </div>
                                  )}
                                  {/* {item?.numberOfApplications >= 0 && (
                                    <div className="flex items-center space-x-1">
                                      <div className="h-1 w-1 rounded-full bg-black-g-400"></div>
                                      <div className="text-xs">
                                        {item?.numberOfApplications}{" "}
                                        {item?.numberOfApplications <= 1
                                          ? "applicant"
                                          : "applicants"}
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              </div>

                              {/* <div className="p-1.5">
                                <div className="bg-blue-b-50 p-2 text-blue-b-primary">
                                  <div className="text-xs">Match</div>
                                  <div className="text-lg font-bold">93%</div>
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <div className="flex items-center space-x-2 py-3 text-xs font-medium">
                            {item?.employmentType > 0 && (
                              <div className="rounded bg-yellow-y-50 px-2 py-1.5 text-yellow-y-500">
                                {EmploymentTypeEnum[item?.employmentType]}
                              </div>
                            )}

                            {item?.experienceLevel > 0 && (
                              <div className="rounded bg-green-g-50 px-2 py-1.5 text-green-g-500">
                                {ExperienceLevelEnum[item?.experienceLevel]}
                              </div>
                            )}

                            {item?.workLocation > 0 && (
                              <div className="rounded bg-purple-100 px-2 py-1.5 text-purple-900">
                                {WorkLocationEnum[item?.workLocation]}
                              </div>
                            )}
                          </div>

                          <p className="text-sm text-black-g-100">
                            {item?.mainMission?.raw}
                          </p>

                          {item?.skills?.length > 0 && (
                            <div className="-m-1 flex flex-wrap">
                              {item?.skills?.slice(0, 6)?.map((skill) => (
                                <div key={skill?.id} className="p-1">
                                  <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                                    {skill?.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          <div className="mt-auto">
                            <Divider className="my-4" />

                            <div className="flex items-center justify-between">
                              <button className="btn-outline-blue-b-50 rounded py-1.5 text-sm font-medium">
                                Details
                                {/* {`${redirectionUrl}/job-detail/${item.id}`} */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-3">
                  <Pagination.NextPage>
                    <div className="h-9 w-9">
                      <HiChevronRight className="h-full w-full stroke-[1.5px]" />
                    </div>
                  </Pagination.NextPage>
                </div>
              </div>
            </Pagination>
          </div>
          <div className="page-torn-25 -scale-100"></div>
        </div>
      </section>
    );
}

export default MatchJobs;
