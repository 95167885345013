import PropTypes from "prop-types";

function UserIcon({ width, height, className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 28 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9993 14.8747C10.301 14.8747 7.29102 11.8647 7.29102 8.16634C7.29102 4.46801 10.301 1.45801 13.9993 1.45801C17.6977 1.45801 20.7077 4.46801 20.7077 8.16634C20.7077 11.8647 17.6977 14.8747 13.9993 14.8747ZM13.9993 3.20801C11.2693 3.20801 9.04102 5.43634 9.04102 8.16634C9.04102 10.8963 11.2693 13.1247 13.9993 13.1247C16.7293 13.1247 18.9577 10.8963 18.9577 8.16634C18.9577 5.43634 16.7293 3.20801 13.9993 3.20801Z" />
      <path d="M24.0218 26.5417C23.5435 26.5417 23.1468 26.145 23.1468 25.6667C23.1468 21.6417 19.0402 18.375 14.0002 18.375C8.96018 18.375 4.85352 21.6417 4.85352 25.6667C4.85352 26.145 4.45685 26.5417 3.97852 26.5417C3.50018 26.5417 3.10352 26.145 3.10352 25.6667C3.10352 20.685 7.99185 16.625 14.0002 16.625C20.0085 16.625 24.8968 20.685 24.8968 25.6667C24.8968 26.145 24.5002 26.5417 24.0218 26.5417Z" />
    </svg>
  );
}

UserIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default UserIcon;
