import PropTypes from "prop-types";

function LogoIcon({ size, className, src = "/assets/logo-icon-blue.svg" }) {
  const logoIconWidth = `${parseFloat(size) / 4}rem`;
  const style = size ? { width: logoIconWidth, maxWidth: logoIconWidth } : {};

  return (
    <div className={className}>
      <img className="block h-full w-full" style={style} src={src} />
    </div>
  );
}

LogoIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  src: PropTypes.string,
};

export default LogoIcon;
