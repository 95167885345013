import PropTypes from "prop-types";
import { useOutletContext } from "react-router-dom";
import CustomDatePick from "../../../../shared/datepicker/CustomDatePick";
import Input from "../../../../shared/inputs/Input";
import SelectBox from "../../../../shared/inputs/SelectBox";
import TextArea from "../../../../shared/inputs/TextArea";
import { useGetIndustries } from "../../../../hooks/useGetIndustries";
import { companySizeArray } from "../../../../utils/constants";

function CompanyProfile() {
  const { formMethods, isUploadingUserPicture, isPending } = useOutletContext();
  const { control, resetField } = formMethods;

  const { isLoading: isLoadingIndustries, data: industries } =
    useGetIndustries();

  return (
    <div className="max-w-screen-sm">
      <h1 className="mb-16 text-2xl font-extrabold">2. Company profile</h1>
      <div className="-m-3 flex flex-col">
        <div className="p-3">
          <Input
            type={"text"}
            label={"Company name"}
            placeholder={"Enter company name"}
            control={control}
            controllerName={"company.employerName"}
            resetField={resetField}
            disabled={isUploadingUserPicture || isPending}
          />
        </div>

        <div className="p-3">
          <SelectBox
            label="Company industry"
            placeholder="Select company industry"
            id="industry"
            control={control}
            controllerName="company.industry"
            rules={{
              required: "Please select an industry",
            }}
            isLoading={isLoadingIndustries}
            options={{
              data: industries,
              key: "id",
              value: "id",
              name: "name",
            }}
            resetField={resetField}
            disabled={isUploadingUserPicture || isPending}
          />
        </div>

        <div className="p-3">
          <CustomDatePick
            controllerName={"companyFoundedYear"}
            labelName={"Founded year"}
            views={["year", "month"]}
            inputFormat={"MM/yyyy"}
            control={control}
            className="flex-1"
            disabled={isUploadingUserPicture || isPending}
          />
        </div>

        <div className="p-3">
          <SelectBox
            label="Size"
            placeholder="Select company size"
            id="companySize"
            control={control}
            controllerName="company.companySize"
            rules={{
              required: "Please select company size",
            }}
            options={{
              data: companySizeArray,
              key: "id",
              value: "value",
              name: "name",
            }}
            resetField={resetField}
            disabled={isUploadingUserPicture || isPending}
          />
        </div>

        <div className="p-3">
          <TextArea
            label={"About your company"}
            placeholder={"Describe your business ..."}
            control={control}
            controllerName={"about"}
            resetField={resetField}
            disabled={isUploadingUserPicture || isPending}
          />
        </div>
      </div>
    </div>
  );
}

CompanyProfile.propTypes = {
  step: PropTypes.number,
};

export default CompanyProfile;
