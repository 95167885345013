import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useResetPassword } from "../../hooks/useResetPassword";
import Input from "../../shared/inputs/Input";
import Loader from "../../shared/loader/Loader";

function ResetPasswordForm() {
  const { control, getValues, handleSubmit, resetField, reset } = useForm();

  const { error, resetPassword, isPending, isSuccess, isError } =
    useResetPassword();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const onSubmit = ({ newPassword }) => {
    if (!token || !email) return;

    resetPassword(
      { email, token, newPassword },
      {
        onSettled: () => reset(),
      },
    );
  };

  const onError = () => {
    toast.error((t) => (
      <div className="flex items-start">
        <p className="mx-2">
          Oops! There are errors in your form submission. Please review and
          correct them.
        </p>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="rounded border bg-slate-200 px-2 py-1 text-xs hover:border-slate-200 hover:bg-white"
        >
          close
        </button>
      </div>
    ));
  };

  if (!token || !email)
    return (
      <div className="rounded-md bg-red-o-50 p-4 text-center text-lg font-medium text-red-o-primary">
        Invalid reset password link
      </div>
    );

  if (!isSuccess && !isError)
    return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="-m-3 flex flex-col">
          <div className="p-3">
            <Input
              label="New password"
              type="password"
              id="newPassword"
              placeholder="min 8 characters"
              autoComplete="new-password"
              controllerName="newPassword"
              control={control}
              rules={{
                required: "New password is required",
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#&!/*-+=?%_]).{8,}$/,
                  message:
                    "Password needs at least one uppercase letter, one lowercase letter, one number, and one of the characters # & ! / * - + = ? %_",
                },
              }}
              showPasswordStrength={true}
              resetField={resetField}
              disabled={isPending}
            />
          </div>

          <div className="p-3">
            <Input
              label="Repeat new password"
              type="password"
              id="confirm_password"
              placeholder="Repeat new password"
              autoComplete="off"
              controllerName="confirm_password"
              control={control}
              rules={{
                required: "Please confirm the password",
                validate: (value) =>
                  value === getValues()?.newPassword ||
                  "Passwords need to match",
              }}
              resetField={resetField}
              disabled={isPending}
            />
          </div>

          <div className="p-3">
            <button
              className="btn-blue-b-primary w-full text-lg font-medium"
              disabled={isPending}
            >
              {isPending ? <Loader color="#ffffff" /> : "Set new password"}
            </button>
          </div>
        </div>
      </form>
    );

  if (isError && error?.message)
    return (
      <div>
        <div className="rounded-md bg-red-o-50 p-4 text-center text-lg font-medium text-red-o-primary">
          {error?.message}
        </div>

        <Link
          className="btn-blue-b-primary mx-auto mt-8 block text-center"
          to={"/forgot-password"}
        >
          Request new link
        </Link>
      </div>
    );

  if (isSuccess)
    return (
      <div>
        <div className="rounded-md bg-green-g-50 p-4 text-lg font-medium text-green-g-primary">
          Your password was reset successfully.
        </div>
        <Link
          className="btn-blue-b-primary mx-auto mt-8 block text-center text-lg font-medium"
          to={"/login"}
        >
          Log in
        </Link>
      </div>
    );
}

export default ResetPasswordForm;
