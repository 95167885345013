import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  HiCheck,
  HiEnvelope,
  HiMapPin,
  HiPhone,
  HiUser,
} from "react-icons/hi2";
import { getFileType } from "../../utils/helpers";
import TextArea from "../../shared/inputs/TextArea";
import Loader from "../../shared/loader/Loader";
import FileInput from "../../shared/inputs/FileInput";
import { useUploadApplicationResume } from "../../hooks/useUploadApplicationResume";
import { useUserResume } from "../../hooks/useUserResume";
import { useSubmitApplication } from "../../hooks/useSubmitApplication";
import Modal from "../../shared/Modal";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "../../hooks/useIsAuthenticated";

function ApplicationForm({ jobDescriptionId }) {
  const { redirectionUrl } = useIsAuthenticated();
  const { isLoading, data } = useUserResume();
  const { id, profilePic, firstName, lastName, contactInfo } = data || {};

  const { handleSubmit, control, setError, resetField, reset } = useForm();
  const {
    isPending,
    submitApplication,
    isSuccess: isSubmitted,
  } = useSubmitApplication();

  const { uploadResume, isUploadingResume, uploadedResume, resetUploadResume } =
    useUploadApplicationResume();

  const handleUploadResume = (e) => {
    const file = e.target.files[0];

    resetUploadResume();

    uploadResume(file, {
      onSuccess: () => {},
      onError: (err) => {
        if (err?.code === 400)
          setError(
            "fileUrl",
            { type: "Unsupported", message: err?.message },
            { shouldFocus: true },
          );
      },
    });
  };

  const onSubmit = (data) => {
    submitApplication(
      {
        ...data,
        jobDescriptionId,
        resumeId: id,
        pdfResume: uploadedResume?.result,
      },
      { onSuccess: () => reset() },
    );
  };

  if (isLoading)
    return (
      <div className="m-8">
        <Loader />
      </div>
    );

  if (isSubmitted)
    return (
      <div>
        <div className="text-center">
          <div className="mx-auto h-20 w-20 rounded-full bg-blue-b-50 p-2 text-blue-b-primary">
            <HiCheck className="h-full w-full" />
          </div>
          <h1 className="my-8 text-3xl font-semibold">Application sent!</h1>
          <p>
            Thank you for applying for this job, we&apos;ve sent your
            application to the hiring manager
          </p>
        </div>

        <div className="my-8 overflow-hidden">
          <div className="-m-3 flex justify-center">
            <div className="p-3">
              <Modal.Close className="btn-outline-primary">Back</Modal.Close>
            </div>
            <div className="p-3">
              <Link
                className="btn-blue-b-primary block"
                to={`${redirectionUrl}/jobs`}
              >
                Jobs
              </Link>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="mb-16 text-center">
        <h1 className="text-xl font-semibold">
          Apply with your Devadoot Profile
        </h1>
        <p>
          Your application will be submitted along with your Profile, your
          resume and your cover letter.
        </p>
      </div>

      <div className="overflow-hidden">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="-m-4 flex flex-wrap">
            <div className="flex-1 p-4">
              <div className="flex flex-col items-center">
                <div className="mt-8 h-28 w-28 overflow-hidden rounded-full">
                  {getFileType(profilePic) === "image" ? (
                    <img
                      className="h-full w-full object-cover object-center"
                      src={profilePic}
                    />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-[#C4C4C4] p-6 text-black-g-50">
                      <HiUser className="h-full w-full" />
                    </div>
                  )}
                </div>

                <div className="text-lg font-medium">{`${firstName} ${lastName}`}</div>
                <div className="mt-2">
                  <div className="-mx-3 -my-1 flex flex-wrap justify-center">
                    <div className="px-3 py-1">
                      <div className="flex items-center space-x-2">
                        <HiMapPin className="h-4 w-4 text-black-g-100" />
                        <div>{`${contactInfo?.address?.city?.name}, ${contactInfo?.address?.country?.name}`}</div>
                      </div>
                    </div>

                    <div className="px-3 py-1">
                      <div className="flex items-center space-x-2">
                        <HiEnvelope className="h-4 w-4 text-black-g-100" />
                        <div>{contactInfo?.email}</div>
                      </div>
                    </div>
                    <div className="px-3 py-1">
                      <div className="flex items-center space-x-2">
                        <HiPhone className="h-4 w-4 text-black-g-100" />
                        <div>{contactInfo?.phone}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full p-4 transition-all duration-300 sm:w-1/2">
              <div className="-m-4 flex flex-col">
                <div className="p-4">
                  <FileInput
                    label="Upload your resume"
                    id={"pdfResume"}
                    controllerName={"pdfResume"}
                    control={control}
                    btnLabel={"Select PDF file"}
                    uploadFile={handleUploadResume}
                    isUploading={isUploadingResume}
                    disabled={isUploadingResume || isPending}
                  />
                </div>
                <div className="p-4">
                  <TextArea
                    label="Message"
                    id="message"
                    controllerName="message"
                    control={control}
                    resetField={resetField}
                    containerClassName="border-2 border-black-g-50"
                    rows={6}
                    disabled={isUploadingResume || isPending}
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center p-4">
              <button
                className="btn-blue-b-primary rounded-md"
                disabled={isUploadingResume || isPending}
              >
                Submit application
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

ApplicationForm.propTypes = {
  jobDescriptionId: PropTypes.number,
};

export default ApplicationForm;
