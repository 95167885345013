import { HiCheckBadge } from "react-icons/hi2";
import { useSearchCandidate } from "../../hooks/useSearchCandidate";
import Loader from "../../shared/loader/Loader";
//import Pagination from "../../shared/Pagination";
import { Link } from "react-router-dom";
import Divider from "../../shared/Divider";
import { LeaveNoticeEnum } from "../../utils/constants";
import { getFileType } from "../../utils/helpers";

function MatchCandidates() {
  const POSTS_PER_PAGE = 3;
  const QUERY_STRING = "candidate_search_page";

  const { isLoading, data } = useSearchCandidate({
    queryString: QUERY_STRING,
    size: POSTS_PER_PAGE,
  });

  const { results /* totalCount */ } = data || {};

  if (isLoading)
    return (
      <section>
        <div className="page-px py-16">
          <Loader />
        </div>
      </section>
    );

  if (!results?.length) return null;

  if (results?.length > 0)
    return (
      <section>
        <div className="page-px my-16">
          <div className="page-torn-25"></div>
          <div className="bg-[#F7F9FB] py-16">
            <div className="mb-12">
              <p className="mb-2 text-center uppercase text-blue-b-primary">
                Discover tailored
              </p>
              <h1 className="text-center text-[2.75rem] font-medium leading-[3.25rem]">
                Talent For Your Needs
              </h1>
            </div>

            <div className="my-12">
              <div className="flex items-center justify-center space-x-2">
                <span>To connect with talents</span>
                <Link
                  className="text-blue-b-primary hover:underline"
                  to={"/login"}
                >
                  log in
                </Link>
                <span>or</span>
                <Link
                  className="text-blue-b-primary hover:underline"
                  to={"/signup"}
                >
                  create an account
                </Link>
              </div>
            </div>

            {/* <Pagination
              queryString={QUERY_STRING}
              size={POSTS_PER_PAGE}
              totalCount={totalCount}
            > */}
            <div className="-m-3 flex items-center">
              {/* <div className="p-3">
                <Pagination.PrevPage>
                  <div className="h-9 w-9">
                    <HiChevronLeft className="h-full w-full stroke-[1.5px]" />
                  </div>
                </Pagination.PrevPage>
              </div> */}

              <div className="flex-1 p-3">
                <div className="-m-3 flex flex-wrap justify-center">
                  {results?.map((item) => (
                    <div
                      key={item?.id}
                      className="w-full p-3 lg:w-1/2 xl:w-1/3"
                    >
                      <div className="flex h-full flex-col bg-white p-6 shadow-2xl shadow-slate-300">
                        <div className="overflow-hidden">
                          <div className="-m-1.5 flex items-center">
                            <div className="p-1.5">
                              <div className="h-14 w-14 overflow-hidden rounded-full">
                                <img
                                  className={`w-ful h-full object-cover object-center ${getFileType(item?.profilePic) === "image" ? "blur-sm" : ""}`}
                                  src={
                                    getFileType(item?.profilePic) === "image"
                                      ? item?.profilePic
                                      : "/uploads/avatar.png"
                                  }
                                />
                              </div>
                            </div>

                            <div className="flex-1 p-1.5">
                              <div className="font-semibold">
                                {`${item?.candidate?.firstName} ${item?.candidate?.lastName?.toString()?.charAt(0)}`}
                              </div>
                              <div className="flex items-center space-x-2 text-black-g-100">
                                <div className="text-xs">{item?.jobTitle}</div>

                                {item?.yearsOfExperience > 0 && (
                                  <div className="flex items-center space-x-1">
                                    <div className="h-1 w-1 rounded-full bg-black-g-400"></div>
                                    <div className="text-xs">
                                      {`${item?.yearsOfExperience} ${item?.yearsOfExperience === 1 ? "year" : "years"}`}
                                    </div>
                                  </div>
                                )}

                                {item?.contactInfo?.address?.country?.name && (
                                  <div className="flex items-center space-x-1">
                                    <div className="h-1 w-1 rounded-full bg-black-g-400"></div>
                                    <div className="text-xs">
                                      {
                                        item?.contactInfo?.address?.country
                                          ?.name
                                      }
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            {/* <div className="p-1.5">
                                <div className="bg-blue-b-50 p-2 text-blue-b-primary">
                                  <div className="text-xs">Match</div>
                                  <div className="text-lg font-bold">93%</div>
                                </div>
                              </div> */}
                          </div>
                        </div>

                        <div className="flex items-center space-x-2 py-3 text-xs font-medium">
                          <div className="rounded bg-green-g-50 px-2 py-1.5 text-green-g-500">
                            Disp. {LeaveNoticeEnum[item?.disponibility]}
                          </div>
                          {/* <div className="rounded bg-green-g-50 px-2 py-1.5 text-green-g-500">
                            Qualified
                          </div>
                          <div className="rounded bg-purple-100 px-2 py-1.5 text-purple-900">
                            Available
                          </div>
                          <div className="rounded bg-yellow-y-50 px-2 py-1.5 text-yellow-y-500">
                            Full-time
                          </div> */}
                        </div>

                        {/* <p className="text-sm text-black-g-100">
                          Here, you can add your note about this candidate.
                        </p> */}

                        {item?.hardSkills?.length > 0 && (
                          <div className="-m-1 flex flex-wrap">
                            {item?.hardSkills?.slice(0, 6)?.map((skill) => (
                              <div key={skill?.id} className="p-1">
                                <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                                  {skill?.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="mt-auto">
                          <Divider className="my-4" />

                          <div className="flex items-center justify-between">
                            <Link
                              className="btn-outline-blue-b-50 rounded py-1.5 text-sm font-medium"
                              to={"/signup"}
                              state={{
                                redirectionUrl: "recruiter/candidates",
                              }}
                            >
                              Details
                            </Link>
                            <div className="flex items-center space-x-1">
                              <HiCheckBadge className="h-5 w-5 text-blue-b-primary" />
                              <div className="text-sm text-blue-b-primary">
                                Verified Expert
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* <div className="p-3">
                <Pagination.NextPage>
                  <div className="h-9 w-9">
                    <HiChevronRight className="h-full w-full stroke-[1.5px]" />
                  </div>
                </Pagination.NextPage>
              </div> */}
            </div>
            {/* </Pagination> */}
          </div>
          <div className="page-torn-25 -scale-100"></div>
        </div>
      </section>
    );
}

export default MatchCandidates;
