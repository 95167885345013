import PropTypes from "prop-types";

function ChartIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M4.6665 51.333H51.3332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.75 9.33366V51.3337H33.25V9.33366C33.25 6.76699 32.2 4.66699 29.05 4.66699H26.95C23.8 4.66699 22.75 6.76699 22.75 9.33366Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 23.3337V51.3337H16.3333V23.3337C16.3333 20.767 15.4 18.667 12.6 18.667H10.7333C7.93333 18.667 7 20.767 7 23.3337Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6665 34.9997V51.333H48.9998V34.9997C48.9998 32.433 48.0665 30.333 45.2665 30.333H43.3998C40.5998 30.333 39.6665 32.433 39.6665 34.9997Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChartIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ChartIcon;
