import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  HiIdentification,
  HiOutlineIdentification,
  HiOutlineStar,
  HiOutlineUser,
  HiStar,
  HiUser,
} from "react-icons/hi2";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../../shared/logo/Logo";
import { useSignupUser } from "../../../../hooks/useSignupUser";
import { useUploadUserPicture } from "../../../../hooks/useUploadUserPicture";

const STEP_MENU = [
  {
    icon_outline: <HiOutlineUser className="h-full w-full stroke-1" />,
    icon_solid: <HiUser className="h-full w-full" />,
    id: "basic-information",
    name: "Basic information",
    href: "basic-information",
  },
  {
    icon_outline: (
      <HiOutlineIdentification className="h-full w-full stroke-1" />
    ),
    icon_solid: <HiIdentification className="h-full w-full" />,
    id: "contacts",
    name: "Company profile",
    href: "company-profile",
  },
  {
    icon_outline: <HiOutlineStar className="h-full w-full stroke-1" />,
    icon_solid: <HiStar className="h-full w-full" />,
    id: "on-the-web",
    name: "On the web",
    href: "social-media",
  },
];

function ProfileSetup() {
  const location = useLocation();
  const initialPayload = location?.state?.payload;

  const formMethods = useForm({
    values: {
      email: initialPayload?.email,
      firstName: initialPayload?.firstName,
      lastName: initialPayload?.lastName,
      profilePicture: initialPayload?.profilePicture,
      company: {
        employerName: initialPayload?.employerName,
        companyPhone: initialPayload?.companyPhone,
        countryId: initialPayload?.countryId,
        countryName: initialPayload?.countryName,
        industry: initialPayload?.industry,
      },
    },
  });

  const currentHref = location?.pathname?.split("/")?.slice(-1)?.join("/");
  const [currentStep, setCurrentStep] = useState(
    STEP_MENU?.findIndex((item) => item?.href === currentHref) + 1,
  );

  const navigate = useNavigate();

  useEffect(
    function () {
      const to = STEP_MENU?.[currentStep - 1]?.href;

      navigate(to, { state: { payload: initialPayload } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, navigate],
  );

  const {
    uploadUserPicture,
    isUploadingUserPicture,
    uploadedUserPicture,
    resetUploadUserPicture,
  } = useUploadUserPicture();

  const { signupUser, isPending } = useSignupUser();

  const onSubmit = (data) => {
    signupUser(
      {
        ...data,
        password: initialPayload?.password,
        role: initialPayload?.role,
      },
      {
        onSuccess: () => {
          resetUploadUserPicture();
          formMethods.reset();
          navigate("/signup/confirm-email", {
            state: {
              email: data?.email,
            },
            replace: true,
          });
        },
      },
    );
  };

  const onError = (errs) => {
    console.log(errs);
  };

  return (
    <div className="flex min-h-screen">
      <div className="p-16 shadow-xl">
        <Logo className={"m-auto w-20"} />

        <ul className="mt-32">
          {STEP_MENU?.map((item, index) => (
            <li key={item?.id}>
              <div className="flex space-x-4">
                {item?.icon_outline && (
                  <div className="flex flex-col items-center text-blue-b-primary">
                    <div className="h-8 w-8">
                      {currentStep === index + 1
                        ? item?.icon_solid
                        : item?.icon_outline}
                    </div>
                    {index !== STEP_MENU?.length - 1 && (
                      <div className="my-2 h-10 w-px border-l border-dotted border-blue-b-primary"></div>
                    )}
                  </div>
                )}
                <div
                  className={`mt-2 text-lg ${currentStep === index + 1 ? "text-blue-b-primary" : ""}`}
                >
                  {item?.name}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-1 flex-col p-16">
        <form onSubmit={formMethods?.handleSubmit(onSubmit, onError)}>
          <div className="flex-1">
            <Outlet
              context={{
                formMethods,
                uploadUserPicture,
                isUploadingUserPicture,
                uploadedUserPicture,
                isPending,
              }}
            />
          </div>

          <div className="mt-8">
            <div className="-m-3 flex justify-center">
              <div className="p-3">
                {currentStep > 1 && (
                  <button
                    type="button"
                    className="btn-outline-primary h-full rounded-md px-10"
                    disabled={isPending || isUploadingUserPicture}
                    onClick={() => {
                      if (currentStep > 1) setCurrentStep((step) => step - 1);
                    }}
                  >
                    Back
                  </button>
                )}
              </div>

              <div className="p-3">
                <button
                  type={
                    currentHref === STEP_MENU[STEP_MENU.length - 1].href
                      ? "submit"
                      : "button"
                  }
                  className="btn-blue-b-primary h-full rounded-md px-10"
                  disabled={
                    !formMethods?.formState?.isValid ||
                    isPending ||
                    isUploadingUserPicture
                  }
                  onClick={async () => {
                    const isValid = await formMethods?.trigger();

                    if (!isValid) {
                      toast?.error(
                        "Almost there! Please fix the highlighted errors to continue.",
                      );
                      return;
                    }

                    if (currentStep < STEP_MENU?.length)
                      setCurrentStep((step) => step + 1);
                  }}
                >
                  {currentStep < STEP_MENU?.length ? "Next" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileSetup;
