import PropTypes from "prop-types";

function TimerIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M17.7796 26.5413H10.2196C8.01463 26.5413 6.40463 25.6197 5.7863 24.033C5.14463 22.3763 5.73963 20.323 7.27963 18.9347L12.693 13.9997L7.27963 9.06467C5.73963 7.67634 5.14463 5.62301 5.7863 3.96634C6.40463 2.36801 8.01463 1.45801 10.2196 1.45801H17.7796C19.9846 1.45801 21.5946 2.37967 22.213 3.96634C22.8546 5.62301 22.2596 7.67634 20.7196 9.06467L15.3063 13.9997L20.7313 18.9347C22.2596 20.323 22.8663 22.3763 22.2246 24.033C21.5946 25.6197 19.9846 26.5413 17.7796 26.5413ZM13.9996 15.178L8.45796 20.218C7.47796 21.1163 7.0463 22.423 7.41963 23.3913C7.76963 24.2897 8.7613 24.7913 10.2196 24.7913H17.7796C19.238 24.7913 20.2296 24.3013 20.5796 23.3913C20.953 22.423 20.533 21.1163 19.5413 20.218L13.9996 15.178ZM10.2196 3.20801C8.7613 3.20801 7.76963 3.69801 7.41963 4.60801C7.0463 5.57634 7.4663 6.88301 8.45796 7.78134L13.9996 12.8213L19.5413 7.78134C20.5213 6.88301 20.953 5.57634 20.5796 4.60801C20.2296 3.70967 19.238 3.20801 17.7796 3.20801H10.2196Z" />
    </svg>
  );
}

TimerIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default TimerIcon;
