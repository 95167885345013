import { useQuery } from "@tanstack/react-query";
import { getCities } from "../services/apiInformation";

export function useGetCities(countryId) {
  const { isLoading, data, error } = useQuery({
    queryKey: ["cities", countryId],
    queryFn: () => getCities(countryId),
    enabled: !!(countryId > 0), //The query will not execute until countryID > 0
    retry: false,
    refetchOnWindowFocus: false,
  });

  return { isLoading, data, error };
}
