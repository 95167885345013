import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RiDraggable } from "react-icons/ri";

function Container({ id, children, title, className }) {
  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, data: { type: "container" } });

  return (
    <div
      {...attributes}
      ref={setNodeRef}
      style={{ transition, transform: CSS.Translate.toString(transform) }}
      className={`${className} ${isDragging ? "z-[1000] opacity-50" : ""}`}
    >
      <div className={`h-full min-h-96 rounded-xl bg-black-g-25`}>
        {title && (
          <div
            {...listeners}
            className="flex items-center justify-between space-x-4 border-b border-black-g-75 p-4 text-xl font-medium"
          >
            <span>{title}</span>
            <RiDraggable className="h-8 w-8 text-black-g-75" />
          </div>
        )}

        <div className="px-1 py-8">
          <div className="-m-2">
            {children}

            <div className="p-2">
              <p className="w-full p-8 text-center text-lg font-medium text-black-g-75">
                Drag and drop a candidate card here
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Container.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Container;
