import PropTypes from "prop-types";

function ChartSquareIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 56"
      stroke="currentColor"
      strokeWidth={2.5}
    >
      <path
        d="M23.5899 26.0176H17.4065C15.9365 26.0176 14.7466 27.2075 14.7466 28.6775V40.6241H23.5899V26.0176V26.0176Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7763 15.4004H26.2295C24.7595 15.4004 23.5696 16.5904 23.5696 18.0604V40.6005H32.4129V18.0604C32.4129 16.5904 31.2463 15.4004 29.7763 15.4004Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6123 29.9834H32.429V40.6002H41.2723V32.6434C41.249 31.1734 40.0589 29.9834 38.6123 29.9834Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0001 51.3337H35.0001C46.6668 51.3337 51.3334 46.667 51.3334 35.0003V21.0003C51.3334 9.33366 46.6668 4.66699 35.0001 4.66699H21.0001C9.33342 4.66699 4.66675 9.33366 4.66675 21.0003V35.0003C4.66675 46.667 9.33342 51.3337 21.0001 51.3337Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ChartSquareIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ChartSquareIcon;
