import { Link, useNavigate, useOutletContext } from "react-router-dom";
import toast from "react-hot-toast";
import LogoIcon from "../../../shared/logo/LogoIcon";
import LinkedInOIDC from "../providers/LinkedInOIDC";
import Divider from "../../../shared/Divider";
import Input from "../../../shared/inputs/Input";
import { useEmailExist } from "../../../hooks/useEmailExist";

function AccountSetupFlow() {
  const navigate = useNavigate();
  const { control, setError, trigger, getValues, handleSubmit, resetField } =
    useOutletContext();
  const { emailExist, isPendingEmailExist, exist } = useEmailExist();

  const handleOnBlurEmail = (e) => {
    if (e.target.value) trigger("email", { shouldFocus: true });

    emailExist(
      { email: e.target.value },
      {
        onSuccess: (response) => {
          if (response?.exist) {
            setError(
              "email",
              {
                type: "taken-email",
                message:
                  "This email is already in use. Please try using a different email address or log in if you already have an account.",
              },
              { shouldFocus: true },
            );
          }
        },
      },
    );
  };

  const onSubmit = ({ email }) => {
    emailExist(
      { email },
      {
        onSuccess: (response) => {
          if (response?.exist) {
            setError(
              "email",
              {
                type: "taken-email",
                message:
                  "This email is already in use. Please try using a different email address or log in if you already have an account.",
              },
              { shouldFocus: true },
            );
          } else {
            navigate("profile-setup");
          }
        },
      },
    );
  };

  const onError = () => {
    toast.error((t) => (
      <div className="flex items-start">
        <p className="mx-2">
          Oops! There are errors in your form submission. Please review and
          correct them.
        </p>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="rounded border bg-slate-200 px-2 py-1 text-xs hover:border-slate-200 hover:bg-white"
        >
          close
        </button>
      </div>
    ));
  };

  return (
    <div className="m-auto w-full max-w-xl bg-white p-8 shadow-md shadow-slate-200">
      <div className="mb-6 flex justify-center">
        <LogoIcon size={18} className="mx-auto" />
      </div>

      <div className="mb-8 flex flex-col text-center">
        <h1 className="text-[2rem] font-medium">Join us Today</h1>
        <p className="text-black-g-100">Connect with people who can help</p>
      </div>

      {/* <LinkedInOIDC>Sign up with LinkedIN</LinkedInOIDC>

      <Divider className="my-6 text-lg font-medium uppercase">OR</Divider> */}

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="-m-3 flex flex-col">
          <div className="p-3">
            <Input
              label="Mail"
              type="email"
              id="email"
              placeholder="example@domain.com"
              autoComplete="email"
              controllerName="email"
              control={control}
              rules={{
                required: "Email address is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please provide a valid email address",
                },
                onBlur: handleOnBlurEmail,
              }}
              isLoading={isPendingEmailExist}
              isAsyncSuccess={!exist}
              resetField={resetField}
            />
          </div>

          <div className="p-3">
            <Input
              label="Password"
              type="password"
              id="password"
              placeholder="min 8 characters"
              autoComplete="new-password"
              controllerName="password"
              control={control}
              rules={{
                required: "Password is required",
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#&!/*-+=?%_]).{8,}$/,
                  message:
                    "Password needs at least one uppercase letter, one lowercase letter, one number, and one of the characters # & ! / * - + = ? %_",
                },
              }}
              showPasswordStrength={true}
              resetField={resetField}
            />
          </div>

          <div className="p-3">
            <Input
              label="Confirm password"
              type="password"
              id="confirm_password"
              placeholder="Confirm password"
              autoComplete="off"
              controllerName="confirm_password"
              control={control}
              rules={{
                required: "Please confirm the password",
                validate: (value) =>
                  value === getValues()?.password || "Passwords need to match",
              }}
              resetField={resetField}
            />
          </div>

          <div className="p-3">
            <button className="btn-blue-b-primary w-full text-lg font-medium">
              Next
            </button>
          </div>
        </div>
      </form>

      <div className="mt-8 flex items-center justify-center space-x-2 text-lg font-medium">
        <span>Have an account already?</span>
        <Link className="text-blue-b-primary hover:underline" to={"/login"}>
          Log in
        </Link>
      </div>
    </div>
  );
}

export default AccountSetupFlow;
