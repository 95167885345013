import { format, parse, parseISO } from "date-fns";

export function getFileType(filePath) {
  const image = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
  const video = ["mp4", "mov", "wmv", "webm", "bmp", "webp", "svg"];

  const fileExtension = filePath?.split(".").pop().toLowerCase();

  if (image.includes(fileExtension)) return "image";
  if (video.includes(fileExtension)) return "video";
  else return fileExtension;
}

export function detectAndParseDate(dateString) {
  let parsedDate = dateString.includes("/")
    ? parse(dateString, "dd/MM/yyyy", new Date())
    : parseISO(dateString);

  const dateAsString = parsedDate.toString();

  if (dateAsString.includes("Invalid Date")) {
    return null; // Date string is not in a recognized format
  }

  if (dateAsString.includes("GMT") || dateAsString.includes("UTC")) {
    // Parse and format for GMT/UTC format
    const formattedDate = format(parsedDate, "MMMM yyyy");

    return formattedDate;
  } else {
    // Parse and format for other formats
    // Parse and format for other formats (e.g., dd/mm/yyyy)
    const formattedDate = format(parsedDate, "MMMM yyyy");
    return formattedDate;
  }
}
export const transformSentSocialPlatforms = (receivedPlatformsArray) => {
  //DOC Check if any item in receivedPlatformsArray contains data in the url attribute
  const hasDataInUrl = receivedPlatformsArray.some((item) => !!item.url);

  //DOC If none of the items have data in the url attribute, return an empty array
  if (!hasDataInUrl) {
    return [];
  }
  //DOC Otherwise, transform the array and add id to each item attributes
  const res = receivedPlatformsArray.map((item, index) => ({
    url: item.url,
    platform: index + 1,
  }));

  return res.filter(Boolean); // Remove undefined entries from the array
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
