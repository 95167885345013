export const REQUEST_URL = {
  email_exist: "/api/auth/email-exist",
  signup_user: "/api/auth/register",
  confirm_email: "/api/auth/confirm-email",
  login: "/api/auth/login",
  forgot_password: "/api/auth/forgot-password",
  reset_password: "/api/auth/reset-password",
  isAuthenticated: "/api/auth/is-auth",
  logout: "/api/auth/logout",
  user: "/api/user",
  profilePic: "/api/user/upload-picture",
  contact: "/api/guest/contact",
  jobDescription_search: "/api/job_description/search",
  jobDescription_id: "/api/job_description",
  jobDescription_recruiter: "/api/job_description/recruiter",
  industries: "/api/info/industries",
  countries: "/api/info/countries",
  cities: "/api/info/cities",
  currencies: "/api/info/currencies",
  submitApplication: "/api/application",
  applicationResume: "/api/application/pdf_resume",
  applicationSearch: "/api/application/search",
  candidate_search: "/api/candidate/search",
  my_resume: "/api/resume/my-resume",
  resume: "/api/resume",
  pdf_resume: "/api/resume/user/pdf_resume",
  company_logo: "/api/resume/company/logo",
  searchSkills: "/api/skill",
  getLanguages: "/api/lang",
  my_company_profile: "/api/recruiter/my-company-profile",
  company_profile: "/api/recruiter/company-profile",
  linkedin_redirect_uri: "/signup/profile-setup",
  resume_action: "/api/resume/action",
  application_check: "/api/application/check",
  resume_talent_pool: "/api/resume/talent-pool",
  resume_parse: "/api/resume/parse",
  recruiter_logo: "/api/recruiter/upload-logo",
};
