import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  HiMiniChevronDown,
  HiMiniChevronUp,
  HiOutlineViewColumns,
  HiOutlineWallet,
  HiXMark,
} from "react-icons/hi2";
import { AiOutlineMessage } from "react-icons/ai";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { useOutsideClick } from "../hooks/useOutsideClick";
import ProfileUserIcon from "../icons/ProfileUserIcon";
import DocumentTextIcon from "../icons/DocumentTextIcon";
import CategoryIcon from "../icons/CategoryIcon";

const MENU = [
  /*  {
    name: "Dashboard",
    icon: <CategoryIcon className="h-6 w-6" />,
    url: "dashboard",
    roles: ["candidate", "recruiter"],
  }, */
  {
    name: "Jobs",
    icon: <DocumentTextIcon className="h-6 w-6" />,
    url: "jobs",
    roles: ["candidate", "recruiter"],
  },
  {
    name: "Pipeline",
    icon: <HiOutlineWallet className="h-6 w-6" />,
    roles: ["recruiter"],
    children: [
      {
        name: "My talent pool",
        icon: <ProfileUserIcon className="h-6 w-6" />,
        url: "applicants",
        roles: ["recruiter"],
      },
      {
        name: "Recruitment board",
        icon: <HiOutlineViewColumns className="h-6 w-6" />,
        url: "recruitment-board",
        roles: ["recruiter"],
      },
    ],
  },
  {
    name: "Talents",
    icon: <ProfileUserIcon className="h-6 w-6" />,
    url: "candidates",
    roles: ["recruiter"],
  },
  {
    name: "Messaging",
    icon: <AiOutlineMessage className="h-6 w-6" />,
    url: "chat-box",
    roles: ["candidate", "recruiter"],
  },
];

function PrimaryMenuPrivate({ className, menuRef, onClose }) {
  const isRTL = document.dir === "rtl";

  const [openIndex, setOpenIndex] = useState(null);
  const initialClassName = "xl:scale-x-50 scale-y-0 opacity-0";
  const animationClassName = "transform-none opacity-100";
  const [openClassName, setOpenClassName] = useState(initialClassName);

  const toggleSubMenu = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    setOpenClassName((prevIndex) =>
      prevIndex === index ? animationClassName : initialClassName,
    );
  };

  useEffect(
    function () {
      if (openIndex !== null) setOpenClassName(animationClassName);
      else setOpenClassName(initialClassName);
    },
    [openIndex],
  );

  const itemRef = useOutsideClick(() => setOpenIndex(null), false);

  const { roles } = useIsAuthenticated();

  return (
    <div ref={menuRef} className={className}>
      <div>
        <div className="mb-8 flex justify-end xl:hidden">
          <div
            className="cursor-pointer rounded bg-slate-200 p-0.5 hover:bg-slate-300 hover:text-blue-b-primary"
            onClick={onClose}
          >
            <HiXMark className="h-8 w-8" />
          </div>
        </div>

        <ul className="block items-center font-primary text-lg font-medium transition-all duration-300 xl:flex">
          {MENU?.filter((menuItem) => {
            return roles?.some((role) => menuItem?.roles?.includes(role));
          })?.map((item, index) => (
            <li
              key={item?.name}
              className="group relative cursor-pointer transition-all duration-200 hover:bg-blue-b-100/20 xl:hover:bg-transparent"
              onClick={(e) => {
                e.stopPropagation();
                toggleSubMenu(index);
              }}
              ref={itemRef}
            >
              {item?.url ? (
                <NavLink
                  className={({ isActive }) => {
                    return `flex items-center space-x-1 px-5 py-2.5 transition-all duration-300 hover:text-blue-b-primary ${isActive ? "text-blue-b-primary" : ""}`;
                  }}
                  to={item?.url}
                  state={item?.state}
                >
                  {item.icon}

                  <div>{item?.name}</div>
                  {item?.children && (
                    <HiMiniChevronDown className="block group-hover:hidden" />
                  )}

                  {item?.children && (
                    <HiMiniChevronUp className="hidden group-hover:block" />
                  )}
                </NavLink>
              ) : (
                <div
                  className={
                    "flex items-center space-x-1 px-4 py-3.5 transition-all duration-300 hover:text-blue-b-primary"
                  }
                >
                  {item.icon}
                  <div className="flex-1">{item?.name}</div>
                  {item?.children && (
                    <HiMiniChevronDown
                      className={openIndex === index ? "hidden" : "block"}
                    />
                  )}
                  {item?.children && (
                    <HiMiniChevronUp
                      className={openIndex === index ? "block" : "hidden"}
                    />
                  )}
                </div>
              )}

              {item?.children && openIndex === index && (
                <ul
                  className={`top-full z-10 min-w-60 xl:absolute ${isRTL ? "right-0 origin-top-right" : "left-0 origin-top-left"} rounded p-4 transition-all duration-200 xl:border xl:border-slate-300 xl:bg-white/90 xl:shadow xl:backdrop-blur ${openClassName}`}
                >
                  {item?.children?.map((child) => (
                    <li
                      key={child?.name}
                      className="border-b border-slate-200 last:border-none"
                    >
                      <NavLink
                        className={({ isActive }) => {
                          return `block p-2 transition-all duration-200 hover:px-4 hover:text-blue-b-primary ${isActive ? "text-blue-b-primary" : ""}`;
                        }}
                        to={child?.url}
                        state={item?.state}
                      >
                        <div className="flex space-x-1">
                          {child?.icon}
                          <div className="flex-1 whitespace-nowrap">
                            {child?.name}
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

PrimaryMenuPrivate.propTypes = {
  className: PropTypes.string,
  menuRef: PropTypes.any,
  menu: PropTypes.array,
  onClose: PropTypes.func,
  role: PropTypes.string,
};

export default PrimaryMenuPrivate;
