import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { generatePdfFile } from "../services/apiResume";

export const useGeneratePdf = () => {
  const {
    mutate: generatePDFLink,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: ({ resumeId }) =>
      generatePdfFile({
        resumeId,
      }),
    onMutate: () => {
      toast.loading("Generating PDF file, please wait...");
    },
    onSuccess: (response) => {
      toast.dismiss();
      return response;
    },
    onError: () => {
      toast.dismiss();
      toast.error("Failed to generate file");
    },
  });

  return { generatePDFLink, isPending, isSuccess };
};
