import PropTypes from "prop-types";

function Logo({ className }) {
  return (
    <img
      className={`block transition-all duration-300 ${className}`}
      src="/assets/logo-icon-blue.svg"
      alt="Logo"
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
