import React, { useEffect, useMemo, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useUser } from "../../hooks/useUser.js";
import { sendMessageFile } from "../../services/apiChat.js";
import MessageHeader from "./MessageHeader";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";

const defaultProfilePic = "../../../public/uploads/defaultProfilePic.png"; // Path to default image in public folder

const Messages = ({ selectedCandidateId, candidates, sessions }) => {
  const { user: currentUser } = useUser();
  const BASE_URL = import.meta.env.VITE_API_BASE_URL;

  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [connection, setConnection] = useState(null);
  const chatEndRef = useRef(null);

  const currentCondidate = useMemo(() => {
    return sessions.filter(
      (session) => session.condidate.id === selectedCandidateId[0].condidateId,
    )[0].condidate;
  }, [selectedCandidateId]);

  useEffect(() => {
    setMessages(
      sessions.filter(
        (session) =>
          session.condidate.id === selectedCandidateId[0].condidateId,
      )[0].messages,
    );
  }, [currentCondidate, selectedCandidateId]);

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(BASE_URL + "/Hubs/chatHub")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    setConnection(newConnection);

    newConnection
      .start()
      .then(async () => {
        await newConnection.invoke("SetUserId", currentUser.id);

        newConnection.on("ReceiveMessage", (message) => {
          console.log("Real-time message received:", message);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              senderId: message.senderId,
              receiverId: message.recieverId,
              content: message.contents,
              type: message.contents.includes("http") ? 2 : message.type,
            },
          ]);
        });
      })
      .catch((e) => console.error("SignalR connection failed:", e));

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [currentUser.id]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSendMessage = async (inputMessage, file) => {
    if (file && inputMessage.trim()) {
      try {
        const { result } = await sendMessageFile(file, currentUser.id);
        const fileMessage = {
          senderId: currentUser.id,
          receiverId: currentCondidate.id,
          content: result,
          type: 2,
        };
        const textMessage = {
          senderId: currentUser.id,
          receiverId: currentCondidate.id,
          content: inputMessage,
          type: 1,
        };
        await connection.invoke("SendMessage", [fileMessage, textMessage]);
        setMessages((prevState) => [...prevState, fileMessage, textMessage]);
        setSelectedFile(null);
        setMessageInput("");
      } catch (e) {
        console.error("Error uploading file or sending message:", e);
      }
    } else if (file) {
      try {
        const { result } = await sendMessageFile(file, currentUser.id);
        const message = {
          senderId: currentUser.id,
          receiverId: currentCondidate.id,
          content: result,
          type: 2,
        };
        await connection.invoke("SendMessage", [message]);
        setMessages((prevState) => [...prevState, message]);
        setSelectedFile(null);
      } catch (error) {
        console.error("Error uploading file or sending message:", error);
      }
    } else if (inputMessage.trim()) {
      const message = {
        senderId: currentUser.id,
        receiverId: currentCondidate.id,
        content: inputMessage,
        type: 1,
      };
      try {
        await connection.invoke("SendMessage", [message]);
        setMessages((prevState) => [...prevState, message]);
        setMessageInput("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const users = [...candidates, currentUser]; // Adjust as necessary based on your data

  return (
    <div className="flex h-full flex-col">
      <MessageHeader
        currentCondidate={currentCondidate}
        defaultProfilePic={defaultProfilePic}
      />
      <MessageList
        messages={messages}
        currentUser={currentUser}
        chatEndRef={chatEndRef}
        users={users}
      />
      <div className="mt-4 flex justify-center">
        {" "}
        {/* Added a container to center the MessageInput */}
        <MessageInput handleSendMessage={handleSendMessage} />
      </div>
    </div>
  );
};

export default Messages;
