import PropTypes from "prop-types";
import Input from "../../../shared/inputs/Input";
import CheckButton from "../../../shared/buttons/CheckButton";
import { useGetSkills } from "../../../hooks/useGetSkills";
import { usePostSkill } from "../../../hooks/usePostSkill";
import { useRef } from "react";

function WorkExperienceHardSkills({
  watch,
  control,
  resetField,
  setValue,
  controllerName,
}) {
  const hardSkillRef = useRef(null);

  const selectedHardSkills = watch(controllerName);
  //   console.log("selectedHardSkills", selectedHardSkills);

  const payload = {
    keyword: watch("exp-hard-input"),
    type: 2,
    page: 1,
    size: 5,
  };
  const { data } = useGetSkills(payload);
  const { results: suggestedSkills } = data || {};
  const { saveSkill } = usePostSkill();

  const handleKeyDown = async (event) => {
    saveSkill({ keyword: event.target.value, type: payload.type });
  };

  return (
    <div className="flex flex-1 flex-col gap-2 rounded-lg border bg-white p-4">
      <div className="font-primary text-base font-medium text-[#4A5164]">
        Hard Skills
      </div>
      <div className="flex  gap-4">
        <Input
          type="text"
          id={"exp-hard-input"}
          placeholder="Add skill"
          controllerName={"exp-hard-input"}
          control={control}
          mainDivClassname={"flex-1"}
          onKeyDown={handleKeyDown}
          containerClassName="border-black-g-50"
          containerBaseClassName="border rounded-md"
          fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
          resetField={resetField}
        />
        <div className="flex flex-1 flex-wrap items-center gap-2">
          {suggestedSkills?.map((item) => (
            <CheckButton
              key={item.id}
              text={item?.name}
              onClick={() => {
                setValue(controllerName, [...selectedHardSkills, item]);
                hardSkillRef.current.scrollIntoView();
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-2" ref={hardSkillRef}>
        {selectedHardSkills?.map((item) => (
          <CheckButton
            key={item.id || item.skillId}
            text={item?.name}
            selected={true}
            onClick={() => {
              const updatedKeywords = selectedHardSkills?.filter(
                (obj) => obj.id !== item.id || obj.skillId !== item.skillId,
              );
              setValue(controllerName, updatedKeywords); // Update the form value
            }}
          />
        ))}
      </div>
    </div>
  );
}

WorkExperienceHardSkills.propTypes = {
  watch: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  controllerName: PropTypes.string.isRequired,
};

export default WorkExperienceHardSkills;
