import PropTypes from "prop-types";

function CustomImage({ imgSrc, imgClassName }) {
  return <img src={imgSrc} className={imgClassName} alt="" />;
}

CustomImage.propTypes = {
  imgSrc: PropTypes.string,
  imgClassName: PropTypes.string,
};
CustomImage.defaultProps = {
  imgSrc: "https://placehold.co/200x200",
  imgClassName: "image-layout",
};
export default CustomImage;
