import PropTypes from "prop-types";
import { FaLinkedin } from "react-icons/fa";
import { REQUEST_URL } from "../../../services/request-url";

function LinkedInOIDC({ children }) {
  const STATE = "LinkedInOIDC";
  const LINKEDIN_CLIENT_ID = import.meta.env.VITE_LINKEDIN_CLIENT_ID;
  const LINKEDIN_REDIRECT_URI =
    import.meta.env.VITE_FRONT_BASE_URL + REQUEST_URL.linkedin_redirect_uri;
  const LINKEDIN_SCOPE = "openid%20profile%20w_member_social%20email";

  const handleOnClick = (e) => {
    e.preventDefault();
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?state=${STATE}&response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${LINKEDIN_REDIRECT_URI}&scope=${LINKEDIN_SCOPE}`;
  };

  return (
    <div
      className="flex cursor-pointer items-center justify-center space-x-2.5 border border-black-g-50 px-5 py-4 hover:bg-blue-b-50"
      onClick={handleOnClick}
    >
      <FaLinkedin className="h-6 w-6 text-[#0A66C2]" />
      <div className="text-lg font-medium">{children}</div>
    </div>
  );
}

LinkedInOIDC.propTypes = {
  children: PropTypes.node,
};

export default LinkedInOIDC;
