import { useForm } from "react-hook-form";
import Input from "../shared/inputs/Input";
import Dropdown from "../shared/dropdown/Dropdown";
import {
  EmploymentTypeEnum,
  ExperienceLevelEnum,
  LeaveNoticeEnum,
  jobStatusStep,
} from "../utils/constants";
import CustomImage from "../shared/images/CustomImage";
import { IoEllipseSharp } from "react-icons/io5";
import Divider from "../shared/Divider";
import CustomCard from "../shared/cards/CustomCard";
import { useApplicationSearch } from "./../hooks/useApplicationSearch";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Pagination from "../shared/Pagination";
import {
  HiChevronLeft,
  HiChevronRight,
  HiMiniChevronLeft,
  HiMiniChevronRight,
  HiQuestionMarkCircle,
  HiXMark,
} from "react-icons/hi2";
import Loader from "../shared/loader/Loader";
import StatusFilter from "../shared/filters/StatusFilter";
import { filterStatus } from "../shared/filters/data";
import { Breadcrumbs, Dialog } from "@mui/material";
import { useJobDescriptionSingle } from "../hooks/useJobDescriptionSingle";
import CandidateModalPreview from "../components/candidate/CandidateModalPreview";
import { RxDownload } from "react-icons/rx";
import AddToProjectIcon from "../icons/AddToProjectIcon";
import { useGeneratePdf } from "../hooks/useGeneratePdf";
import download from "downloadjs";
import RatingEmojiInput from "../shared/inputs/RatingEmojiInput";
import { useApplicationRate } from "../hooks/useApplicationRate";
import { Tooltip } from "react-tooltip";
import SelectBox from "../shared/inputs/SelectBox";
import { useGetRecruiterJDescription } from "../hooks/useGetRecruiterJDescription";

function JobApplicants() {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const { jobDescriptionId: jdID } = useParams();
  const [jobDescriptionId, setJobDescriptionId] = useState(jdID);

  const { control, setValue, resetField } = useForm();

  const JOB_POSTS_PER_PAGE = 9;
  const QUERY_STRING = "application_search_page";

  const { data, isLoading } = useApplicationSearch({
    jobDescriptionId,
    //resumeId,
    rate: selectedFilters?.rate || 0,
    //jdApplicationStatus: 1,
    queryString: QUERY_STRING,
    size: JOB_POSTS_PER_PAGE,
  });

  const { rateApplication } = useApplicationRate();

  const { generatePDFLink, isPending: isGeneratingFile } = useGeneratePdf();

  const { results, totalCount } = data || {};

  const [isOpen, setIsOpen] = useState(false);

  // State to track the index of the currently selected item
  const [selectedItem, setSelectedItem] = useState(null);
  const [currenIndex, setCurrenIndex] = useState(0);

  // Function to handle clicking the next button
  const handleNext = (previewId) => {
    let obIndex = results.findIndex((x) => x.resume?.id === previewId);
    obIndex = obIndex + 1;
    setCurrenIndex(obIndex);
    setSelectedItem(results[obIndex]?.resume?.id);
  };

  // Function to handle clicking the previous button
  const handlePrevious = (previewId) => {
    let obIndex = results.findIndex((x) => x.resume?.id === previewId);
    obIndex = obIndex - 1;
    setCurrenIndex(obIndex);
    setSelectedItem(results[obIndex]?.resume?.id);
  };

  const handleOpenPopup = (id) => {
    setIsOpen(true);
    setSelectedItem(id);
    let obIndex = results.findIndex((x) => x.resume?.id === id);
    setCurrenIndex(obIndex);
  };

  const handledownload = (id) => {
    generatePDFLink(
      { resumeId: id },
      {
        onSuccess: async (res) => {
          const response = await fetch(res?.url);
          const blob = await response.blob();
          const obj = results.find((x) => x.resume?.id === id);
          download(
            blob,
            obj?.resume?.firstName + " " + obj?.resume?.lastName + " Resume",
          );
        },
      },
    );
  };

  const { data: job } = useJobDescriptionSingle();
  const handleSelection = (item) => {
    if (item?.id?.includes("list")) {
      setSelectedFilters((prevState) => ({
        ...prevState,
        rate: item?.id.includes("alist")
          ? 1
          : item?.id.includes("blist")
            ? 2
            : item?.id.includes("clist")
              ? 3
              : 0,
      }));
    } else {
      setSelectedFilters((prevState) => ({
        ...prevState,
        rate: 0,
      }));
    }
  };

  const {
    isLoading: isLoadingRecruiterJDescriptions,
    data: recruiterJDescriptions,
  } = useGetRecruiterJDescription({ page: 1, size: 20 });

  return (
    <div className="page-px flex flex-col gap-4 py-4">
      {jobDescriptionId && job ? (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            className="font-primary text-sm font-medium text-black-g-primary underline"
            to={`/recruiter/jobs`}
          >
            Jobs
          </Link>
          <Link
            className="font-primary text-sm font-medium text-black-g-primary underline"
            to={`/recruiter/job-detail/${job?.id}`}
          >
            {job?.title}
          </Link>
          <span className="font-primary text-sm font-medium text-blue-b-primary">
            All candidates
          </span>
        </Breadcrumbs>
      ) : null}

      <div className="flex items-center md:items-center md:justify-between">
        <span className="title-primary-300-4xl flex-1 font-medium">
          My Candidates
        </span>
      </div>
      <div className="flex w-full flex-col flex-wrap gap-4 md:flex-row md:items-center">
        <div className="flex flex-1 items-center space-x-4">
          <StatusFilter
            items={filterStatus}
            dropdownMobileLabel="Talents Status"
            mainContainerClassname="flex  md:items-center md:justify-center"
            onSelect={handleSelection}
          />

          <div className="relative">
            <button
              className="text-blue-b-primary hover:text-blue-b-500"
              data-tooltip-id={"rating-tooltip"}
              data-tooltip-html={
                "<ul><li>« A » Très près du profil recherché</li><li>« B » Assez près du profil recherché</li><li>« C » Non pertinents au poste affiché</li></ul>"
              }
            >
              <HiQuestionMarkCircle className="h-6 w-6" />
            </button>

            <Tooltip id={"rating-tooltip"} style={{ zIndex: "2000" }} />
          </div>
        </div>

        <div className="flex flex-col flex-wrap gap-4 md:flex-row md:items-center ">
          <Input
            type="text"
            id="keyword"
            placeholder="React AND Angular"
            controllerName="keyword"
            control={control}
            fieldClassName="input-transparent py-2 text-sm font-primary font-normal w-[200px]"
            resetField={resetField}
          />
          {/* <Dropdown
            className="rounded-[4px] border border-black-g-50 px-2 font-primary text-sm font-medium"
            label="Availablity"
            optionsConfig={{
              options: leaveNoticeArray,
              idKey: "id",
              nameKey: "name",
            }}
            onSelect={(value) => setValue("industries", value)}
          /> */}
          {/* <Dropdown
            className="rounded-[4px] border border-black-g-50 px-2 font-primary text-sm font-medium"
            // icon={<HiMiniBriefcase className="h-5 w-5" />}
            label="Interview"
            optionsConfig={{
              options: jobStatusStep,
              idKey: "id",
              nameKey: "name",
            }}
            onSelect={(value) => setValue("industries", value)}
          /> */}
          <SelectBox
            id={"jobs"}
            placeholder={"Select job"}
            control={control}
            resetField={resetField}
            isLoading={isLoadingRecruiterJDescriptions}
            options={{
              data: recruiterJDescriptions?.results,
              key: "id",
              value: "id",
              name: "title",
            }}
            containerBaseClassName="rounded-r-md border-2 h-full"
            containerClassName="bg-white border-black-g-50"
            onClick={(value) => {
              setJobDescriptionId(value);
            }}
          />
          {/* <CustomButton
            btnStyle={`filter-tab px-3 rounded-[4px] flex items-center`}
            icon={<TbChartCandle />}
            title={"Advanced filters"}
            onClick={() => setShowFilters((prevState) => !prevState)}
          /> */}
        </div>
      </div>

      {!isLoading ? (
        <>
          {totalCount > 0 ? (
            <div className="flex flex-col flex-wrap gap-8 md:flex-row md:gap-0 ">
              {/* {showFilters ? (
                <div className="w-full pr-0 md:w-2/5 md:pr-4 lg:w-1/5 xl:w-1/5 2xl:w-1/5">
                  <div className="filter-layout flex flex-col">
                    <span className="text-primary-300 font-primary text-xl font-medium">
                      Advanced Filters
                    </span>

                    <div className="mb-2 mt-4">
                      <span className="text-primary-300 font-primary text-lg font-semibold">
                        Job Type
                      </span>
                      <div className="mt-2 flex flex-col gap-2">
                        {contractTypeArray?.map((item) => (
                          <CustomCheckbox
                            key={item.id}
                            inputId={item.id}
                            labelName={item.name}
                            value={item.value}
                            onSelect={(item) => {
                              setSelectedOptions(item.value);
                              setValue(
                                "employmentType",
                                item.isChecked ? item.value : 0,
                              );
                            }}
                            isChecked={selectedOptions === item.value}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="mb-2">
                      <span className="text-primary-300  font-primary text-lg font-semibold">
                        Salary/Daily rate range
                      </span>

                      <div className="mb-2 mt-2 block">
                        <Input
                          label="From"
                          gap={0}
                          column={false}
                          type="text"
                          id="mins"
                          placeholder="1200"
                          controllerName="minSalary"
                          control={control}
                          resetField={resetField}
                          labelClassName="w-[60px] p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                          fieldClassName="input-transparent h-auto p-3"
                          containerBaseClassName="border border-black-g-50 rounded-r-md"
                          containerClassName="bg-white"
                        />
                      </div>

                      <div className="block">
                        <Input
                          label="To"
                          gap={0}
                          column={false}
                          type="text"
                          id="maxs"
                          placeholder="5000"
                          controllerName="maxSalary"
                          control={control}
                          resetField={resetField}
                          labelClassName="w-[60px] p-3 text-base text-black-g-100 border border-r-0 border-black-g-50 rounded-l-md"
                          fieldClassName="input-transparent h-auto p-3"
                          containerBaseClassName="border border-black-g-50 rounded-r-md"
                          containerClassName="bg-white"
                        />
                      </div>
                    </div>

                    <div className="mb-2">
                      <span className="text-primary-300  font-primary text-lg font-semibold">
                        Expertise
                      </span>

                      <div className="mt-2 flex flex-col gap-2">
                        {experienceLevelArray?.map((item) => (
                          <CustomCheckbox
                            key={item.id}
                            inputId={item.id}
                            labelName={item.name}
                            onSelect={(isChecked) =>
                              console.log(
                                `Checkbox checked: ${isChecked}`,
                                item,
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div className="mb-2">
                      <span className="text-primary-300 mb-3 font-primary text-lg font-semibold">
                        Work Location
                      </span>

                      <div className="mt-2 flex flex-col gap-2">
                        {workLocationArray?.map((item) => (
                          <CustomCheckbox
                            key={item.id}
                            inputId={item.id}
                            labelName={item.name}
                            onSelect={(isChecked) =>
                              console.log(
                                `Checkbox checked: ${isChecked}`,
                                item,
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex gap-2">
                      <div
                        className="btn-white flex flex-1 cursor-pointer items-center justify-center rounded-[4px] py-3 font-primary  text-sm font-medium"
                        onClick={() =>
                          setShowFilters((prevState) => !prevState)
                        }
                      >
                        Cancel
                      </div>
                      <div className="btn-blue-b-primary flex flex-1 cursor-pointer items-center justify-center rounded-[4px] py-3 font-primary text-sm font-medium">
                        Save
                      </div>
                    </div>
                  </div>
                </div>
              ) : null} */}

              <div
                className={`flex w-full flex-wrap gap-6  ${showFilters ? "gap-2 md:w-3/5 md:gap-6 lg:w-4/5 lg:gap-5 xl:w-4/5 xl:gap-6 2xl:w-4/5 2xl:gap-6" : "lg:gap-6 xl:gap-6 2xl:gap-6"} `}
              >
                {results?.map((item, index) => (
                  <div
                    key={index}
                    className={`h-72 w-full pr-0 transition delay-150 duration-300 ease-in-out  md:w-full  ${!showFilters ? "lg:w-[48.7%] xl:w-[31.7%] 2xl:w-[32%]" : "lg:w-[47.7%] xl:w-[48.2%] 2xl:w-[32.3%] "} `} // Add padding-left to the last card in each row
                  >
                    <CustomCard
                      CardHeader={
                        <div className="flex items-center gap-1">
                          <div className="flex flex-1 items-center gap-2">
                            <CustomImage
                              imgClassName={"image-layout w-16 h-16"}
                              imgSrc={
                                item?.resume?.candidate?.profilePic ||
                                "https://placehold.co/200x200"
                              }
                            />
                            <div className="flex flex-col gap-1">
                              <div className="flex items-center gap-1">
                                <Link
                                  className="title-primary-300-sm w-[158px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold sm:w-full"
                                  onClick={() => {
                                    handleOpenPopup(item?.resume?.id);
                                  }}
                                >
                                  {item?.resume?.candidate?.firstName
                                    ? item?.resume?.candidate?.firstName +
                                      " " +
                                      item?.resume?.candidate?.lastName
                                    : "No Title"}
                                </Link>
                              </div>

                              <div className="flex flex-wrap items-center gap-1">
                                {item?.resume?.jobTitle && (
                                  <Link
                                    className="subtitle-blackG-100-xs font-normal"
                                    // to={`${redirectionUrl}/profile`}
                                  >
                                    {item?.resume?.jobTitle}
                                  </Link>
                                )}

                                <IoEllipseSharp size={8} />
                                <span className="subtitle-blackG-100-xs font-normal">
                                  {item?.resume?.candidate?.contact?.address
                                    ?.country
                                    ? item?.resume?.candidate?.contact?.address
                                        ?.country?.name
                                    : "No country"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-center rounded-md bg-blue-b-50 px-3 py-1 font-primary text-xs font-normal text-blue-b-primary">
                            <span>Match</span>
                            <span className="text-base font-bold">93%</span>
                          </div>
                        </div>
                      }
                      CardContent={
                        <div className="flex flex-1 flex-col  gap-4 ">
                          <div className="flex items-center gap-1">
                            <span className="px-2 py-1 font-primary text-xs font-medium text-blue-b-primary">
                              {item?.resume?.rate
                                ? item?.resume?.rate?.max +
                                  " " +
                                  item?.resume?.rate?.currency?.symbol
                                : null}
                            </span>
                            <span className="rounded bg-[#E3FFF0] px-2 py-1 font-primary text-xs font-medium text-[#098D2E]">
                              {ExperienceLevelEnum[
                                item?.jobDescription?.experienceLevel
                              ] ?? ExperienceLevelEnum[0]}
                            </span>
                            <span className="rounded bg-[#F1E3FF] px-2 py-1 font-primary text-xs font-medium text-[#500F8F]">
                              {EmploymentTypeEnum[
                                item?.jobDescription?.employmentType
                              ] ?? EmploymentTypeEnum[0]}
                            </span>
                            <span className="rounded bg-[#FFEDE3] px-2 py-1 font-primary text-xs font-medium text-[#914E14]">
                              {LeaveNoticeEnum[item?.resume?.disponibility] ??
                                LeaveNoticeEnum[0]}
                            </span>
                          </div>
                          <div className="h-100  font-primary text-sm  font-normal text-black-g-100">
                            {/*  {item?.message
                              ? item?.message?.length > 175
                                ? `${item?.message?.substring(0, 175)}...`
                                : item?.message
                              : "No message available"} */}

                            {item?.hardSkills?.length > 0 && (
                              <div className="-m-1 flex flex-wrap">
                                {item?.hardSkills?.map((skill) => (
                                  <div key={skill?.id} className="p-1">
                                    <div className="rounded bg-blue-b-50 px-2 py-1.5 text-sm font-medium text-blue-b-primary">
                                      {skill?.name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      CardFooter={
                        <div className="flex flex-col">
                          <Divider className="mb-4 mt-1" />

                          <div className="flex items-center justify-between">
                            <div
                              className={`flex items-center gap-1 rounded px-2 py-1 `}
                            >
                              <RatingEmojiInput
                                controllerName={"candidate-rating"}
                                control={control}
                                onRate={(value) =>
                                  rateApplication({
                                    applicationId: item.id,
                                    rate: value,
                                  })
                                }
                                defaultValue={item.rate}
                              />
                              {/* <CustomImage
                                imgClassName={"image-layout w-7 h-7"}
                                imgSrc={
                                  item?.jobDescription?.company?.logo ||
                                  "https://placehold.co/200x200"
                                }
                              />
                              <span className="font-primary text-sm font-medium text-green-g-500">
                                {item?.jobDescription?.company
                                  ? item?.jobDescription?.company?.name
                                  : "No company info"}
                              </span> */}
                            </div>

                            <div className="flex items-center gap-1">
                              <Link
                                className="btn-outline-blueB flex items-center rounded-[4px] px-5 py-2 text-sm font-medium"
                                to={`/recruiter/candidate/${item?.resume?.id}`}
                              >
                                See more
                              </Link>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="mt-6 flex w-full items-center justify-center">
                <Pagination
                  queryString={QUERY_STRING}
                  totalCount={totalCount}
                  size={JOB_POSTS_PER_PAGE}
                >
                  <Pagination.PrevPage>
                    <div className="px-4 py-2 hover:bg-blue-b-50">
                      <HiMiniChevronLeft className="h-4 w-4 stroke-1" />
                    </div>
                  </Pagination.PrevPage>

                  <Pagination.Numbers />

                  <Pagination.NextPage>
                    <div className="px-4 py-2 hover:bg-blue-b-50">
                      <HiMiniChevronRight className="h-4 w-4 stroke-1" />
                    </div>
                  </Pagination.NextPage>
                </Pagination>
              </div>
            </div>
          ) : (
            <div>no data</div>
          )}
          <Dialog
            open={isOpen}
            maxWidth="xl"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              },
            }}
            fullWidth
            onClose={() => setIsOpen(false)}
          >
            <div className="flex h-full w-full">
              <div className="mr-4 flex h-full w-[10%]  flex-col  items-center justify-end">
                <button
                  className="h-fit items-end rounded-full bg-white p-4 disabled:cursor-not-allowed"
                  onClick={() => handlePrevious(selectedItem)}
                  disabled={currenIndex <= 0}
                >
                  <div className="h-6 w-6" onClick={handlePrevious}>
                    <HiChevronLeft className="h-full w-full stroke-[1.5px]" />
                  </div>
                </button>
              </div>

              <div className="flex  h-full w-full flex-col  overflow-auto rounded-xl  bg-white p-12 shadow-lg">
                <CandidateModalPreview previewId={selectedItem} />
              </div>
              <div className="ml-4 flex h-full w-[10%] flex-col  items-center justify-between">
                <div className="mt-8 flex flex-col items-center space-y-3">
                  <button
                    className="flex flex-col items-center"
                    onClick={() => handledownload(selectedItem)}
                    disabled={isGeneratingFile}
                  >
                    <div className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed">
                      <div className="h-6 w-6">
                        <RxDownload className="h-full w-full " />
                      </div>
                    </div>
                    <span className="font-primary text-lg font-normal text-black-g-23">
                      Download
                    </span>
                  </button>

                  <button className="flex flex-col items-center">
                    <div className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed">
                      <div className="h-6 w-6">
                        <AddToProjectIcon height={24} width={24} />
                      </div>
                    </div>
                    <span className="w-max font-primary text-lg font-normal text-black-g-23">
                      Add To Project
                    </span>
                  </button>
                </div>

                <button
                  className="h-fit  items-end rounded-full bg-white p-4 disabled:cursor-not-allowed"
                  onClick={() => handleNext(selectedItem)}
                  disabled={currenIndex === results?.length - 1}
                >
                  <div className="h-6 w-6">
                    <HiChevronRight className="h-full w-full stroke-[1.5px]" />
                  </div>
                </button>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <div
          className={`flex ${showFilters ? "w-4/5" : "w-full"}  justify-center`}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}

export default JobApplicants;
