import { REQUEST_URL } from "../services/request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const INDUSTRIES_URL = REQUEST_URL.industries;
const COUNTRIES_URL = REQUEST_URL.countries;
const CITIES_URL = REQUEST_URL.cities;
const CURRENCIES_URL = REQUEST_URL.currencies;

export async function getIndustries() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + INDUSTRIES_URL);

  if (!response.ok) throw new Error("Dispalying job categories failed!");
  return response.json();
}

export async function getCountries() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + COUNTRIES_URL);

  if (!response.ok) throw new Error("Dispalying countries failed!");
  return response.json();
}

export async function searchCountries({ name }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(`${BASE_URL}${COUNTRIES_URL}?q=${name}`);

  if (!response.ok) throw new Error("Dispalying countries failed!");
  return response.json();
}

export async function getCities(countryId) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    BASE_URL + CITIES_URL + "?CountryId=" + countryId,
  );

  if (!response.ok) throw new Error("Dispalying cities failed!");
  return response.json();
}

export async function searchCities({ countryId, name }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(
    `${BASE_URL}${CITIES_URL}?CountryId=${countryId}&q=${name}`,
  );

  if (!response.ok) throw new Error("Dispalying cities failed!");
  return response.json();
}

export async function getCurrencies() {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + CURRENCIES_URL);

  if (!response.ok) throw new Error("Dispalying currencies failed!");
  return response.json();
}
