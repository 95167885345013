import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { searchCandidate } from "../services/apiCandidate";

export function useSearchCandidate({
  queryString,
  size,
  keywords,
  category,
  experienceLevels,
  workLocations,
  employmentTypes,
  leaveNotices,
  minSalary,
  maxSalary,
}) {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const page = !searchParams.get(queryString)
    ? 1
    : Number(searchParams.get(queryString));

  const { isLoading, data, error } = useQuery({
    queryKey: [
      "candidateSearch",
      page,
      size,
      keywords,
      category,
      experienceLevels,
      workLocations,
      employmentTypes,
      leaveNotices,
      minSalary,
      maxSalary,
    ],
    queryFn: () =>
      searchCandidate({
        page,
        size,
        keywords,
        category,
        experienceLevels,
        workLocations,
        employmentTypes,
        leaveNotices,
        minSalary,
        maxSalary,
      }),
    retry: false,
  });

  //PRE-FETCHING
  const pageCount = Math.ceil(data?.totalCount / size);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: [
        "candidateSearch",
        page + 1,
        size,
        keywords,
        category,
        experienceLevels,
        workLocations,
        employmentTypes,
        leaveNotices,
        minSalary,
        maxSalary,
      ],
      queryFn: () =>
        searchCandidate({
          page: page + 1,
          size,
          keywords,
          category,
          experienceLevels,
          workLocations,
          employmentTypes,
          leaveNotices,
          minSalary,
          maxSalary,
        }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: [
        "candidateSearch",
        page - 1,
        size,
        keywords,
        category,
        experienceLevels,
        workLocations,
        employmentTypes,
        leaveNotices,
        minSalary,
        maxSalary,
      ],
      queryFn: () =>
        searchCandidate({
          page: page - 1,
          size,
          keywords,
          category,
          experienceLevels,
          workLocations,
          employmentTypes,
          leaveNotices,
          minSalary,
          maxSalary,
        }),
    });

  return { isLoading, data, error };
}
