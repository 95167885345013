import { REQUEST_URL } from "./request-url";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const APPLICATION_RESUME_URL = REQUEST_URL.applicationResume;
const SUBMIT_APPLICATION_URL = REQUEST_URL.submitApplication;
const SEARCH_APPLICATION_URL = REQUEST_URL.applicationSearch;
const APPLICATION_CHECK_URL = REQUEST_URL.application_check;

export async function uploadResume(file) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(BASE_URL + APPLICATION_RESUME_URL, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  if (response.status === 400) {
    const error = new Error("Only PDF files are allowed");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error("Failed to upload the resume, please try again later.");
  return response.json();
}

export async function submitApplication({
  jobDescriptionId,
  resumeId,
  status,
  pdfResume,
  message,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + SUBMIT_APPLICATION_URL, {
    method: "POST",
    body: JSON.stringify({
      jobDescriptionId: Number(jobDescriptionId) || 0,
      resumeId: Number(resumeId) || 0,
      status: Number(status) || 0,
      pdfResume,
      message,
    }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

  if (response.status === 400) {
    const error = new Error("You have already applied to this job");
    error.code = 400;
    throw error;
  }

  if (!response.ok)
    throw new Error(
      "Failed to submit the application, please try again later.",
    );
  return response.json();
}

export async function applicationSearch({
  jobDescriptionId,
  resumeId,
  rate,
  jdApplicationStatus,
  page,
  size,
}) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const response = await fetch(BASE_URL + SEARCH_APPLICATION_URL, {
    method: "POST",
    body: JSON.stringify({
      jobDescriptionId: Number(jobDescriptionId),
      resumeId: Number(resumeId),
      rate: Number(rate),
      jdApplicationStatus: Number(jdApplicationStatus),
      page: Number(page),
      size: Number(size),
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok)
    throw new Error("Failed to retrieve data, please try again later.");

  return response.json();
}

export async function rateApplicationAPI({ applicationId, rate }) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const URL = `${BASE_URL}${SUBMIT_APPLICATION_URL}/${Number(applicationId)}/rate`;

  const response = await fetch(URL, {
    method: "PUT",
    body: JSON.stringify({
      rate: Number(rate) || 0,
    }),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("application rating failed");
  }

  return response;
}

export async function applicationCheck(jobdescriptionId) {
  if (!navigator.onLine) throw new Error("No internet connection");

  const URL = `${BASE_URL}${APPLICATION_CHECK_URL}?jobdescriptionId=${Number(jobdescriptionId)}`;

  const response = await fetch(URL, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Application checking failed");
  }

  return response.json();
}
