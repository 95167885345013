import { PropTypes } from "prop-types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useSearchCities } from "../../hooks/useSearchCities";
import { useSearchCountries } from "../../hooks/useSearchCountries";
import Divider from "../../shared/Divider";
import Input from "../../shared/inputs/Input";
import Loader from "../../shared/loader/Loader";

function ContactsInfoStep({ hideClearSection, hideHeader }) {
  const { getValues, control, resetField, setValue, trigger } =
    useFormContext();

  // Country autocomplete
  const [openCountryList, setOpenCountryList] = useState(false);
  const [countryName, setCountryName] = useState("");

  const { data: searchedCountries, isLoading: isLoadingSearchedCountries } =
    useSearchCountries({ name: countryName });

  const countryList = useOutsideClick(() => setOpenCountryList(false));

  //City autocomplete
  const [openCityList, setOpenCityList] = useState(false);
  const [cityName, setCityName] = useState("");

  const { data: searchedCities, isLoading: isLoadingSearchedCities } =
    useSearchCities({
      countryId: getValues("contactInfo.address.countryId"),
      name: cityName,
    });

  const cityList = useOutsideClick(() => setOpenCityList(false));

  return (
    <div className="flex flex-1 flex-col">
      {!hideHeader ? (
        <div className="py-4 font-primary text-[25px] font-black leading-10 text-black-g-primary">
          2. How can we reach you ?
        </div>
      ) : null}

      <div className="mt-4 h-full flex-1">
        <div className="flex  flex-col">
          <div className="flex w-full ">
            <div
              className={`flex ${!hideClearSection ? "w-full" : "w-7/12"} flex-col`}
            >
              <div className="flex  items-center">
                <div className="font-primary text-xl font-bold text-black-g-500">
                  Contact details
                </div>
                <Divider className="ml-4 flex-1" />
              </div>

              <div className="flex w-full flex-col gap-4 py-4">
                <Input
                  label={"Your email"}
                  type="text"
                  id={"email"}
                  placeholder="Enter email"
                  controllerName={"contactInfo.email"}
                  control={control}
                  containerClassName="border-black-g-50"
                  rules={{ required: "Please enter your email address" }}
                  resetField={resetField}
                />

                <Input
                  label={"Phone number"}
                  type="number"
                  id={"phone"}
                  placeholder="Enter your phone"
                  controllerName={"contactInfo.phone"}
                  control={control}
                  rules={{ required: "Please enter your phone number" }}
                  resetField={resetField}
                />

                <Input
                  label={"Address"}
                  type="text"
                  id={"contactInfo.address.address"}
                  placeholder="Enter your address"
                  controllerName={"contactInfo.address.address"}
                  control={control}
                  resetField={resetField}
                />
                <div className="flex gap-2">
                  <div className="relative">
                    <Input
                      label={"Country"}
                      type={"hidden"}
                      control={control}
                      controllerName={"contactInfo.address.countryId"}
                      id={"countryId"}
                      resetField={resetField}
                    />

                    <Input
                      label={"Country"}
                      type={"text"}
                      control={control}
                      autoComplete={"off"}
                      controllerName={"contactInfo.address.countryName"}
                      id={"countryName"}
                      placeholder={"Search country name"}
                      rules={{
                        validate: () => {
                          return (
                            getValues("contactInfo.address.countryId") ||
                            "Please select a country"
                          );
                        },
                        onChange: (e) => {
                          setOpenCountryList(true);
                          setCountryName(e.target?.value);
                          setCityName("");
                          setValue("contactInfo.address.cityId", 0);
                          setValue("contactInfo.address.cityName", "");
                        },
                        onBlur: () => {
                          setValue(
                            "contactInfo.address.countryName",
                            searchedCountries?.[0]
                              ? searchedCountries?.[0]?.name
                              : "",
                          );
                          setValue(
                            "contactInfo.address.countryId",
                            searchedCountries?.[0]
                              ? searchedCountries?.[0]?.id
                              : "",
                          );
                          trigger("contactInfo.address.countryName");
                        },
                      }}
                      resetField={() => {
                        resetField("contactInfo.address.countryId");
                        resetField("contactInfo.address.countryName");
                      }}
                    />
                    {openCountryList && (
                      <div
                        ref={countryList}
                        className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                      >
                        {isLoadingSearchedCountries && (
                          <div className="p-4">
                            <Loader />
                          </div>
                        )}

                        {searchedCountries?.map((country) => (
                          <div
                            key={country?.id}
                            className="ingml-btn hover:bg-blue-b-50"
                            onClick={() => {
                              setValue(
                                "contactInfo.address.countryId",
                                country?.id,
                              );
                              setValue(
                                "contactInfo.address.countryName",
                                country?.name,
                              );

                              setOpenCountryList(false);
                            }}
                          >
                            {country?.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Input
                      label={"State/City"}
                      type={"hidden"}
                      control={control}
                      controllerName={"contactInfo.address.cityId"}
                      id={"cityId"}
                      resetField={resetField}
                    />

                    <Input
                      label={"State/City"}
                      type={"text"}
                      control={control}
                      controllerName={"contactInfo.address.cityName"}
                      id={"cityName"}
                      placeholder={"Search city name"}
                      rules={{
                        validate: () => {
                          return (
                            getValues("contactInfo.address.cityId") ||
                            "Please select a city"
                          );
                        },
                        onChange: (e) => {
                          setOpenCityList(true);
                          setCityName(e.target?.value);
                        },
                        onBlur: () => {
                          setValue(
                            "contactInfo.address.cityName",
                            searchedCities?.[0]
                              ? searchedCities?.[0]?.name
                              : "",
                          );
                          setValue(
                            "contactInfo.address.cityId",
                            searchedCities?.[0] ? searchedCities?.[0]?.id : "",
                          );
                          trigger("contactInfo.address.cityName");
                        },
                      }}
                      resetField={() => {
                        resetField("contactInfo.address.cityId");
                        resetField("contactInfo.address.cityName");
                      }}
                    />

                    {openCityList && (
                      <div
                        ref={cityList}
                        className="absolute left-0 top-full z-50 min-w-full rounded border border-black-g-50 bg-white shadow-lg"
                      >
                        {isLoadingSearchedCities && (
                          <div className="p-4">
                            <Loader />
                          </div>
                        )}
                        {searchedCities?.map((city) => (
                          <div
                            key={city?.id}
                            className="ingml-btn hover:bg-blue-b-50"
                            onClick={() => {
                              setValue("contactInfo.address.cityId", city?.id);
                              setValue(
                                "contactInfo.address.cityName",
                                city?.name,
                              );

                              setOpenCityList(false);
                            }}
                          >
                            {city?.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <Input
                    column={true}
                    label={"Zip code"}
                    type="text"
                    id={"contactInfo.address.postalCode"}
                    placeholder="Zip code"
                    controllerName={"contactInfo.address.postalCode"}
                    control={control}
                    mainDivClassname={"flex-1 "}
                    containerClassName="border-black-g-50"
                    containerBaseClassName="border rounded-md"
                    fieldClassName="input-transparent text-sm font-primary font-normal bg-white "
                    resetField={resetField}
                  />
                </div>
              </div>
            </div>

            {/* {!hideClearSection ? (
              <div className="ml-4 flex w-5/12 flex-col">
                <div className="flex items-center">
                  <div className="font-primary text-base font-light text-blue-b-primary">
                    Clear section
                  </div>
                  <Divider className="ml-4 flex-1" />
                </div>
                <div className="my-4 font-primary text-sm font-light text-[#4A5164]">
                  Here, you&apos;ll highlight contact details such as you Email
                  and phone number.
                </div>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

ContactsInfoStep.propTypes = {
  hideClearSection: PropTypes.bool,
  hideHeader: PropTypes.bool,
};
ContactsInfoStep.defaultProps = {
  hideClearSection: false,
  hideHeader: false,
};

export default ContactsInfoStep;
