import { Link } from "react-router-dom";
import LogoIcon from "../shared/logo/LogoIcon";
import LinkedInOAuth from "../components/authentication/providers/LinkedInOIDC";
import Divider from "../shared/Divider";
import LoginForm from "../components/authentication/LoginForm";

function Login() {
  return (
    <section>
      <div className="page-px bg-[#F7F9FB] py-16">
        <div className="m-auto max-w-xl bg-white p-8 shadow-md shadow-slate-200">
          <div className="mb-8 flex flex-col text-center">
            <LogoIcon size={18} className="mx-auto" />

            <h1 className="mt-6 text-[2rem] font-medium">Login</h1>
            <p className="text-black-g-100">Connect with people who can help</p>
          </div>

          {/*  <LinkedInOAuth>Log in with LinkedIN</LinkedInOAuth>

          <Divider className="my-6 text-lg font-medium uppercase">OR</Divider> */}

          <LoginForm />

          <div className="my-6 flex justify-end">
            <Link
              className="block text-blue-600 hover:underline"
              to={"/forgot-password"}
              state={{ source: "login" }}
            >
              Forgot password?
            </Link>
          </div>

          <div className="flex items-center justify-center space-x-2 text-lg font-medium">
            <span>Don&apos;t have an account?</span>
            <Link
              className="text-blue-b-primary hover:underline"
              to={"/signup"}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
