import React, { useState } from "react";
import PropTypes from "prop-types";
import { HiMiniChevronDown } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";

function CollapsableCardContent({
  title,
  children,
  maxVisibleChildren,
  cardWrapperClassName,
  childrenWrapperClassName,
  removeCard,
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpansion = () => setIsExpanded(!isExpanded);
  //   const containerRef = useOutsideClick(() => setIsExpanded(false), true);
  //   console.log(containerRef.current);

  const visibleChildren = React.Children.toArray(children).slice(
    0,
    isExpanded ? children.length : maxVisibleChildren,
  );

  return (
    <div
      //   ref={containerRef}
      className={`flex  flex-col rounded-[10px] border border-black-g-50 bg-[#F7F9FB] ${cardWrapperClassName} `}
    >
      <div
        className="flex w-full cursor-pointer items-center"
        onClick={toggleExpansion}
      >
        <div className="flex-1 font-primary text-base font-semibold text-black-g-primary">
          {title}
        </div>
        <MdDelete color="red" size={20} onClick={removeCard} />
      </div>
      <div className="flex flex-wrap">
        <div className={childrenWrapperClassName}>
          {!isExpanded ? visibleChildren : children}
        </div>
      </div>
      <div
        className="flex w-full items-center justify-center"
        onClick={toggleExpansion}
      >
        <HiMiniChevronDown
          className={`h-4 w-4 ${isExpanded ? "-rotate-180" : ""} origin-center transition-all duration-200`}
        />
      </div>
    </div>
  );
}

CollapsableCardContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxVisibleChildren: PropTypes.number,
  childrenWrapperClassName: PropTypes.string,
  cardWrapperClassName: PropTypes.string,
  removeCard: PropTypes.func,
};

CollapsableCardContent.defaultProps = {
  title: null,
  maxVisibleChildren: Infinity, // Set a default value indicating all children are visible by default
  childrenWrapperClassName: null,
  cardWrapperClassName: null,
  removeCard: () => {},
};

export default CollapsableCardContent;
