import PropTypes from "prop-types";

function EmojiSadIcon({ width, height, className }) {
  return (
    <svg
      height={height}
      width={width}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 29"
      fill="currentColor"
    >
      <path d="M18.1253 27.4899H10.8753C4.31408 27.4899 1.51074 24.6866 1.51074 18.1253V10.8753C1.51074 4.31408 4.31408 1.51074 10.8753 1.51074H18.1253C24.6866 1.51074 27.4899 4.31408 27.4899 10.8753V18.1253C27.4899 24.6866 24.6866 27.4899 18.1253 27.4899ZM10.8753 3.32324C5.30491 3.32324 3.32324 5.30491 3.32324 10.8753V18.1253C3.32324 23.6957 5.30491 25.6774 10.8753 25.6774H18.1253C23.6957 25.6774 25.6774 23.6957 25.6774 18.1253V10.8753C25.6774 5.30491 23.6957 3.32324 18.1253 3.32324H10.8753Z" />
      <path d="M12.8563 11.4794C12.6267 11.4794 12.3971 11.3948 12.2158 11.2136C11.3579 10.3557 9.95625 10.3557 9.09833 11.2136C8.74792 11.564 8.16792 11.564 7.8175 11.2136C7.46708 10.8632 7.46708 10.2832 7.8175 9.93273C9.37625 8.37398 11.9258 8.37398 13.4967 9.93273C13.8471 10.2832 13.8471 10.8632 13.4967 11.2136C13.3154 11.3948 13.0858 11.4794 12.8563 11.4794Z" />
      <path d="M16.1435 11.4794C15.9139 11.4794 15.6843 11.3948 15.503 11.2136C15.1526 10.8632 15.1526 10.2832 15.503 9.93273C17.0618 8.37398 19.6114 8.37398 21.1822 9.93273C21.5326 10.2832 21.5326 10.8632 21.1822 11.2136C20.8318 11.564 20.2518 11.564 19.9014 11.2136C19.0435 10.3557 17.6418 10.3557 16.7839 11.2136C16.6147 11.3948 16.3851 11.4794 16.1435 11.4794Z" />
      <path d="M18.85 22.2936H10.15C9.05042 22.2936 8.15625 21.3994 8.15625 20.2998C8.15625 16.8077 10.9958 13.9561 14.5 13.9561C18.0042 13.9561 20.8438 16.8077 20.8438 20.2998C20.8438 21.3994 19.9496 22.2936 18.85 22.2936ZM14.5 15.7686C11.9988 15.7686 9.96875 17.7986 9.96875 20.2998C9.96875 20.3965 10.0533 20.4811 10.15 20.4811H18.85C18.9467 20.4811 19.0312 20.3965 19.0312 20.2998C19.0312 17.7986 17.0013 15.7686 14.5 15.7686Z" />
    </svg>
  );
}

EmojiSadIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default EmojiSadIcon;
